import MonthlyBarChart from "components/common/MonthlyBarChart";
import { PeriodTime } from "interfaces/portfolio";
import { useFunds } from "context/FundsProvider";
import { FundsHistoricalProfits, FundsPeriodProfit } from "interfaces/fci";
import Variation from "components/common/CurrentVariation";

import { mapToDataLabels, monthMap } from "./utils";
import ArrowButtonsReturns from "./ArrowButtonsReturns";

import styles from "./styles.module.scss";

export const BAR_CHART_TIME_OPTIONS = [
  { label: PeriodTime.WEEK, value: 0 },
  { label: PeriodTime.MONTH, value: 1 },
  { label: PeriodTime.YEAR, value: 2 },
];

interface FundsChartProps {
  maxOffset: number;
  historicalProfits: FundsHistoricalProfits;
  periodProfit: FundsPeriodProfit;
  rate: number;
}

const FundsChart = ({
  historicalProfits,
  maxOffset,
  periodProfit,
  rate,
}: FundsChartProps) => {
  const { selectedPeriod, setOffset, setSelectedPeriod } = useFunds();

  const period =
    BAR_CHART_TIME_OPTIONS.find(({ label }) => label === selectedPeriod)
      ?.value ?? BAR_CHART_TIME_OPTIONS[2].value;

  const chartData = mapToDataLabels(
    historicalProfits.interval,
    historicalProfits.data
  );

  const getPeriodText = (period: number) => {
    const date = periodProfit.from.split("-");
    let periodText = "semanal";

    if (period === 1) {
      const month = monthMap.get(date[1]) ?? "mensual";

      periodText = month;
    }

    if (period === 2) {
      const currentYear = new Date().getFullYear();
      const year = Number(date[0]);
      const yearText = year === currentYear ? "del último año" : `del ${year}`;

      periodText = yearText;
    }

    return `Rendimiento ${periodText}`;
  };

  const handleOnChangeTime = (val: number) => {
    setOffset(0);
    setSelectedPeriod(BAR_CHART_TIME_OPTIONS[val].label);
  };

  return (
    <div className={styles.barChartContainer}>
      <Variation value={rate} text="TNA" withArrow={false} />
      <ArrowButtonsReturns
        periodText={getPeriodText(period)}
        periodProfit={periodProfit}
        maxOffset={maxOffset}
      />
      <MonthlyBarChart
        onChangeTime={(d) => handleOnChangeTime(d)}
        currency={periodProfit.currency}
        color="var(--blue800)"
        chartData={chartData}
        selectedTime={period}
        timeOptions={BAR_CHART_TIME_OPTIONS}
      />
    </div>
  );
};

export default FundsChart;
