import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { PeriodTime } from "interfaces/portfolio";

interface Context {
  offset: number;
  selectedPeriod: PeriodTime;
  setOffset: (off: number) => void;
  setSelectedPeriod: (period: PeriodTime) => void;
}

const FundsContext = createContext<Context | null>(null);

export const FundsProvider = ({ children }: { children: ReactNode }) => {
  const [offset, setOffset] = useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodTime>(
    PeriodTime.YEAR
  );

  const memoizedValues = useMemo(() => {
    return {
      offset,
      selectedPeriod,
      setOffset,
      setSelectedPeriod,
    };
  }, [offset, selectedPeriod, setOffset, setSelectedPeriod]);

  return (
    <FundsContext.Provider value={memoizedValues}>
      {children}
    </FundsContext.Provider>
  );
};

export default FundsProvider;

export const useFunds = () => {
  const context = useContext(FundsContext);
  if (!context) {
    throw new Error("[FundsContext] Missing context");
  }
  return context;
};
