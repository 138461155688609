import { Typography } from "@mui/material";
import { getFormattedNumber } from "utils";

interface InstrumentClosePriceProps {
  close?: number | null;
  idTickerSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentClosePrice: React.FC<InstrumentClosePriceProps> = ({
  close,
  idTickerSizeRule,
  tickSize,
}) => (
  <div className="grid-col">
    <Typography variant="textXS">
      {getFormattedNumber(close, idTickerSizeRule, tickSize)}
    </Typography>
  </div>
);

export default InstrumentClosePrice;
