import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency, getCurrencySymbol } from "utils";
import type { CashHoldings } from "interfaces/portfolio";
import { useAppState } from "context/AppStateProvider";
import HiddenValue from "components/common/HiddenValue";

import styles from "./styles.module.scss";

const ToBeSettledText = ({ settlements }: { settlements: CashHoldings[] }) => {
  const { isBalanceHidden } = useAppState();
  const getMiddleWords = (index: number, length: number): string => {
    if (index == 1 && length > 2) return ", ";

    return " y ";
  };

  return (
    <div className={styles.toBeSettled}>
      <Text
        variant={TextVariants.RegularTextS}
        color="var(--slate800)"
        className={styles.toBeSettledText}
      >
        Tenés
        <span className={styles.settledValue}>
          {isBalanceHidden ? (
            <HiddenValue color="var(--slate800)" />
          ) : (
            settlements?.map(({ ticker, toBeSettled }, index) => (
              <span key={ticker} className={styles.settledText}>
                {index > 0 && getMiddleWords(index, settlements.length)}
                {getCurrencySymbol(ticker)}
                <b className={styles.boldNumber}>
                  {formatToCurrency(toBeSettled)}
                </b>
              </span>
            ))
          )}
        </span>
        a liquidar
      </Text>
    </div>
  );
};

export default ToBeSettledText;
