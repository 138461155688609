import { Typography } from "@mui/material";

export const HeaderCif = () => {
  return (
    <div className="header">
      <Typography variant="textS"> Plazo Rescate</Typography>
      <Typography variant="textS"> Moneda </Typography>
      <Typography variant="textS"> Tipo </Typography>
      <Typography variant="textS">Rend. Mensual </Typography>
    </div>
  );
};
