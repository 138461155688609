import { useEffect, useState } from "react";

import BasicWrapper from "components/common/BasicWrapper";
import { OrderType } from "interfaces/markets";
import { ThemeVariants } from "interfaces/theme";
import { ArrowLeft, MessageCircleQuestion } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";
import SegmentedControl from "components/common/SegmentedControl";
import { MIN_TO_BUY, MIN_TO_SELL } from "components/common/mep-trade/constants";
import { NewMepSteps } from "components/page-mep/NewMep";
import { useAppSelector, useAppDispatch } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import ProfileHelp from "components/page-profile/page-help";
import PageHeader from "components/common/PageHeader";
import useKeyboardHeight, { getExtraSpace } from "hooks/useKeyboardHeight";

import PriceBox from "./PriceBox";
import Disclaimer from "./Disclaimer";
import Transaction from "./Transaction";
import OrderBottomSheet from "./OrderBottomSheet";
import ErrorMessage from "./ErrorMessage";
import Fees from "./Fees";

import styles from "./styles.module.scss";

interface NewMepPagePros {
  amount: string;
  mepPrice: number;
  isFixed: boolean;
  orderSide: OrderType;
  convertedAmount: number;
  setAmount: (value: string) => void;
  setCurrentStep: (step: NewMepSteps) => void;
  setOrderSide: React.Dispatch<React.SetStateAction<OrderType>>;
}

export enum MepErrors {
  UnderMinimum,
  InsufficientBalance,
}

const FOOTER_HEIGHT = 80;

const CreateOrder: React.FC<NewMepPagePros> = ({
  amount,
  isFixed,
  mepPrice,
  setAmount,
  orderSide,
  setOrderSide,
  setCurrentStep,
  convertedAmount,
}) => {
  const [isOrderSheetOpen, setIsOrderSheetOpen] = useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [error, setError] = useState<MepErrors>();
  const { isMobile } = useDeviceCheck();
  const dispatch = useAppDispatch();

  const pageTitle = isFixed ? "Dólar asegurado" : "Dólar estimado";
  const isBuy = orderSide === OrderType.Buy;
  const navigate = useNavigate();

  const { wallet } = useAppSelector((state) => state.wallet);

  const maxAmount = (isBuy ? wallet?.CI.ars : wallet?.CI.usd) ?? "0";
  const buttonDisabled = error !== undefined || !amount;
  const mepSides = [{ name: "Comprar" }, { name: "Vender" }];

  const keyboardHeight = useKeyboardHeight();
  const extraSpace = getExtraSpace(keyboardHeight, FOOTER_HEIGHT);
  const maxAmountNumber = Number(maxAmount);

  const badgeProps: {
    badgeText?: string;
    variant?: ThemeVariants.Capital | ThemeVariants.Crypto;
  } = isFixed
    ? {
        badgeText: "24/7",
        variant: ThemeVariants.Capital,
      }
    : {};

  const onClose = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }

    dispatch(closeRightModal());
  };

  const onClickHelp = () => {
    if (isMobile) {
      navigate(mainRoutes.help);
      return;
    }
    setIsHelpOpen(true);
  };

  const toggleSide = () => {
    setOrderSide((state) => {
      return state === OrderType.Buy ? OrderType.Sell : OrderType.Buy;
    });
  };

  const checkForErrors = (value: number) => {
    const isUnderMinimum = isBuy
      ? value < MIN_TO_BUY * mepPrice
      : value < MIN_TO_SELL;

    if (!value) {
      setError(undefined);
      return;
    }

    if (isUnderMinimum) {
      setError(MepErrors.UnderMinimum);
      return;
    }

    if (!maxAmount || value > maxAmountNumber) {
      setError(MepErrors.InsufficientBalance);
      return;
    }

    setError(undefined);
  };

  useEffect(() => {
    setAmount("");
  }, [orderSide]);

  if (isHelpOpen) {
    return (
      <>
        <PageHeader
          iconColor="var(--blue900)"
          icon={ArrowLeft}
          onClick={() => {
            setIsHelpOpen(false);
          }}
          className={styles.helpHeader}
        />
        <div className={styles.helpWrapper}>
          <ProfileHelp />
        </div>
      </>
    );
  }

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Capital}
      navigationHeaderProps={{
        title: pageTitle,
        onClick: onClose,
        rightIcon: (
          <MessageCircleQuestion color="var(--blue900)" size={px2rem(24)} />
        ),
        rightIconClick: onClickHelp,
        withCloseIcon: !isMobile,
        ...badgeProps,
      }}
      primaryButtonProps={{
        variant: ThemeVariants.Capital,
        onClick: () => setIsOrderSheetOpen(true),
        children: `Previsualizar ${isBuy ? "compra" : "venta"}`,
        disabled: buttonDisabled,
      }}
    >
      <div
        className={styles.contentWrapper}
        style={{ paddingBottom: extraSpace }}
      >
        <SegmentedControl
          options={mepSides}
          selected={isBuy ? mepSides[0].name : mepSides[1].name}
          onClick={toggleSide}
        />
        <Disclaimer isBuy={isBuy} isFixed={isFixed} />
        <Transaction
          isBuy={isBuy}
          amount={amount}
          setAmount={setAmount}
          maxAmount={maxAmountNumber}
          checkForErrors={checkForErrors}
          convertedAmount={convertedAmount}
        />
        <ErrorMessage error={error} isBuy={isBuy} />
        <PriceBox isBuy={isBuy} isFixed={isFixed} mepPrice={mepPrice} />
        <Fees isFixed={isFixed} />
      </div>
      <OrderBottomSheet
        isBuy={isBuy}
        amount={amount}
        isFixed={isFixed}
        mepPrice={mepPrice}
        orderType={orderSide}
        isOpen={isOrderSheetOpen}
        setCurrentStep={setCurrentStep}
        convertedAmount={convertedAmount}
        onClose={() => setIsOrderSheetOpen(false)}
      />
    </BasicWrapper>
  );
};

export default CreateOrder;
