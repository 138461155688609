import IconBadge from "components/common/Badges/IconBadge";
import Text, { TextVariants } from "components/common/Text";
import { Copy } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CopyFieldProps {
  label: string;
  text: string;
  onClick: () => void;
}

const CopyField = ({ text, label, onClick }: CopyFieldProps) => {
  return (
    <div key={label} className={styles.copyFieldContainer}>
      <div className={styles.fieldTextContainer}>
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {label}
        </Text>
        <Text variant={TextVariants.RegularText} color="var(--slate900)">
          {text}
        </Text>
      </div>
      <div onClick={onClick} data-testid="copy-button">
        <IconBadge backgroundColor="var(--blue300)" isSmall>
          <Copy size={px2rem(16)} color="var(--slate900)" />
        </IconBadge>
      </div>
    </div>
  );
};

export default CopyField;
