export const intervalsOptions = ["3M", "1A", "3A"];

export const caucionMin = 10000;

export const closedMepTyC = `La operatoria de compra y venta de dólar MEP implican la compra de títulos en pesos o en dólares, y su posterior venta en pesos o en dólares, según corresponda. 

El precio de compra y de venta de dólar MEP expresado al momento de cursar la correspondiente orden de compra o venta de dólar MEP será un precio fijo, el que no dependerá de la variación de la cotización de los títulos que sean operados luego de cursada la orden de compra o venta de dólar MEP. Sin perjuicio de ello, el precio final de compra o venta de dólar MEP puede sufrir variaciones mínimas, en función entre otras cuestiones, de los derechos de mercado que resulten aplicables a las operaciones de títulos que efectivamente se realicen para concretar la compra o venta de dólar MEP. 

El importe final por el que se operará la compra o venta de dólar MEP dependerá de la cantidad de títulos, la especie, y su precio de mercado al momento de cursar la orden de compra o venta de dólar MEP que sean efectivamente operados para concretar la compra o venta de dólar MEP. 

Una vez solicitada la compra o venta de dólar MEP, las órdenes de compra y de venta de los títulos que correspondan se enviarán automáticamente al mercado, sin necesidad de intervención adicional por parte del cliente. Cocos no será responsable por pérdidas que pudiera sufrir el cliente como resultado de una interferencia por parte del mismo cliente sobre las operaciones de títulos que automáticamente se hayan enviado al mercado.`;
export const openMepTyC =
  "La operatoria de compra y venta de dólar MEP implican la compra de títulos en pesos o en dólares, y su posterior venta en pesos o en dólares, según corresponda. El precio de compra y de venta de dólar MEP expresado al momento de cursar la correspondiente orden de compra o venta de dólar MEP será un precio estimativo, en función del precio de los títulos al momento de cursar la operación, y dependerá de la variación en la cotización de los títulos que sean operados luego de cursada la orden de compra o venta de dólar MEP.";

export const conservativeInvestorProfile = [
  "Renta Fija de Corto Plazo",
  "Renta Fija de Largo Plazo",
  "Renta Variable",
  "Fondo Comunes de Inversión",
  "Letras",
  "Cedines",
];

export const moderateInvestorProfile = [
  "Renta Fija de Corto Plazo",
  "Renta Fija de Largo Plazo",
  "Renta Variable",
  "Fondo Comunes de Inversión",
  "Letras",
  "Cedines",
  "Operaciones en el exterior",
];

export const aggressiveInvestorProfile = [
  "Renta Fija de Corto Plazo",
  "Renta Fija de Largo Plazo",
  "Renta Variable",
  "Fondo Comunes de Inversión",
  "Derivados",
  "Letras",
  "Cedines",
  "Operaciones en el exterior",
];
