import React, { ReactNode, useEffect } from "react";

import { Dialog, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { IoAlertCircleOutline } from "react-icons/io5";

import ButtonUI, { ButtonSizes, ButtonVariants } from "../button";

import "./index.scss";

interface Props {
  open: boolean;
  headingText: ReactNode | string;
  bodyText?: string;
  primaryButtonText: string;
  onClickMainButton: () => void;
  secondaryButtonText?: string;
  onClickSecondButton?: () => void;
  classes?: string;
  isFirstButtonDisabled?: boolean;
  bodyIsNotString?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide ref={ref} direction="up" {...props} />;
});

const UIModalInfo = ({
  open,
  headingText,
  bodyText,
  bodyIsNotString,
  primaryButtonText,
  onClickMainButton,
  secondaryButtonText,
  onClickSecondButton,
  classes,
  isFirstButtonDisabled = false,
}: Props) => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Dialog
      keepMounted
      TransitionComponent={Transition}
      className={`dialog-modal ${classes ? classes : ""}`}
      open={open}
    >
      <div className="component-alert-modal">
        <div className="alert-modal">
          <IoAlertCircleOutline color="#0062e1" size="50px" />
          <Typography component="p" variant="heading4_bold">
            {headingText}
          </Typography>
          {bodyIsNotString && <div className="modal-body-text">{bodyText}</div>}
          {!bodyIsNotString && (
            <Typography className="subtitle" component="p" variant="textL">
              {bodyText}
            </Typography>
          )}
        </div>
        <div className="buttons-wrapper">
          <ButtonUI
            disabled={isFirstButtonDisabled}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Primary}
            onClick={onClickMainButton}
          >
            <Typography variant="buttonL">{primaryButtonText}</Typography>
          </ButtonUI>
          {onClickSecondButton && (
            <ButtonUI
              size={ButtonSizes.Full}
              variant={ButtonVariants.Secondary}
              onClick={onClickSecondButton}
            >
              <Typography variant="buttonL">{secondaryButtonText}</Typography>
            </ButtonUI>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default UIModalInfo;
