import { ampli } from "ampli";
import { ArrowUpRight } from "lucide-react";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import { CardTypesLabel } from "interfaces/card/enums";
import Link from "components/common/Link";
import mainRoutes from "router/routes";
import { CardTransactionsGroup as TransactionGroup } from "interfaces/card/interface";
import { useCocosCard } from "context/CocosCardProvider";
import Lens from "assets/illustrations/Lens";
import Text, { TextVariants } from "components/common/Text";
import EmptyState from "components/common/EmptyState";

import TransactionsList from "./List";
import Currency, { CurrencySizes } from "./List/Row/Currency";
import { getTransactionGroupName } from "../utils";

import styles from "./styles.module.scss";

const CardTransactionsGroup: React.FC<{ isHome?: boolean }> = ({
  isHome = false,
}) => {
  const navigate = useNavigate();
  const { cardTransactions, selectedCard } = useCocosCard();

  const cardType =
    selectedCard === CardTypesLabel.PHYSICAL ? "física" : "virtual";

  const goToMovements = () => {
    ampli.cardMovements({ card_type: selectedCard });
    navigate(mainRoutes.cardTransactions);
  };

  if (!cardTransactions || isEmpty(cardTransactions?.groups)) {
    return (
      <div
        className={classNames({
          [styles.homeEmptyState]: isHome,
        })}
      >
        <EmptyState
          illustration={<Lens size={64} />}
          title="No tenés consumos aún"
          subtitle={`Acá vas a encontrar todas las compras que hagas con tu tarjeta ${cardType}.`}
        />
      </div>
    );
  }

  return (
    <>
      {cardTransactions.groups.map((transactionGroup: TransactionGroup) => {
        const { timestamp, total, transactions } = transactionGroup;

        return (
          <div
            className={styles.cardTransactionsGroupContainer}
            key={timestamp}
          >
            <div className={styles.listHeader}>
              <Text
                variant={TextVariants.RegularText}
                color="var(--slate800)"
                className={styles.month}
              >
                {getTransactionGroupName(timestamp)}
              </Text>
              <Text
                variant={TextVariants.RegularValueS}
                color="var(--slate800)"
                className={styles.consumption}
              >
                Total del mes
                <Currency amount={Math.abs(total)} size={CurrencySizes.SMALL} />
              </Text>
            </div>
            <TransactionsList transactions={transactions} />
          </div>
        );
      })}
      {isHome ? (
        <div className={styles.textFooter}>
          <Link
            variant={ThemeVariants.Pay}
            icon={ArrowUpRight}
            onClick={goToMovements}
            isSmall
          >
            Ver más movimientos
          </Link>
        </div>
      ) : (
        <Text
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
          className={styles.textFooter}
        >
          No hay más movimientos.
        </Text>
      )}
    </>
  );
};

export default CardTransactionsGroup;
