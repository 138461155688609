import { Cryptos } from "constants/cryptos";

import { usePricesQuery } from "apis/crypto/queries/usePrices";
import DAIIcon from "assets/icons/DAI";
import USDCIcon from "assets/icons/USDC";
import USDTIcon from "assets/icons/USDT";
import Heading, { HeadingVariants } from "components/common/Heading";
import LoadingSpinner from "components/common/LoadingSpinner";
import SingleQuoteCard from "components/common/SingleQuoteCard";
import { CryptoPrice } from "interfaces/api-responses";
import { ThemeVariants } from "interfaces/theme";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";

import styles from "./styles.module.scss";

interface DolarCryptoProps {
  pricesData?: CryptoPrice[];
}

const DolarCrypto: React.FC<DolarCryptoProps> = ({ pricesData }) => {
  const navigate = useNavigate();
  const { isLoading } = usePricesQuery();

  if (isLoading) return <LoadingSpinner variant={ThemeVariants.Crypto} />;

  if (!pricesData) return null;

  const stableCoins = [Cryptos.DAI, Cryptos.USDT, Cryptos.USDC];

  const dolarCrypto = pricesData.find((crypto) =>
    stableCoins.includes(crypto.baseTicker)
  );

  return (
    <div className={styles.dolarCryptoContainer}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h3"
        color="var(--slate800)"
      >
        Operá 24/7
      </Heading>
      <SingleQuoteCard
        icons={[USDTIcon, USDCIcon, DAIIcon]}
        name="Dólar Crypto"
        bid={dolarCrypto?.ask ?? 0}
        ask={dolarCrypto?.bid ?? 0}
        variation={dolarCrypto?.varDailyPricePercentage ?? 0}
        onClick={() =>
          navigate(CryptoRoutes.SWAP, {
            state: {
              isBuying: true,
              defaultTicker: dolarCrypto?.baseTicker ?? Cryptos.DAI,
            },
          })
        }
      />
    </div>
  );
};

export default DolarCrypto;
