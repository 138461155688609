import { cryptoStatus } from "constants/cryptos";

import { useEffect } from "react";

import API from "apis";
import { cryptoService } from "apis/services";
import { updateTag } from "features/user/userSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { CryptoCustomer } from "interfaces/api-responses";
import { CocosProducts } from "interfaces/user";
import { Navigate, useLocation } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { RootState } from "store/store";

interface CryptoRouteProps {
  children: React.ReactNode;
}

const CryptoRoute: React.FC<CryptoRouteProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { user, has2FA } = useAppSelector((state: RootState) => state.user);
  const needs2FA = ![CryptoRoutes.TWOFA, CryptoRoutes.TYC].includes(
    location.pathname as CryptoRoutes
  );
  const notAbleForCrypto =
    !user?.enabled_products.includes(CocosProducts.CRYPTO) ||
    (user.status_crypto !== cryptoStatus.APPROVED &&
      location.pathname !== CryptoRoutes.TYC);

  const gotTag = user && Object.prototype.hasOwnProperty.call(user, "tag");

  useEffect(() => {
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  useEffect(() => {
    const getTag = async () => {
      const { data } = await API.get<CryptoCustomer>(cryptoService.customer);

      if (data) {
        dispatch(updateTag(data.tag));
      }
    };

    if (!gotTag) {
      getTag();
    }
  }, []);

  const { isMobile } = useDeviceCheck();

  if (notAbleForCrypto || !isMobile)
    return <Navigate replace to={mainRoutes.home} />;

  if (!has2FA && needs2FA) return <Navigate replace to={CryptoRoutes.TWOFA} />;

  return <>{children}</>;
};

export default CryptoRoute;
