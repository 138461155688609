import { useState } from "react";

import { Clipboard } from "@capacitor/clipboard";
import { ampli } from "ampli";
import Banner, { BannerVariants } from "components/common/Banner";
import BulletList, { BulletListVariants } from "components/common/BulletList";
import Heading, { HeadingVariants } from "components/common/Heading";
import { UIModalSlider } from "components/common/modal-slider";
import Toast, { ToastVariants } from "components/common/Toast";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { BadgePercent, Banknote, Clock4 } from "lucide-react";
import { getLoggedUser } from "store/selectors/user.selector";
import { updateCableNotification } from "features/user/userActions";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import CopyField from "./CopyField";
import CableDollarModalSlider from "./CableDollarModalSlider";

import styles from "./styles.module.scss";

const ReceiveCableDollar = () => {
  const dispatch = useAppDispatch();
  const { isMobile, isDesktopOrTablet } = useDeviceCheck();
  const [isCopied, setIsCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const user = useAppSelector(getLoggedUser);
  const isNotificationAccepted = useAppSelector(getLoggedUser)
    ?.cable_notification;

  const bulletList = [
    { icon: Banknote, text: "Costo fijo por transacción de 10 USD." },
    { icon: BadgePercent, text: "Sin comisiones." },
    { icon: Clock4, text: "Normalmente, demora 2 días hábiles." },
  ];

  const bankingData = [
    {
      label: "Bank name",
      text: "JPMorgan Chase Bank NA.",
      item: "bank_name",
    },
    {
      label: "Routing number",
      text: "021000021",
      item: "routing_number",
    },
    {
      label: "SWIFT",
      text: "CHASUS33",
      item: "swift",
    },
    {
      label: "Account name",
      text: "Cocos Capital S.A.",
      item: "bank_account_number",
    },
    {
      label: "Account number",
      text: "912868897",
      item: "comitente",
    },
  ];

  const handleCopyClick = async (textToCopy: string, item: string) => {
    try {
      await Clipboard.write({ string: textToCopy });
      ampli.receiveCclInformationCopied({
        item,
      });
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const handleContinueClick = async () => {
    ampli.receiveCclBottomsheetContinued();
    const newUserData = { ...user, cable_notification: true };
    dispatch(updateCableNotification(newUserData));
  };

  if (isDesktopOrTablet && !isNotificationAccepted)
    return (
      <CableDollarModalSlider
        className={styles.desktopWrapper}
        handleContinueClick={handleContinueClick}
      />
    );

  return (
    <div className={styles.cableDollarTab}>
      {!isNotificationAccepted && isMobile && (
        <div className={styles.modalWrapper}>
          <UIModalSlider
            open={isOpen}
            onToggleDisplay={() => setIsOpen(false)}
            classes={styles.sheetWrapper}
          >
            <CableDollarModalSlider handleContinueClick={handleContinueClick} />
          </UIModalSlider>
        </div>
      )}
      <Banner
        variant={BannerVariants.INFO_CAPITAL}
        text="Sólo aceptamos transferencias Wire en dólares."
        className={styles.infoBanner}
      />
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
      >
        Datos para transferir
      </Heading>
      <div className={styles.copyFieldDataContainer}>
        {bankingData.map(({ label, text, item }) => {
          return (
            <CopyField
              key={label}
              label={label}
              text={text}
              onClick={() => handleCopyClick(text, item)}
            />
          );
        })}
      </div>
      <BulletList
        variant={BulletListVariants.Capital}
        bullets={bulletList}
        className={styles.cableDollarBullets}
      />
      {isCopied && (
        <Toast
          variant={ToastVariants.Success}
          message="Copiado exitosamente"
          onClose={() => setIsCopied(false)}
        />
      )}
    </div>
  );
};

export default ReceiveCableDollar;
