import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GlobalState {
  currentScreen: string;
  acceptedCryptoTyc: boolean;
}

export const initialState: GlobalState = {
  currentScreen: "",
  acceptedCryptoTyc: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setCurrentScreen(state, action: PayloadAction<string>) {
      state.currentScreen = action.payload;
    },
    acceptCryptoTyc(state) {
      state.acceptedCryptoTyc = true;
    },
  },
  selectors: {
    getCurrentScreen(state) {
      return state.currentScreen;
    },
    getCryptoTycStatus(state) {
      return state.acceptedCryptoTyc;
    },
  },
});

export const { setCurrentScreen, acceptCryptoTyc } = globalSlice.actions;

export const { getCurrentScreen, getCryptoTycStatus } = globalSlice.selectors;

export default globalSlice.reducer;
