import { useEffect, useState } from "react";

import { ampli } from "ampli";
import API from "apis";
import { cardService } from "apis/services";
import AssetSelectCard from "components/common/AssetSelectCard";
import Banner, { BannerVariants } from "components/common/Banner";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { UIModalSlider } from "components/common/modal-slider";
import { MIN_USD_TO_PAY } from "components/page-cocos-pay/utils";
import { useCocosCard } from "context/CocosCardProvider";
import { CardTypesLabel } from "interfaces/card/enums";
import {
  CURRENCIES,
  FCIS,
  PaymentMethodProps,
} from "interfaces/card/interface";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { ArrowLeft, Trash2 } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

type PaymentMethodBottomSheetProps = {
  open: boolean;
  onClose: () => void;
  positionSelected: number;
};

type PaymentMethodSelected = PaymentCurrencies | null;

const PaymentMethodBottomSheet: React.FC<PaymentMethodBottomSheetProps> = ({
  open,
  onClose,
  positionSelected,
}) => {
  const {
    cardPaymentMethods,
    selectedCard,
    physicalCard,
    virtualCard,
    setPomeloData,
    pomeloData,
    setIsLoading,
    setDisplayToast,
  } = useCocosCard();

  const paymentMethodArray = cardPaymentMethods?.map(
    (paymentMethod: any) => paymentMethod.paymentMethod
  );

  const positionIndex = positionSelected - 1;

  const [
    paymentMethodSelected,
    setPaymentMethodSelected,
  ] = useState<PaymentMethodSelected>(paymentMethodArray[positionIndex]);

  useEffect(() => {
    ampli.cardChangePaymentMethod({
      card_type: selectedCard,
      method_changed: positionSelected,
    });
    setPaymentMethodSelected(paymentMethodArray[positionIndex]);
  }, [positionSelected]);

  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;
  const idCard = isPhysicalCard ? physicalCard?.idCard : virtualCard?.idCard;

  const updatePaymentMethod = async (ticker: PaymentMethodSelected) => {
    setIsLoading(true);
    let result;

    if (ticker === null) {
      result = paymentMethodArray.filter(
        (_: any, index: number) => index !== positionIndex
      );
    } else {
      paymentMethodArray[positionIndex] = ticker;
      result = paymentMethodArray;
      ampli.cardPaymentMethodChanged({
        card_type: selectedCard,
        method_changed: positionSelected,
        method: ticker,
      });
    }

    if (result.includes(undefined)) {
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await API.patch(cardService.paymentMethod, {
        idCard,
        paymentMethodProfile: result,
      });

      if (!pomeloData) return;

      const cardToUpdate = pomeloData.cards.find(
        (card) => card.idCard === idCard
      );

      if (cardToUpdate) {
        setPomeloData({
          ...pomeloData,
          cards: pomeloData.cards.map((card) =>
            card.idCard === idCard ? data : card
          ),
        });
      }

      setDisplayToast({
        visible: true,
        message: "¡Listo! Actualizaste tus métodos de pago.",
      });
    } catch (error: any) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setDisplayToast({
          visible: false,
          message: "",
        });
      }, 2500);
    }
  };

  const getPositionText = () => {
    const positionsText = ["primer", "segundo", "tercer"];
    return positionsText[positionSelected - 1];
  };

  const onClickAsset = (ticker: PaymentCurrencies) => {
    if (paymentMethodSelected === ticker) {
      onRemove();
      return;
    }

    setPaymentMethodSelected(ticker);
    updatePaymentMethod(ticker);
    onClose();
  };

  const onRemove = () => {
    updatePaymentMethod(null);
    onClose();
  };

  const getIsDisabled = (ticker: PaymentCurrencies) =>
    paymentMethodArray.includes(ticker) &&
    paymentMethodSelected !== ticker &&
    paymentMethodArray[positionIndex] !== ticker;

  const getAssetsList = (title: string, assetList: PaymentMethodProps[]) => (
    <>
      <Text variant={TextVariants.RegularText} className={styles.subtitle}>
        {title}
      </Text>
      <div className={styles.assetWrapper}>
        {assetList.map(({ ticker, name, icon }) => (
          <AssetSelectCard
            icon={icon}
            ticker={ticker}
            name={name}
            key={ticker}
            onClick={() => onClickAsset(ticker)}
            checked={paymentMethodSelected === ticker}
            disabled={getIsDisabled(ticker)}
          />
        ))}
      </div>
    </>
  );

  return (
    <UIModalSlider
      open={open}
      onToggleDisplay={onClose}
      classes={styles.sheetWrapper}
    >
      <div className={styles.header}>
        <ArrowLeft
          size={px2rem(24)}
          color="var(--slate900)"
          onClick={onClose}
        />
        <Text variant={TextVariants.RegularText} className={styles.headerTitle}>
          ¿Cómo vas a pagar?
        </Text>
        {paymentMethodSelected ? (
          <Trash2
            size={px2rem(24)}
            color="var(--slate900)"
            onClick={onRemove}
          />
        ) : (
          <span style={{ width: px2rem(24) }} />
        )}
      </div>
      <Heading
        variant={HeadingVariants.RegularTitle}
        component="h2"
        className={styles.title}
      >
        Elegí tu {getPositionText()} método de pago
      </Heading>
      <Banner
        variant={BannerVariants.INFO_PAY}
        text={`El monto mínimo para pagar con Dólar MEP o Cocos Ahorro Dólares es de US$ ${MIN_USD_TO_PAY}.`}
        className={styles.bannerInfo}
      />
      {getAssetsList("Moneda", CURRENCIES)}
      {getAssetsList("Fondos de inversión", FCIS)}
    </UIModalSlider>
  );
};

export default PaymentMethodBottomSheet;
