import { Instruments, MarketSection } from "interfaces/markets";

import ListHeader from "./ListHeader";
import FCIHeader from "./FCIHeader";

interface HeaderProps {
  selectedInstrumentType: MarketSection;
}

const Header: React.FC<HeaderProps> = ({ selectedInstrumentType }) => {
  if (selectedInstrumentType !== Instruments.FCI)
    return <ListHeader selectedInstrumentType={selectedInstrumentType} />;

  return <FCIHeader />;
};

export default Header;
