import Accordion from "components/common/Accordion";
import PageHeader from "components/common/PageHeader";
import Text, { TextVariants } from "components/common/Text";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

import styles from "./styles.module.scss";

const FaqsSection: React.FC = () => {
  const navigate = useNavigate();
  const FAQS = [
    {
      label: "Fondeo de cuenta",
      detail:
        "No es necesario “fondear” la cuenta de Crypto. Cuando vayas a la pantalla de compra vas a ver los pesos disponibles en tu cuenta comitente de Cocos",
    },
    {
      label: "Redes disponibles",
      detail:
        "Recordá que para:\n\t- ETH, la red disponible es Ethereum.\n\t- Bitcoin, la red disponible es Bitcoin.\n\t- Para USDC, USDT, LINK, MATIC, y DAI, tendrás dos redes disponibles a elegir: Ethereum o Polygon.\n\nTe sugerimos especial atención a la hora de seleccionar las redes para el envío.",
    },
    {
      label: "Límites de envío",
      detail:
        "Para ampliar tu límite de envío Crypto, solo tenes que escribirnos a nuestro mail atencion@cocoscrypto.com, donde te vamos a compartir toda la información necesaria.",
    },
    {
      label: "Otras preguntas",
      detail: (
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Ingresá a este{" "}
          <Text
            variant={TextVariants.SemiboldTextS}
            color="var(--purple800)"
            href="https://cocoscrypto.zendesk.com/hc/es-419"
            component="a"
          >
            link
          </Text>{" "}
          para más información
        </Text>
      ),
    },
  ];
  return (
    <div className={styles.faqsSectionContainer}>
      <PageHeader
        icon={ArrowLeft}
        title="Preguntas frecuentes"
        onClick={() => navigate(-1)}
      />
      <Accordion title="Preguntas frecuentes" options={FAQS} />
    </div>
  );
};

export default FaqsSection;
