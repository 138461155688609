import React from "react";

import { motion } from "framer-motion";
import { InstrumentData } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  resetMarketDataDetail,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets/marketsSlice";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getSettlementDays } from "utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { getAnnualRate } from "utils/getAnnualRate";

import InstrumentLogo from "../common/InstrumentLogo";
import InstrumentTicker from "../common/InstrumentTicker";
import InstrumentAsk from "./InstrumentAsk";
import InstrumentAskVolume from "./InstrumentAskVolume";
import InstrumentBid from "./InstrumentBid";
import InstrumentBidVolume from "./InstrumentBidVolume";
import InstrumentClosePrice from "./InstrumentClosePrice";
import InstrumentHigh from "./InstrumentHigh";
import InstrumentLastPrice from "./InstrumentLastPrice";
import InstrumentLow from "./InstrumentMin";
import InstrumentTurnover from "./InstrumentTurnover";
import InstrumentVariation from "./InstrumentVariation";
import InstrumentVolume from "./InstrumentVolume";

import "./index.scss";

interface MarketRowProps {
  market: InstrumentData;
  onClickRow: (market: InstrumentData) => void;
  onClickMarketOptions: (market: InstrumentData) => void;
}

const MarketRow: React.FC<MarketRowProps> = ({
  market,
  onClickRow,
  onClickMarketOptions,
}) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const handleClickRow = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (
      market.instrument_code &&
      !order.instrument_code?.includes(market.instrument_code)
    ) {
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
      dispatch(clearSellingPower());
      dispatch(cleanHistoricalData());
      dispatch(
        resetOrder({
          instrument_code: market.instrument_code,
          price: market.last,
          long_ticker: market.long_ticker,
          instrument_short_name: market.instrument_short_name,
          term: getSettlementDays(market.settlement_days),
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
        })
      );
    } else {
      dispatch(
        updateOrderOptions({
          ...order,
          instrument_code: market.instrument_code,
          price: market.last,
          long_ticker: market.long_ticker,
          instrument_short_name: market.instrument_short_name,
          term: getSettlementDays(market.settlement_days),
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
        })
      );
    }
    dispatch(setMarketDataDetail(market));

    onClickRow(market);
  };

  return (
    <motion.div
      className="comp-desktop-market-row wide"
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      onClick={handleClickRow}
      id={`${market.instrument_short_name}-market-row`}
    >
      <InstrumentLogo
        logoFileName={market.logo_file_name}
        instrumentType={market.instrument_type}
      />
      <InstrumentTicker
        longTicker={market.long_ticker}
        instrumentShortName={market.instrument_short_name ?? ""}
      />
      <InstrumentLastPrice
        last={market.last ?? null}
        close={market.close}
        idTickSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentVariation
        variation={getAnnualRate(market.short_ticker, market.variation)}
      />
      <InstrumentBidVolume volume={market.bids && market.bids[0].size} />
      <InstrumentBid
        bid={market.bid}
        idTickerSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentAsk
        ask={market.ask}
        idTickSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentAskVolume volume={market.asks && market.asks[0].size} />
      <InstrumentClosePrice
        close={market.close}
        idTickerSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentLow
        low={market.low}
        idTickSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentHigh
        high={market.high}
        idTickSizeRule={market.id_tick_size_rule}
        tickSize={market.tick_size}
      />
      <InstrumentTurnover turnover={market.turnover} />
      <InstrumentVolume
        onClickMarketOptions={onClickMarketOptions}
        volume={market.volume}
        market={market}
      />
    </motion.div>
  );
};

export default MarketRow;
