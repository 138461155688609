import { Capacitor } from "@capacitor/core";
import CircularButton, {
  CircularButtonVariants,
} from "components/common/CircularButton";
import { useAppSelector } from "hooks/redux";
import { CocosProducts } from "interfaces/user";
import { Plus, QrCode, RefreshCw, Send } from "lucide-react";
import { useNavigate } from "react-router";
import { Navigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const ActionsWrapper: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { enabled_products, hasAccount } = user;

  const isNative = Capacitor.isNativePlatform();

  const isPaymentEnabled =
    isNative && enabled_products.includes(CocosProducts.PAY) && hasAccount;

  const actionsList = [
    {
      label: "Comprar",
      icon: Plus,
      route: CryptoRoutes.SWAP,
    },
    {
      label: "Convertir",
      icon: RefreshCw,
      route: CryptoRoutes.SWAP,
    },
    {
      label: "Pagar",
      icon: QrCode,
      route: mainRoutes.pay,
      disabled: !isPaymentEnabled,
    },
    {
      label: "Enviar",
      icon: Send,
      route: CryptoRoutes.SEND,
    },
  ];
  return (
    <div className={styles.actionsWrapperContainer}>
      {actionsList.map(({ label, icon: Icon, route, disabled }) => (
        <CircularButton
          key={label}
          variant={CircularButtonVariants.Crypto}
          label={label}
          disabled={disabled}
          onClick={() =>
            navigate(route, {
              state: {
                isBuying: label === "Comprar" || undefined,
              },
            })
          }
        >
          <Icon size={px2rem(20)} color="var(--white-to-black" />
        </CircularButton>
      ))}
    </div>
  );
};

export default ActionsWrapper;
