import * as React from "react";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import styles from "./styles.module.scss";

interface Option {
  label: string;
  value: string;
}

interface UIToggleProps {
  selectedValue: string;
  onChangeValue: (value: any) => void;
  options: Option[];
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  label?: string;
}

export const UIToggle: React.FC<UIToggleProps> = ({
  disabled = false,
  selectedValue,
  options,
  onChangeValue,
  className,
  onClick,
  label,
}) => {
  const handleChangeValue = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    event.stopPropagation();
    if (value && value !== selectedValue) {
      onChangeValue(value);
    }
  };

  return (
    <div className={styles.divContainer}>
      {label && <Typography className={styles.label}>{label}</Typography>}
      <ToggleButtonGroup
        exclusive
        aria-label="text alignment"
        id={styles.uiToggle}
        className={`${disabled && "disabled"} ${className || ""}`}
        disabled={disabled}
        value={selectedValue}
        onChange={handleChangeValue}
        onClick={onClick}
      >
        {options.map((option: Option) => {
          const { value, label } = option;
          return (
            <ToggleButton
              key={value}
              aria-label={label}
              value={value}
              onClick={onClick}
              id={label}
            >
              <Typography>{label}</Typography>
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};
