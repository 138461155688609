import { Typography } from "@mui/material";
import { InfoIcon } from "assets/icons/info";
import { OrderBook } from "components/common/order-book";
import PriceVariationDisplayer from "components/common/variation-price-display";
import UITooltip from "components/common/tooltip";
import { compactNumber, getCurrencyLabel } from "utils";
import { getColor } from "utils/getColor";

import styles from "./styles.module.scss";

interface TradingCardHiddenProps {
  instrumentProp: any;
  numOfDecimals: number;
}

const TradingCardHidden: React.FC<TradingCardHiddenProps> = ({
  instrumentProp,
  numOfDecimals,
}) => {
  return (
    <div className={styles.bookBox}>
      <div className={styles.bookTopRow}>
        <Typography className={styles.bookInfo}>
          <span style={{ display: "inline-block" }}>Volumen:&nbsp;</span>
          <span style={{ whiteSpace: "nowrap" }}>
            {instrumentProp.turnover
              ? `${getCurrencyLabel(instrumentProp.currency)} ${compactNumber(
                  instrumentProp.turnover
                )}`
              : "-"}
          </span>
        </Typography>
        <div className={styles.dailyRange}>
          <div className="tooltip">
            <UITooltip
              icon={
                <InfoIcon color={getColor("--dark-blue-to-olb")} size={14} />
              }
              title={"Rango de precio diario"}
              color={
                instrumentProp.last < instrumentProp.open ? "roja" : "verde"
              }
            />
          </div>
          <PriceVariationDisplayer
            open={instrumentProp.open}
            high={instrumentProp.high}
            last={instrumentProp.last}
            low={instrumentProp.low}
          />
        </div>
      </div>
      <OrderBook
        bids={instrumentProp.bids}
        asks={instrumentProp.asks}
        numOfDecimals={numOfDecimals}
      />
    </div>
  );
};

export default TradingCardHidden;
