import { useState, useEffect } from "react";

import { usePrevious } from "hooks/usePrevious";
import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";

interface InstrumentTurnoverProps {
  turnover?: number | null;
}

const InstrumentTurnover: React.FC<InstrumentTurnoverProps> = ({
  turnover,
}) => {
  const [turnoverClass, setTurnoverClass] = useState<string>("grid-col");
  const previousLow = usePrevious(turnover);

  useEffect(() => {
    if (previousLow !== turnover) {
      setTurnoverClass("grid-col change");
      setTimeout(() => {
        setTurnoverClass("grid-col");
      }, 400);
    }
  }, [turnover]);
  return (
    <div className={turnoverClass}>
      <Typography variant="textXS">
        <Currency withoutDecimals>{turnover || "-"}</Currency>
      </Typography>
    </div>
  );
};

export default InstrumentTurnover;
