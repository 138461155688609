import { ThemeVariants } from "interfaces/theme";

import { IllustrationProps } from "../FirstInvestment";

const FirstDeposit: React.FC<IllustrationProps> = ({
  size,
  variant = ThemeVariants.Capital,
  ...props
}) => {
  const isCapital = variant === ThemeVariants.Capital;

  const color700 = isCapital ? "#0588F0" : "#8347B9";
  const color900 = isCapital ? "#113264" : "#402060";
  const color800Dark = isCapital ? "#70B8FF" : "#D19DFF";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 124 68"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_10748_21637)">
        <path
          d="M120.19 7.4624H74.7301C72.6872 7.4624 71.0311 9.11848 71.0311 11.1614V56.6217C71.0311 58.6645 72.6872 60.3206 74.7301 60.3206H120.19C122.233 60.3206 123.889 58.6645 123.889 56.6217V11.1614C123.889 9.11848 122.233 7.4624 120.19 7.4624Z"
          fill="url(#paint0_linear_10748_21637)"
        />
        <path
          d="M25.5543 7.4624H71.0312V60.3206H25.5543C24.5733 60.3206 23.6324 59.9309 22.9387 59.2372C22.2451 58.5435 21.8554 57.6027 21.8554 56.6217V11.1669C21.8546 10.6807 21.9498 10.1991 22.1353 9.74965C22.3209 9.30024 22.5932 8.89182 22.9368 8.54775C23.2803 8.20369 23.6884 7.93073 24.1375 7.7445C24.5866 7.55826 25.0681 7.4624 25.5543 7.4624Z"
          fill="url(#paint1_linear_10748_21637)"
        />
        <path
          d="M106.34 0H75.4828C73.0242 0 71.0311 1.99306 71.0311 4.45163V63.5484C71.0311 66.0069 73.0242 68 75.4828 68H106.34C108.799 68 110.792 66.0069 110.792 63.5484V4.45163C110.792 1.99306 108.799 0 106.34 0Z"
          fill={color700}
        />
        <path
          d="M110.792 7.4624H71.0311V60.3206H110.792V7.4624Z"
          fill={color900}
        />
        <path
          d="M110.792 14.1702C111.009 14.1704 111.223 14.2135 111.423 14.297C111.623 14.3805 111.805 14.5028 111.958 14.6567C112.11 14.8106 112.231 14.9932 112.312 15.194C112.394 15.3947 112.435 15.6097 112.434 15.8264V22.6649C112.434 23.1007 112.261 23.5187 111.952 23.8269C111.644 24.1351 111.226 24.3082 110.79 24.3082V14.1702H110.792Z"
          fill={color700}
        />
        <path
          d="M86.8023 4.09277H95.5381"
          stroke="#1C2024"
          strokeWidth="0.640416"
          strokeMiterlimit="10"
        />
        <path
          d="M90.9117 66.1082C91.9788 66.1082 92.8439 65.2431 92.8439 64.1759C92.8439 63.1088 91.9788 62.2437 90.9117 62.2437C89.8445 62.2437 88.9794 63.1088 88.9794 64.1759C88.9794 65.2431 89.8445 66.1082 90.9117 66.1082Z"
          fill={color900}
        />
        <mask
          id="mask0_10748_21637"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="30"
          y="13"
          width="62"
          height="39"
        >
          <path
            d="M91.1859 13.6604H30.8948V51.4872H91.1859V13.6604Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_10748_21637)">
          <path
            d="M96.3331 17.8545H36.4009V49.2864H96.3331V17.8545Z"
            fill={color800Dark}
          />
          <path
            d="M54.4871 45.6205H40.2305V21.6509H54.4871"
            stroke="#1C2024"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
          <path
            d="M77.5991 21.6509H92.9341V45.6205H77.5991"
            stroke="#1C2024"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
          <path
            d="M62.0507 37.7902C62.0507 40.1972 64.132 42.1479 66.6974 42.1479C69.2627 42.1479 71.344 40.1972 71.344 37.7902C71.344 32.8509 62.8641 33.0699 62.8641 28.7084C62.8641 26.7264 64.5774 25.1199 66.6918 25.1199C68.8063 25.1199 70.5196 26.7264 70.5196 28.7084"
            stroke="#1C2024"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
          <path
            d="M66.6973 44.6471V22.6245"
            stroke="#1C2024"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
          <path
            d="M49.879 36.3345C51.5875 36.3345 52.9725 34.9495 52.9725 33.241C52.9725 31.5325 51.5875 30.1475 49.879 30.1475C48.1705 30.1475 46.7855 31.5325 46.7855 33.241C46.7855 34.9495 48.1705 36.3345 49.879 36.3345Z"
            stroke="white"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
          <path
            d="M83.5156 36.3345C85.2241 36.3345 86.6091 34.9495 86.6091 33.241C86.6091 31.5325 85.2241 30.1475 83.5156 30.1475C81.8071 30.1475 80.4221 31.5325 80.4221 33.241C80.4221 34.9495 81.8071 36.3345 83.5156 36.3345Z"
            stroke="white"
            strokeWidth="0.640416"
            strokeMiterlimit="10"
          />
        </g>
        <path
          d="M91.1859 51.4892V16.4963"
          stroke="white"
          strokeWidth="0.638575"
          strokeMiterlimit="10"
        />
        <path
          d="M15.8266 49.2861C24.5063 49.2861 31.5426 42.2499 31.5426 33.5702C31.5426 24.8905 24.5063 17.8542 15.8266 17.8542C7.14693 17.8542 0.110657 24.8905 0.110657 33.5702C0.110657 42.2499 7.14693 49.2861 15.8266 49.2861Z"
          fill="#FFC419"
        />
        <path
          d="M15.8303 45.3942C22.3624 45.3942 27.6577 40.0988 27.6577 33.5667C27.6577 27.0346 22.3624 21.7393 15.8303 21.7393C9.29813 21.7393 4.00281 27.0346 4.00281 33.5667C4.00281 40.0988 9.29813 45.3942 15.8303 45.3942Z"
          fill="url(#paint2_linear_10748_21637)"
        />
        <path
          d="M15.8266 45.3942C17.3916 45.4137 18.9449 45.1223 20.3965 44.5369C21.848 43.9515 23.1689 43.0838 24.2825 41.984C25.3961 40.8842 26.2802 39.5743 26.8837 38.1301C27.4871 36.686 27.7979 35.1365 27.7979 33.5713C27.7979 32.0062 27.4871 30.4566 26.8837 29.0125C26.2802 27.5684 25.3961 26.2585 24.2825 25.1587C23.1689 24.0589 21.848 23.1911 20.3965 22.6057C18.9449 22.0203 17.3916 21.729 15.8266 21.7485"
          stroke="#1C2024"
          strokeWidth="0.640416"
          strokeMiterlimit="10"
        />
        <path
          d="M7.88582 24.8125C6.4252 26.1382 5.31929 27.8082 4.66872 29.6703C4.01815 31.5325 3.84358 33.5278 4.1609 35.4747C4.47821 37.4215 5.27733 39.2581 6.48555 40.8174C7.69376 42.3766 9.2727 43.6089 11.0787 44.4022"
          stroke="white"
          strokeWidth="0.640416"
          strokeMiterlimit="10"
        />
        <path
          d="M15.8542 27.5269L21.9676 33.6402L15.8542 39.7518"
          stroke="#1C2024"
          strokeWidth="0.640416"
          strokeMiterlimit="10"
        />
        <path
          d="M21.9676 33.6401H8.81885"
          stroke="#1C2024"
          strokeWidth="0.640416"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10748_21637"
          x1="154.791"
          y1="33.8924"
          x2="72.4886"
          y2="33.8924"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.37" stopColor="#D1D5E0" />
          <stop offset="0.4" stopColor="#D6DAE5" />
          <stop offset="0.54" stopColor="#E6EAF4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10748_21637"
          x1="21.8554"
          y1="33.8924"
          x2="69.04"
          y2="33.8924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D1D5E0" />
          <stop offset="0.32" stopColor="#DADEE9" />
          <stop offset="0.86" stopColor="#E6EAF4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10748_21637"
          x1="27.2271"
          y1="33.5667"
          x2="3.57218"
          y2="33.5667"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.11" stopColor="#FFC419" />
          <stop offset="0.22" stopColor="#FFC724" stopOpacity="0.97" />
          <stop offset="0.38" stopColor="#FFCF42" stopOpacity="0.89" />
          <stop offset="0.58" stopColor="#FFDB74" stopOpacity="0.76" />
          <stop offset="0.8" stopColor="#FFEDB9" stopOpacity="0.58" />
          <stop offset="1" stopColor="white" stopOpacity="0.4" />
        </linearGradient>
        <clipPath id="clip0_10748_21637">
          <rect
            width="123.779"
            height="68"
            fill="white"
            transform="translate(0.110657)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FirstDeposit;
