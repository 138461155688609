import { SVGProps } from "react";
interface PropTypes extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const ChevronDownIcon = ({ color, size, ...props }: PropTypes) => {
  return (
    <svg
      className="chevron-down-icon"
      fill="none"
      height={`${size ? size : 24}`}
      viewBox="0 0 25 25"
      width={`${size ? size : 24}`}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.5 8.5L12.5 16.5L20.5 8.5"
        stroke={color || "#060D12"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
