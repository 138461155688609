import React, { useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { ArrowRigth } from "assets/icons/arrow-rigth";
import { HeaderLists } from "components/common/list-header";
import { Sections, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useAppSelector } from "hooks/redux";
import SectionsButtons from "components/common/SectionsButtons";
import { DesktopOrTablet } from "utils/responsive";
import MarketList from "components/common/MarketList";
import { getLoggedUser } from "store/selectors/user.selector";
import mainRoutes from "router/routes";

import "./index.scss";

interface HomeTab {
  name: Sections.FAVORITOS | Sections.HOME;
  id: string | null;
}

const MarketsModal: React.FC = () => {
  const navigate = useNavigate();

  const user = useAppSelector(getLoggedUser);

  const [selectedTab, setSelectedTab] = useState<HomeTab>({
    name: Sections.HOME,
    id: Sections.HOME,
  });

  const handleFavoritesClick = (option: string) => {
    setSelectedTab({ id: option, name: Sections.FAVORITOS });
  };

  const handleHomeClick = () => {
    setSelectedTab({ id: Sections.HOME, name: Sections.HOME });
  };

  const handleGoToMarket = () => {
    navigate(mainRoutes.market);
  };

  const homeValues = { name: "Destacados", id_list: "Destacados" };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { favoritesLists } = user;

  const getListName = (id: string | null) => {
    if (!id) return;
    const listById = favoritesLists.find((list) => list.id_list === id);

    return listById?.name;
  };

  return (
    <div className="markets-container">
      <DesktopOrTablet>
        <Typography variant="heading4">Operar</Typography>
      </DesktopOrTablet>
      <div className="headers">
        <div className="header-markets-modal">
          <div className="filters-buttons">
            <div>
              <HeaderLists
                placeholder="Favoritos"
                isActive={selectedTab.name === Sections.FAVORITOS}
                options={favoritesLists}
                selected={getListName(selectedTab.id)}
                onClickSelectOptions={handleFavoritesClick}
              />
            </div>
            <SectionsButtons
              listsOptions={[homeValues]}
              selected={selectedTab.name === "Home" ? "Destacados" : ""}
              onClickHeaderOption={handleHomeClick}
            />
          </div>
          <DesktopOrTablet>
            <div className="go-to-market" onClick={handleGoToMarket}>
              <Typography className="handle-market-text" variant="buttonS">
                Ir a Mercado <ArrowRigth size={18} />
              </Typography>
            </div>
          </DesktopOrTablet>
        </div>
      </div>
      <div className="markets-table-content">
        <MarketList
          hasCurrency
          currency={Currencies.ARS}
          settlementDays={SettlementTerms._24}
          selectedInstrumentType={selectedTab.name}
          selectedInstrumentSubtype={selectedTab.id}
        />
      </div>
    </div>
  );
};

export default MarketsModal;
