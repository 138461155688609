import { PortfolioTabs, portfolioTabOptions } from "constants/portfolio";

import { useEffect, useState } from "react";

import { ampli } from "ampli";
import AvailableLabel from "components/common/AvailableLabel";
import NavigationHeader from "components/common/NavigationHeader";
import SegmentedControl from "components/common/SegmentedControl";
import Avatar from "components/common/avatar";
import { usePortfolio } from "context/PortfolioProvider";
import { StatusOnboarding } from "interfaces/createAccount";
import { ThemeVariants } from "interfaces/theme";
import { UserData } from "interfaces/user";
import type { BalanceCurrencies } from "interfaces/wallet";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import DistributionTab from "./components/DistributionTab";
import ReturnsTab from "./components/ReturnsTab";
import ValueTab from "./components/ValueTab";
import PortfolioBalance, {
  SelectedTab,
} from "./components/common/PortfolioBalance";
import PortfolioEmptyState, {
  PortfolioEmpty,
} from "./components/common/PortfolioEmptyState";

import styles from "./styles.module.scss";

interface PortfolioContentProps {
  user: UserData;
  selectedCurrency: BalanceCurrencies;
  variant?: ThemeVariants;
}

const PortfolioContent = ({
  user,
  selectedCurrency,
  variant = ThemeVariants.Capital,
}: PortfolioContentProps) => {
  const navigate = useNavigate();
  const { setSelectedCurrency } = usePortfolio();

  const [selectedTab, setSelectedTab] = useState<PortfolioTabs>(
    PortfolioTabs.VALUE
  );
  const [portfolioState, setPortfolioState] = useState<PortfolioEmpty | null>(
    null
  );

  const { hasAccount, statusOnboarding } = user;

  const getHasEmptyState = () => {
    if (!hasAccount) return setPortfolioState(PortfolioEmpty.ACCOUNT);

    if (statusOnboarding === StatusOnboarding.IN_PROGRESS)
      return setPortfolioState(PortfolioEmpty.ONBOARDING);

    return setPortfolioState(null);
  };

  const getTabName = (tab: PortfolioTabs) => {
    return tab === PortfolioTabs.RETURNS
      ? SelectedTab.RETURNS
      : SelectedTab.VALUE;
  };

  const handleCurrencyChange = (option: PortfolioTabs) => {
    setSelectedTab(option);

    ampli.portfolioScreenViewed({ tab: getTabName(option) });
  };

  useEffect(() => {
    ampli.portfolioScreenViewed({ tab: getTabName(selectedTab) });

    getHasEmptyState();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <NavigationHeader
          variant={variant}
          title="Portfolio"
          onClick={() => navigate(mainRoutes.profile)}
          leftIcon={<Avatar variant={variant} />}
          withCloseIcon={false}
        />
        <div className={styles.tabsContainer}>
          <SegmentedControl
            options={portfolioTabOptions}
            selected={selectedTab}
            onClick={(option) => handleCurrencyChange(option)}
          />
        </div>
      </div>
      {portfolioState && (
        <>
          <PortfolioBalance
            selectedTab={SelectedTab.VALUE}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            variant={variant}
          />
          <AvailableLabel
            currency={selectedCurrency}
            available={0}
            variant={variant}
          />
          <PortfolioEmptyState
            portfolioState={portfolioState}
            variant={variant}
          />
        </>
      )}
      {hasAccount && (
        <>
          {selectedTab === PortfolioTabs.VALUE && (
            <ValueTab variant={variant} />
          )}
          {selectedTab === PortfolioTabs.RETURNS && (
            <ReturnsTab variant={variant} />
          )}
          {selectedTab === PortfolioTabs.DISTRIBUTION && <DistributionTab />}
        </>
      )}
    </div>
  );
};

export default PortfolioContent;
