import ErrorContainer from "components/common/error-modal";
import { getMessageError } from "utils";

import styles from "./styles.module.scss";

const ERRORS = [
  "ERROR_RULE_USD_PURCHASE",
  "ERROR_RULE_USD_PURCHASE_11",
  "ERROR_RULE_USD_PURCHASE_12",
  "ERROR_RULE_USD_PURCHASE_21",
  "ERROR_RULE_USD_PURCHASE_22",
  "ERROR_RULE_USD_PURCHASE_31",
];

interface ErrorProps {
  message: string;
  onClose: () => void;
}

const Error: React.FC<ErrorProps> = ({ message, onClose }) => {
  const getClassName = () => {
    if (ERRORS.includes(message)) return styles.NoSVG;
  };

  return (
    <div className={getClassName()}>
      <ErrorContainer
        textPrimaryButton="Volver al activo"
        title="Ups... hubo un error."
        subtitle={getMessageError(message)}
        onClickPrimaryButton={onClose}
      />
    </div>
  );
};

export default Error;
