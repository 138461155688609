import { Typography } from "@mui/material";
import { ArrowIndicatorIcon } from "assets/icons/arrow-indicator";
import { formatDynamicCurrency } from "utils";
import { getColor } from "utils/getColor";

import styles from "./styles.module.scss";

interface Props {
  high?: number;
  low?: number;
  last?: number;
  open?: number;
}

const PriceVariationDisplayer = ({
  high = 0,
  low = 0,
  last = 0,
  open = 0,
}: Props) => {
  const isLoss = last < open;
  const total = high - low;
  const leftDistance = ((Math.min(open, last) - low) * 100) / total;
  const length = (Math.abs(open - last) / total) * 100;
  const finalLength = length + leftDistance > 100 ? 100 - leftDistance : length;
  const finalLeft = open < low ? 0 : leftDistance;
  const barColor = getColor(isLoss ? "--red" : "--green");
  const fontColor = getColor("--text-color-primary");

  return (
    <div className={styles.variationDisplayer}>
      <div className={styles.topRow}>
        <div className={styles.displayerBackground}>
          <div
            className={styles.displayerColoured}
            style={{
              width: `${finalLength}%`,
              left: `${finalLeft}%`,
              backgroundColor: barColor,
            }}
          >
            <ArrowIndicatorIcon
              color={fontColor}
              className={`${styles.svgArrow} ${
                isLoss ? styles.red : styles.green
              }`}
            />
          </div>
        </div>
      </div>
      <div className={styles.priceTips}>
        <Typography>{low === 0 ? "-" : formatDynamicCurrency(low)}</Typography>
        <Typography>
          {high === 0 ? "-" : formatDynamicCurrency(high)}
        </Typography>
      </div>
    </div>
  );
};

export default PriceVariationDisplayer;
