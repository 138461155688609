import { EllipseIcon } from "assets/icons/ellipse";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface HiddenValueProps {
  size?: number | string;
  color?: string;
  count?: number;
  className?: string;
}

const HiddenValue: React.FC<HiddenValueProps> = ({
  count = 5,
  size = 8,
  color,
  className,
}) => (
  <div className={classNames(styles.hiddenValueContainer, className)}>
    {Array.from(Array(count).keys()).map((i) => (
      <EllipseIcon key={i} size={size} color={color} />
    ))}
  </div>
);

export default HiddenValue;
