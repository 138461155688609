import { Typography } from "@mui/material";
import { getFormattedNumber } from "utils";

interface InstrumentAskProps {
  ask?: number | null;
  idTickSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentAsk: React.FC<InstrumentAskProps> = ({
  ask,
  idTickSizeRule,
  tickSize,
}) => (
  <div className="grid-col ask-price">
    <Typography variant="textXS_bold">
      {getFormattedNumber(ask, idTickSizeRule, tickSize)}
    </Typography>
  </div>
);

export default InstrumentAsk;
