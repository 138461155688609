import { useEffect } from "react";

import { ampli } from "ampli";
import { usePricesQuery } from "apis/crypto/queries/usePrices";
import SectionHeader from "components/common/SectionHeader";
import {
  getCurrentScreen,
  setCurrentScreen,
} from "features/global/globalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import CryptoPage from "layouts/CryptoPageLayout";
import { cryptoScreenNames } from "router/routes";

import AssetsSection from "./AssetsSection";

import styles from "./styles.module.scss";

const CryptoMarketPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { data: pricesData, isError, isLoading } = usePricesQuery();

  const previousScreen = useAppSelector(getCurrentScreen);

  useEffect(() => {
    ampli.cryptoScreenMarket({
      previous_screen: previousScreen,
    });

    dispatch(setCurrentScreen(cryptoScreenNames.market));
  }, []);

  return (
    <CryptoPage loading={isLoading} error={isError}>
      <div className={styles.pageMarketContainer}>
        <SectionHeader title="Mercado" />
        <AssetsSection cryptos={pricesData} />
      </div>
    </CryptoPage>
  );
};

export default CryptoMarketPage;
