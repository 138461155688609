import { CDN_URL } from "constants/index";

import { Typography } from "@mui/material";
import { getInstrumentTypePlaceholder } from "utils";

import "./index.scss";

export enum InstrumentLogoVariants {
  Primary = "primary",
  Secondary = "secondary",
}

interface PropTypes {
  type?: string | null;
  variant?: InstrumentLogoVariants;
  placeholderStyle?: "negative" | "positive" | "gray";
  logoFileName?: string | null;
  isRound?: boolean;
}

export const InstrumentLogoUI = (props: PropTypes) => {
  const {
    type,
    variant,
    placeholderStyle,
    logoFileName,
    isRound = false,
  } = props;

  return (
    <div
      className={`ui-instrument-logo ${variant} ${
        placeholderStyle ? placeholderStyle : "common"
      } ${logoFileName ? "with-logo" : "no-logo"} ${isRound && "round"}`}
    >
      {logoFileName ? (
        <div className="section-logo with-img">
          <img loading="lazy" src={`${CDN_URL}/logos/${logoFileName}`} />
        </div>
      ) : (
        <div className="section-logo">
          <Typography className={placeholderStyle} variant="buttonS">
            {getInstrumentTypePlaceholder(type)}
          </Typography>
        </div>
      )}
    </div>
  );
};
