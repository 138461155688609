import type { CurrencyDetail } from "constants/portfolio";

import { useState } from "react";

import classNames from "classnames";
import AvailableLabel from "components/common/AvailableLabel";
import Heading, { HeadingVariants } from "components/common/Heading";
import { UIModalSlider } from "components/common/modal-slider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { PortfolioQueryResponse, SettlementPeriod } from "interfaces/portfolio";
import { ThemeVariants } from "interfaces/theme";
import Skeleton from "react-loading-skeleton";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";

import CompositionInfo from "../CompositionInfo";
import DetailBox from "../DetailBox";

import styles from "./styles.module.scss";

interface BalanceDetailsProps {
  currencyDetail: CurrencyDetail;
  holdings: PortfolioQueryResponse;
  variant?: ThemeVariants;
}

const BalanceDetails = ({
  currencyDetail,
  holdings,
  variant = ThemeVariants.Capital,
}: BalanceDetailsProps) => {
  const { isIOS } = getPlatform();
  const { isMobile } = useDeviceCheck();
  const [isModalOpen, setModalOpen] = useState(false);
  const Icon = currencyDetail.icon;

  const detailHolding = holdings.cash.find(
    ({ ticker }) => ticker === currencyDetail.currency
  );
  const availableBalance = detailHolding?.settlements.find(
    ({ period }) => period === SettlementPeriod.INF
  )?.amount;

  if (!detailHolding) return <Skeleton height={200} />;

  return (
    <div className={styles.detailBalanceHeadingWrapper}>
      <div className={styles.detailBalanceHeading}>
        <Icon size={px2rem(32)} />
        <Heading
          variant={HeadingVariants.RegularTitle}
          component="h3"
          color="var(--slate800)"
        >
          {currencyDetail.title}
        </Heading>
      </div>
      <AvailableLabel
        currency={currencyDetail.currency}
        available={availableBalance || 0}
        variant={variant}
      />
      <DetailBox
        detailHolding={detailHolding}
        handleCompositionClick={() => setModalOpen(true)}
      />
      {isMobile ? (
        <UIModalSlider
          open={!!isModalOpen}
          onToggleDisplay={() => setModalOpen(false)}
          classes={classNames(styles.bottomSheet, { [styles.ios]: isIOS })}
        >
          <CompositionInfo
            onButtonClick={() => setModalOpen(false)}
            variant={variant}
          />
        </UIModalSlider>
      ) : (
        isModalOpen && (
          <CompositionInfo
            onButtonClick={() => setModalOpen(false)}
            variant={variant}
          />
        )
      )}
    </div>
  );
};

export default BalanceDetails;
