import { Typography } from "@mui/material";

interface InstrumentVariationProps {
  variation: number;
}

const InstrumentVariation: React.FC<InstrumentVariationProps> = ({
  variation,
}) => {
  const getVariationClass = () => {
    if (!variation) {
      return "grid-col variation";
    }

    return `grid-col variation ${variation > 0 ? "positive" : "negative"}`;
  };

  const formatPercentage = (percentage: number) => {
    const formattedVariation = (percentage * 100).toFixed(2);

    return `${percentage > 0 ? "+" : ""}${formattedVariation}%`;
  };

  return (
    <div className={getVariationClass()}>
      <div>
        <Typography variant="textXS_bold">
          {variation ? formatPercentage(variation) : "-"}
        </Typography>
      </div>
    </div>
  );
};

export default InstrumentVariation;
