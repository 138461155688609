import { IconProps } from "assets/icons/BTC";

const CheckedDNI: React.FC<IconProps> = ({ size, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 94 68"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_10748_22114)">
      <path
        d="M76.5494 8.91089H17.1181C12.8758 8.91089 9.43665 12.35 9.43665 16.5924V51.171C9.43665 55.4133 12.8758 58.8525 17.1181 58.8525H76.5494C80.7918 58.8525 84.2309 55.4133 84.2309 51.171V16.5924C84.2309 12.35 80.7918 8.91089 76.5494 8.91089Z"
        fill="url(#paint0_linear_10748_22114)"
      />
      <path
        d="M67.6383 0H8.20698C3.96462 0 0.525513 3.43911 0.525513 7.68146V42.2601C0.525513 46.5025 3.96462 49.9416 8.20698 49.9416H67.6383C71.8806 49.9416 75.3197 46.5025 75.3197 42.2601V7.68146C75.3197 3.43911 71.8806 0 67.6383 0Z"
        fill="#0588F0"
      />
      <path
        d="M31.9313 9.56201H9.48962V35.0753H31.9313V9.56201Z"
        fill="white"
      />
      <path
        d="M20.7104 23.8543C23.7729 23.8543 26.2557 21.3716 26.2557 18.309C26.2557 15.2464 23.7729 12.7637 20.7104 12.7637C17.6478 12.7637 15.165 15.2464 15.165 18.309C15.165 21.3716 17.6478 23.8543 20.7104 23.8543Z"
        fill="#0588F0"
      />
      <path
        d="M31.9313 35.0753C31.9313 32.0994 30.7491 29.2453 28.6448 27.141C26.5404 25.0367 23.6864 23.8545 20.7104 23.8545C17.7345 23.8545 14.8804 25.0367 12.7761 27.141C10.6718 29.2453 9.48962 32.0994 9.48962 35.0753H31.9313Z"
        fill="#1C2024"
      />
      <path
        d="M39.0558 35.0752H52.3886"
        stroke="white"
        strokeWidth="0.894483"
        strokeMiterlimit="10"
      />
      <path
        d="M39.0558 28.3872H65.7215"
        stroke="white"
        strokeWidth="0.894483"
        strokeMiterlimit="10"
      />
      <path
        d="M39.0558 41.3269H65.7215"
        stroke="white"
        strokeWidth="0.894483"
        strokeMiterlimit="10"
      />
      <path
        d="M52.3886 17.3254H39.0558V21.7014H52.3886V17.3254Z"
        fill="white"
      />
      <path
        d="M65.7215 9.56201H39.0558V13.938H65.7215V9.56201Z"
        fill="#113264"
      />
      <path
        d="M75.3197 67.9997C85.3463 67.9997 93.4745 59.8715 93.4745 49.8448C93.4745 39.8182 85.3463 31.6899 75.3197 31.6899C65.293 31.6899 57.1648 39.8182 57.1648 49.8448C57.1648 59.8715 65.293 67.9997 75.3197 67.9997Z"
        fill="#3DD68C"
      />
      <path
        d="M83.7607 44.3525L71.2428 56.8705L66.0229 51.6531"
        stroke="#1C2024"
        strokeWidth="0.894483"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10748_22114"
        x1="9.43665"
        y1="33.8841"
        x2="84.2309"
        y2="33.8841"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D1D5E0" />
        <stop offset="0.32" stopColor="#DADEE9" />
        <stop offset="0.86" stopColor="#E6EAF4" />
      </linearGradient>
      <clipPath id="clip0_10748_22114">
        <rect
          width="92.9491"
          height="68"
          fill="white"
          transform="translate(0.525513)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CheckedDNI;
