import * as React from "react";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";

import "./index.scss";

interface Option {
  label: string;
  value: string;
}

export enum OverlappedButtonsSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}

export enum OverlappedButtonsTypes {
  Primary = "primary",
  Secondary = "secondary",
  BuySell = "buy-sell",
}

interface PropTypes {
  selectedValue: string;
  onChangeValue?: (value: any) => void;
  options: any;
  size?: OverlappedButtonsSizes;
  type?: OverlappedButtonsTypes;
  className?: string;
  disabled?: boolean;
  styleClass?: any;
  onClick?: () => void;
}

export const UIOverlappedButtons = ({
  disabled = false,
  selectedValue,
  options,
  size = OverlappedButtonsSizes.Large,
  type = OverlappedButtonsTypes.Primary,
  onChangeValue,
  styleClass,
  onClick,
}: PropTypes) => {
  const handleChangeValue = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    event.stopPropagation();
    if (value !== null && value !== selectedValue && onChangeValue) {
      onChangeValue(value);
    }
  };

  const getVariant = (o: Option) => {
    if (type === OverlappedButtonsTypes.BuySell) {
      return selectedValue === o.value ? "textS" : "textS_bold";
    }

    return selectedValue === o.value ? "textS_bold" : "textS";
  };

  return (
    <ToggleButtonGroup
      exclusive
      aria-label="text alignment"
      id={"ui-overlapped-buttons"}
      className={`ui-overlapped-buttons ${size} ${type} ${
        disabled && "disabled"
      } ${styleClass} `}
      disabled={disabled}
      value={selectedValue}
      onChange={handleChangeValue}
      onClick={onClick}
    >
      {options.map((o: any) => (
        <ToggleButton
          key={o.value}
          aria-label={o.label}
          value={o.value}
          id={o.value}
          onClick={onClick}
        >
          <Typography variant={getVariant(o)}>{o.label}</Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
