import dayjs from "dayjs";
import { Typography } from "@mui/material";
import {
  UIOverlappedButtons,
  OverlappedButtonsTypes,
} from "components/common/overlapped-buttons";
import { SettlementCodes } from "components/common/BondCalculator";

import styles from "./styles.module.scss";

interface SettlementButtonsProps {
  settlementCode: string;
  setSettlementDate: (v: string) => void;
  setSettlementCode: (v: SettlementCodes) => void;
}

const SettlementButtons: React.FC<SettlementButtonsProps> = ({
  settlementCode,
  setSettlementCode,
  setSettlementDate,
}) => {
  const FRIDAY = 5;
  const SATURDAY = 6;

  const handleChangeSettlementTerm = (newValue: SettlementCodes) => {
    setSettlementCode(newValue);

    let plazo: number = newValue === SettlementCodes.OTHER ? 3 : +newValue;
    --plazo;

    if (
      dayjs().add(plazo, "days").day() === SATURDAY ||
      dayjs().add(plazo, "days").day() === FRIDAY
    ) {
      if (plazo !== 0) {
        if (dayjs().day() === 4 || (dayjs().day() === 3 && plazo == 2)) {
          plazo += 2;
        }
      }
    }

    const newSettlementDate = dayjs().add(plazo, "days").format("YYYY-MM-DD");

    setSettlementDate(newSettlementDate);
  };

  const settlementOptions = [
    { value: SettlementCodes._1, label: "t+0" },
    { value: SettlementCodes._2, label: "t+1" },
    { value: SettlementCodes._3, label: "t+2" },
    { value: SettlementCodes.OTHER, label: "Otro" },
  ];

  return (
    <div className={styles.buttonsWrapper}>
      <Typography>Plazo de liquidación</Typography>
      <UIOverlappedButtons
        options={settlementOptions}
        selectedValue={settlementCode}
        type={OverlappedButtonsTypes.Primary}
        onChangeValue={handleChangeSettlementTerm}
      />
    </div>
  );
};

export default SettlementButtons;
