import React, { Dispatch, useEffect, useRef, useState } from "react";

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import API from "apis";
import MarketDetailSidebarDesktop from "components/common/market-detail-sidebar";
import TradingContainer from "components/common/trading-container";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useOutsideClick } from "hooks/useOutsideClick";
import {
  InstrumentData,
  InstrumentSubtype,
  SearchListResponse,
} from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import DrawerLayout from "layouts/drawer";
import LayoutV2 from "layouts/v2";
import { useNavigate } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  getMarketDetailByShortTicker,
  resetMarketDataDetail,
  resetOrder,
  updateOrderOptions,
} from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { SearchBarUI, SearchTypes } from "components/common/search-bar-ui";
import { trackAction } from "utils/amplitude";
import { marketService } from "apis/services";
import { useAppState } from "context/AppStateProvider";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface SearchBarCompProps {
  handleToggleDisplayModal?: () => void;
  onDisplayButtons?: (value: boolean) => void;
  onClickMarket?: (
    code: string,
    instrument: InstrumentData,
    dispatch: Dispatch<any>
  ) => void;
  isHeaderSearchBar?: boolean;
  classes?: string;
  type?: SearchTypes;
  onlySearch?: boolean;
  setInstrumentData?: (instrument: any) => void | "";
  setMarketSelected?: (market: any) => Promise<void> | "";
  quickPurchase?: boolean;
  placeholder?: string;
  value?: string;
  marketDetail?: boolean;
}
let timerId: NodeJS.Timeout;

const SearchBarComp: React.FC<SearchBarCompProps> = ({
  onDisplayButtons,
  handleToggleDisplayModal,
  onClickMarket,
  classes,
  type,
  isHeaderSearchBar = false,
  onlySearch = false,
  setInstrumentData,
  quickPurchase = false,
  placeholder = "Buscá por nombre o ticker",
  value,
  marketDetail = false,
}) => {
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();

  const [list, setList] = useState<SearchListResponse[]>([]);
  const [open, setOpen] = useState(false);
  const initialValue = value;
  const [searchTicker, setSearchTicker] = useState(value || "");
  const [loading, setLoading] = useState(false);
  const { isDesktopOrTablet } = useDeviceCheck();
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const shortTickerData = useAppSelector(
    (state: RootState) => state?.markets?.shortTickerData
  );

  const handleOpenList = () => {
    setOpen(true);
  };
  const handleCloseList = () => {
    setOpen(false);
  };

  const ref: React.RefObject<HTMLDivElement> = useOutsideClick(
    handleCloseList,
    open
  );

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    if (searchTicker !== initialValue) {
      const getOptions = (signal: AbortSignal) => {
        API.get(marketService.searchTicker, {
          params: { q: searchTicker },
          signal,
        })
          .then((response: any) => {
            handleOpenList();
            response ? setList([...response.data]) : setList([]);
            setLoading(false);
          })
          .catch((e) => {
            if (e.name !== "AbortError") {
              setLoading(false);
            }
          });
      };

      // ENVIAR API
      clearTimeout(timerId);

      // Abort any pending requests
      if (abortControllerRef.current) {
        setLoading(false);
        abortControllerRef.current.abort();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      if (searchTicker.length > 1) {
        setLoading(true);
        timerId = setTimeout(function () {
          getOptions(newAbortController.signal);
          clearTimeout(timerId);
        }, 500);
      }
    }

    // Clean up
    return () => {
      // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [searchTicker]);

  const handleChangeSearch = (value: string) => {
    setSearchTicker(value);
    if (value === "") {
      setOpen(false);
    }
  };

  const filterOptions = list.filter(
    (l) =>
      l.instrument_type === Instruments.ACCIONES ||
      l.instrument_type === Instruments.BONOS_PUBLICOS ||
      l.instrument_type === Instruments.BONOS_CORP ||
      l.instrument_type === Instruments.CEDEARS
  );

  const handleClick = (
    instrument_code: string,
    instrument_type: string,
    currency: Currencies,
    instrument: any
  ) => (ev: any) => {
    ev.stopPropagation();
    trackAction(`${screenNames.search} - Select instrument`, {
      ticker: instrument_code,
      type: instrument_type,
    });
    instrument = { ...instrument, instrument_type };
    handleCloseList();
    if (order && !order.instrument_code?.includes(instrument_code)) {
      dispatch(
        resetOrder({ currency: currency, instrument_code: instrument_code })
      );
      dispatch(cleanHistoricalData());
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
    } else {
      dispatch(updateOrderOptions({ ...order, currency: currency }));
    }
    if (isHeaderSearchBar) {
      if (instrument.instrument_type === Instruments.FCI) {
        openRightModalContent(
          <DrawerLayout>
            <MarketDetailSidebarDesktop
              id={instrument.short_ticker}
              ticker={instrument}
            />
          </DrawerLayout>
        );
      } else {
        openRightModalContent(
          <LayoutV2
            title={instrument.short_ticker}
            withSearch
            quickOperation
            instrument={instrument}
            onClickExtended={() =>
              redirectToTrading(
                instrument.instrument_type,
                instrument.instrument_code ?? "",
                instrument
              )
            }
          >
            <TradingContainer instrumentProp={instrument} />
          </LayoutV2>
        );
      }
      onClickMarket && onClickMarket(instrument_code, instrument, dispatch);
      onlySearch && setInstrumentData ? setInstrumentData(searchTicker) : null;
    } else {
      handleToggleDisplayModal && handleToggleDisplayModal();
      navigate(`${mainRoutes.market}/${instrument_type}/${instrument_code}`, {
        state: instrument,
      });

      if (isDesktopOrTablet) {
        dispatch(closeRightModal());
      }
    }
  };

  const showQuickPurchase = (market: any) => {
    dispatch(getMarketDetailByShortTicker(market?.dataMarket?.short_ticker));
    handleToggleDisplayModal && handleToggleDisplayModal();
  };

  const redirectToTrading = (
    type: string | undefined,
    code: string,
    ticker: InstrumentData
  ) => {
    navigate(`${mainRoutes.market}/${type}/${code}`, { state: ticker });
  };

  useEffect(() => {
    if (quickPurchase && isMobile) {
      shortTickerData?.map((ticker) => {
        if (ticker?.term === 2 && ticker?.currency === Currencies.ARS) {
          navigate(
            `/market/${ticker.instrument_type}/${ticker.instrument_code}`,
            { state: ticker }
          );
        }
      });
    } else if (quickPurchase && !isMobile) {
      shortTickerData?.map((ticker) => {
        if (ticker?.term === 2 && ticker?.currency === Currencies.ARS)
          openRightModalContent(
            <LayoutV2
              title={ticker.short_ticker ?? ""}
              withSearch
              quickOperation
              onClickExtended={() =>
                redirectToTrading(
                  ticker.instrument_type,
                  ticker.instrument_code ?? "",
                  ticker
                )
              }
              instrument={ticker}
            >
              <TradingContainer instrumentProp={ticker} />
            </LayoutV2>
          );
      });
    }
  }, [shortTickerData]);

  const handleClickQuickPurchase = (
    instrument: InstrumentData,
    instrument_type: SearchListResponse
  ) => {
    trackAction(`${screenNames.newOrder} - Select Instrument`);
    showQuickPurchase({
      dataMarket: instrument,
      instrument_type: instrument_type,
    });
    setOpen(false);
  };

  return (
    <div className={`comp-search-bar ${classes}`}>
      {!isHeaderSearchBar && <Typography variant="textS">Especie</Typography>}
      <div ref={ref} className="list-wrapper">
        <SearchBarUI
          loading={loading}
          marketDetail={marketDetail}
          name="search"
          placeholder={placeholder}
          type={type}
          value={searchTicker}
          onChange={handleChangeSearch}
          onClick={handleOpenList}
          onDisplayButtons={onDisplayButtons}
        />
        {open && (
          <List className="search-list">
            {list.length === 0 && searchTicker.length > 1 && !loading && (
              <Typography className="no-results" variant="textS">
                Modifique el ticker para buscar.
              </Typography>
            )}
            {!quickPurchase &&
              list.map((instrument_type: SearchListResponse) => {
                return instrument_type.instrument_subtypes.map(
                  (subtype: InstrumentSubtype, index: number) => (
                    <div key={instrument_type.instrument_type}>
                      <ListSubheader key={index}>
                        <Typography>
                          {instrument_type.type_name !== null
                            ? instrument_type.type_name
                            : "Otros"}
                        </Typography>
                      </ListSubheader>
                      {subtype.market_data.map(
                        (t: InstrumentData, index: number) => (
                          <ListItem
                            key={index}
                            onClick={handleClick(
                              t.instrument_code || "",
                              instrument_type.instrument_type,
                              t.currency as Currencies,
                              t
                            )}
                          >
                            <ListItemText>
                              <Typography>{t.short_ticker}</Typography>
                            </ListItemText>
                          </ListItem>
                        )
                      )}
                    </div>
                  )
                );
              })}
            {quickPurchase &&
              filterOptions.map((instrument_type: SearchListResponse) => {
                return instrument_type.instrument_subtypes.map(
                  (li: InstrumentSubtype, index: number) => (
                    <div key={index}>
                      <ListSubheader key={index}>
                        <Typography>
                          {instrument_type.type_name !== null
                            ? instrument_type.type_name
                            : "Otros"}
                        </Typography>
                      </ListSubheader>
                      {li.market_data.map(
                        (t: InstrumentData, index: number) =>
                          t?.currency !== "USD" && (
                            <ListItem
                              key={index}
                              onClick={() =>
                                handleClickQuickPurchase(t, instrument_type)
                              }
                            >
                              <ListItemText>
                                <Typography>{t.short_ticker}</Typography>
                              </ListItemText>
                            </ListItem>
                          )
                      )}
                    </div>
                  )
                );
              })}
          </List>
        )}
      </div>
    </div>
  );
};

export default SearchBarComp;
