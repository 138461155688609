import type { MenuNavigation, SettingsNavigation } from "constants/capitalMenu";

import Text, { TextVariants } from "components/common/Text";
import { CocosLogo } from "assets/icons/cocos-logo";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface SidebarProps {
  menuList: MenuNavigation[];
  userSettings: SettingsNavigation[];
  selected: string;
  handleOpenMenu: (opt: string) => void;
}

const Sidebar = ({
  handleOpenMenu,
  menuList,
  userSettings,
  selected,
}: SidebarProps) => (
  <aside className={styles.sidebar}>
    <div className={styles.menuList}>
      <div className={styles.iconContainer}>
        <CocosLogo size={20} />
      </div>
      {menuList.map(({ id, path, label, icon }) => (
        <div
          key={label}
          className={classNames(styles.menuLink, {
            [styles.linkSelected]: selected === id,
          })}
          id={id}
          onClick={() => handleOpenMenu(path)}
        >
          {icon}
          <Text
            className={styles.menuLinkText}
            variant={TextVariants.SemiboldText}
          >
            {label}
          </Text>
        </div>
      ))}
    </div>
    <div className={styles.menuList}>
      {userSettings.map(({ path, icon }) => (
        <div
          key={path}
          className={styles.menuLink}
          onClick={() => handleOpenMenu(path)}
        >
          {icon}
        </div>
      ))}
    </div>
  </aside>
);

export default Sidebar;
