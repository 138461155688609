interface PropTypes {
  size?: number;
  color?: string;
}

export const ClockIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      width={size || "43"}
      height={size || "42"}
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 9V21L29.5 25M41.5 21C41.5 32.0457 32.5457 41 21.5 41C10.4543 41 1.5 32.0457 1.5 21C1.5 9.9543 10.4543 1 21.5 1C32.5457 1 41.5 9.9543 41.5 21Z"
        stroke={color || "#0062E1"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
