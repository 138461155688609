import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import classNames from "classnames";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NavBarButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: LucideIcon;
  variant?: ThemeVariants;
}

const NavBarButton: React.FC<NavBarButtonProps> = ({
  icon: Icon,
  variant = ThemeVariants.Capital,
  ...props
}) => {
  return (
    <button
      className={classNames(styles.navBarButton, styles[variant])}
      {...props}
    >
      <Icon size={px2rem(16)} color="var(--slate900)" />
    </button>
  );
};

export default NavBarButton;
