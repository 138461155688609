import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Method, OrderType, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { updateOrderOptions } from "features/markets";
import { RootState } from "store/store";
import { useAppDispatch, useAppSelector } from "hooks/redux";

interface Context {
  type: OrderType;
  setType: (v: OrderType) => void;
  currency?: Currencies;
  setCurrency: (v: Currencies) => void;
  term?: SettlementTerms;
  setTerm: (v: SettlementTerms) => void;
  method: Method;
  setMethod: (v: Method) => void;
  available: number;
  loading: boolean;
  setLoading: (v: boolean) => void;
}

const TradingContext = createContext<Context | null>(null);

export const TradingProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const [type, setType] = useState<OrderType>(OrderType.Buy);
  const [currency, setCurrency] = useState<Currencies>();
  const [term, setTerm] = useState<SettlementTerms>();
  const [method, setMethod] = useState<Method>(Method.MARKET);
  const { wallet, sellingPower } = useAppSelector(
    (state: RootState) => state.wallet
  );

  const getAvailable = () => {
    if (type === OrderType.Buy && wallet && term)
      if (currency === Currencies.ARS) return Number(wallet[term].ars);
      else if (currency === Currencies.USD) return Number(wallet[term].usd);
      else if (currency === Currencies.EXT) return Number(wallet[term].ext);

    if (type === OrderType.Sell && sellingPower && term)
      return Number(sellingPower.available[term]);
    return 0;
  };
  const [available, setAvailable] = useState<number>(getAvailable());

  useEffect(() => {
    if (wallet) {
      setAvailable(getAvailable());
    }
  }, [wallet, currency, term, type]);

  useEffect(() => {
    if (type !== order.type)
      dispatch(
        updateOrderOptions({
          ...order,
          type,
          amount: 0,
          quantity: 0,
        })
      );
  }, [type]);

  useEffect(() => {
    if (method !== order.method)
      dispatch(
        updateOrderOptions({
          ...order,
          method,
        })
      );
  }, [method]);

  const [loading, setLoading] = useState<boolean>(false);

  const memoizedValues = useMemo(() => {
    return {
      type,
      setType,
      currency,
      setCurrency,
      term,
      setTerm,
      method,
      setMethod,
      available,
      loading,
      setLoading,
    };
  }, [
    type,
    setType,
    currency,
    setCurrency,
    term,
    setTerm,
    method,
    setMethod,
    available,
    loading,
    setLoading,
  ]);

  return (
    <TradingContext.Provider value={memoizedValues}>
      {children}
    </TradingContext.Provider>
  );
};

export default TradingContext;

export const useTrading = () => {
  const context = useContext(TradingContext);
  if (!context) {
    throw new Error("[TradingContext] Missing context");
  }
  return context;
};
