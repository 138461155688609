import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "apis";
import { dolarMepService, marketService, ordersService } from "apis/services";
import {
  ApiOrderResponse,
  CreateListResponse,
  DolarMepResponse,
  HistoricDataMarketData,
  InstrumentData,
  MarketDataFilters,
  OrderDetailResponse,
  OrdersResponse,
  TickersResponse,
  TickersRulesResponse,
} from "interfaces/api-responses";
import {
  BidResponse,
  CaucionOrder,
  DolarMepOrder,
  DolarTypes,
  Instruments,
  InvestmentSuggestedResponse,
  Order,
  OrderType,
} from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { getIdSegment, getIntervalParameters } from "utils";
import { setUserData } from "features/user/userSlice";
import { MarketSchedules } from "interfaces/calendar";
import { store } from "store/store";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import { getPortfolio } from "features/wallet/portfolioSlice";
import dayjs from "dayjs";
import { NewMepSteps } from "components/page-mep/NewMep";
import { queryClient } from "apis/queryClient";
import { marketKeys } from "apis/queryKeyFactories";

import {
  removeMarketError,
  setCaucionOrder,
  setCaucionOrderError,
  setCreateListLoading,
  setCreateOrderError,
  setCreateOrderLoading,
  setDolarMepData,
  setDolarMepOrder,
  setGetOrderError,
  setGetOrderLoading,
  setInvestmentSuggested,
  setListError,
  setMarketData,
  setMarketDetailLoading,
  setMarketError,
  setMarketHistoricalData,
  setMarketLoading,
  setMarketSchedules,
  setOrderDetail,
  setOrderListError,
  setOrderListLoading,
  setOrdersData,
  setTickersRulesData,
  setTickersRulesError,
  setTickersRulesLoading,
  updateOrderOptions,
} from "./marketsSlice";

export const getSegment = (type: string) => {
  switch (type) {
    case Instruments.CAUCION:
      return "U";
    case Instruments.FCI:
      return Instruments.FCI;
    default:
      return "C";
  }
};

export const getMarketsTypesLists = createAsyncThunk(
  "markets/getMarketsTypesLists",
  async (filters: Partial<MarketDataFilters>, { dispatch }) => {
    try {
      dispatch(removeMarketError());
      dispatch(setMarketLoading(true));

      const { data } = await API.get<InstrumentData[]>(
        marketService.listsTicker,
        {
          params: {
            instrument_type: filters.type,
            instrument_subtype: filters.subtype,
            settlement_days:
              filters.type !== Instruments.CAUCION &&
              filters.type !== Instruments.FCI
                ? filters.settlement_days
                : undefined,
            currency:
              filters.currency === undefined ? "INDISTINTO" : filters.currency,
            segment: getSegment(filters.type || ""),
          },
        }
      );

      if (filters.type === Instruments.CAUCION) {
        data.sort((a, b) => (a.term || 0) - (b.term || 0));
      }

      dispatch(setMarketData(data));
      dispatch(removeMarketError());
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setMarketError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setMarketLoading(false));
    }
  }
);

interface GetHistoricalDataByLongTickerProps {
  long_ticker: string;
  interval?: string;
}

export const getHistoricalDataByLongTicker = createAsyncThunk(
  "markets/getHistoricalDataByLongTicker",
  async (
    { long_ticker, interval }: GetHistoricalDataByLongTickerProps,
    { dispatch }
  ) => {
    try {
      dispatch(removeMarketError());
      dispatch(setMarketDetailLoading(true));

      const intervalParams = getIntervalParameters(interval);
      const { data } = await API.get<HistoricDataMarketData>(
        `${marketService.tickers}/${long_ticker}/historic-data`,
        {
          params: {
            date_from: dayjs()
              .subtract(intervalParams.value, intervalParams.frame)
              .format("YYYY-MM-DD"),
          },
        }
      );

      dispatch(setMarketHistoricalData(data));

      dispatch(removeMarketError());
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setMarketError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setMarketDetailLoading(false));
    }
  }
);

interface GetMarketDetailByShortTickerProps {
  short_ticker: string;
  instrument_type?: string | undefined;
}

export const getMarketDetailByShortTicker = createAsyncThunk(
  "markets/getMarketDetailByShortTicker",
  async (
    { short_ticker, instrument_type }: GetMarketDetailByShortTickerProps,
    { dispatch }
  ) => {
    try {
      dispatch(removeMarketError());
      dispatch(setMarketDetailLoading(true));

      const response = await API.get<TickersResponse[]>(
        `${marketService.tickers}/${short_ticker}`,
        {
          params: {
            segment: getIdSegment(instrument_type),
          },
        }
      );

      const market: InstrumentData[] = response.data.map(
        (d: TickersResponse) => ({
          bids: d.bids,
          asks: d.asks,
          bid: d.bid || undefined,
          ask: d.ask || undefined,
          close: d.close,
          high: d.high || undefined,
          last: d.last,
          lastSize: d.volume || undefined,
          long_ticker: d.long_ticker,
          low: d.low || undefined,
          open: d.open || undefined,
          turnover: d.turnover || undefined,
          variation: d.variation,
          volume: d.volume || undefined,
          term: d.newTerm,
          currency: d.currency as Currencies,
          instrument_subtype: d.instrument_subtype,
          instrument_type: d.instrument_type,
          short_ticker: d.short_ticker,
          price_factor: d.price_factor,
          contract_size: d.contract_size,
          instrument_code: d.instrument_code,
          id_tick_size_rule: d.id_tick_size_rule,
          tick_size: d.tick_size,
          min_lot_size: d.min_lot_size || 1,
          instrument_short_name: d.instrument_short_name,
          returns_perc_last_year: d.returns_perc_last_year || undefined,
          returns_perc_last_month: d.returns_perc_last_month || undefined,
          returns_perc_begin_year: d.returns_perc_begin_year || undefined,
          nav: d.nav || undefined,
          risk: d.risk || undefined,
          rules_file_name: d.rules_file_name || undefined,
          redemption_settlement_days: d.redemption_settlement_days || undefined,
          share_class: d.share_class || undefined,
          logo_file_name: d.logo_file_name || undefined,
          isFavorite: d.is_favorite,
          prev_close: d.prev_close || undefined,
          settlement_days: d.settlement_days,
        })
      );

      return market;
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setMarketError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setMarketDetailLoading(false));
    }
  }
);

export const getInvestmentSuggested = createAsyncThunk(
  "markets/getInvestmentSuggested",
  async (_, { dispatch }) => {
    try {
      dispatch(setMarketLoading(true));
      const { data } = await API.get<InvestmentSuggestedResponse[]>(
        marketService.investmentSuggest
      );
      if (data) {
        dispatch(setInvestmentSuggested(data));
      } else {
        throw Error();
      }
    } catch (error) {
      dispatch(
        setMarketError({
          message: "Error getting investment suggest.",
          status: 500,
        })
      );
    } finally {
      dispatch(setMarketLoading(false));
    }
  }
);

interface ConfirmOrderProps {
  order: Optional<Order, "price" | "quantity" | "long_ticker">;
  instrument_type?: string;
}

export const confirmOrder = createAsyncThunk(
  "markets/confirmOrder",
  async ({ order, instrument_type }: ConfirmOrderProps, { dispatch }) => {
    try {
      dispatch(setCreateOrderLoading(true));
      dispatch(setCreateOrderError({ message: "", status: null }));

      let resp;
      if (instrument_type !== Instruments.FCI) {
        let orderQuantity: number | undefined = Math.floor(order.quantity || 0);
        let orderAmount = order.amount;

        if (order.method === "mercado") {
          if (order.enabledAmount && order.type === OrderType.Buy) {
            orderQuantity = undefined;
          } else {
            orderAmount = undefined;
          }
        }

        resp = await API.post<ApiOrderResponse>(ordersService.orders, {
          type: order.method === "mercado" ? "MARKET" : "LIMIT",
          side: order.type,
          quantity: orderQuantity,
          long_ticker: order.long_ticker,
          price: order.method === "limite" ? order.price : undefined,
          amount: order.method === "mercado" ? orderAmount : undefined,
        });
      } else {
        resp = await API.post<ApiOrderResponse>(ordersService.fciOrders, {
          type: order.type === OrderType.Buy ? "FUND" : "REDEMPTION",
          quantity: undefined,
          amount:
            order.type === OrderType.Sell && order.totalRedemption
              ? undefined
              : order.amount,
          long_ticker: order.long_ticker,
          total_redemption: order.totalRedemption,
        });
      }

      const orderId = resp?.data.Orden;
      dispatch(updateOrderOptions({ ...order, id: orderId }));

      dispatch(getWallet());
      dispatch(getOrders());
      dispatch(getPortfolio());
      if (order.long_ticker) {
        dispatch(getSellingPower(order.long_ticker));
      }
    } catch (error: any) {
      console.log("Order Error: ", error);
      if (error?.response?.status !== 401) {
        dispatch(
          setCreateOrderError({
            message: error?.response?.data?.message
              ? error.response.data.message.toString()
              : error.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setCreateOrderLoading(false));
    }
  }
);

export const getOrderById = createAsyncThunk(
  "markets/getOrderById",
  async (id: string, { dispatch }) => {
    try {
      dispatch(setGetOrderLoading(true));
      dispatch(
        setGetOrderError({
          message: "",
          status: null,
        })
      );

      const { data } = await API.get<OrderDetailResponse>(
        `${ordersService.orders}/${id}`
      );

      dispatch(setOrderDetail(data));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setGetOrderError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setGetOrderLoading(false));
    }
  }
);

export const getOrders = createAsyncThunk(
  "markets/getOrders",
  async (_, { dispatch }) => {
    try {
      dispatch(setOrderListLoading(true));
      dispatch(setOrderListError({ message: "", status: null }));

      const { data } = await API.get<OrdersResponse[]>(ordersService.orders);

      dispatch(setOrdersData(data.length > 0 ? data : []));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setOrderListError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setOrderListLoading(false));
    }
  }
);

export const getDolarMep = createAsyncThunk(
  "markets/getDolarMep",
  async (_, { dispatch }) => {
    try {
      dispatch(removeMarketError());
      dispatch(setMarketLoading(true));

      const { data } = await API.get<DolarMepResponse>(
        dolarMepService.getMepPrices
      );
      dispatch(setDolarMepData(data));

      dispatch(removeMarketError());
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setMarketError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setMarketLoading(false));
    }
  }
);

interface ConfirmDolarOrderProps {
  dolarType: DolarTypes;
  dolarOrder: DolarMepOrder;
  onComplete?: (step: NewMepSteps) => void;
}

export const confirmDolarOrder = createAsyncThunk(
  "markets/confirmDolarOrder",
  async (
    { dolarType, dolarOrder, onComplete }: ConfirmDolarOrderProps,
    { dispatch }
  ) => {
    try {
      dispatch(setCreateOrderLoading(true));
      dispatch(setCreateOrderError({ message: "", status: null }));

      let resp;
      if (dolarType === DolarTypes.OPEN) {
        if (dolarOrder.type === OrderType.Sell) {
          resp = await API.post<ApiOrderResponse>(dolarMepService.sellOpenMep, {
            quantity: dolarOrder.usdAmount,
          });
        } else {
          resp = await API.post<ApiOrderResponse>(
            dolarMepService.confirmOpenOrder,
            {
              type: dolarOrder.type,
              quantity: dolarOrder.arsAmount,
            }
          );
        }
      } else if (dolarType === DolarTypes.CLOSE) {
        if (dolarOrder.type === OrderType.Sell) {
          resp = await API.post<ApiOrderResponse>(
            dolarMepService.sellCloseMep,
            {
              quantity: dolarOrder.usdAmount,
            }
          );
        } else {
          resp = await API.post<ApiOrderResponse>(
            dolarMepService.confirmCloseOrder,
            {
              quantity: Number((dolarOrder.usdAmount * 0.9998).toFixed(2)),
            }
          );
        }
      } else {
        if (dolarOrder.type === OrderType.Sell) {
          resp = await API.post<ApiOrderResponse>(
            dolarMepService.sellOvernightMep,
            {
              quantity: dolarOrder.usdAmount,
            }
          );
        } else {
          resp = await API.post<ApiOrderResponse>(
            dolarMepService.confirmOrderOvernight,
            {
              quantity: Number((dolarOrder.usdAmount * 0.9998).toFixed(2)),
            }
          );
        }
      }

      const orderId = resp.data.Orden;
      dispatch(setDolarMepOrder({ ...dolarOrder, id: orderId }));

      dispatch(getOrders());
      dispatch(getWallet());
      dispatch(getPortfolio());
      onComplete && onComplete(NewMepSteps.Success);
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setCreateOrderError({
            message: error?.response?.data?.message
              ? error.response.data.message.toString()
              : error.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
      onComplete && onComplete(NewMepSteps.Failure);
    } finally {
      dispatch(setCreateOrderLoading(false));
    }
  }
);

export const confirmCaucionOrder = createAsyncThunk(
  "markets/confirmCaucionOrder",
  async (order: CaucionOrder, { dispatch }) => {
    try {
      dispatch(setCaucionOrderError({ message: "", status: null }));
      dispatch(setCreateOrderLoading(true));
      const { amount, currency, term, rate } = order;

      const resp = await API.post<ApiOrderResponse>(
        `${ordersService.orders}/caucion`,
        {
          currency,
          amount,
          term,
          rate,
        }
      );

      const orderId = resp.data.Orden;

      dispatch(setCaucionOrder({ ...order, id: orderId }));

      dispatch(getWallet());
      dispatch(getOrders());
      dispatch(getPortfolio());
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setCaucionOrderError({
            message: error.response.message,
            status: null,
          })
        );
      }
    } finally {
      dispatch(setCreateOrderLoading(false));
    }
  }
);

export const getTickersRules = createAsyncThunk(
  "markets/getTickersRules",
  async (_, { dispatch }) => {
    try {
      dispatch(setTickersRulesLoading(true));
      dispatch(setTickersRulesError({ message: "", status: null }));

      const { data } = await API.get<TickersRulesResponse>(
        marketService.tickerRules
      );

      dispatch(setTickersRulesData(data || undefined));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setTickersRulesError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setTickersRulesLoading(false));
    }
  }
);

export const getMarketSchedules = createAsyncThunk(
  "markets/getMarketSchedules",
  async (_, { dispatch }) => {
    try {
      dispatch(removeMarketError());
      dispatch(setMarketLoading(true));

      const { data } = await API.get<MarketSchedules>(marketService.schedules);

      dispatch(setMarketSchedules(data));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setMarketError({
            message: error?.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setMarketLoading(false));
    }
  }
);

interface CreateListProps {
  name: string;
  long_ticker?: string;
}

export const createList = createAsyncThunk(
  "markets/createList",
  async ({ name, long_ticker }: CreateListProps, { dispatch }) => {
    try {
      dispatch(setListError({ message: "", status: null }));
      dispatch(setCreateListLoading(true));

      const { data } = await API.post<CreateListResponse>(marketService.lists, {
        name: name,
        long_ticker: long_ticker,
      });
      const { user } = store.getState().user;

      if (!user) return;

      const updatedUserData = {
        ...user,
        favoritesLists: [
          ...user.favoritesLists,
          {
            name,
            id_list: data.id_list,
          },
        ],
      };

      dispatch(setUserData(updatedUserData));
    } catch (error: any) {
      if (error?.response?.status !== 401) {
        dispatch(
          setListError({
            message: error?.response?.data?.Detalle
              ? error.response.data.Detalle.toString()
              : error.toString(),
            status: error?.response ? error.response.status : "500",
          })
        );
      }
    } finally {
      dispatch(setCreateListLoading(false));
    }
  }
);

export const toggleInList = async (
  isInList: boolean,
  listId: string,
  long_ticker: string
) => {
  try {
    if (isInList) {
      await API.patch(`${marketService.lists}/${listId}/delete-ticker`, {
        long_ticker: long_ticker,
      });
    } else {
      await API.patch(`${marketService.lists}/${listId}`, {
        long_tickers: [long_ticker],
      });
    }
  } catch (err: any) {
    console.error("error", err.message);
  } finally {
    queryClient.invalidateQueries(marketKeys.favoriteInstruments(listId));
  }
};

interface ConfirmBidOrderProps {
  short_ticker: string;
  amount: number;
}

export const confirmBidOrder = createAsyncThunk(
  "markets/confirmBidOrder",
  async ({ short_ticker, amount }: ConfirmBidOrderProps, { dispatch }) => {
    try {
      const { data } = await API.post<BidResponse>(ordersService.bid, {
        short_ticker,
        amount,
      });

      return data;
    } catch (error: any) {
      console.error("Error ConfirmBidOrder: ", error);

      dispatch(
        setCreateOrderError({
          message: error?.response?.data?.message
            ? error.response.data.message.toString()
            : error.toString(),
          status: error?.response ? error.response.status : "500",
        })
      );
    }
  }
);
