import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import NoCtte from "components/common/NoCtte";
import { OrderType } from "interfaces/markets";
import mainRoutes, { screenNames } from "router/routes";
import { updateOrderOptions } from "features/markets/marketsSlice";
import { RootState } from "store/store";
import { getLoggedUser } from "store/selectors/user.selector";
import { calculateNetAmount } from "utils";
import { trackAction } from "utils/amplitude";
import { Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import BodyBuy from "./components/body-buy";
import BodySell from "./components/body-sell";
import ConfirmSection from "./components/confirm-section";
import { getNumOfDecimals } from "./utils";

import styles from "./styles.module.scss";

interface TradingBodyProps {
  instrumentProp: any;
  isMarketOpen: boolean;
}

const TradingBody: React.FC<TradingBodyProps> = ({
  instrumentProp,
  isMarketOpen,
}) => {
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const dispatch = useAppDispatch();
  const isBuy = order.type === OrderType.Buy;
  const [selectedInstrument, setSelectedInstrument] = useState(instrumentProp);
  const user = useAppSelector(getLoggedUser);

  const [advancePurchase, setAdvancePurchase] = useState(false);

  const viewMoreLabel = `Ver ${advancePurchase ? "menos" : "más"} opciones`;

  const [operationAble, setOperationAble] = useState(false);

  useEffect(() => {
    if (instrumentProp) setSelectedInstrument(instrumentProp);
  }, [instrumentProp]);

  const handleOperationAble = (value: boolean) => {
    setOperationAble(value);
  };

  const handleClickViewMore = () => {
    setAdvancePurchase(!advancePurchase);
    trackAction(
      `${screenNames.trading} - Click View ${advancePurchase ? "Less" : "More"}`
    );
  };

  const tickerRules = useAppSelector(
    (state: RootState) => state.markets.tickersRulesData
  );

  const numOfDecimals = getNumOfDecimals(
    tickerRules,
    instrumentProp.id_tick_size_rule || "",
    instrumentProp.tick_size || 0
  );

  const handleChangePrice = (value: number) => {
    let newAmount = calculateNetAmount(
      order.price || selectedInstrument.bid,
      value,
      selectedInstrument.contract_size,
      selectedInstrument.price_factor
    );
    let newQuantity = value;
    if (isBuy) {
      newAmount = value;
      newQuantity =
        value /
        ((order.price ||
          selectedInstrument.ask * selectedInstrument.contract_size) /
          selectedInstrument.price_factor);
    }
    dispatch(
      updateOrderOptions({
        ...order,
        amount: newAmount,
        quantity: newQuantity,
      })
    );
    return;
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  return (
    <div className={styles.operationContainer}>
      {hasAccount && isBuy && (
        <BodyBuy
          instrumentProp={selectedInstrument}
          ableOperation={handleOperationAble}
          advancePurchase={advancePurchase}
          handleChangePrice={handleChangePrice}
          numOfDecimals={numOfDecimals}
        />
      )}
      {hasAccount && !isBuy && (
        <BodySell
          instrumentProp={selectedInstrument}
          ableOperation={handleOperationAble}
          advancePurchase={advancePurchase}
          handleChangePrice={handleChangePrice}
          numOfDecimals={numOfDecimals}
        />
      )}
      <NoCtte withImage />
      <Typography
        className={styles.moreOptions}
        onClick={handleClickViewMore}
        id="view-more-less-options"
      >
        {viewMoreLabel}
      </Typography>
      <ConfirmSection
        instrumentProp={selectedInstrument}
        isMarketOpen={isMarketOpen}
        operationAble={operationAble}
        handleChangePrice={handleChangePrice}
        numOfDecimals={numOfDecimals}
      />
    </div>
  );
};

export default TradingBody;
