import Skeleton from "react-loading-skeleton";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const PortfolioSkeletonLoader = () => {
  return (
    <div className={styles.loadingContainer}>
      <Skeleton height={40} borderRadius={px2rem(4)} />
      <div className={styles.loadingContentContainer}>
        <Skeleton count={2} containerClassName={styles.loadingContent} />
        <Skeleton count={2} containerClassName={styles.loadingContent} />
      </div>
    </div>
  );
};

export default PortfolioSkeletonLoader;
