import Text, { TextVariants } from "components/common/Text";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface CurrencySwitchProps {
  selectedCurrency: BalanceCurrencies;
  onClickSwitch: () => void;
}

const CurrencySwitch: React.FC<CurrencySwitchProps> = ({
  selectedCurrency,
  onClickSwitch,
}) => {
  const isARS = selectedCurrency === Currencies.ARS;
  return (
    <div onClick={onClickSwitch} className={styles.wrapper}>
      <Text variant={TextVariants.SemiboldUpperValueS} color="var(--slate900)">
        {isARS ? "ARS" : "US$"}
      </Text>
      <div
        className={classNames(styles.switchWrapper, { [styles.usd]: !isARS })}
      >
        {isARS ? <ARGFlag /> : <USFlag />}
      </div>
    </div>
  );
};

export default CurrencySwitch;
