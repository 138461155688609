import { useState, useEffect } from "react";

import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { usePrevious } from "hooks/usePrevious";

interface InstrumentBidVolumeProps {
  volume?: number | null;
}

const InstrumentBidVolume: React.FC<InstrumentBidVolumeProps> = ({
  volume,
}) => {
  const [bidVolumeClass, setBidVolumeClass] = useState<string>("grid-col bid");
  const previousVolume = usePrevious(volume);

  useEffect(() => {
    if (previousVolume !== volume) {
      setBidVolumeClass("grid-col bid change");
      setTimeout(() => {
        setBidVolumeClass("grid-col bid");
      }, 400);
    }
  }, [volume]);
  return (
    <div className={bidVolumeClass}>
      <Typography variant="textXS">
        <Currency withoutDecimals>{volume || "-"}</Currency>
      </Typography>
    </div>
  );
};

export default InstrumentBidVolume;
