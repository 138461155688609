import type React from "react";

import classNames from "classnames";
import { ArrowRight } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface ExchangeIconsProps {
  firstIcon: React.ReactNode;
  secondIcon: React.ReactNode;
  isReverse?: boolean;
}
const ExchangeIcons = ({
  firstIcon,
  secondIcon,
  isReverse = false,
}: ExchangeIconsProps) => {
  return (
    <div className={classNames(styles.icons, { [styles.reverse]: isReverse })}>
      {firstIcon}
      <ArrowRight size={px2rem(16)} color="var(--slate800)" />
      {secondIcon}
    </div>
  );
};

export default ExchangeIcons;
