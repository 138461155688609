import { ReactNode } from "react";

import CocosCard from "assets/icons/CocosCard";
import Text, { TextVariants } from "components/common/Text";
import { CardTypes } from "interfaces/card/enums";
import { ThemeVariants } from "interfaces/theme";
import { ArrowUpRight } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface CardBannerProps {
  onClick: () => void;
  mainContent: ReactNode;
  secondaryContent: ReactNode;
  cardType?: CardTypes;
  variant?: ThemeVariants;
}

const CardBanner: React.FC<CardBannerProps> = ({
  onClick,
  cardType,
  mainContent,
  secondaryContent,
  variant = ThemeVariants.Capital,
}) => {
  const isBlack = cardType === CardTypes.VIRTUAL;

  const mainContentVariant = cardType
    ? TextVariants.SemiboldTextS
    : TextVariants.RegularText;

  const isCapital = variant === ThemeVariants.Capital;

  const arrowColor = isCapital ? "#5EB1EF" : "#BE93E4";
  const textColor = isCapital ? "#ACD8FC" : "#E0C4F4";

  return (
    <div className={styles.cardBox} onClick={onClick}>
      <CocosCard size={64} isBlack={isBlack} />
      <div className={styles.text}>
        <Text variant={mainContentVariant} color="white">
          {mainContent}
        </Text>
        <Text
          variant={TextVariants.RegularTextS}
          color={textColor}
          className={styles.secondaryContent}
        >
          {secondaryContent}
        </Text>
      </div>
      <ArrowUpRight color={arrowColor} size={px2rem(20)} />
    </div>
  );
};

export default CardBanner;
