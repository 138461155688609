interface PropTypes {
  size?: number;
  color?: string;
}

export const ShareIcon = (props: PropTypes) => {
  const { color, size } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15 18.3334C14.3056 18.3334 13.7153 18.0903 13.2292 17.6042C12.7431 17.1181 12.5 16.5278 12.5 15.8334C12.5 15.7361 12.5069 15.6353 12.5208 15.5309C12.5347 15.4264 12.5556 15.3328 12.5833 15.25L6.70833 11.8334C6.47222 12.0417 6.20833 12.205 5.91667 12.3234C5.625 12.4417 5.31944 12.5006 5 12.5C4.30556 12.5 3.71528 12.257 3.22917 11.7709C2.74306 11.2847 2.5 10.6945 2.5 10C2.5 9.30558 2.74306 8.7153 3.22917 8.22919C3.71528 7.74308 4.30556 7.50002 5 7.50002C5.31944 7.50002 5.625 7.55919 5.91667 7.67752C6.20833 7.79585 6.47222 7.95891 6.70833 8.16669L12.5833 4.75002C12.5556 4.66669 12.5347 4.57308 12.5208 4.46919C12.5069 4.3653 12.5 4.26446 12.5 4.16669C12.5 3.47224 12.7431 2.88196 13.2292 2.39585C13.7153 1.90974 14.3056 1.66669 15 1.66669C15.6944 1.66669 16.2847 1.90974 16.7708 2.39585C17.2569 2.88196 17.5 3.47224 17.5 4.16669C17.5 4.86113 17.2569 5.45141 16.7708 5.93752C16.2847 6.42363 15.6944 6.66669 15 6.66669C14.6806 6.66669 14.375 6.6078 14.0833 6.49002C13.7917 6.37224 13.5278 6.20891 13.2917 6.00002L7.41667 9.41669C7.44444 9.50002 7.46528 9.59391 7.47917 9.69835C7.49306 9.8028 7.5 9.90335 7.5 10C7.5 10.0972 7.49306 10.1981 7.47917 10.3025C7.46528 10.407 7.44444 10.5006 7.41667 10.5834L13.2917 14C13.5278 13.7917 13.7917 13.6286 14.0833 13.5109C14.375 13.3931 14.6806 13.3339 15 13.3334C15.6944 13.3334 16.2847 13.5764 16.7708 14.0625C17.2569 14.5486 17.5 15.1389 17.5 15.8334C17.5 16.5278 17.2569 17.1181 16.7708 17.6042C16.2847 18.0903 15.6944 18.3334 15 18.3334Z"
        fill={color}
      />
    </svg>
  );
};
