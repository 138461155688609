import { createContext, useContext, useState } from "react";

import { openRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";

interface AppStateContextType {
  isBrazeInitialized: boolean;
  modalContent: React.ReactNode;
  openRightModalContent: (content?: React.ReactNode) => void;
  zendeskOpen: boolean;
  setZendeskOpen: (v: boolean) => void;
  isBalanceHidden: boolean;
  setIsBalanceHidden: (state: boolean) => void;
}

const AppStateContext = createContext<AppStateContextType | undefined>(
  undefined
);

interface AppStateProviderProps {
  value: Omit<
    AppStateContextType,
    | "openRightModalContent"
    | "modalContent"
    | "zendeskOpen"
    | "setZendeskOpen"
    | "isBalanceHidden"
    | "setIsBalanceHidden"
  >;
  children: React.ReactNode;
}

export const AppStateProvider: React.FC<AppStateProviderProps> = ({
  value,
  children,
}) => {
  const dispatch = useAppDispatch();
  const [isBalanceHidden, setIsBalanceHidden] = useState(false);
  const [
    modalContent,
    openRightModalContent,
  ] = useState<React.ReactNode | null>(null);

  const handleOpenRightModalContent = (content?: React.ReactNode) => {
    dispatch(openRightModal({ withCloseButton: false }));
    openRightModalContent(content);
  };

  const [zendeskOpen, setZendeskOpen] = useState<boolean>(false);

  return (
    <AppStateContext.Provider
      value={{
        ...value,
        zendeskOpen,
        setZendeskOpen,
        modalContent,
        isBalanceHidden,
        setIsBalanceHidden,
        openRightModalContent: handleOpenRightModalContent,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppState = (): AppStateContextType => {
  const context = useContext(AppStateContext);

  if (!context) {
    throw new Error("[AppStateContext] Missing context");
  }

  return context;
};

export default AppStateContext;
