import { IconButton, Input } from "@mui/material";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { AmountQuantityToggleIcon } from "assets/icons/amount-quantity-toggle";
import { getCurrencyLabel } from "utils";

import "./index.scss";

export enum AmountInputSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}

interface PropTypes {
  disabled?: boolean;
  value?: string;
  currencyValue?: string;
  onChangeValue: (values: NumberFormatValues) => void;
  size?: AmountInputSizes;
  classes?: string;
  withToggle?: boolean;
  onClickToggle?: () => void;
  customSuffix?: string;
  customPrefix?: string;
  disableDecimals?: boolean;
  prefix?: boolean;
}

export const UIAmountInput = ({
  disabled,
  value,
  currencyValue,
  size = AmountInputSizes.Medium,
  classes,
  customPrefix,
  customSuffix,
  withToggle = true,
  disableDecimals = false,
  onChangeValue,
  onClickToggle,
  prefix = true,
}: PropTypes) => {
  const handleChangeValue = (values: NumberFormatValues) => {
    onChangeValue(values);
  };

  const getPlaceholder = (): string => {
    if (customSuffix) {
      return `0 ${customSuffix}`;
    } else if (customPrefix) {
      return `${customPrefix} 0`;
    } else if (!currencyValue) {
      return "0";
    } else {
      return `${getCurrencyLabel(currencyValue)} 0`;
    }
  };

  return (
    <div
      className={`ui-amount-input-container ${
        onClickToggle ? "with-toggle" : "no-toggle"
      }`}
    >
      <NumberFormat
        fixedDecimalScale
        className={
          "ui-amount-input " +
          `${size} ` +
          (value === "0" ? "empty" : "") +
          `${onClickToggle ? "with-toggle" : "no-toggle"}` +
          ` ${classes || ""}`
        }
        customInput={Input}
        decimalScale={disableDecimals ? 0 : 2}
        decimalSeparator=","
        disabled={disabled}
        displayType="input"
        inputProps={{
          inputMode: "decimal",
        }}
        pattern="[0-9]+([\.,][0-9]+)?"
        placeholder={getPlaceholder()}
        prefix={prefix ? `${getCurrencyLabel(currencyValue)} ` : ""}
        thousandSeparator="."
        value={value ? value.replace(".", ",") : ""}
        onValueChange={handleChangeValue}
      />
      {withToggle && onClickToggle && (
        <IconButton
          aria-label="menu"
          className="dots-icon arrows-icon-introjs"
          color="inherit"
          edge="start"
          onClick={onClickToggle}
        >
          <AmountQuantityToggleIcon />
        </IconButton>
      )}
    </div>
  );
};
