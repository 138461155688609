import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { Instruments } from "interfaces/markets";

interface InstrumentLogoProps {
  logoFileName?: string | null;
  instrumentType?: Instruments;
}

const InstrumentLogo: React.FC<InstrumentLogoProps> = ({
  logoFileName,
  instrumentType,
}) => (
  <div className="grid-col img">
    <div className="market-logo">
      <InstrumentLogoUI
        logoFileName={logoFileName}
        type={instrumentType}
        variant={InstrumentLogoVariants.Primary}
      />
    </div>
  </div>
);

export default InstrumentLogo;
