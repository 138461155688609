import BTCIcon, { IconProps } from "assets/icons/BTC";
import DAIIcon from "assets/icons/DAI";
import ETHIcon from "assets/icons/ETH";
import LINKIcon from "assets/icons/LINK";
import POLYGONIcon from "assets/icons/Polygon";
import USDCIcon from "assets/icons/USDC";
import USDTIcon from "assets/icons/USDT";

export enum Cryptos {
  BTC = "BTC",
  ETH = "ETH",
  DAI = "DAI",
  USDT = "USDT",
  USDC = "USDC",
  LINK = "LINK",
  POL = "POL",
}

export interface Crypto {
  name: string;
  icon: React.FC<IconProps>;
  ticker: Cryptos;
  color: string;
}

export const CRYPTOS: Crypto[] = [
  {
    name: "Bitcoin",
    icon: BTCIcon,
    ticker: Cryptos.BTC,
    color: "var(--crypto-btc)",
  },
  {
    name: "Ethereum",
    icon: ETHIcon,
    ticker: Cryptos.ETH,
    color: "var(--crypto-eth)",
  },
  {
    name: "Dai",
    icon: DAIIcon,
    ticker: Cryptos.DAI,
    color: "var(--crypto-dai)",
  },
  {
    name: "Tether",
    icon: USDTIcon,
    ticker: Cryptos.USDT,
    color: "var(--crypto-usdt)",
  },
  {
    name: "USD Coin",
    icon: USDCIcon,
    ticker: Cryptos.USDC,
    color: "var(--crypto-usdc)",
  },
  {
    name: "Chainlink",
    icon: LINKIcon,
    ticker: Cryptos.LINK,
    color: "var(--crypto-link)",
  },
  {
    name: "Polygon",
    icon: POLYGONIcon,
    ticker: Cryptos.POL,
    color: "var(--crypto-pol)",
  },
];

export const getCrypto = (value: string) => {
  const foundCrypto = CRYPTOS.find(({ ticker }) => ticker === value);
  return foundCrypto || CRYPTOS[0];
};

export enum Networks {
  POLYGON = "Polygon",
  ETH = "Ethereum",
  BTC = "Bitcoin",
}
export interface Network {
  icon: React.FC<IconProps>;
  name: Networks;
}

export const NETWORKS: Network[] = [
  {
    icon: ETHIcon,
    name: Networks.ETH,
  },
  {
    icon: POLYGONIcon,
    name: Networks.POLYGON,
  },
  {
    icon: BTCIcon,
    name: Networks.BTC,
  },
];

export const getNetwork = (value: Networks | string) => {
  const foundNetwork = NETWORKS.find(({ name }) => name === value);

  return foundNetwork || NETWORKS[0];
};

export enum cryptoStatus {
  PENDING = "PENDING",
  PENDING_DOCUMENTS = "PENDING_DOCUMENTS",
  PENDING_ADDRESSES = "PENDING_ADDRESSES",
  APPROVED = "APPROVED",
}
