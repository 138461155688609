import { MouseEvent } from "react";

import { Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { Instruments } from "interfaces/markets";

import styles from "./styles.module.scss";

interface HeaderProps {
  bondType: Instruments.BONOS_CORP | Instruments.BONOS_PUBLICOS;
  handleChangeType: (
    e: MouseEvent<HTMLElement>,
    v: Instruments.BONOS_CORP | Instruments.BONOS_PUBLICOS
  ) => void;
}

const Header: React.FC<HeaderProps> = ({ bondType, handleChangeType }) => (
  <div className={styles.headerWrapper}>
    <Typography className={styles.title}>Tipo bono</Typography>
    <ToggleButtonGroup
      exclusive
      aria-label="text alignment"
      className={styles.toggle}
      value={bondType}
      onChange={handleChangeType}
    >
      <ToggleButton value={Instruments.BONOS_PUBLICOS}>
        <Typography variant="textS_bold">PÚBLICOS</Typography>
      </ToggleButton>
      <ToggleButton value={Instruments.BONOS_CORP}>
        <Typography variant="textS_bold">CORPORATIVOS</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  </div>
);

export default Header;
