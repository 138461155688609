import { useEffect, useState } from "react";

import { IconButton } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { setCurrentScreen } from "features/global/globalSlice";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackScreen } from "utils/amplitude";
import { CloseIcon } from "assets/icons/close";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import "./index.scss";

export default function RightModalDrawer() {
  const dispatch = useAppDispatch();
  const { open, onClose, withCloseButton } = useAppSelector(
    (state) => state.rightModal
  );
  const { modalContent } = useAppState();
  const currentScreen = useAppSelector(getCurrentScreen);
  const [previousScreen, setPreviousScreen] = useState(currentScreen);

  useEffect(() => {
    if (!open) {
      trackScreen(previousScreen, currentScreen);
      dispatch(setCurrentScreen(previousScreen));
      return;
    }
    setPreviousScreen(currentScreen);
  }, [open]);

  const toggleDrawer = () => {
    onClose && onClose();
    dispatch(closeRightModal());
  };

  return (
    <Drawer
      anchor="right"
      className="comp-desktop-right-modal"
      classes={{ paper: "drawer-content" }}
      open={open}
      onClose={toggleDrawer}
    >
      {withCloseButton && (
        <IconButton className="close-button" onClick={toggleDrawer}>
          <CloseIcon size={20} />
        </IconButton>
      )}
      {modalContent}
    </Drawer>
  );
}
