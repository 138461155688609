enum FeatureFlags {
  ENABLE_USD_CRYPTO_TRANSFER = "enable_usd_crypto_transfers",
  ENABLE_CARD = "enable_card",
  ENABLE_MEP_REDESIGN = "enable_mep_redesign",
  ENABLE_BID = "enable_bid",
  ENABLE_PORTFOLIO_DOUBLE_REQUEST = "temp_double_portfolio_request_enabled",
  ENABLE_CARD_OUT_OF_STOCK = "card_out_of_stock",
  ENABLE_FCI_EARNINGS = "enable_fci_earnings",
}

export default FeatureFlags;
