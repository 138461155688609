import { ACADEMY_URL } from "constants/index";

import { Browser } from "@capacitor/browser";
import AddCrypto from "assets/illustrations/AddCrypto";
import Eye from "assets/illustrations/Eye";
import Swap from "assets/illustrations/Swap";

import Card from "./Card";

import styles from "./styles.module.scss";

const CardCarrousel: React.FC = () => {
  const handleOnClick = (link: string) => {
    Browser.open({
      url: link,
    });
  };

  return (
    <div className={styles.cardCarrouselContainer}>
      <Card
        className={styles.first}
        title="Iniciar en el mundo Crypto"
        illustration={AddCrypto}
        onClick={() =>
          handleOnClick(`${ACADEMY_URL}courses/primeros-pasos-al-mundo-crypto`)
        }
      />
      <Card
        className={styles.second}
        title="¿Qué es una blockchain?"
        illustration={Eye}
        onClick={() =>
          handleOnClick(
            "https://cocos.capital/university/que-son-las-cryptomendas"
          )
        }
      />
      <Card
        className={styles.third}
        title="¿Qué son las criptomonedas?"
        illustration={Swap}
        onClick={() =>
          handleOnClick(
            "https://cocos.capital/university/que-son-las-cryptomendas"
          )
        }
      />
    </div>
  );
};

export default CardCarrousel;
