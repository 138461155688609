export enum Operations {
  SWAP = "SWAP",
  SEND = "SEND",
  RECEIVE = "RECEIVE",
  BUY = "BUY",
  SELL = "SELL",
  SETTLEMENT = "SETTLEMENT",
  PROFIT = "PROFIT",
  SUBSCRIPTION = "SUBSCRIPTION",
  REDEMPTION = "REDEMPTION",
}

export const operationsLabels = {
  [Operations.SWAP]: "Convertiste",
  [Operations.SEND]: "Enviaste",
  [Operations.RECEIVE]: "Recibiste",
  [Operations.BUY]: "Compraste",
  [Operations.SELL]: "Vendiste",
  [Operations.SETTLEMENT]: "Vencimiento",
  [Operations.PROFIT]: "Rendimiento",
  [Operations.SUBSCRIPTION]: "Suscribiste",
  [Operations.REDEMPTION]: "Rescataste",
};
