//TODO Change to util function
interface PropTypes {
  children: number | string | undefined | null;
}

export const BigNumberFormatter = ({ children }: PropTypes) => {
  if (Number.isNaN(Number(children))) return null;

  const formatter = Intl.NumberFormat("es", { notation: "compact" });

  return <>{formatter.format(Number(children))}</>;
};
