import * as React from "react";

import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { motion, LayoutGroup } from "framer-motion";
import { Currencies } from "interfaces/wallet";

import "./index.scss";

interface PropTypes {
  currencyValue: string;
  onChangeCurrency: (value: Currencies) => void;
  label?: string;
  disabled?: boolean;
}

export default function UICurrencyToggle(props: PropTypes) {
  const { currencyValue, onChangeCurrency, label, disabled } = props;

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    value: Currencies | null
  ) => {
    event.stopPropagation();
    if (value !== null) {
      onChangeCurrency(value);
    }
  };

  const spring = {
    duration: 0.2,
  };

  return (
    <LayoutGroup>
      <div className="currency-toggle-component">
        {label && (
          <Typography className="currency-toggle-label" variant="textS">
            {label}
          </Typography>
        )}
        <ToggleButtonGroup
          exclusive
          aria-label="text alignment"
          className="ui-currency-toggle"
          value={currencyValue}
          onChange={handleAlignment}
          disabled={disabled}
        >
          <ToggleButton
            layout
            aria-label="ARS"
            component={motion.button}
            transition={spring}
            value="ARS"
          >
            <Typography variant="textS_bold">ARS</Typography>
          </ToggleButton>
          <ToggleButton
            layout
            aria-label="USD"
            component={motion.button}
            transition={spring}
            value="USD"
          >
            <Typography variant="textS_bold">USD</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </LayoutGroup>
  );
}
