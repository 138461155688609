import { VARIATION_TEXT } from "constants/portfolio";

import { ampli } from "ampli";
import { StatusOnboarding } from "interfaces/createAccount";
import { PeriodTime } from "interfaces/portfolio";
import { ThemeVariants } from "interfaces/theme";
import { Currencies } from "interfaces/wallet";
import { ArrowDownToLine } from "lucide-react";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import px2rem from "utils/px2rem";

import QuietButton, { QuietButtonSizes } from "../QuietButton";
import BalanceWithVariation from "../BalanceWithVariation";

import styles from "./styles.module.scss";

interface TotalBalanceCardProps {
  variant: ThemeVariants;
  trackingEvent?: () => void;
  setIsNoAccountSheetOpen: (isOpen: boolean) => void;
}

const TotalBalanceCard: React.FC<TotalBalanceCardProps> = ({
  variant,
  trackingEvent,
  setIsNoAccountSheetOpen,
}) => {
  const navigate = useNavigate();
  const user = useSelector(getLoggedUser);

  const oneDayVariation = 0;
  const isDisabled =
    !user ||
    !user.hasAccount ||
    user.statusOnboarding !== StatusOnboarding.APPROVED;

  const onClickDeposit = () => {
    if (isDisabled) {
      ampli.onboardingBottomsheetViewed({ referral: "deposit" });
      setIsNoAccountSheetOpen(true);
      return;
    }
    trackingEvent && trackingEvent();

    if (variant === ThemeVariants.Crypto) {
      navigate(CryptoRoutes.RECEIVE);
      return;
    }
    navigate(mainRoutes.receive);
  };

  return (
    <div className={styles.totalBalanceCardContainer}>
      <BalanceWithVariation
        variant={variant}
        variationLabel={VARIATION_TEXT[oneDayVariation]}
        balanceCurrency={Currencies.ARS}
        balancePeriod={PeriodTime.DAY}
      />
      <QuietButton
        className={styles.button}
        size={QuietButtonSizes.Small}
        variant={variant}
        onClick={onClickDeposit}
      >
        <ArrowDownToLine size={px2rem(16)} color={"var(--slate900)"} /> Ingresar
      </QuietButton>
    </div>
  );
};

export default TotalBalanceCard;
