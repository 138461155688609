import { SVGProps } from "react";
interface ArrowIndicatorIconProps extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}
export const ArrowIndicatorIcon: React.FC<ArrowIndicatorIconProps> = ({
  color = "002C65",
  size = 9,
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      {...props}
    >
      <path d="M4.5 7L0.602886 0.25L8.39711 0.25L4.5 7Z" fill={color} />
    </svg>
  );
};
