import { Typography } from "@mui/material";
import Marquee from "react-fast-marquee";

interface MarqueeTextProps {
  textToMarquee: string;
  textLength?: number;
  variantTypography?: "textS" | "textS_bold" | "textXS";
}

export const MarqueeText: React.FC<MarqueeTextProps> = ({
  textToMarquee,
  textLength = 0,
  variantTypography,
}) => {
  const isDinamic = textToMarquee.length > textLength;

  return (
    <Marquee gradient={false} play={isDinamic} pauseOnHover={!isDinamic}>
      <Typography
        noWrap
        className="short-name"
        variant={variantTypography}
        marginRight={2}
      >
        {textToMarquee}
      </Typography>
    </Marquee>
  );
};
