import { IconButton, Tooltip } from "@mui/material";
import { ArrowIndicatorIcon } from "assets/icons/arrow-indicator";
import { getColor } from "utils/getColor";

import styles from "./styles.module.scss";

interface TooltipProps {
  title: string;
  icon: JSX.Element;
  color: string;
}

const UITooltip: React.FC<TooltipProps> = ({ title, icon, color }) => {
  const backgroundColor = "#ECF2FC";
  const fontColor = getColor("--text-color-primary");
  return (
    <Tooltip
      className={styles.tooltip}
      enterTouchDelay={0}
      placement="bottom"
      title={
        <div>
          <div
            style={{
              color: fontColor,
              fontFamily: "Nunito Sans",
              fontSize: "12px",
              fontWeight: 700,
              lineHeight: "16px",
              marginBottom: "8px",
            }}
          >
            {title}
          </div>
          <div
            style={{
              color: fontColor,
              fontFamily: "Nunito Sans",
              fontSize: "12px",
              fontWeight: 400,
              lineHeight: "16px",
            }}
          >
            La barra gris indica el mínimo y máximo diario.
            <br />
            La barra <b>{color}</b> es la variación entre el precio actual (
            {<ArrowIndicatorIcon color={fontColor} />}) y el precio de apertura.
          </div>
        </div>
      }
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: backgroundColor,
            width: "178px",
            "&  .MuiTooltip-arrow": {
              color: backgroundColor,
            },
          },
        },
      }}
    >
      <IconButton>{icon}</IconButton>
    </Tooltip>
  );
};

export default UITooltip;
