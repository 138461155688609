import { useEffect, useState } from "react";

import { ampli } from "ampli";
import { useNavigate, Navigate } from "react-router-dom";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import BasicWrapper from "components/common/BasicWrapper";
import SegmentedControl from "components/common/SegmentedControl";
import NoCtte from "components/common/NoCtte";
import mainRoutes from "router/routes";
import { getCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import { MessageCircleQuestion } from "lucide-react";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import px2rem from "utils/px2rem";

import ReceiveCableDollar from "./ReceiveCableDollar";
import ReceivePage, { ReceiveCurrency } from "./ReceivePage";

import styles from "./styles.module.scss";

enum ReceiveTabs {
  ARS = "ARS",
  USD = "US$",
  USD_CABLE = "US$ Cable",
}

const CapitalReceivePage = ({ isDesktop = false }: { isDesktop?: boolean }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<ReceiveTabs>(ReceiveTabs.ARS);
  const previousScreen = useAppSelector(getCurrentScreen);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { hasAccount } = user;

  if (!hasAccount) {
    return (
      <div className={styles.emptyState}>
        <NoCtte withImage />
      </div>
    );
  }

  const receiveOptions = [
    { name: ReceiveTabs.ARS, icon: <ARGFlag size={px2rem(16)} /> },
    { name: ReceiveTabs.USD, icon: <USFlag size={px2rem(16)} /> },
    { name: ReceiveTabs.USD_CABLE, icon: <USFlag size={px2rem(16)} /> },
  ];

  const currency = {
    [ReceiveTabs.ARS]: "ars",
    [ReceiveTabs.USD]: "mep",
    [ReceiveTabs.USD_CABLE]: "ccl",
  };

  const onClose = () => {
    ampli.receiveExit({ referral: "home" });
    navigate(-1);
  };

  const handleBackHome = () => {
    ampli.receiveExit({ referral: "home" });
    navigate(mainRoutes.home);
  };

  const handleTabClick = (option: ReceiveTabs) => {
    ampli.receiveCurrencySelected({ currency: currency[option] });
    setSelectedTab(option);
  };

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const navigationHeaderProps = {
    rightIcon: isDesktop ? null : (
      <MessageCircleQuestion color="var(--blue900)" />
    ),
    rightIconClick: () => navigate(mainRoutes.help),
    title: "Ingresá dinero",
    onClick: isDesktop ? handleCloseSidebar : onClose,
    withCloseIcon: isDesktop,
  };

  useEffect(() => {
    ampli.receiveScreenViewed({ referral: previousScreen });
  }, []);

  const secondaryButtonProps = isDesktop
    ? undefined
    : { children: "Volver al inicio", onClick: handleBackHome };

  return (
    <div className={styles.capitalReceivePage}>
      <BasicWrapper
        navigationHeaderProps={navigationHeaderProps}
        secondaryButtonProps={secondaryButtonProps}
      >
        <div className={styles.tabControls}>
          <SegmentedControl
            options={receiveOptions}
            selected={selectedTab}
            onClick={(option) => handleTabClick(option)}
          />
        </div>
        {selectedTab === ReceiveTabs.ARS && (
          <ReceivePage aliasCurrency={ReceiveCurrency.ARS} />
        )}
        {selectedTab === ReceiveTabs.USD && (
          <ReceivePage aliasCurrency={ReceiveCurrency.USD} />
        )}
        {selectedTab === ReceiveTabs.USD_CABLE && <ReceiveCableDollar />}
      </BasicWrapper>
    </div>
  );
};

export default CapitalReceivePage;
