import { useEffect, useState } from "react";

import { IconButton, Typography } from "@mui/material";
import { DotsIcon } from "assets/icons/dots";
import { Currency } from "components/common/currency";
import { usePrevious } from "hooks/usePrevious";
import { InstrumentData } from "interfaces/api-responses";
import { useAppSelector } from "hooks/redux";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackAction } from "utils/amplitude";

interface InstrumentVolumeProps {
  market: InstrumentData;
  volume?: number | null;
  onClickMarketOptions: (market: InstrumentData) => void;
}

const InstrumentVolume: React.FC<InstrumentVolumeProps> = ({
  market,
  volume,
  onClickMarketOptions,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);

  const handleClickMarketMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    trackAction(`${currentScreen} - Open Instrument Options`);
    onClickMarketOptions(market);
  };

  const [volumeClass, setVolumeClass] = useState<string>("grid-col");
  const previousLow = usePrevious(volume);

  useEffect(() => {
    if (previousLow !== volume) {
      setVolumeClass("grid-col change");
      setTimeout(() => {
        setVolumeClass("grid-col");
      }, 400);
    }
  }, [volume]);

  return (
    <div className={volumeClass}>
      <Typography variant="textXS">
        <Currency withoutDecimals>{volume || "-"}</Currency>
      </Typography>

      <IconButton
        aria-label="menu"
        className="dots-icon"
        color="inherit"
        edge="start"
        onClick={handleClickMarketMenu}
      >
        <DotsIcon />
      </IconButton>
    </div>
  );
};

export default InstrumentVolume;
