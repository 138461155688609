import { useEffect } from "react";

import classNames from "classnames";
import getPlatform from "utils/getPlatform";
import GoogleTagManager from "react-gtm-module";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import type {
  CocosFund,
  FundScreen,
} from "components/page-capital-funds/helpers";
import { ampli } from "ampli";
import NavigationHeader from "components/common/NavigationHeader";
import FixedHeader from "components/common/FixedHeader";
import { FundsHoldingResponse } from "interfaces/fci";
import { FbEvents, trackFbEvent } from "utils/fbTracking";
import { PaymentCurrencies } from "interfaces/pay/enums";
import { useFundsHoldings } from "apis/fci/queries/useFundsHoldings";
import { useFunds } from "context/FundsProvider";
import { Spinner } from "components/common/spinner";

import FundsHeader from "./components/FundsHeader";
import FundsChart from "./components/FundsChart";
import FundsMovements from "./components/FundsMovements";
import FundsButtons from "./components/FundsButtons";

import styles from "./styles.module.scss";

interface FundsReturnsProps {
  holdings: FundsHoldingResponse;
  selectedFund: CocosFund;
  onClick: () => void;
  setScreen: (opt: FundScreen) => void;
}

const FundsHoldings = ({
  holdings,
  selectedFund,
  onClick,
  setScreen,
}: FundsReturnsProps) => {
  const { isIOS } = getPlatform();
  const { isMobile } = useDeviceCheck();
  const { offset, selectedPeriod: period } = useFunds();

  const {
    data: holdingResponse,
    isLoading: isHoldingsLoading,
    isError,
  } = useFundsHoldings({
    securityId: selectedFund.securityId,
    period,
    offset,
  });

  const isChartLoading = isHoldingsLoading || !holdingResponse || isError;

  useEffect(() => {
    ampli.fciScreenViewed({ tab: "Returns", ticker: selectedFund.ticker });

    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_screen_viewed",
        ticker: selectedFund.ticker,
      },
    });

    if (selectedFund.ticker === PaymentCurrencies.COCORMA) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.fbq("track", "cocorma_fci_screen_viewed");

      trackFbEvent(FbEvents.COCORMA_SCREEN_VIEWED);
    }
  }, []);

  return (
    <div
      className={classNames(styles.capitalFundsWrapper, {
        [styles.isIos]: isIOS,
      })}
    >
      <FixedHeader className={styles.fundsHeader}>
        <NavigationHeader
          title="Fondos de inversión"
          onClick={onClick}
          badgeText="24/7"
          withCloseIcon={!isMobile}
        />
      </FixedHeader>
      <FundsHeader fund={selectedFund} holding={holdings?.holding} />
      {isChartLoading ? (
        <div className={styles.chartLoadingWrapper}>
          <Spinner />
        </div>
      ) : (
        <FundsChart
          rate={selectedFund.rate}
          maxOffset={holdingResponse.maxOffset}
          historicalProfits={holdingResponse.historicalProfits}
          periodProfit={holdingResponse.periodProfit}
        />
      )}
      <FundsMovements dailyMovements={holdings.dailyMovements} />
      <FundsButtons handleOnClick={setScreen} />
    </div>
  );
};

export default FundsHoldings;
