import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { InfoIcon } from "assets/icons/info";
import { MarketSection, Sections } from "interfaces/markets";
import mainRoutes from "router/routes";

interface EmptyMarketListProps {
  selectedInstrumentType?: MarketSection;
}

const EmptyMarketList: React.FC<EmptyMarketListProps> = ({
  selectedInstrumentType,
}) => {
  const navigate = useNavigate();

  const marketRedirect = () => {
    navigate(mainRoutes.market);
  };

  if (selectedInstrumentType && selectedInstrumentType !== Sections.FAVORITOS)
    return null;

  return (
    <div className="no-actives">
      <InfoIcon size={26} />
      <Typography className="no-actives-info" variant="textM">
        Todavía no agregaste ningún activo a tu lista. Desde mercado podés
        agregar uno nuevo.
      </Typography>
      <ButtonUI
        size={ButtonSizes.Medium}
        variant={ButtonVariants.Primary}
        onClick={marketRedirect}
      >
        <Typography variant="buttonL">Ir a mercado</Typography>
      </ButtonUI>
    </div>
  );
};

export default EmptyMarketList;
