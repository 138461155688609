import { VARIATION_TEXT } from "constants/portfolio";

import Text, { TextVariants } from "components/common//Text";
import AvailableLabel from "components/common/AvailableLabel";
import Variation from "components/common/CurrentVariation";
import { ThemeVariants } from "interfaces/theme";
import { useAppState } from "context/AppStateProvider";
import { useAppSelector } from "hooks/redux";
import { getLoggedUser } from "store/selectors/user.selector";
import { useBalanceQuery } from "apis/portfolio/queries/useBalance";
import { Currencies, BalanceCurrencies } from "interfaces/wallet";
import { PeriodTime } from "interfaces/portfolio";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";

import styles from "./styles.module.scss";

interface BalanceWithVariationProps {
  dateToDisplay?: string;
  variationLabel?: string;
  variant: ThemeVariants;
  balanceCurrency: BalanceCurrencies;
  balancePeriod: PeriodTime;
}

const BalanceWithVariation: React.FC<BalanceWithVariationProps> = ({
  dateToDisplay,
  variationLabel,
  variant,
}) => {
  const { isBalanceHidden } = useAppState();
  const user = useAppSelector(getLoggedUser);

  const oneDayVariation = 0;
  const isVariationVisible = false; // TODO: this will be removed when new endpoint data is ready

  if (!user?.hasAccount) {
    return (
      <div className={styles.balanceContainer}>
        <AvailableLabel available={0} variant={variant} />
        {isVariationVisible && (
          <Variation
            value={0}
            amount={0}
            text={VARIATION_TEXT[oneDayVariation]}
            hideBalances={isBalanceHidden}
          />
        )}
      </div>
    );
  }

  const { data, isLoading, error } = useBalanceQuery({
    currency: Currencies.ARS,
    period: PeriodTime.DAY,
  });

  if (isLoading || error) {
    return (
      <div className={styles.skeletonWrapper}>
        <Skeleton className={classNames([styles.skeleton, styles.big])} />
        <Skeleton className={styles.skeleton} />
      </div>
    );
  }

  return (
    <div className={styles.balanceContainer}>
      <AvailableLabel available={data.totalBalance} variant={variant} />
      {dateToDisplay ? (
        <Text variant={TextVariants.RegularTextS}>{dateToDisplay}</Text>
      ) : (
        <>
          {isVariationVisible && (
            <Variation
              value={data?.variation.percentage}
              amount={data?.variation.amount}
              text={variationLabel}
              hideBalances={isBalanceHidden}
            />
          )}
        </>
      )}
    </div>
  );
};

export default BalanceWithVariation;
