import { Typography } from "@mui/material";

import styles from "./styles.module.scss";

interface WithdrawErrorProps {
  error: string;
}

const WithdrawError: React.FC<WithdrawErrorProps> = ({ error }) => {
  const getWithdawalError = () => {
    const fixedError = error.replace("\n", "");
    const errors: { [key: string]: string | null } = {
      "TX500: La cuenta destino no está en condiciones de operar":
        "La cuenta destino no está en condiciones de operar",
      "TX042: El CBU/CVU o Alias de CBU/CVU indicado se encuentra inhabilitado":
        "El CBU/CVU o Alias de CBU/CVU indicado se encuentra inhabilitado",
      "TX500: No se puede realizar transfrerencias entre cuentas de distinta moneda.":
        "No se puede realizar transfrerencias entre cuentas de distinta moneda.",
      default: null,
    };

    return errors[fixedError] || errors.default;
  };

  const subtitle = getWithdawalError();

  return (
    <div className={styles.text_wrapper}>
      <Typography component="h2" variant="textL_bold">
        {subtitle
          ? "No se pudo realizar la transferencia a la cuenta indicada."
          : "No se pudo realizar la transferencia."}
      </Typography>
      {subtitle && (
        <Typography component="p" variant="textS">
          {subtitle}
        </Typography>
      )}
    </div>
  );
};

export default WithdrawError;
