import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";
import { ThemeVariants } from "interfaces/theme";
import Error from "assets/illustrations/Error";
import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";

import styles from "./styles.module.scss";

interface CococCardErrorProps {
  isConfigError?: boolean;
}

const CococCardError: React.FC<CococCardErrorProps> = ({
  isConfigError = true,
}) => {
  const navigate = useNavigate();

  const emailLink = (
    <Text
      component="a"
      href="mailto:atencion@cocos.capital"
      variant={TextVariants.SemiboldText}
      color="var(--slate800)"
    >
      atencion@cocos.capital
    </Text>
  );

  const errorText = isConfigError ? "configurar" : "acceder a";

  return (
    <BasicWrapper
      themeVariant={ThemeVariants.Pay}
      navigationHeaderProps={{
        title: "Algo salió mal",
        onClick: () => navigate(-1),
        withCloseIcon: false,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: () => navigate(mainRoutes.home),
      }}
    >
      <div className={styles.errorContainer}>
        <Error size={px2rem(84)} />
        <Text variant={TextVariants.RegularText} color="var(--slate800)">
          Hubo un error al momento de {errorText} tu cuenta. Por favor,
          contactate con soporte a {emailLink}.
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default CococCardError;
