import React from "react";

import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";

import InputField from "./InputField";

import "./index.scss";

export enum InputSizes {
  Medium = "medium",
  Full = "full",
}

interface PropTypes {
  onChange?: (value: string) => void;
  designPatternFlag?: boolean;
  value?: string;
  error?: boolean;
  helperText?: string;
  errorMessage?: string;
  label?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: "end" | "start" | undefined;
  placeholder?: string;
  type?: string;
  onKeyDown?: (event: any) => void;
  size?: InputSizes;
  onBlur?: (event?: any) => void;
  iconRight?: JSX.Element;
  onClick?: (event?: any) => void;
  btnClick?: (event?: any) => void;
  newClass?: any;
  id?: string;
  name?: string;
}

export const UIInput = (props: PropTypes) => {
  const {
    value,
    error,
    disabled,
    errorMessage,
    helperText,
    label,
    icon,
    iconPosition,
    placeholder,
    iconRight,
    type,
    designPatternFlag = false,
    onChange,
    onBlur,
    onKeyDown,
    onClick,
    size = InputSizes.Medium,
    btnClick,
    newClass,
    id,
    name,
  } = props;

  const getStateClassName = () => {
    if (!value || value === "") {
      if (error) {
        return "empty error";
      }

      return "empty";
    }
    if (error) {
      return "completed error";
    }

    return "completed";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  if (designPatternFlag) {
    return (
      <InputField
        label={label}
        type={type}
        placeholder={placeholder}
        iconRight={iconRight}
        onIconClick={btnClick}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        disabled={disabled}
        id={id}
        name={name}
      />
    );
  }

  return (
    <div
      className={`ui-input ${getStateClassName()} ${
        size === InputSizes.Full ? "full" : ""
      }`}
    >
      {label && <Typography variant="textS">{label}</Typography>}
      <OutlinedInput
        autoComplete="off"
        className={newClass ? newClass : "ui-input-outlined"}
        disabled={disabled}
        endAdornment={
          <InputAdornment
            className={btnClick ? "cursor" : ""}
            onClick={btnClick}
            position="end"
          >
            {iconRight}
          </InputAdornment>
        }
        error={error}
        placeholder={placeholder || "Placeholder"}
        startAdornment={
          <InputAdornment
            className={btnClick ? "cursor" : ""}
            position={iconPosition || "start"}
            onClick={btnClick}
          >
            {icon}
          </InputAdornment>
        }
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        id={id}
        name={name}
      />
      <FormHelperText>{error ? errorMessage : helperText}</FormHelperText>
    </div>
  );
};
