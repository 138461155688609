import { IconProps } from "assets/icons/BTC";

interface CocosCardProps extends IconProps {
  isBlack?: boolean;
}

const CocosCard: React.FC<CocosCardProps> = ({ size, isBlack, ...props }) => {
  const cardColor = isBlack ? "#1C2024" : "#113264";
  const logoColor = isBlack ? "#373B41" : "#104D87";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={Number(size) / 1.6}
      viewBox="0 0 64 40"
      fill="none"
      {...props}
    >
      <path
        d="M64 35.6011L64 4.39886C64 1.96944 62.0208 -1.96631e-07 59.5793 -4.39188e-07L4.42072 -5.91906e-06C1.97923 -6.16162e-06 3.61904e-06 1.96943 3.37768e-06 4.39886L2.77807e-07 35.6011C3.64491e-08 38.0306 1.97922 40 4.42072 40L59.5793 40C62.0208 40 64 38.0306 64 35.6011Z"
        fill={cardColor}
      />
      <path
        d="M7.36566 9.16926C8.0341 9.1673 8.69461 9.31382 9.29936 9.5982C9.90411 9.88259 10.438 10.2977 10.8623 10.8135C11.406 10.1536 11.7508 9.353 11.8564 8.50492C11.962 7.65684 11.824 6.79626 11.4587 6.02346C11.0933 5.25067 10.5155 4.59748 9.79271 4.14003C9.06989 3.68258 8.23176 3.4397 7.37602 3.4397C6.52028 3.4397 5.68216 3.68258 4.95933 4.14003C4.2365 4.59748 3.65874 5.25066 3.29338 6.02346C2.92801 6.79626 2.79009 7.65684 2.89568 8.50492C3.00128 9.353 3.34604 10.1536 3.88979 10.8135C4.31187 10.3004 4.84239 9.8869 5.44332 9.60262C6.04426 9.31835 6.70072 9.17036 7.36566 9.16926Z"
        fill="#DC3E42"
      />
      <path
        d="M11.8813 13.678C11.8816 12.6317 11.5153 11.6183 10.8458 10.8135C10.4223 11.3275 9.89001 11.7415 9.28725 12.0257C8.68448 12.3099 8.02618 12.4573 7.3596 12.4573C6.69303 12.4573 6.03472 12.3099 5.43195 12.0257C4.82919 11.7415 4.29692 11.3275 3.87337 10.8135C3.41333 11.3718 3.09448 12.0324 2.94372 12.7396C2.79296 13.4468 2.81471 14.1798 3.00713 14.8768C3.19956 15.5739 3.55701 16.2145 4.04935 16.7446C4.54168 17.2747 5.15446 17.6788 5.83598 17.9228C6.51751 18.1668 7.2478 18.2436 7.96525 18.1466C8.68271 18.0496 9.36628 17.7817 9.95832 17.3655C10.5504 16.9493 11.0335 16.3971 11.367 15.7553C11.7005 15.1135 11.8745 14.4011 11.8744 13.678L11.8813 13.678Z"
        fill="#FFC419"
      />
      <path
        d="M7.36596 12.4578C8.03439 12.4598 8.69491 12.3133 9.29965 12.0289C9.9044 11.7445 10.4383 11.3294 10.8625 10.8135C10.439 10.2995 9.90673 9.88553 9.30396 9.6013C8.7012 9.31708 8.04289 9.16968 7.37631 9.16968C6.70974 9.16968 6.05144 9.31708 5.44867 9.6013C4.8459 9.88552 4.31364 10.2995 3.89008 10.8135C4.31216 11.3267 4.84268 11.7402 5.44362 12.0245C6.04455 12.3087 6.70102 12.4567 7.36596 12.4578Z"
        fill="#F7881A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.926 22.1698L56.926 21.7786C56.926 20.9638 56.2646 20.3032 55.4487 20.3032L51.7478 20.3032L53.2589 22.1698L56.9244 22.1698C56.925 22.1698 56.9255 22.1698 56.926 22.1698ZM51.3177 20.3032L47.6236 20.3032C46.8077 20.3032 46.1463 20.9638 46.1463 21.7786L46.1463 22.1702L49.8063 22.1702L51.3177 20.3032ZM46.1463 22.5044L46.1463 26.3131L49.7191 26.3131L49.7191 22.5044L46.1463 22.5044ZM46.1463 26.6474L46.1463 27.0389C46.1463 27.8538 46.8077 28.5143 47.6236 28.5143L51.3178 28.5143L49.8064 26.6474L46.1463 26.6474ZM51.7477 28.5143L55.4487 28.5143C56.2646 28.5143 56.926 27.8538 56.926 27.0389L56.926 26.6474C56.9255 26.6474 56.925 26.6474 56.9244 26.6474L53.2591 26.6474L51.7477 28.5143ZM56.926 26.3131L56.926 22.5041C56.9255 22.5041 56.925 22.5041 56.9244 22.5041L53.3464 22.5041L53.3464 26.3131L56.9244 26.3131C56.925 26.3131 56.9255 26.3131 56.926 26.3131ZM51.5327 20.5688L53.0121 22.3961L53.0121 26.4213L51.5327 28.2486L50.0534 26.4213L50.0534 22.3961L51.5327 20.5688Z"
        fill="url(#paint0_linear_14353_38314)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4175 15.1816L36.518 16.6707L35.7621 15.2229C34.3952 12.5721 31.5476 11.3035 29.4213 12.3963C27.2951 13.489 26.6599 16.5294 28.0406 19.1802L28.7862 20.628L25.8937 22.1172L25.1481 20.6763C22.9424 16.4294 24.2092 11.4242 27.9371 9.50757C31.6649 7.59096 36.4835 9.50757 38.6754 13.7407L39.4175 15.1816ZM20.3018 24.3938L23.2013 22.9047L23.9572 24.3525C25.3241 27.0033 28.1717 28.2719 30.298 27.1791C32.4242 26.0864 33.0594 23.046 31.6787 20.3952L30.9331 18.9474L33.8256 17.4582L34.5712 18.8991C36.7769 23.146 35.5101 28.1512 31.7822 30.0678C28.0544 31.9844 23.2358 30.0678 21.0439 25.8347L20.3018 24.3938Z"
        fill={logoColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_14353_38314"
          x1="46.1463"
          y1="24.4088"
          x2="56.926"
          y2="24.4088"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.18" stopColor="#D1D5E0" />
          <stop offset="0.4" stopColor="#DADEE9" />
          <stop offset="0.76" stopColor="#E6EAF4" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CocosCard;
