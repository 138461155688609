import { Capacitor } from "@capacitor/core";
import { Typography } from "@mui/material";
import NothingHere from "assets/icons/nothing-here";
import OpenAccountButton from "components/common/OpenAccountButton";
import { useAppSelector } from "hooks/redux";
import { StatusOnboarding } from "interfaces/createAccount";
import { getLoggedUser } from "store/selectors/user.selector";
import { ButtonSizes } from "components/common/button";
import DownloadApp from "components/common/DownloadApp";
import { Navigate } from "react-router-dom";
import mainRoutes from "router/routes";

import styles from "./styles.module.scss";

interface NoCtteProps {
  withImage?: boolean;
}

const NoCtte: React.FC<NoCtteProps> = ({ withImage = false }) => {
  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { statusOnboarding } = user;

  const isOnboardingApproved = statusOnboarding === StatusOnboarding.APPROVED;

  const isOnboardingNotStarted =
    statusOnboarding === StatusOnboarding.NOT_STARTED;

  const isOnboardingInProgress =
    statusOnboarding === StatusOnboarding.IN_PROGRESS;

  const isOnboardingFinished = statusOnboarding === StatusOnboarding.FINISHED;

  if (isOnboardingApproved) return null;

  const getButtonText = () => {
    if (isOnboardingNotStarted) return "Abrí tu comitente";
    if (isOnboardingInProgress) return "Continuá tu apertura";
    return "Ver estado de apertura";
  };

  return (
    <div className={styles.NoCtteContainer}>
      {withImage && <NothingHere />}
      <Typography variant="textL_bold" component="h3">
        Todavía no tenés tu cuenta comitente
      </Typography>

      {isOnboardingFinished && (
        <Typography variant="textM" component="p">
          Estamos validando tus datos para abrir tu cuenta.
        </Typography>
      )}
      {(isOnboardingNotStarted || isOnboardingInProgress) && (
        <Typography variant="textM" component="p">
          Abrila en menos de 3 minutos y comenzá a operar. Es gratis y sin
          costos de mantenimiento.
        </Typography>
      )}
      {Capacitor.isNativePlatform() ? (
        <OpenAccountButton buttonSize={ButtonSizes.Large}>
          {getButtonText()}
        </OpenAccountButton>
      ) : (
        <DownloadApp />
      )}
    </div>
  );
};

export default NoCtte;
