import { UISelect, SelectSizes, SelectTypes } from "components/common/select";
import { BondData } from "interfaces/markets";

import styles from "./styles.module.scss";

interface BondSelectorProps {
  isLoading: boolean;
  selectedBond: string;
  bondList: BondData[];
  defaultBondOpt: string;
  handleChangeBond: (v?: string) => void;
}

const BondSelector: React.FC<BondSelectorProps> = ({
  defaultBondOpt,
  isLoading,
  selectedBond,
  handleChangeBond,
  bondList,
}) => {
  const options = bondList.map((bond) => {
    return {
      value: bond.short_ticker,
      label: bond.short_ticker,
    };
  });

  return (
    <div className={styles.selectorWrapper}>
      <UISelect
        cantBeEmpty
        defaultValue={defaultBondOpt}
        disabled={isLoading}
        label="Bono"
        options={options}
        size={SelectSizes.Medium}
        type={SelectTypes.Secondary}
        value={selectedBond}
        onChange={handleChangeBond}
        downArrowSize={25}
      />
    </div>
  );
};

export default BondSelector;
