import { CDN_URL } from "constants/index";

import { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { InfoIcon } from "assets/icons/info";
import Error from "components/common/confirm-market-order-container/Error";
import { OrderDetailSidebarDesktop } from "components/common/order-detail-sidebar";
import Success from "components/common/success";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Instruments, OrderType, LabelValue } from "interfaces/markets";
import Drawer from "layouts/drawer";
import { useNavigate } from "react-router-dom";
import GoogleTagManager from "react-gtm-module";
import {
  confirmOrder,
  getOrderById,
  confirmBidOrder,
  setCreateOrderError,
  resetOrder,
} from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import CheckboxUI from "components/common/controls/checkbox";
import IconText from "components/common/icon-text";
import { useAppState } from "context/AppStateProvider";
import { trackAction } from "utils/amplitude";
import { getColor } from "utils/getColor";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { ACTIVE_BID } from "../bid/constants";

import "./index.scss";

interface PropTypes {
  onClose: () => void;
  onClickOrderDetail?: (orderId: string) => void;
  data: LabelValue[];
  instrument_type: string;
  infoLabel?: string;
  isBidOrder?: boolean;
}

export const ConfirmOrder = (props: PropTypes) => {
  const {
    onClose,
    onClickOrderDetail,
    data,
    instrument_type,
    infoLabel,
    isBidOrder,
  } = props;
  const location = useLocation();
  const { openRightModalContent } = useAppState();

  const [confirmReglamento, setConfirmReglamento] = useState(false);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const loading = useAppSelector(
    (state: RootState) => state.markets.createOrderLoading
  );
  const orderError = useAppSelector(
    (state: RootState) => state.markets.createOrderError
  );
  const marketDetail = useAppSelector(
    (state: RootState) => state.markets.marketDetail
  );
  const getOrderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const { isMobile } = useDeviceCheck();
  const [orderId, setOrderId] = useState<string>("");
  const currentScreen = useAppSelector(getCurrentScreen);

  const isBid = new URLSearchParams(location.search).get("isBid") === "true";

  const isFCI = instrument_type === Instruments.FCI;

  const isBidTransaction = isBidOrder || isBid;

  const settlementDate = ACTIVE_BID.settlement_date.replace(/-/g, "/");

  const getHeaderLabel = () => {
    if (order.type === OrderType.Sell && isFCI) {
      return "rescate";
    }
    if (order.type === OrderType.Sell) {
      return "venta";
    }
    if (isFCI) {
      return "suscripción";
    }

    return "compra";
  };

  useEffect(() => {
    if (order.id || orderError.message) {
      setStep(step + 1);
    }
  }, [order.id, orderError]);

  useEffect(() => {
    if (order.id) {
      setOrderId(order.id);
    }

    return () => {
      if (order.id && order.long_ticker) {
        dispatch(resetOrder({ long_ticker: order.long_ticker }));
      }
      dispatch(setCreateOrderError({ message: "", status: null }));
    };
  }, [order.id]);

  const handleClickConfirm = async () => {
    if (isFCI) {
      GoogleTagManager.dataLayer({
        dataLayer: {
          event: "fci_order_confirmed",
          ticker: order.long_ticker,
        },
      });
    }

    const trackProps = {
      fee: data[5]?.value,
      ...order,
      quantity: Math.floor(order.quantity || 0),
      ticker: order.instrument_code || order.long_ticker?.split("-")[0],
    };

    trackAction(`${currentScreen} - Send Order`, trackProps);

    isBidTransaction
      ? await dispatch(
          confirmBidOrder({
            short_ticker: order.instrument_code || "",
            amount: order.amount || 0,
          })
        )
      : await dispatch(confirmOrder({ order, instrument_type }));

    setStep(step + 1);
  };

  const handleClickCancel = async () => {
    dispatch(resetOrder());
    if (isMobile) {
      navigate(-1);
    }
    onClose && onClose();
  };

  const handleClickOrderDetail = () => {
    if (onClickOrderDetail && orderId) {
      trackAction(`${currentScreen} - View Order`, { id: orderId });
      onClickOrderDetail(orderId);
    }
  };

  const handleCloseSidebar = () => {
    if (isMobile) {
      navigate(mainRoutes.home);
    }

    dispatch(closeRightModal());
  };

  const goToOrder = () => (
    <Drawer>
      <OrderDetailSidebarDesktop
        id={orderId}
        onCloseSidebar={handleCloseSidebar}
      />
    </Drawer>
  );

  const handleOpenDetail = () => {
    if (isBidTransaction) {
      navigate(`${mainRoutes.orders}?from=success_screen`);
      dispatch(closeRightModal());
    }

    if (order.id) {
      trackAction(`${currentScreen} - View Order`, { id: orderId });
      dispatch(getOrderById(order.id.toString()));
      openRightModalContent(goToOrder());
    }
  };

  const trackingEvent = () => {
    trackAction("bid_order_sent", {
      ticker: order.instrument_code,
      amount: order.amount,
    });
  };

  useEffect(() => {
    GoogleTagManager.dataLayer({
      dataLayer: {
        event: "fci_order_screen_viewed",
        ticker: order.long_ticker,
      },
    });
  }, []);

  return (
    <div className="orders-confirm confirm-order-component">
      {step === 1 && (
        <div className="step-1">
          <div>
            <Typography className="title-confirm" variant="textS_bold">
              Confirmar orden de {getHeaderLabel()}
            </Typography>
            <div className="box">
              {data.map((val, index) => (
                <div key={index} className={"order-confirm-section"}>
                  <Typography component={"p"}>{val.label}</Typography>
                  <Typography variant="textL_bold">{val.value}</Typography>
                </div>
              ))}
            </div>
          </div>
          <div>
            {isFCI && order.type === OrderType.Buy && (
              <CheckboxUI
                checked={confirmReglamento}
                label={
                  <Typography variant="textM">
                    Acepto el&nbsp;
                    <b>
                      <a
                        href={`${CDN_URL}/reglamentos/${marketDetail?.rules_file_name}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Reglamento de gestión
                      </a>
                    </b>
                  </Typography>
                }
                onChange={(newValue) => setConfirmReglamento(newValue)}
              />
            )}
            {infoLabel && (
              <IconText
                icon={
                  <InfoIcon size={24} color={getColor("--darkblue-to-light")} />
                }
                label={<Typography variant="textS">{infoLabel}</Typography>}
              />
            )}
            <ButtonUI
              disabled={
                loading ||
                (isFCI && !confirmReglamento && order.type === OrderType.Buy)
              }
              size={ButtonSizes.Full}
              variant={ButtonVariants.Primary}
              onClick={handleClickConfirm}
              needReset={false}
              newClass={"confirm-button"}
              id={"order-confirm-button"}
            >
              <Typography variant="buttonL">Confirmar</Typography>
            </ButtonUI>
            <ButtonUI
              disabled={loading}
              size={ButtonSizes.Full}
              variant={ButtonVariants.Secondary}
              onClick={handleClickCancel}
              needReset={false}
              id={"order-cancel-button"}
            >
              <Typography variant="buttonL">Cancelar</Typography>
            </ButtonUI>
          </div>
        </div>
      )}
      {step === 2 && order.id && (
        <div className="step-2">
          {isMobile ? (
            <Success
              loadingSecondaryButton={getOrderLoading}
              subtitle="Tu inversión se mostrará en los próximos minutos en tu billetera"
              textPrimaryButton="Ver estado de orden"
              textSecondaryButton="Ir al inicio"
              title="¡Listo!"
              urlPrimaryButton={`${mainRoutes.orderDetail}/${order.id}`}
              onClickPrimaryButton={handleClickOrderDetail}
              onClickSecondaryButton={onClose}
            />
          ) : (
            <Success
              loadingSecondaryButton={getOrderLoading}
              subtitle="Tu inversión se mostrará en los próximos minutos en tu billetera"
              textPrimaryButton="Ver estado de orden"
              textSecondaryButton="Ir al inicio"
              title="¡Listo!"
              onClickPrimaryButton={handleOpenDetail}
              onClickSecondaryButton={handleCloseSidebar}
            />
          )}
        </div>
      )}
      {step === 2 && isBidTransaction && !orderError.message && (
        <Success
          loadingSecondaryButton={getOrderLoading}
          title="¡Listo!"
          subtitle={`Los títulos se mostrarán en tu billetera el ${settlementDate}.`}
          textPrimaryButton="Ir a órdenes del día"
          textSecondaryButton="Volver al inicio"
          onClickPrimaryButton={handleOpenDetail}
          onClickSecondaryButton={handleCloseSidebar}
          trackingEvent={trackingEvent}
        />
      )}

      {step === 2 && orderError.message && !order.id && (
        <Error message={orderError.message} onClose={onClose} />
      )}
    </div>
  );
};
