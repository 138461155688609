export const MIN_THRESHOLD_CRYPTO = 0.05;

export const MIN_HOLDING_CRYPTO = 1e-8;

export enum SelectedTab {
  RETURNS = "Returns",
  VALUE = "Value",
}

export const getNumberOfDecimals = (amount: number) => {
  return amount < MIN_THRESHOLD_CRYPTO ? 8 : 2;
};

export enum PerformancePeriods {
  DAILY = "daily",
  HISTORIC = "historic",
}
