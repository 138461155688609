import { TrendingDown, TrendingUp } from "lucide-react";

import { DailyTrendOptions } from "../../utils";
import TrendChip from "./Chip";

import styles from "./styles.module.scss";

interface ChipListProps {
  selectedChip: DailyTrendOptions;
  onClickChip: (v: DailyTrendOptions) => void;
}

const ChipList: React.FC<ChipListProps> = ({ selectedChip, onClickChip }) => {
  const list = [
    {
      icon: TrendingUp,
      label: DailyTrendOptions.GAIN,
    },
    {
      icon: TrendingDown,
      label: DailyTrendOptions.LOSS,
    },
  ];

  return (
    <div className={styles.chipListContainer}>
      {list.map(({ icon, label }) => (
        <TrendChip
          selected={label === selectedChip}
          key={label}
          label={label}
          icon={icon}
          onClick={() => onClickChip(label)}
        />
      ))}
    </div>
  );
};

export default ChipList;
