import { useState } from "react";

import { Tooltip, Typography } from "@mui/material";
import { OrderStatusTypes } from "interfaces/api-responses";
import { getOrderStatus } from "utils";

import styles from "./styles.module.scss";

const TooltipText: React.FC<{ status: OrderStatusTypes }> = ({ status }) => (
  <div className={styles.tooltipText}>
    <Typography>{getOrderStatus(status).label}</Typography>
    <Typography>{getOrderStatus(status).description}</Typography>
  </div>
);

export const OrderStatusBadge: React.FC<{ status: OrderStatusTypes }> = ({
  status,
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  // I need this stopPropagation to prevent user from opening order details when clicks on this component
  const handleClick = (e: any) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Tooltip
      title={<TooltipText status={status} />}
      arrow
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      onClick={handleClick}
    >
      <div className={`${styles.common} ${styles[status]}`} />
    </Tooltip>
  );
};
