import { useTrading } from "context/TradingProvider";
import { SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { screenNames } from "router/routes";
import { UIToggle } from "components/common/toggle";
import { getCurrenciesLabels } from "utils";
import { trackAction } from "utils/amplitude";

import styles from "./styles.module.scss";

const TradingCurrencySettlement: React.FC<{ isCableEnabled: boolean }> = ({
  isCableEnabled,
}) => {
  const { currency, setCurrency, term, setTerm } = useTrading();
  const settlementOptions = [
    {
      value: SettlementTerms.CI,
      label: "Contado",
    },
    {
      value: SettlementTerms._24,
      label: SettlementTerms._24,
    },
  ];
  const currencyOptions = [
    {
      value: Currencies.ARS,
      label: getCurrenciesLabels(Currencies.ARS),
    },
    {
      value: Currencies.USD,
      label: getCurrenciesLabels(Currencies.USD),
    },
  ];

  if (isCableEnabled) {
    currencyOptions.push({
      value: Currencies.EXT,
      label: getCurrenciesLabels(Currencies.EXT),
    });
  }

  const onChangeSettlement = (value: string) => {
    trackAction(`${screenNames.trading} - Change settlement ${value}`);
    setTerm(value as SettlementTerms);
  };

  const onChangeCurrency = (value: string) => {
    trackAction(`${screenNames.trading} - Change currency ${value}`);
    setCurrency(value as Currencies);
  };

  return (
    <div className={styles.divContainer}>
      {term && (
        <UIToggle
          label="Plazo de liquidación"
          options={settlementOptions}
          selectedValue={term}
          onChangeValue={onChangeSettlement}
        />
      )}
      {currency && (
        <UIToggle
          label="Moneda"
          options={currencyOptions}
          selectedValue={currency}
          onChangeValue={onChangeCurrency}
        />
      )}
    </div>
  );
};

export default TradingCurrencySettlement;
