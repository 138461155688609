import { QueryClient } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { Cookies } from "react-cookie";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const useAppQuery = <TQueryFnData, TError = unknown>({
  queryKey,
  queryFn,
  options,
}: {
  queryKey: () => readonly unknown[];
  queryFn: () => Promise<TQueryFnData>;
  options?: any;
}) => {
  const withAccountId = (queryKeys: readonly unknown[]) => {
    const cookies = new Cookies();
    const idAccount = cookies.get("id_account");

    if (!Array.isArray(queryKeys)) {
      throw new Error("queryKey must be an array");
    }

    return [...queryKeys, idAccount] as const;
  };

  return useQuery<TQueryFnData, TError>({
    queryKey: withAccountId(queryKey()),
    queryFn,
    ...options,
  });
};
