import { useState } from "react";

import {
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import "./index.scss";

interface PropTypes {
  onChange: (value?: string) => void;
  value?: string;
  helperText?: string;
  label?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  iconPosition?: "end" | "start";
  placeholder?: string;
}

export const UIDateInput2 = (props: PropTypes) => {
  const {
    value,
    disabled,
    helperText,
    label,
    icon,
    iconPosition,
    placeholder,
    onChange,
  } = props;
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getStateClassName = () => {
    if (!value) {
      if (error) {
        return "empty error";
      }

      return "empty";
    }
    if (error) {
      return "completed error";
    }

    return "completed";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      onChange(undefined);
    }
    const dateNew = new Date(event.target.value);

    if (dateNew.getDay() === 5 || dateNew.getDay() === 6) {
      onChange(undefined);
      setError(true);
      setErrorMessage("Solamente días de la semana");
    } else {
      setError(false);
      onChange(event.target.value);
    }
  };

  return (
    <div className={`ui-date-input ${getStateClassName()}`}>
      {label && <Typography variant="textS">{label}</Typography>}
      <OutlinedInput
        aria-describedby="outlined-weight-helper-text"
        autoComplete="off"
        disabled={disabled}
        error={error}
        id="outlined-adornment-weight"
        inputProps={{ min: dayjs().format("YYYY-MM-DD") }}
        placeholder={placeholder || "Seleccionar fecha"}
        startAdornment={
          <InputAdornment position={iconPosition || "start"}>
            {icon}
          </InputAdornment>
        }
        type="date"
        value={value}
        onChange={handleChange}
      />
      <FormHelperText id="outlined-helper-text">
        {error ? errorMessage : helperText}
      </FormHelperText>
    </div>
  );
};
