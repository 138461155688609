import { useEffect, useState } from "react";

import MarketRowDesktop from "components/common/MarketList/List/Rows/desktop/index";
import MarketRowMobile from "components/common/MarketList/List/Rows/mobile/index";
import MarketRowFCIDesktop from "components/common/MarketList/List/Rows/Wrapper/MarketRowFCI";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InstrumentData } from "interfaces/api-responses";
import {
  BookSocketResponse,
  TickerSocketResponse,
} from "interfaces/api-responses/socket/book";
import { Instruments, MarketSection } from "interfaces/markets";
import { socket } from "socket";

interface RowsWrapperProps {
  selectedInstrumentType?: MarketSection;
  marketData: InstrumentData;
  hasCurrency?: boolean;
  handleOpenMarketOptions: () => void;
  handleClickMarketRow: () => void;
}

const RowsWrapper: React.FC<RowsWrapperProps> = ({
  marketData,
  hasCurrency,
  handleClickMarketRow,
  selectedInstrumentType,
  handleOpenMarketOptions,
}) => {
  const { isMobile } = useDeviceCheck();
  const [currentMarketData, setCurrentMarketData] = useState<InstrumentData>(
    marketData
  );

  useEffect(() => {
    const messageHandler = (
      message: TickerSocketResponse | BookSocketResponse
    ) => {
      if (message.long_ticker === marketData.long_ticker) {
        setCurrentMarketData((prev) => {
          if (message.asks && message.bids) {
            return {
              ...prev,
              ask: message.asks[0].price,
              bid: message.bids[0].price,
              ...message,
            };
          }
          return { ...prev, message };
        });
      }
    };
    socket.on("message", messageHandler);
    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  if (isMobile)
    return (
      <MarketRowMobile
        key={marketData.long_ticker}
        hasCurrency={hasCurrency}
        market={currentMarketData}
        onClickMarketOptions={handleOpenMarketOptions}
        onClickRow={handleClickMarketRow}
      />
    );

  if (selectedInstrumentType === Instruments.FCI)
    return (
      <MarketRowFCIDesktop
        key={marketData.long_ticker}
        market={currentMarketData}
        onClickRow={handleClickMarketRow}
      />
    );

  return (
    <MarketRowDesktop
      key={marketData.long_ticker}
      market={currentMarketData}
      onClickMarketOptions={handleOpenMarketOptions}
      onClickRow={handleClickMarketRow}
    />
  );
};

export default RowsWrapper;
