import { Dispatch, ReactNode } from "react";

import MarketDetail from "components/page-market/components/MarketDetail";
import { InstrumentData } from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import { checkFlow } from "features/calculator/calculatorSlice";

export const openMarketDetailSidebar = (
  code: string,
  ticker: InstrumentData,
  dispatch: Dispatch<any>,
  openRightModalContent: (element: ReactNode) => void
) => {
  const isBond =
    ticker.instrument_type === Instruments.BONOS_PUBLICOS ||
    ticker.instrument_type === Instruments.BONOS_CORP;

  if (isBond) {
    dispatch(checkFlow({ instrument_code: code, invest: false }));
  }
  openRightModalContent(MarketDetail(code, ticker));
};
