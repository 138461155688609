import { IconProps } from "assets/icons/BTC";

const Eye: React.FC<IconProps> = ({ width = 81, height = 80, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 81 80"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_14549_16099)">
      <path
        d="M40.0921 79.9938C62.1818 79.9938 80.0891 62.0866 80.0891 39.9969C80.0891 17.9072 62.1818 0 40.0921 0C18.0024 0 0.0952148 17.9072 0.0952148 39.9969C0.0952148 62.0866 18.0024 79.9938 40.0921 79.9938Z"
        fill="#0588F0"
      />
      <path
        d="M73.8439 40.7485C74.0587 40.52 74.1783 40.2183 74.1783 39.9047C74.1783 39.5911 74.0587 39.2893 73.8439 39.0609C70.5057 35.5256 56.7957 22.0557 41.1147 21.4829C40.776 21.4829 40.4372 21.4521 40.0923 21.4521C39.7474 21.4521 39.4148 21.4521 39.0761 21.4829C23.3951 22.0557 9.68504 35.5256 6.34683 39.0609C6.13204 39.2893 6.01245 39.5911 6.01245 39.9047C6.01245 40.2183 6.13204 40.52 6.34683 40.7485C9.68504 44.2838 23.3951 57.7475 39.0761 58.3203C39.4148 58.3203 39.7536 58.3203 40.0923 58.3203C40.4311 58.3203 40.7698 58.3203 41.1086 58.3203C56.7957 57.7475 70.5057 44.2838 73.8439 40.7485Z"
        fill="white"
      />
      <path
        d="M40.0922 55.5179C48.7152 55.5179 55.7054 48.5277 55.7054 39.9047C55.7054 31.2818 48.7152 24.2915 40.0922 24.2915C31.4693 24.2915 24.479 31.2818 24.479 39.9047C24.479 48.5277 31.4693 55.5179 40.0922 55.5179Z"
        fill="#0588F0"
      />
      <path
        d="M40.0922 46.7474C43.8714 46.7474 46.9349 43.6839 46.9349 39.9047C46.9349 36.1256 43.8714 33.062 40.0922 33.062C36.3131 33.062 33.2495 36.1256 33.2495 39.9047C33.2495 43.6839 36.3131 46.7474 40.0922 46.7474Z"
        fill="#1C2024"
      />
    </g>
    <defs>
      <clipPath id="clip0_14549_16099">
        <rect
          width="80"
          height="80"
          fill="white"
          transform="translate(0.0952148)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Eye;
