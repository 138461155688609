import { Typography } from "@mui/material";
import { MarketSection, Sections } from "interfaces/markets";

const ListHeader = ({
  selectedInstrumentType,
}: {
  selectedInstrumentType: MarketSection;
}) => (
  <div className="markets-table-header">
    <div className="grid-col especie">
      <Typography variant="textXS">Especie</Typography>
    </div>
    <div className="grid-col last-price">
      <Typography variant="textXS">Último Precio</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">
        {selectedInstrumentType === Sections.HOME ? "Tasa Anual " : "Var %"}
      </Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">CC</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">PC</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">PV</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">CV</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">Cierre</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">Min</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">Max</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">Total</Typography>
    </div>
    <div className="grid-col">
      <Typography variant="textXS">Oper</Typography>
    </div>
  </div>
);

export default ListHeader;
