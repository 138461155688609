import { UIDateInput2 } from "components/common/input/date2";

import styles from "./styles.module.scss";

interface DateSelectorProps {
  settlementCode: string;
  settlementDate: string;
  handleChangeDate: (v?: string) => void;
}

const DateSelector: React.FC<DateSelectorProps> = ({
  settlementDate,
  settlementCode,
  handleChangeDate,
}) => (
  <div className={styles.selectorWrapper}>
    <UIDateInput2
      disabled={settlementCode !== "otro"}
      label="Fecha de liquidación"
      value={settlementDate}
      onChange={handleChangeDate}
    />
  </div>
);

export default DateSelector;
