import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import Text, { TextVariants } from "components/common//Text";
import Currency, { CurrencySizes } from "components/common//Text/Currency";
import Variation from "components/common/CurrentVariation";
import { useNavigate } from "react-router-dom";
import { CryptoRoutes } from "router/routes";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

type DailyReturnCardProps = {
  icon: React.FC<IconProps>;
  variation: number;
  short_name: string;
} & (
  | {
      isRow: true;
      amount: number;
    }
  | {
      isRow?: false;
      name: string;
    }
);

const DailyReturnCard: React.FC<DailyReturnCardProps> = ({
  icon: Icon,
  variation,
  short_name,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={classNames(styles.divContainer, {
        [styles.row]: props.isRow,
      })}
      onClick={() => navigate(`${CryptoRoutes.MARKET}/${short_name}`)}
    >
      {props.isRow ? (
        <>
          <div className={styles.wrapper}>
            <div className={styles.ticker}>
              <Icon size={px2rem(20)} />
              <Text
                variant={TextVariants.RegularUpperValueS}
                color="var(--slate800)"
              >
                {short_name}
              </Text>
            </div>
            <Currency
              amount={props.amount}
              color="var(--slate900)"
              size={CurrencySizes.SMALL}
            />
          </div>
          <Variation value={variation} />
        </>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.icon}>{<Icon size={px2rem(32)} />}</div>
            <Variation value={variation} />
          </div>
          <div className={styles.bottom}>
            <Text
              variant={TextVariants.RegularText}
              className={styles.name}
              color={"var(--slate900)"}
            >
              {props.name}
            </Text>
            <Text
              variant={TextVariants.RegularUpperTextS}
              color={"var(--slate800)"}
            >
              {short_name}
            </Text>
          </div>
        </>
      )}
    </div>
  );
};

export default DailyReturnCard;
