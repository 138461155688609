import ARGFlag from "assets/icons/ARGFlag";
import type { IconProps } from "assets/icons/BTC";
import USFlag from "assets/icons/USFlag";
import { PeriodTime } from "interfaces/portfolio";
import { Currencies } from "interfaces/wallet";

export enum PortfolioTabs {
  VALUE = "Valor",
  RETURNS = "Rendimiento",
  DISTRIBUTION = "Distribución",
}

export enum SettlementIndex {
  ONE_DAY = 0,
  TWO_DAYS = 1,
  INF = 2,
}

export const portfolioTabOptions = [
  { name: PortfolioTabs.VALUE },
  { name: PortfolioTabs.RETURNS },
  // TODO: { name: PortfolioTabs.DISTRIBUTION },
];

export const LINE_CHART_TIME_OPTIONS = [
  { label: PeriodTime.DAY, value: 0 },
  { label: PeriodTime.WEEK, value: 1 },
  { label: PeriodTime.MONTH, value: 2 },
  { label: PeriodTime.YEAR, value: 3 },
  { label: PeriodTime.MAX, value: 4 },
];

export const PORTFOLIO_CHART_PERIOD = [
  PeriodTime.DAY,
  PeriodTime.WEEK,
  PeriodTime.MONTH,
  PeriodTime.YEAR,
  PeriodTime.MAX,
];

export const PERFORMANCE_PERIOD = [PeriodTime.DAY, PeriodTime.MAX];

export const VARIATION_TEXT = [
  "último día",
  "última semana",
  "último mes",
  "último año",
  "histórico",
];

export interface CurrencyDetail {
  icon: React.FC<IconProps>;
  title: string;
  currency: Currencies;
}

interface DetailBalance {
  [key: string]: CurrencyDetail;
}

export const DETAIL_BALANCE: DetailBalance = {
  [Currencies.ARS]: {
    icon: ARGFlag,
    title: "Peso Argentino",
    currency: Currencies.ARS,
  },
  [Currencies.USD]: {
    icon: USFlag,
    title: "Dólar MEP",
    currency: Currencies.USD,
  },
  [Currencies.EXT]: {
    icon: USFlag,
    title: "Dólar Cable",
    currency: Currencies.EXT,
  },
};
