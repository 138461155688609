import React from "react";

import type { IconProps } from "assets/icons/BTC";
import { ThemeVariants } from "interfaces/theme";

export interface IllustrationProps extends IconProps {
  variant?: ThemeVariants;
}

const FirstInvestment: React.FC<IllustrationProps> = ({
  width = 96,
  height = 68,
  variant = ThemeVariants.Capital,
  ...props
}) => {
  const isCapital = variant === ThemeVariants.Capital;

  const color900 = isCapital ? "#113264" : "#402060";
  const color700 = isCapital ? "#0588F0" : "#8347B9";
  const color800Dark = isCapital ? "#70B8FF" : "#D19DFF";

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Simple/Bar Chart Trend" clipPath="url(#clip0_12826_55780)">
        <g id="Clip path group">
          <mask
            id="mask0_12826_55780"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="96"
            height="68"
          >
            <g id="clip-path">
              <path
                id="Vector"
                d="M91.0094 0H4.99072C2.66765 0 0.784424 1.88322 0.784424 4.20629V63.7937C0.784424 66.1168 2.66765 68 4.99072 68H91.0094C93.3325 68 95.2157 66.1168 95.2157 63.7937V4.20629C95.2157 1.88322 93.3325 0 91.0094 0Z"
                fill="white"
              />
            </g>
          </mask>
          <g mask="url(#mask0_12826_55780)">
            <g id="Group">
              <path
                id="Vector_2"
                d="M95.2157 0H0.784424V68H95.2157V0Z"
                fill={color900}
              />
              <path
                id="Vector_3"
                d="M17.6728 61.2593H7.20435V67.9999H17.6728V61.2593Z"
                fill={color700}
              />
              <path
                id="Vector_4"
                d="M35.234 47.4995H24.7656V67.9999H35.234V47.4995Z"
                fill={color800Dark}
              />
              <path
                id="Vector_5"
                d="M70.3461 43.0146H59.8777V67.9948H70.3461V43.0146Z"
                fill="#3DD68C"
              />
              <path
                id="Vector_6"
                d="M52.7899 35.5117H42.3215V67.9948H52.7899V35.5117Z"
                fill="#FFC419"
              />
              <path
                id="Vector_7"
                d="M87.9074 27.9614H77.439V68.0001H87.9074V27.9614Z"
                fill={color700}
              />
              <path
                id="Vector_8"
                d="M74.3315 8.59131H87.9074V22.1671"
                stroke="white"
                strokeWidth="0.909611"
                strokeMiterlimit="10"
              />
              <path
                id="Vector_9"
                d="M87.9073 8.59131L71.1873 25.3113"
                stroke="white"
                strokeWidth="0.909611"
                strokeMiterlimit="10"
              />
              <path
                id="Vector_10"
                d="M7.20435 56.9059L44.7245 19.3857L60.9187 35.58L60.6032 35.8954L71.1873 25.3114"
                stroke="white"
                strokeWidth="0.909611"
                strokeMiterlimit="10"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_12826_55780">
          <rect
            width="94.4313"
            height="68"
            fill="white"
            transform="translate(0.784424)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FirstInvestment;
