import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import FilledButton from "components/common/FilledButton";
import BulletList, { BulletListVariants } from "components/common/BulletList";
import { DollarSign, Zap } from "lucide-react";
import { RootState } from "store/store";
import classNames from "classnames";
import { useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface CableDollarModalSliderProps {
  handleContinueClick: () => void;
  className?: string;
}

const CableDollarModalSlider = ({
  handleContinueClick,
  className,
}: CableDollarModalSliderProps) => {
  const isDarkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const bulletList = [
    { icon: DollarSign, text: "Transferí a nuestra cuenta en Estados Unidos." },
    { icon: Zap, text: "Rápido, fácil y seguro." },
  ];

  const url = import.meta.env.VITE_CDN_URL;
  const theme = isDarkMode ? "dark" : "light";

  return (
    <div className={classNames(styles.sheetWrapper, className)}>
      <div>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          color="var(--slate900)"
          className={styles.modalHeading}
        >
          Traé tus dólares del exterior
        </Heading>
        <Text
          variant={TextVariants.RegularTextL}
          color="var(--slate800)"
          className={styles.modalText}
        >
          Si tenés una cuenta en el extranjero vas a poder traer tus dólares a
          Cocos de forma rápida, simple, y sin comisiones.
        </Text>
        <div className={styles.modalImageWrapper}>
          <img
            loading="lazy"
            src={`${url}/cable/cable-promo-${theme}.png`}
            alt={`Modal cable dollar ${theme} image`}
            className={styles.modalImage}
          />
        </div>
        <BulletList
          variant={BulletListVariants.Capital}
          bullets={bulletList}
          className={styles.modalBulletList}
        />
      </div>
      <FilledButton onClick={handleContinueClick}>Continuar</FilledButton>
    </div>
  );
};

export default CableDollarModalSlider;
