import React from "react";

import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ChevronDownIcon } from "assets/icons/arrow-down-select";
import { CloseIcon } from "assets/icons/close";

import "./index.scss";

export interface Option {
  value: string;
  label: string | JSX.Element | React.ReactNode;
}

export enum SelectSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}

export enum SelectTypes {
  Primary = "primary",
  Secondary = "secondary",
}

interface UISelectProps {
  onChange: (value?: string) => void;
  value: string | undefined;
  error?: boolean;
  helperText?: string;
  errorMessage?: string;
  disabled?: boolean;
  label?: string;
  options: Option[];
  hideHelperText?: boolean;
  cantBeEmpty?: boolean;
  size?: SelectSizes;
  type?: SelectTypes;
  defaultValue?: string;
  downArrowSize?: number;
}

export const UISelect = (props: UISelectProps) => {
  const {
    value,
    options,
    label,
    error,
    helperText,
    errorMessage,
    disabled,
    hideHelperText = false,
    cantBeEmpty = false,
    size = SelectSizes.Full,
    type = SelectTypes.Primary,
    defaultValue,
    onChange,
    downArrowSize = 25,
  } = props;

  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value);
  };

  const getStateClassName = () => {
    if (!value || value === "") {
      if (error) {
        return "empty error";
      }

      return "empty";
    }
    if (error) {
      return "completed error";
    }

    return "completed";
  };

  return (
    <div className={`ui-select ${getStateClassName()} ${size} ${type}`}>
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        displayEmpty
        IconComponent={() => (
          <ChevronDownIcon
            color="#0062E1"
            size={downArrowSize}
            className="chevron-down-icon"
          />
        )}
        MenuProps={{ classes: { paper: "ui-select-menu " + type } }}
        className="simple-select"
        defaultValue={defaultValue}
        disabled={disabled}
        error={error}
        value={value}
        onChange={handleChange}
      >
        {!cantBeEmpty && (
          <MenuItem className="ui-select-option" value={""}>
            {size === SelectSizes.Small
              ? "Seleccione"
              : "Seleccione una opción"}
          </MenuItem>
        )}
        {options.map((o, index) => (
          <MenuItem key={index} className="ui-select-option" value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
      {!hideHelperText && (
        <FormHelperText id="outlined-helper-text">
          {error && <CloseIcon color={"#EB0A3A"} size={16} />}
          {error ? errorMessage : helperText}
        </FormHelperText>
      )}
    </div>
  );
};
