import { UIModalSlider } from "components/common/modal-slider";
import SearchBarComp from "components/common/search-bar";

import MarketsModal from "../markets-modal";

import "./index.scss";

interface SearchModalProps {
  open: boolean;
  handleToggleDisplayModal: () => void;
  quickPurchase?: boolean;
}

const SearchModal: React.FC<SearchModalProps> = ({
  open,
  handleToggleDisplayModal,
  quickPurchase,
}) => (
  <UIModalSlider
    classes="comp-search-modal"
    open={open}
    onToggleDisplay={handleToggleDisplayModal}
  >
    <SearchBarComp
      handleToggleDisplayModal={handleToggleDisplayModal}
      quickPurchase={quickPurchase}
    />
    <MarketsModal />
  </UIModalSlider>
);

export default SearchModal;
