interface PropTypes {
  size?: number;
  color?: string;
}

export const AmountQuantityToggleIcon = (props: PropTypes) => {
  const { size = 32, color = "#002C65" } = props;

  return (
    <svg
      fill="none"
      height={`${size}`}
      viewBox={`0 0 ${size} ${size}`}
      width={`${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M14.247 19.1882C13.2561 19.1882 12.4486 18.38 12.4486 17.388H12.4303V6.03558L14.504 8.11135C14.8526 8.40526 15.3481 8.40526 15.6784 8.11135C16.0638 7.78069 16.1005 7.21124 15.7702 6.84384L12.1733 3.26177C11.8247 2.91275 11.2558 2.91275 10.9071 3.26177L7.31027 6.86221C7.28657 6.87408 7.27052 6.8936 7.25224 6.91583C7.24221 6.92803 7.23152 6.94105 7.21851 6.95406C6.88819 7.32145 6.94325 7.89091 7.31027 8.22157C7.67729 8.55222 8.24618 8.49711 8.5765 8.12972L10.6502 6.05395V17.388C10.6502 19.3719 12.2651 20.9885 14.247 20.9885C14.7425 20.9885 15.1462 20.5843 15.1462 20.0883C15.1462 19.5924 14.7425 19.1882 14.247 19.1882ZM19.6483 15.5936V26.9277L17.5746 24.8519C17.5627 24.8282 17.5432 24.8121 17.521 24.7938C17.5088 24.7838 17.4958 24.7731 17.4828 24.7601C17.0974 24.4478 16.5286 24.4845 16.2166 24.8703C15.9046 25.256 15.9413 25.8255 16.3267 26.1378L19.9052 29.7382C20.2539 30.0873 20.8227 30.0873 21.1714 29.7382L24.7866 26.1378C25.0619 25.8071 25.0802 25.2928 24.7866 24.9621C24.4746 24.5764 23.9058 24.5396 23.5204 24.8519L21.4467 26.9277V15.5936C21.4467 13.6097 19.8318 11.9932 17.8498 11.9932C17.3544 11.9932 16.9506 12.3973 16.9506 12.8933C16.9506 13.3893 17.3544 13.7934 17.8498 13.7934C18.8408 13.7934 19.6483 14.6017 19.6483 15.5936Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
