import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface TrendChipProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: LucideIcon;
  label: string;
  selected: boolean;
}

const TrendChip: React.FC<TrendChipProps> = ({
  icon: Icon,
  label,
  selected,
  ...props
}) => {
  const iconColor = selected ? "var(--white-to-black)" : "var(--slate800)";
  const textColor = selected ? "var(--white-to-black)" : "var(--slate900)";

  const textVariant = selected
    ? TextVariants.SemiboldTextS
    : TextVariants.RegularTextS;

  return (
    <button
      className={classNames(styles.trendChipContainer, {
        [styles.selected]: selected,
      })}
      {...props}
    >
      <Icon size={px2rem(16)} color={iconColor} />
      <Text variant={textVariant} color={textColor}>
        {label}
      </Text>
    </button>
  );
};

export default TrendChip;
