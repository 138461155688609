import Skeleton from "react-loading-skeleton";
import px2rem from "utils/px2rem";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import NavigationHeader from "components/common/NavigationHeader";
import FixedHeader from "components/common/FixedHeader";
import { Spinner } from "components/common/spinner";

import styles from "./styles.module.scss";

const FCIHoldingsSkeletonLoader = ({ onClick }: { onClick: () => void }) => {
  const { isMobile } = useDeviceCheck();

  return (
    <div className={styles.loadingContainer}>
      <FixedHeader className={styles.fundsHeader}>
        <NavigationHeader
          title="Fondos de inversión"
          onClick={onClick}
          badgeText="24/7"
          withCloseIcon={!isMobile}
        />
      </FixedHeader>
      <div className={styles.headerLoadingContainer}>
        <Skeleton height={px2rem(32)} borderRadius={px2rem(4)} />
        <Skeleton height={px2rem(45)} borderRadius={px2rem(4)} />
        <Skeleton height={px2rem(24)} borderRadius={px2rem(4)} />
      </div>
      <div className={styles.headerLoadingGraphic}>
        <Spinner />
      </div>
    </div>
  );
};

export default FCIHoldingsSkeletonLoader;
