import ErrorContainer from "components/common/error-modal";

import styles from "./styles.module.scss";

interface ErrorProps {
  onRetry: () => void;
}

const Error: React.FC<ErrorProps> = ({ onRetry }) => {
  return (
    <div className={styles.errorWrapper}>
      <ErrorContainer
        textPrimaryButton="Volver a intentar"
        onClickPrimaryButton={onRetry}
      />
    </div>
  );
};

export default Error;
