import React from "react";

import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DownArrow } from "assets/icons/down-icon";
import { SearchIcon } from "assets/icons/search-icon";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";

import { UIInput } from "../input";

import "./index.scss";

export enum SearchSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}
export enum SearchTypes {
  Secondary = "secondary",
  Primary = "primary",
}

interface PropTypes {
  size?: SearchSizes;
  type?: SearchTypes;
  disabled?: boolean;
  onClick?: () => void;
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
  name?: string;
  autoComplete?: string;
  loading?: boolean;
  onDisplayButtons?: (value: boolean) => void;
  marketDetail?: boolean;
}

export const SearchBarUI = (props: PropTypes) => {
  const {
    size,
    disabled,
    onClick,
    placeholder,
    value,
    onChange,
    name,
    loading,
    onDisplayButtons,
    type = SearchTypes.Primary,
    marketDetail = false,
  } = props;
  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const handleClickSearch = () => (event: React.MouseEvent) => {
    if (onClick) {
      event.stopPropagation();
      onClick();
      onDisplayButtons && onDisplayButtons(true);
    }
  };

  const handleClickOutside = () => () => {
    if (onClick) {
      onClick();
      onDisplayButtons && onDisplayButtons(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={`ui-search-bar ${type}`}>
      {type === SearchTypes.Primary ? (
        !marketDetail ? (
          <div className="search-wrapper">
            <TextField
              autoComplete="off"
              classes={{ root: `ui-search ${size} ${type}` }}
              disabled={disabled}
              id="input-with-icon-adornment"
              label={placeholder}
              name={name}
              value={value}
              variant="standard"
              onBlur={handleClickOutside()}
              onChange={handleChange}
              onClick={handleClickSearch()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        ) : (
          <div className="search-wrapper">
            <TextField
              InputProps={{
                disableUnderline: true,
              }}
              autoComplete="off"
              classes={{ root: `ui-search ${size} ${type} ui-search-jq` }}
              disabled={disabled}
              id="input-with-icon-adornment"
              label={placeholder}
              name={name}
              value={value}
              variant="standard"
              onBlur={handleClickOutside()}
              onChange={handleChange}
              onClick={handleClickSearch()}
            />
            <IconButton
              aria-label="menu"
              className="dots-icon"
              color="inherit"
              edge="start"
              onClick={handleClickSearch()}
            >
              <DownArrow color={darkMode ? "#ffffff" : "#0062E1"} />
            </IconButton>
          </div>
        )
      ) : (
        <>
          <UIInput
            iconRight={
              loading ? (
                <CircularProgress color={"secondary"} size={16} />
              ) : (
                <SearchIcon />
              )
            }
            placeholder={placeholder}
            id="input-search"
            value={value}
            onChange={onChange}
            onClick={handleClickSearch()}
          />
        </>
      )}
    </div>
  );
};
