import { Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BondData } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { getAnalysis } from "features/calculator/calculatorSlice";
import {
  openRightModal,
  closeRightModal,
} from "features/rightModal/rightModalSlice";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import DrawerLayout from "layouts/drawer";
import Results from "pages/calculadora/results";
import { trackAction } from "utils/amplitude";
import { getCurrentScreen } from "features/global/globalSlice";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

interface CalculateButtonProps {
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  setOpen: (v: boolean) => void;
  price: number;
  settlementDate: string;
  amount: number;
  exchangeRate: number;
  currency: Currencies;
  mainBond?: BondData;
}

const CalculateButton: React.FC<CalculateButtonProps> = ({
  price,
  amount,
  setOpen,
  currency,
  mainBond,
  isLoading,
  setIsLoading,
  exchangeRate,
  settlementDate,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const { openRightModalContent } = useAppState();

  const currentScreen = useAppSelector(getCurrentScreen);

  const { isMobile } = useDeviceCheck();

  const openResult = () => (
    <DrawerLayout>
      <Results />
    </DrawerLayout>
  );

  const validateInputs = () => {
    const isPriceValid = price > 0;
    const hasSettlementDate = !!settlementDate;
    const isAmountValid = amount > 0;

    return isPriceValid && hasSettlementDate && isAmountValid;
  };

  const getFinalPrice = (price: number, id_currency: string) => {
    if (currency !== id_currency && id_currency === Currencies.ARS) {
      return price * exchangeRate;
    }

    if (currency !== id_currency && id_currency === Currencies.USD) {
      return price / exchangeRate;
    }

    return price;
  };

  const handleCalcular = () => {
    if (!validateInputs()) {
      setOpen(true);
      setIsLoading(false);
    } else {
      if (!mainBond) return;
      setIsLoading(true);

      const finalPrice = getFinalPrice(price, mainBond.id_currency);

      const objectToSend = {
        bond: mainBond.short_ticker,
        price: finalPrice,
        expiration_date: settlementDate,
        quantity: amount,
        logo: mainBond.logo_file_name,
      };

      trackAction(`${currentScreen} - Calculate bond`, {
        ...objectToSend,
        logo: undefined,
      });

      dispatch(getAnalysis(objectToSend));
      setTimeout(() => {
        if (isMobile) {
          setIsLoading(false);
          navigate(mainRoutes.calculatorResult);
        } else {
          dispatch(closeRightModal());
          setIsLoading(false);

          openRightModalContent(openResult());
          dispatch(
            openRightModal({
              onClose: () => setSearchParams({}),
            })
          );
        }
      }, 2500);
    }
  };

  return (
    <div>
      <ButtonUI
        onClick={handleCalcular}
        disabled={isLoading}
        size={ButtonSizes.Full}
        variant={ButtonVariants.Primary}
      >
        <Typography variant="buttonS">Calcular</Typography>
      </ButtonUI>
    </div>
  );
};

export default CalculateButton;
