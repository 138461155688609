export const CheckboxIcon = () => {
  return (
    <svg
      className="checkbox-icon"
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        height="16"
        rx="4"
        stroke="#002C65"
        strokeLinecap="round"
        strokeWidth="2"
        width="16"
        x="4"
        y="4"
      />
    </svg>
  );
};
