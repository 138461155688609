import { useState, useEffect } from "react";

import { usePrevious } from "hooks/usePrevious";
import { Typography } from "@mui/material";
import { getFormattedNumber } from "utils";

interface InstrumentHighProps {
  high?: number | null;
  idTickSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentHigh: React.FC<InstrumentHighProps> = ({
  high,
  idTickSizeRule,
  tickSize,
}) => {
  const [highClass, setHighClass] = useState<string>("grid-col");
  const previousLow = usePrevious(high);

  useEffect(() => {
    if (previousLow !== high) {
      setHighClass("grid-col change");
      setTimeout(() => {
        setHighClass("grid-col");
      }, 400);
    }
  }, [high]);

  return (
    <div className={highClass}>
      <Typography variant="textXS">
        {getFormattedNumber(high, idTickSizeRule, tickSize)}
      </Typography>
    </div>
  );
};

export default InstrumentHigh;
