import { Typography } from "@mui/material";
import { MarqueeText } from "components/common/marquee";

import styles from "./styles.module.scss";

interface InstrumentTickerProps {
  longTicker: string;
  instrumentShortName: string | null;
}

const InstrumentTicker: React.FC<InstrumentTickerProps> = ({
  longTicker,
  instrumentShortName,
}) => (
  <div className={styles.shortTicker}>
    <Typography variant="textS">{longTicker.split("-")[0]}</Typography>
    <Typography noWrap className={styles.shortName} variant="textXS">
      {instrumentShortName && (
        <MarqueeText
          textLength={15}
          textToMarquee={instrumentShortName}
          variantTypography="textXS"
        />
      )}
    </Typography>
  </div>
);

export default InstrumentTicker;
