import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cardKeys } from "apis/queryKeyFactories";
import { cardService } from "apis/services";
import { AxiosError } from "axios";
import { PomeloData } from "interfaces/card/interface";

const HALF_HOUR = 1000 * 60 * 30;

const fetchCardUserData = async () => {
  const { data } = await API.get<PomeloData>(
    `${cardService.user}?isLight=true`
  );

  return data;
};

export const useCardUserQuery = () => {
  return useAppQuery<PomeloData, AxiosError>({
    queryKey: () => cardKeys.cardUser,
    queryFn: fetchCardUserData,
    options: {
      onError: (e: AxiosError) => {
        return e;
      },
      retry: false,
      staleTime: HALF_HOUR,
      cacheTime: HALF_HOUR,
    },
  });
};
