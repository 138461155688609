import React from "react";

import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { DesktopOrTablet, Mobile } from "utils/responsive";
import { useAppDispatch } from "hooks/redux";
import { ThemeVariants } from "interfaces/theme";
import BottomNavigation from "components/common/BottomNavigation";

import MobileHeader from "../components/common/header";
import RightModalDrawer from "../components/common/right-modal";
import Sidebar from "./DesktopLayout/Sidebar";
import { useDeviceCheck } from "../hooks/useDeviceCheck";

import "./index.scss";

interface CommonLayoutProps {
  children?:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode[]
    | React.ReactNode;
  mobileHeaderTitle?: string;
  subtitle?: string | null;
  search?: (v: string) => void;
  withHeader?: boolean;
  hideBottomNavigation?: boolean;
  hideSidebar?: boolean;
  goBack?: () => void;
  assetToolbar?: boolean;
  isBond?: boolean;
  quickPurchase?: boolean;
  variant?: ThemeVariants;
  menuList?: any;
  menuSettings?: any;
  onRedirect?: any;
  classes?: any;
  link?: React.ReactChild;
}

const CommonLayout: React.FC<CommonLayoutProps> = ({
  children,
  mobileHeaderTitle,
  search,
  subtitle,
  goBack,
  withHeader = true,
  hideBottomNavigation = false,
  hideSidebar = false,
  assetToolbar = false,
  isBond = false,
  quickPurchase,
  variant = ThemeVariants.Capital,
  menuList,
  menuSettings,
  onRedirect,
  classes,
  link,
}) => {
  const { isDesktopOrTablet } = useDeviceCheck();
  const device = isDesktopOrTablet ? "desktop" : "mobile";
  const dispatch = useAppDispatch();
  const selected = useLocation().pathname.split("/")[1];
  const navigate = useNavigate();

  const handleOpenMenu = (path: string) => {
    dispatch(closeRightModal());
    if (onRedirect) {
      return onRedirect(path);
    }
    return navigate(path);
  };

  const getClass = () =>
    `common-layout ${device} ${search ? "with-search" : ""}${
      withHeader ? " with-header" : ""
    }${!hideBottomNavigation ? " with-bottom-nav" : ""} ${classes || ""}`;

  return (
    <div className={getClass()}>
      <DesktopOrTablet>
        <div className="content-wrapper-page">
          {!hideSidebar && (
            <Sidebar
              handleOpenMenu={handleOpenMenu}
              menuList={menuList}
              userSettings={menuSettings}
              selected={selected}
            />
          )}
          <main className="content-wrapper">
            {children}
            <RightModalDrawer />
          </main>
        </div>
      </DesktopOrTablet>
      <Mobile>
        {withHeader && (
          <MobileHeader
            assetToolbar={assetToolbar}
            goBack={goBack}
            isBond={isBond}
            quickPurchase={quickPurchase}
            search={search}
            subtitle={subtitle}
            title={mobileHeaderTitle}
          />
        )}
        {subtitle && (
          <Typography
            component={"p"}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "18px",
              marginLeft: "18px",
              width: "70%",
            }}
            className="layout-subtitle"
          >
            {subtitle}
            {link && link}
          </Typography>
        )}
        <main className="content-wrapper">{children}</main>
        {!hideBottomNavigation && <BottomNavigation variant={variant} />}
      </Mobile>
    </div>
  );
};

export default CommonLayout;
