import { ATENCION_CAPITAL_EMAIL } from "constants/emails";

import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Trash2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";

import BasicWrapper from "../BasicWrapper";

import styles from "./styles.module.scss";

interface CloseAccountPageProps {
  closeModal: () => void;
}
const CloseAccountPage: React.FC<CloseAccountPageProps> = ({ closeModal }) => {
  const { isMobile } = useDeviceCheck();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    if (isMobile) {
      closeModal();
      return;
    }
    dispatch(closeRightModal());
  };

  const contactSupport = () => {
    window.location.href = `mailto:${ATENCION_CAPITAL_EMAIL}?subject=Solicitud cierre de cuenta`;
  };

  const goHome = () => {
    if (!isMobile) {
      dispatch(closeRightModal());
    }
    navigate(mainRoutes.home);
  };

  return (
    <BasicWrapper
      className={styles.closeAccountContainer}
      navigationHeaderProps={
        isMobile
          ? {
              onClick: goBack,
            }
          : undefined
      }
      icon={Trash2}
      title="Eliminá tu cuenta"
      subtitle={`Recordá que para cerrar tu cuenta, esta tiene que estar con saldos en 0 y sin títulos. Contactate con ${ATENCION_CAPITAL_EMAIL} para solicitar la eliminación de tu cuenta.`}
      badgeColor="var(--blue300)"
      primaryButtonProps={{
        children: `Contactar a ${ATENCION_CAPITAL_EMAIL}`,
        onClick: contactSupport,
      }}
      secondaryButtonProps={
        isMobile ? { children: "Ir al inicio", onClick: goHome } : undefined
      }
    />
  );
};

export default CloseAccountPage;
