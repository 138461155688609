import { IconProps } from "assets/icons/BTC";
import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface CardProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  illustration: React.FC<IconProps>;
}

const Card: React.FC<CardProps> = ({
  title,
  illustration: Illustration,
  className,
  ...props
}) => {
  return (
    <button className={classNames(styles.cardContainer, className)} {...props}>
      <Illustration />
      <Text variant={TextVariants.SemiboldTextS} color="var(--slate900)">
        {title}
      </Text>
    </button>
  );
};

export default Card;
