import { IconProps } from "assets/icons/BTC";
import Variation from "components/common/CurrentVariation";
import Divider from "components/common/Divider";
import PriceLabel, { PriceLabelSizes } from "components/common/PriceLabel";
import Text, { TextVariants } from "components/common/Text";
import { isNumber } from "lodash";
import { LucideIcon } from "lucide-react";

import OverlappedIcons from "../OverlappedIcons";

import styles from "./styles.module.scss";

interface SingleQuoteCardProps {
  icon?: JSX.Element;
  icons?: (React.FC<IconProps> | LucideIcon)[];
  name: string;
  variation?: number;
  bid: number;
  ask: number;
  onClick?: () => void;
}

const SingleQuoteCard: React.FC<SingleQuoteCardProps> = ({
  icon,
  icons,
  name,
  variation,
  bid,
  ask,
  onClick,
}) => {
  return (
    <div className={styles.singleQuoteCardContainer} onClick={onClick}>
      <div className={styles.headerRow}>
        <div className={styles.wrapper}>
          {icons ? <OverlappedIcons icons={icons} /> : icon}
          <Text variant={TextVariants.RegularText} color="var(--slate900)">
            {name}
          </Text>
        </div>
        {isNumber(variation) && <Variation value={variation} />}
      </div>
      <Divider className={styles.horizontal} />
      <div className={styles.prices}>
        <div className={styles.priceSection}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Vendés a
          </Text>
          <PriceLabel price={ask} size={PriceLabelSizes.Small} />
        </div>
        <Divider className={styles.vertical} orientation="vertical" />
        <div className={styles.priceSection}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            Comprás a
          </Text>
          <PriceLabel price={bid} size={PriceLabelSizes.Small} />
        </div>
      </div>
    </div>
  );
};

export default SingleQuoteCard;
