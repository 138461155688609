import { UIModalSlider } from "components/common/modal-slider";
import classNames from "classnames";
import OutlinedButton from "components/common/OutlinedButton";
import FilledButton from "components/common/FilledButton";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import getPlatform from "utils/getPlatform";
import Text, { TextVariants } from "components/common/Text";
import Heading, { HeadingVariants } from "components/common/Heading";
import Amount, { AmountSizes } from "components/common/Amount";
import { Currencies } from "interfaces/wallet";
import { Browser } from "@capacitor/browser";
import ExchangeIcons from "components/common/ExchangeIcons";
import px2rem from "utils/px2rem";
import ARGFlag from "assets/icons/ARGFlag";
import USFlag from "assets/icons/USFlag";
import CocosBrand from "assets/icons/CocosBrand";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import type { CocosFund } from "components/page-capital-funds/helpers";

import styles from "./styles.module.scss";

interface ConfirmBottomSheetProps {
  amount: number;
  fund: CocosFund;
  isOpen: boolean;
  isWithdraw: boolean;
  setIsOpen: (value: boolean) => void;
  onConfirm: () => void;
}

const ConfirmBottomSheet = ({
  amount,
  fund,
  isOpen,
  isWithdraw,
  setIsOpen,
  onConfirm,
}: ConfirmBottomSheetProps) => {
  const { isDesktop } = useDeviceCheck();
  const { isIOS } = getPlatform();

  const amountText = isWithdraw ? "retirar" : "agregar";
  const isArs = fund.currency === Currencies.ARS;

  const CountryFlag = isArs ? (
    <ARGFlag size={px2rem(32)} />
  ) : (
    <USFlag size={px2rem(32)} />
  );

  const onClickBack = () => {
    setIsOpen(false);
  };

  const onClickTyC = () => {
    Browser.open({
      url: fund.tyc,
    });
  };

  return (
    <UIModalSlider
      open={isOpen}
      onToggleDisplay={onClickBack}
      classes={classNames(styles.sheetWrapper, { [styles.desktop]: isDesktop })}
    >
      <div className={styles.bottomSheetContainer}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h2"
          color="var(--slate900)"
        >
          Monto a {amountText}
        </Heading>
        <ExchangeIcons
          firstIcon={CountryFlag}
          secondIcon={
            <CocosBrand
              color={getFCIIconColor(fund.ticker)}
              size={px2rem(32)}
            />
          }
          isReverse={isWithdraw}
        />
        <Amount
          amount={amount}
          currency={fund.currency}
          size={AmountSizes.Large}
          isSmallCurrency
        />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          Al confirmar, acepto el{" "}
          <Text
            variant={TextVariants.SemiboldTextS}
            color="var(--blue800)"
            onClick={onClickTyC}
            component="span"
            className={styles.cursorPointer}
          >
            Reglamento de Gestión
          </Text>{" "}
          del fondo.
        </Text>
      </div>
      <div
        className={classNames(styles.buttonsWrapper, { [styles.ios]: isIOS })}
      >
        <OutlinedButton onClick={onClickBack}>Volver</OutlinedButton>
        <FilledButton onClick={onConfirm}>Confirmar</FilledButton>
      </div>
    </UIModalSlider>
  );
};

export default ConfirmBottomSheet;
