import { ReactNode, useEffect } from "react";

import { Browser } from "@capacitor/browser";
import classNames from "classnames";

import styles from "./styles.module.scss";

export enum TextVariants {
  RegularText = "regularText",
  SemiboldText = "semiboldText",
  RegularUpperText = "regularUpperText",
  RegularTextL = "regularTextL",
  RegularTextS = "regularTextS",
  SemiboldTextS = "semiboldTextS",
  SemiboldTextXS = "semiboldTextXS",
  RegularUpperTextS = "regularUpperTextS",
  RegularUpperValue = "regularUpperValue",
  SemiboldUpperValue = "semiboldUpperValue",
  RegularUpperValueS = "regularUpperValueS",
  SemiboldUpperValueS = "semiboldUpperValueS",
  RegularValueXS = "regularValueXS",
  SemiboldValueXS = "semiboldValueXS",
  SemiboldTextL = "semiboldTextL",
  RegularTextXL = "regularTextXL",
  SemiboldTextXL = "semiboldTextXL",
  RegularValueS = "regularValueS",
}

type TextProps = {
  variant: TextVariants;
  className?: string;
  color?: string;
  onClick?: () => void;
  children: ReactNode;
} & (
  | {
      component?: "p" | "span";
    }
  | {
      component: "a";
      href?: string;
    }
);

const Text: React.FC<TextProps> = ({
  variant,
  component: Component = "p",
  className,
  color,
  onClick,
  ...props
}) => {
  useEffect(() => {
    if (Component !== "a") return;

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;

      const href = target.getAttribute("href");
      if (href) {
        event?.preventDefault();

        Browser.open({
          url: href,
        });
        return;
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <Component
      {...props}
      className={classNames(styles.text, styles[variant], className)}
      style={{ color: color }}
      onClick={onClick}
    />
  );
};

export default Text;
