import { IconProps } from "assets/icons/BTC";

const AddCrypto: React.FC<IconProps> = ({
  width = 108,
  height = 80,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 108 80"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_14549_16063)">
      <path
        d="M99.9247 0H63.6215C60.729 0 58.3843 2.34478 58.3843 5.23721V74.7628C58.3843 77.6552 60.729 80 63.6215 80H99.9247C102.817 80 105.162 77.6552 105.162 74.7628V5.23721C105.162 2.34478 102.817 0 99.9247 0Z"
        fill="#8E4EC6"
      />
      <path
        d="M105.162 8.7793H58.3843V70.9654H105.162V8.7793Z"
        fill="#113264"
      />
      <path
        d="M105.162 16.6709C105.417 16.6709 105.67 16.7214 105.905 16.8195C106.141 16.9176 106.355 17.0614 106.534 17.2425C106.714 17.4237 106.856 17.6386 106.952 17.8749C107.049 18.1112 107.097 18.3643 107.095 18.6194V26.6647C107.095 27.1774 106.892 27.6692 106.529 28.0318C106.166 28.3944 105.675 28.598 105.162 28.598V16.6709Z"
        fill="#8E4EC6"
      />
      <path
        d="M76.9385 4.81494H87.2159"
        stroke="#1C2024"
        strokeWidth="0.75343"
        strokeMiterlimit="10"
      />
      <path
        d="M81.773 77.7746C83.0285 77.7746 84.0463 76.7568 84.0463 75.5013C84.0463 74.2458 83.0285 73.228 81.773 73.228C80.5175 73.228 79.4998 74.2458 79.4998 75.5013C79.4998 76.7568 80.5175 77.7746 81.773 77.7746Z"
        fill="#073E86"
      />
      <mask
        id="mask0_14549_16063"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="18"
        width="83"
        height="45"
      >
        <path
          d="M0 18.0112L0 62.2666L82.0135 62.2666V18.0112L0 18.0112Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_14549_16063)">
        <path
          d="M26.3418 60.5738C37.7059 60.5738 46.9183 51.3614 46.9183 39.9973C46.9183 28.6333 37.7059 19.4209 26.3418 19.4209C14.9778 19.4209 5.76538 28.6333 5.76538 39.9973C5.76538 51.3614 14.9778 60.5738 26.3418 60.5738Z"
          fill="#70B8FF"
        />
        <path
          d="M26.3461 55.4773C34.899 55.4773 41.8325 48.5438 41.8325 39.9908C41.8325 31.4379 34.899 24.5044 26.3461 24.5044C17.7931 24.5044 10.8596 31.4379 10.8596 39.9908C10.8596 48.5438 17.7931 55.4773 26.3461 55.4773Z"
          fill="url(#paint0_linear_14549_16063)"
        />
        <path
          d="M26.3418 55.4775C30.4473 55.4775 34.3847 53.8466 37.2878 50.9435C40.1908 48.0405 41.8218 44.1031 41.8218 39.9975C41.8218 35.892 40.1908 31.9546 37.2878 29.0516C34.3847 26.1485 30.4473 24.5176 26.3418 24.5176"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M15.9432 28.5293C14.0302 30.2654 12.5819 32.4525 11.7303 34.8914C10.8787 37.3303 10.6508 39.9436 11.0675 42.4931C11.4841 45.0426 12.532 47.4474 14.1157 49.4884C15.6994 51.5293 17.7685 53.1416 20.1347 54.1784"
          stroke="white"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M26.3765 32.084L34.3806 40.0881L26.3765 48.09"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M34.3806 40.0884H17.1643"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M70.7726 60.5736C82.1403 60.5736 91.3556 51.3583 91.3556 39.9907C91.3556 28.623 82.1403 19.4077 70.7726 19.4077C59.405 19.4077 50.1897 28.623 50.1897 39.9907C50.1897 51.3583 59.405 60.5736 70.7726 60.5736Z"
          fill="#D19DFF"
        />
        <path
          d="M70.7726 55.4773C79.3255 55.4773 86.259 48.5438 86.259 39.9908C86.259 31.4379 79.3255 24.5044 70.7726 24.5044C62.2197 24.5044 55.2861 31.4379 55.2861 39.9908C55.2861 48.5438 62.2197 55.4773 70.7726 55.4773Z"
          fill="url(#paint1_linear_14549_16063)"
        />
        <path
          d="M70.7725 55.4772C72.843 55.5371 74.9046 55.1811 76.8352 54.4302C78.7657 53.6792 80.526 52.5486 82.0118 51.1052C83.4976 49.6618 84.6787 47.935 85.4853 46.027C86.2918 44.1191 86.7074 42.0687 86.7074 39.9972C86.7074 37.9257 86.2918 35.8753 85.4853 33.9673C84.6787 32.0594 83.4976 30.3326 82.0118 28.8892C80.526 27.4458 78.7657 26.3152 76.8352 25.5642C74.9046 24.8133 72.843 24.4572 70.7725 24.5172"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M60.3717 28.5186C58.458 30.2547 57.009 32.4419 56.1566 34.881C55.3042 37.3202 55.0754 39.9339 55.4911 42.484C55.9069 45.0342 56.9539 47.4399 58.5369 49.482C60.1199 51.5242 62.1886 53.1379 64.5546 54.1763"
          stroke="white"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M77.6639 34.6412C76.5451 33.1886 74.9997 32.1227 73.2445 31.5929C71.4893 31.0631 69.6122 31.0959 67.8766 31.6869C66.141 32.2778 64.6339 33.3971 63.5667 34.8879C62.4994 36.3787 61.9255 38.1662 61.9255 39.9996C61.9255 41.8331 62.4994 43.6205 63.5667 45.1113C64.6339 46.6021 66.141 47.7215 67.8766 48.3124C69.6122 48.9033 71.4893 48.9362 73.2445 48.4063C74.9997 47.8765 76.5451 46.8106 77.6639 45.3581"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M68.8413 31.437V28.5186"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M72.2947 31.2465V28.5186"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M68.8413 51.5V48.4863"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
        <path
          d="M72.2947 51.5004V48.7725"
          stroke="#1C2024"
          strokeWidth="0.75343"
          strokeMiterlimit="10"
        />
      </g>
      <path
        d="M82.0979 60.5753V19.4072"
        stroke="white"
        strokeWidth="0.751265"
        strokeMiterlimit="10"
      />
      <path
        d="M9.20997 77.6379C9.20997 75.8768 8.51038 74.1879 7.26512 72.9426C6.01985 71.6974 4.3309 70.9978 2.56982 70.9978C4.3311 70.9972 6.02004 70.2971 7.26525 69.0515C8.51045 67.8059 9.20997 66.1167 9.20997 64.3555C9.21054 66.1169 9.91054 67.8061 11.1561 69.0517C12.4016 70.2972 14.0908 70.9972 15.8523 70.9978C14.091 70.9978 12.4018 71.6973 11.1562 72.9425C9.91061 74.1877 9.21054 75.8766 9.20997 77.6379Z"
        fill="#8145B5"
        stroke="#8145B5"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.916 16.8983C45.9154 15.645 45.4173 14.4432 44.531 13.557C43.6448 12.6708 42.443 12.1726 41.1897 12.1721C42.4432 12.1721 43.6453 11.6741 44.5317 10.7878C45.418 9.90143 45.916 8.69928 45.916 7.4458C45.916 8.69928 46.4139 9.90143 47.3002 10.7878C48.1866 11.6741 49.3887 12.1721 50.6422 12.1721C49.3887 12.1721 48.1866 12.67 47.3002 13.5563C46.4139 14.4427 45.916 15.6448 45.916 16.8983Z"
        fill="#70B8FF"
        stroke="#70B8FF"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.3526 6.98211C17.3526 6.17478 17.0319 5.40052 16.461 4.82965C15.8902 4.25878 15.1159 3.93807 14.3086 3.93807C15.1157 3.9375 15.8897 3.61661 16.4604 3.04586C17.0312 2.47512 17.3521 1.70119 17.3526 0.894043C17.3526 1.70137 17.6733 2.47563 18.2442 3.0465C18.8151 3.61736 19.5893 3.93807 20.3967 3.93807C19.9968 3.93779 19.6009 4.01633 19.2314 4.16921C18.862 4.32208 18.5263 4.54629 18.2436 4.82902C17.9608 5.11174 17.7366 5.44742 17.5838 5.81687C17.4309 6.18632 17.3523 6.58228 17.3526 6.98211Z"
        fill="#D19DFF"
        stroke="#D19DFF"
        strokeWidth="0.850856"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_14549_16063"
        x1="41.2675"
        y1="39.9908"
        x2="10.2945"
        y2="39.9908"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#70B8FF" />
        <stop offset="0.22" stopColor="#77BBFF" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#8AC5FF" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#A9D4FF" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#D3E9FF" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14549_16063"
        x1="85.694"
        y1="39.9908"
        x2="54.7232"
        y2="39.9908"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#D19DFF" />
        <stop offset="0.22" stopColor="#D3A2FF" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#D9AFFF" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#E3C4FF" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#F1E1FF" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <clipPath id="clip0_14549_16063">
        <rect width="107.095" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AddCrypto;
