import { Typography } from "@mui/material";

import { Bid } from "../../../interfaces/api-responses/socket/book";
import { Currency } from "../currency";
import { DecimalRound } from "../decimal-round-rules";
import { BigNumberFormatter } from "../number-formatter";

import "./index.scss";

interface PropTypes {
  type: "bid" | "ask";
  rows: Bid[];
  displayPercentage?: boolean;
  tickSize?: number | null;
  ruleId?: string | null;
  reverseBidOrder?: boolean;
}

export const BidOfferTable = (props: PropTypes) => {
  const {
    tickSize,
    type,
    rows,
    ruleId,
    displayPercentage,
    reverseBidOrder = false,
  } = props;

  const sortedRows = rows.sort((a, b) =>
    type === "bid" && reverseBidOrder ? a.price - b.price : b.price - a.price
  );

  //para que queden las rows vacías de ask primeras
  const getSortArr = () => {
    if (type === "bid" || rows.length >= 5 || rows.length === 0) {
      return [0, 1, 2, 3, 4];
    }
    const diff = 5 - rows.length;
    const emptyRows = [4, 3, 2, 1, 0].slice(0, diff);
    const fillRows = [0, 1, 2, 3, 4].slice(0, rows.length);

    return [...emptyRows, ...fillRows];
  };

  const getFormattedPrice = (price?: number) => {
    if (!price) {
      return "-";
    }

    if (displayPercentage) {
      return <Currency>{price}</Currency>;
    }
    return (
      <DecimalRound ruleId={ruleId} tickSize={tickSize}>
        {price}
      </DecimalRound>
    );
  };

  return (
    <div className={`comp-table-bid-offer ${type}`}>
      <div className="table-header">
        <Typography variant="textXS_bold">Cantidad</Typography>
        <Typography variant="textXS_bold">
          {type === "bid" ? "P. Compra" : "P. Venta"}
        </Typography>
      </div>
      {getSortArr().map((id) => (
        <div key={id} className="grid-container">
          <Typography variant="textS">
            {sortedRows[id]?.size && sortedRows[id].size > 99999 ? (
              <BigNumberFormatter>{sortedRows[id]?.size}</BigNumberFormatter>
            ) : (
              sortedRows[id]?.size || "-"
            )}
          </Typography>
          <Typography variant="textS_bold">
            {getFormattedPrice(sortedRows[id]?.price)}
            {displayPercentage && sortedRows[id]?.price && " %"}
          </Typography>
        </div>
      ))}
    </div>
  );
};
