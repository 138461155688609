import { useEffect, useState } from "react";

import { Instruments } from "interfaces/markets";
import { updateOrderOptions } from "features/markets/marketsSlice";
import { RootState } from "store/store";
import { UIPriceInputV2 } from "components/common/price-input";
import { calculateNetAmount } from "utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import {
  getIsCableEnabled,
  getMinToOperateMessage,
  getNominalValue,
} from "../../utils";
import TradingCurrencySettlement from "../advance-operation/currency-settlement";
import TradingMethod from "../advance-operation/method";

import styles from "./styles.module.scss";

interface BodyBuyProps {
  instrumentProp: any;
  ableOperation: (value: boolean) => void;
  advancePurchase: boolean;
  handleChangePrice: (value: number) => void;
  numOfDecimals: number;
}

const BodyBuy: React.FC<BodyBuyProps> = ({
  instrumentProp,
  ableOperation,
  advancePurchase,
  handleChangePrice,
  numOfDecimals,
}) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { wallet } = useAppSelector((state: RootState) => state.wallet);

  const currency: "ars" | "usd" | "ext" =
    (instrumentProp.currency && instrumentProp.currency.toLowerCase()) ?? "ars";
  const buyingPower = +(+(wallet?.["24hs"]?.[currency] ?? 0));

  const minToOperate =
    ((order.price || instrumentProp.ask || instrumentProp.last) *
      instrumentProp.contract_size *
      instrumentProp.min_lot_size) /
    instrumentProp.price_factor;

  const isCableEnabled = getIsCableEnabled(instrumentProp.instrument_type);

  const [error, setError] = useState<boolean>(false);

  const getInputLabel = () => {
    const nominalValue = getNominalValue(instrumentProp.instrument_type);
    const isMale = [
      Instruments.CEDEARS,
      Instruments.BONOS_CORP,
      Instruments.BONOS_PUBLICOS,
    ].includes(instrumentProp.instrument_type);

    return `${nominalValue} ${
      isMale ? "aproximados" : "aproximadas"
    } a recibir`;
  };

  const errorMessage = getMinToOperateMessage(
    instrumentProp.min_lot_size,
    minToOperate,
    instrumentProp.currency,
    instrumentProp.instrument_type
  );

  useEffect(() => {
    if (order.amount) {
      if (order.amount > 0 && order.amount < minToOperate) {
        ableOperation(false);
        setError(true);
        return;
      }
      if (order.amount >= minToOperate && order.amount <= buyingPower) {
        ableOperation(true);
        setError(false);
        return;
      }
    }
    ableOperation(false);
    setError(false);
  }, [order.amount]);

  const handleChangeQuantity = (value: number) => {
    const newAmount = calculateNetAmount(
      order.price || instrumentProp.ask,
      value,
      instrumentProp.contract_size,
      instrumentProp.price_factor
    );

    dispatch(
      updateOrderOptions({
        ...order,
        amount: newAmount,
        quantity: value,
      })
    );
    return;
  };

  return (
    <div>
      {advancePurchase && (
        <TradingCurrencySettlement isCableEnabled={isCableEnabled} />
      )}
      <UIPriceInputV2
        label="¿Qué monto querés invertir?"
        newClass={styles.investmentAmount}
        key="amount"
        value={order.amount}
        currency={instrumentProp.currency}
        handleChangeValue={handleChangePrice}
        error={error}
        errorMessage={errorMessage}
        decimals={numOfDecimals}
        id="investment-amount-buy"
      />
      <UIPriceInputV2
        label={getInputLabel()}
        newClass={styles.amountToReceive}
        key="format-quantity"
        value={order.quantity}
        currency={instrumentProp.instrument_type}
        disabled={!advancePurchase}
        handleChangeValue={
          advancePurchase ? (value) => handleChangeQuantity(value) : undefined
        }
        logoFileName={instrumentProp.logo_file_name}
        id="amount-to-receive-buy"
      />
      {advancePurchase && (
        <TradingMethod
          instrumentProp={instrumentProp}
          numOfDecimals={numOfDecimals}
        />
      )}
    </div>
  );
};

export default BodyBuy;
