import { Typography } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { useTrading } from "context/TradingProvider";
import { Currencies } from "interfaces/wallet";
import openDDJJ from "utils/openDDJJ";

interface TradingFooterProps {
  isBuy: boolean;
}

const TradingFooter: React.FC<TradingFooterProps> = ({ isBuy }) => {
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { currency } = useTrading();
  const hasFees = currency === Currencies.USD || currency === Currencies.EXT;

  const shouldShowDDJJ =
    order.currency === Currencies.USD || order.currency === Currencies.EXT;
  return (
    <>
      {shouldShowDDJJ && (
        <Typography
          variant="textXS"
          style={{ display: "inline-block", marginTop: "10px" }}
        >
          Al presionar Revisar {isBuy ? "Compra" : "Venta"} estás aceptando
          la&nbsp;
          <b onClick={openDDJJ} style={{ cursor: "pointer" }}>
            <u>Declaración Jurada</u>
          </b>
        </Typography>
      )}
      <br />
      {hasFees && (
        <Typography variant="textXS" style={{ marginTop: "10px" }}>
          Esta operación tiene comisiones según el&nbsp;
          <a
            href="https://cocos.capital/tarifario"
            target="_blank"
            rel="noreferrer"
          >
            <b>
              <u>Tarifario Vigente</u>
            </b>
          </a>
        </Typography>
      )}
    </>
  );
};

export default TradingFooter;
