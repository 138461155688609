import { IconButton } from "@mui/material";
import { motion } from "framer-motion";
import { RefreshIcon } from "assets/icons/refresh";

import "./index.scss";

interface onClickProp {
  onClick: () => void;
  loading: boolean;
  disabled?: boolean;
}

const RetryButton = ({ onClick, loading, disabled }: onClickProp) => {
  return (
    <IconButton onClick={onClick} className={"button"} disabled={disabled}>
      <motion.div
        animate={{
          rotate: [loading ? 360 : 0, 0],
        }}
        transition={{
          duration: 1,
          repeat: Infinity,
        }}
      >
        <RefreshIcon size={12} />
      </motion.div>
    </IconButton>
  );
};

export default RetryButton;
