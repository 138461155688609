import React from "react";

import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxIcon } from "assets/icons/checkbox/checkbox-icon";
import { CheckedCheckboxIcon } from "assets/icons/checkbox/checked-checkbox";

import "./index.scss";

interface PropTypes {
  disabled?: boolean;
  checked?: any;
  onChange: (newValue: boolean) => void;
  label?: string | React.ReactNode;
}

export default function CheckboxUI({
  disabled,
  checked,
  onChange,
  label,
}: PropTypes) {
  const handleChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <div className="ui-check">
      <Checkbox
        checked={checked}
        checkedIcon={<CheckedCheckboxIcon />}
        disabled={disabled}
        icon={<CheckboxIcon />}
        onChange={handleChangeChecked}
      />
      {label && <Typography variant="textM">{label}</Typography>}
    </div>
  );
}
