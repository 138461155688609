import React, { useEffect } from "react";

export const useOutsideClick = (callback: () => void, condition?: boolean) => {
  const ref: React.RefObject<any> = React.createRef();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        condition !== false &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return ref;
};
