import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { TickersResponse } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import { NumberFormatValues } from "react-number-format";
import UICurrencyToggle from "components/common/currency-toggle";
import { UIPriceInput } from "components/common/input/price";

import styles from "./styles.module.scss";

interface BondPriceProps {
  bondCurrentData: TickersResponse;
  price: number;
  currency: Currencies;
  handleChangePrice: (v: NumberFormatValues) => void;
  handleChangeCurrency: (v: Currencies) => void;
}

const BondPrice: React.FC<BondPriceProps> = ({
  price,
  currency,
  bondCurrentData,
  handleChangePrice,
  handleChangeCurrency,
}) => {
  const isARS = currency === Currencies.ARS;

  return (
    <div className={styles.bondPriceWrapper}>
      <div className={styles.inputWrapper}>
        <UIPriceInput
          label="Precio"
          value={price?.toString()}
          onChangeValue={handleChangePrice}
        />
        <Typography className={styles.lastPrice}>
          Ult. Operado:
          <Typography className={styles.lastPriceValue}>
            {isARS ? "AR$" : "US$"}{" "}
            <Currency>
              {Math.abs(Number(bondCurrentData.asks[0].price))}
            </Currency>
          </Typography>
        </Typography>
      </div>
      <UICurrencyToggle
        currencyValue={currency?.toUpperCase()}
        label="Moneda"
        onChangeCurrency={handleChangeCurrency}
      />
    </div>
  );
};

export default BondPrice;
