import { useEffect, useState } from "react";

import { CircularProgress, Typography } from "@mui/material";
import API from "apis";
import { InstrumentData } from "interfaces/api-responses";
import { OrderType } from "interfaces/markets";
import { Navigate, useNavigate } from "react-router-dom";
import { toggleInList } from "features/markets/marketsActions";
import { getCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import { trackAction } from "utils/amplitude";
import { marketService } from "apis/services";
import mainRoutes from "router/routes";
import { useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface ContentAddListCommonProps {
  selectedMarket?: InstrumentData;
  onClickCreateList: () => void;
}

const ContentAddListCommon: React.FC<ContentAddListCommonProps> = ({
  selectedMarket,
  onClickCreateList,
}) => {
  const navigate = useNavigate();
  const currentScreen = useAppSelector(getCurrentScreen);

  const user = useAppSelector(getLoggedUser);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tickerFavorites, setTickerFavorites] = useState<string[]>([]);

  useEffect(() => {
    if (!selectedMarket) return;

    const getFavoriteLists = async () => {
      try {
        setLoading(true);
        const { data } = await API.get<{ favoritesLists: string[] }>(
          `${marketService.favoriteLists}?long_ticker=${selectedMarket.long_ticker}`
        );
        const { favoritesLists } = data;
        setTickerFavorites(favoritesLists);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    getFavoriteLists();
  }, []);

  const handleClickList = async (id_list: string, isInFavorites: boolean) => {
    if (!selectedMarket) return;
    await toggleInList(isInFavorites, id_list, selectedMarket.long_ticker);

    trackAction(
      `${currentScreen} - ${
        isInFavorites ? "Remove Instrument From List" : "Add Instrument To List"
      }`,
      {
        listId: id_list,
        instrument_code: selectedMarket.instrument_code,
        long_ticker: selectedMarket.long_ticker,
      }
    );
    if (tickerFavorites.includes(id_list)) {
      setTickerFavorites(tickerFavorites.filter((list) => list !== id_list));
      return;
    }

    setTickerFavorites((prev) => [...prev, id_list]);
  };

  const handleCreateListWithTicker = () => {
    trackAction(`${currentScreen} - Open New List`);
    if (selectedMarket) {
      onClickCreateList();
    }
  };

  const handleTickerDetail = (type: OrderType) => () => {
    navigate(
      `/market/${selectedMarket?.instrument_type}/${selectedMarket?.instrument_code}`,
      {
        state: {
          type: type,
          currency: selectedMarket?.currency,
        },
      }
    );
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { favoritesLists } = user;

  if (loading)
    return (
      <div className={styles.loader_wrapper}>
        <CircularProgress size={40} className={styles.loading} />
      </div>
    );

  if (error)
    return (
      <Typography component="h2" variant="textS_bold">
        Ups.. hubo un error.
      </Typography>
    );

  return (
    <div>
      <Typography className="list-header" component={"p"} variant="textS_bold">
        Agregar o eliminar activo de tu lista
      </Typography>
      <div className="list">
        {favoritesLists.map((list) => {
          const isInFavorites = tickerFavorites.includes(list.id_list);
          return (
            <div
              key={list.id_list}
              className="list-name"
              onClick={() => handleClickList(list.id_list, isInFavorites)}
            >
              <Typography
                noWrap
                className="name"
                component="p"
                variant={isInFavorites ? "textS_bold" : "textS"}
              >
                {list.name}
              </Typography>
              {isInFavorites && (
                <div className="ticker-exist">
                  <Typography component={"p"} variant="textXS">
                    Agregado
                  </Typography>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div
        className="add-to-list-container"
        onClick={handleCreateListWithTicker}
      >
        <div className="add-to-list">
          <Typography component="p" variant="buttonS">
            + Agregar a nueva lista
          </Typography>
        </div>
      </div>
      <div className="buttons-wrapper">
        <ButtonUI
          size={ButtonSizes.Small}
          variant={ButtonVariants.Sell}
          onClick={handleTickerDetail(OrderType.Sell)}
        >
          <Typography variant="buttonS">Vender</Typography>
        </ButtonUI>
        <ButtonUI
          size={ButtonSizes.Small}
          variant={ButtonVariants.Buy}
          onClick={handleTickerDetail(OrderType.Buy)}
        >
          <Typography variant="buttonS">Comprar</Typography>
        </ButtonUI>
      </div>
    </div>
  );
};

export default ContentAddListCommon;
