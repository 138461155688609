import { SVGProps } from "react";

const AppStore: React.FC<SVGProps<SVGSVGElement>> = (_props) => {
  return (
    <svg
      width="153"
      height="44"
      viewBox="0 0 153 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="App Store">
        <g id="Group">
          <path
            id="BG"
            d="M151.615 38.9257C151.615 41.2705 149.676 43.1698 147.278 43.1698H5.34283C2.94573 43.1698 1 41.2705 1 38.9257V5.0799C1 2.73619 2.94573 0.8302 5.34283 0.8302H147.277C149.676 0.8302 151.614 2.73619 151.614 5.0799L151.615 38.9257Z"
            fill="black"
          />
          <g id="Group_2">
            <path
              id="Vector"
              d="M52.1452 7.35898C52.621 7.32483 53.0986 7.39672 53.5433 7.56947C53.988 7.74222 54.3889 8.01151 54.7169 8.35793C55.045 8.70436 55.2921 9.11927 55.4403 9.57274C55.5886 10.0262 55.6344 10.5069 55.5744 10.9803C55.5744 13.3086 54.316 14.6469 52.1452 14.6469H49.5127V7.35898H52.1452ZM50.6446 13.6163H52.0187C52.3588 13.6366 52.6991 13.5808 53.0149 13.4531C53.3306 13.3253 53.614 13.1287 53.8442 12.8776C54.0744 12.6265 54.2458 12.3273 54.3457 12.0016C54.4457 11.676 54.4718 11.3321 54.4222 10.9951C54.4682 10.6594 54.4395 10.3177 54.338 9.99445C54.2364 9.67119 54.0647 9.37438 53.835 9.1253C53.6053 8.87622 53.3233 8.68103 53.0093 8.55371C52.6953 8.42639 52.357 8.37011 52.0187 8.38888H50.6446V13.6163Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M61.7769 13.162C61.6294 13.6647 61.3099 14.0995 60.8742 14.3903C60.4385 14.6812 59.9145 14.8096 59.3937 14.7532C59.0313 14.7627 58.6712 14.6933 58.3384 14.5498C58.0056 14.4062 57.708 14.192 57.4662 13.9219C57.2245 13.6518 57.0444 13.3323 56.9386 12.9856C56.8327 12.639 56.8035 12.2734 56.853 11.9143C56.8048 11.5542 56.8345 11.1878 56.9401 10.8401C57.0458 10.4924 57.2249 10.1715 57.4653 9.89901C57.7058 9.62655 58.002 9.40893 58.3338 9.26088C58.6657 9.11284 59.0255 9.03783 59.3889 9.04092C60.9192 9.04092 61.8425 10.0865 61.8425 11.8135V12.1923H57.9588V12.2531C57.9418 12.4549 57.9672 12.6581 58.0333 12.8496C58.0994 13.041 58.2048 13.2165 58.3427 13.3649C58.4806 13.5132 58.648 13.6311 58.8341 13.711C59.0202 13.7909 59.221 13.831 59.4236 13.8287C59.6832 13.8599 59.9462 13.8131 60.1792 13.6944C60.4122 13.5757 60.6047 13.3903 60.732 13.162L61.7769 13.162ZM57.9587 11.3895H60.7367C60.7504 11.2049 60.7253 11.0195 60.6631 10.8452C60.6008 10.6709 60.5028 10.5115 60.3753 10.3773C60.2478 10.2432 60.0937 10.1371 59.9228 10.0661C59.7518 9.99502 59.568 9.96051 59.3829 9.96475C59.1952 9.9624 59.0089 9.99765 58.835 10.0684C58.6611 10.1392 58.5032 10.2441 58.3704 10.3769C58.2377 10.5096 58.1329 10.6677 58.0622 10.8416C57.9915 11.0155 57.9564 11.2018 57.9588 11.3895H57.9587Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M65.31 9.04083C66.5469 9.04083 67.3556 9.61635 67.4617 10.5861H66.4204C66.3203 10.1823 65.9254 9.92469 65.31 9.92469C64.704 9.92469 64.2437 10.2122 64.2437 10.6416C64.2437 10.9701 64.5216 11.1771 65.118 11.3137L66.0316 11.5254C67.0777 11.7681 67.5679 12.2178 67.5679 13.0259C67.5679 14.0613 66.603 14.7531 65.2897 14.7531C63.9812 14.7531 63.1284 14.162 63.0317 13.1875H64.1184C64.212 13.4195 64.3808 13.6132 64.5978 13.7377C64.8148 13.8622 65.0672 13.9102 65.3147 13.874C65.991 13.874 66.4717 13.5711 66.4717 13.1315C66.4717 12.8035 66.2141 12.5911 65.663 12.4599L64.704 12.2375C63.6579 11.99 63.1737 11.5206 63.1737 10.7024C63.1737 9.72728 64.0671 9.04083 65.31 9.04083Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M72.6312 10.9447C72.562 10.6595 72.393 10.4085 72.1547 10.2371C71.9164 10.0658 71.6247 9.98549 71.3323 10.0107C70.4234 10.0107 69.8675 10.7073 69.8675 11.8792C69.8675 13.0714 70.4282 13.7835 71.3323 13.7835C71.6218 13.817 71.9136 13.7436 72.1528 13.5771C72.392 13.4106 72.5622 13.1625 72.6312 12.8794H73.6868C73.617 13.4363 73.3324 13.9439 72.8937 14.294C72.4549 14.644 71.8968 14.8088 71.3383 14.7532C70.9705 14.7648 70.6047 14.696 70.2663 14.5514C69.928 14.4069 69.6253 14.1901 69.3794 13.9164C69.1336 13.6427 68.9505 13.3185 68.843 12.9666C68.7355 12.6148 68.7062 12.2436 68.7571 11.8792C68.7087 11.5175 68.7399 11.1496 68.8485 10.8012C68.957 10.4528 69.1403 10.1323 69.3855 9.86201C69.6307 9.59175 69.932 9.37826 70.2682 9.23646C70.6045 9.09466 70.9676 9.02796 71.3323 9.04102C71.8954 8.98915 72.4565 9.15906 72.8963 9.51459C73.336 9.87012 73.6196 10.3832 73.6868 10.9447L72.6312 10.9447Z"
              fill="white"
            />
            <path
              id="Vector_5"
              d="M74.7639 13.0915C74.7639 12.1015 75.5011 11.5308 76.8095 11.4496L78.2993 11.3638V10.889C78.2993 10.3082 77.9153 9.98015 77.1733 9.98015C76.5674 9.98015 76.1476 10.2026 76.0271 10.5914H74.9762C75.0872 9.64676 75.9758 9.04083 77.2234 9.04083C78.6023 9.04083 79.38 9.72727 79.38 10.889V14.6469H78.3351V13.874H78.2492C78.0749 14.1512 77.8301 14.3773 77.5399 14.529C77.2497 14.6807 76.9244 14.7526 76.5972 14.7375C76.3663 14.7615 76.133 14.7369 75.9122 14.6652C75.6914 14.5935 75.4881 14.4764 75.3154 14.3213C75.1426 14.1662 75.0043 13.9766 74.9094 13.7648C74.8144 13.553 74.7648 13.3236 74.7639 13.0915ZM78.2993 12.6216V12.1617L76.9563 12.2476C76.1988 12.2983 75.8553 12.556 75.8553 13.0408C75.8553 13.5358 76.2847 13.8239 76.8752 13.8239C77.0482 13.8414 77.223 13.8239 77.3891 13.7725C77.5552 13.7211 77.7093 13.6368 77.8421 13.5246C77.975 13.4124 78.084 13.2747 78.1625 13.1195C78.241 12.9644 78.2876 12.795 78.2993 12.6216Z"
              fill="white"
            />
            <path
              id="Vector_6"
              d="M81.0797 9.14681H82.1246V9.99011H82.2057C82.3227 9.69737 82.5294 9.44922 82.7962 9.28132C83.063 9.11341 83.3762 9.03437 83.6907 9.05556C83.8533 9.05337 84.0156 9.06857 84.175 9.10088V10.1721C83.9758 10.1295 83.7727 10.1075 83.5691 10.1065C83.39 10.0904 83.2096 10.1119 83.0394 10.1697C82.8692 10.2275 82.713 10.3203 82.5808 10.4421C82.4486 10.5638 82.3433 10.7119 82.2717 10.8768C82.2001 11.0417 82.1638 11.2197 82.1652 11.3994V14.6468H81.0797L81.0797 9.14681Z"
              fill="white"
            />
            <path
              id="Vector_7"
              d="M85.1721 15.1974H86.2832C86.3751 15.5963 86.8343 15.854 87.5667 15.854C88.4708 15.854 89.0064 15.4246 89.0064 14.6976V13.642H88.9253C88.755 13.9375 88.5064 14.1801 88.2068 14.3432C87.9073 14.5062 87.5685 14.5832 87.2279 14.5658C85.824 14.5658 84.9551 13.4804 84.9551 11.8337C84.9551 10.1567 85.8336 9.05572 87.2387 9.05572C87.5878 9.04356 87.9335 9.12758 88.2381 9.2986C88.5427 9.46962 88.7944 9.72107 88.9658 10.0255H89.0517V9.14697H90.0918V14.6922C90.0918 15.95 89.1066 16.7479 87.5512 16.7479C86.1771 16.748 85.2879 16.1319 85.1721 15.1974ZM89.0266 11.8283C89.0266 10.7327 88.4601 10.0308 87.5357 10.0308C86.6017 10.0308 86.0769 10.7328 86.0769 11.8283C86.0769 12.9245 86.6017 13.6265 87.5357 13.6265C88.466 13.6265 89.0267 12.9299 89.0267 11.8283H89.0266Z"
              fill="white"
            />
            <path
              id="Vector_8"
              d="M91.5076 13.0915C91.5076 12.1015 92.2447 11.5308 93.5532 11.4496L95.043 11.3638V10.889C95.043 10.3082 94.6589 9.98015 93.917 9.98015C93.3111 9.98015 92.8912 10.2026 92.7707 10.5914H91.7199C91.8308 9.64676 92.7194 9.04083 93.9671 9.04083C95.3459 9.04083 96.1236 9.72727 96.1236 10.889V14.6469H95.0788V13.874H94.9929C94.8186 14.1512 94.5738 14.3773 94.2836 14.529C93.9933 14.6807 93.668 14.7526 93.3409 14.7375C93.11 14.7615 92.8766 14.7369 92.6559 14.6652C92.4351 14.5935 92.2318 14.4764 92.059 14.3213C91.8863 14.1662 91.748 13.9766 91.653 13.7648C91.558 13.553 91.5085 13.3236 91.5076 13.0915ZM95.043 12.6216V12.1617L93.6999 12.2476C92.9425 12.2983 92.599 12.556 92.599 13.0408C92.599 13.5358 93.0284 13.8239 93.6188 13.8239C93.7918 13.8414 93.9666 13.8239 94.1327 13.7725C94.2988 13.7211 94.4529 13.6368 94.5858 13.5246C94.7187 13.4124 94.8276 13.2747 94.9061 13.1195C94.9847 12.9644 95.0312 12.795 95.043 12.6216Z"
              fill="white"
            />
            <path
              id="Vector_9"
              d="M97.8735 7H98.959V14.6469H97.8735V7Z"
              fill="white"
            />
            <path
              id="Vector_10"
              d="M100.468 11.8945C100.433 11.5331 100.475 11.1684 100.589 10.8239C100.704 10.4794 100.889 10.1626 101.133 9.8939C101.378 9.62521 101.675 9.41053 102.007 9.26362C102.339 9.11672 102.698 9.04083 103.062 9.04083C103.425 9.04083 103.784 9.11672 104.116 9.26362C104.448 9.41053 104.745 9.62521 104.99 9.8939C105.234 10.1626 105.419 10.4794 105.534 10.8239C105.648 11.1684 105.69 11.5331 105.655 11.8945C105.69 12.2563 105.65 12.6215 105.535 12.9666C105.421 13.3117 105.236 13.629 104.991 13.8982C104.747 14.1674 104.449 14.3826 104.117 14.5298C103.785 14.677 103.425 14.7531 103.062 14.7531C102.698 14.7531 102.339 14.677 102.006 14.5298C101.674 14.3826 101.376 14.1674 101.132 13.8982C100.887 13.629 100.702 13.3117 100.588 12.9666C100.473 12.6215 100.433 12.2563 100.468 11.8945ZM104.539 11.8945C104.539 10.7023 104.003 10.0051 103.063 10.0051C102.12 10.0051 101.589 10.7023 101.589 11.8945C101.589 13.0963 102.12 13.7881 103.063 13.7881C104.003 13.788 104.539 13.0915 104.539 11.8945H104.539Z"
              fill="white"
            />
            <path
              id="Vector_11"
              d="M114.747 13.162C114.599 13.6647 114.28 14.0995 113.844 14.3903C113.408 14.6812 112.884 14.8096 112.363 14.7532C112.001 14.7627 111.641 14.6933 111.308 14.5498C110.975 14.4062 110.678 14.192 110.436 13.9219C110.194 13.6518 110.014 13.3323 109.908 12.9856C109.802 12.639 109.773 12.2734 109.823 11.9143C109.775 11.5542 109.804 11.1878 109.91 10.8401C110.015 10.4924 110.195 10.1715 110.435 9.89901C110.676 9.62655 110.972 9.40893 111.304 9.26088C111.635 9.11284 111.995 9.03783 112.359 9.04092C113.889 9.04092 114.812 10.0865 114.812 11.8135V12.1923H110.928V12.2531C110.911 12.4549 110.937 12.6581 111.003 12.8496C111.069 13.041 111.174 13.2165 111.312 13.3649C111.45 13.5132 111.618 13.6311 111.804 13.711C111.99 13.7909 112.191 13.831 112.393 13.8287C112.653 13.8599 112.916 13.8131 113.149 13.6944C113.382 13.5757 113.574 13.3903 113.702 13.162L114.747 13.162ZM110.928 11.3895H113.706C113.72 11.2049 113.695 11.0195 113.633 10.8452C113.571 10.6709 113.473 10.5115 113.345 10.3773C113.218 10.2432 113.063 10.1371 112.892 10.0661C112.722 9.99502 112.538 9.96051 112.353 9.96475C112.165 9.9624 111.979 9.99764 111.805 10.0684C111.631 10.1392 111.473 10.2441 111.34 10.3769C111.207 10.5096 111.103 10.6676 111.032 10.8416C110.961 11.0155 110.926 11.2018 110.928 11.3895H110.928Z"
              fill="white"
            />
            <path
              id="Vector_12"
              d="M116.27 9.14704H117.315V10.0208H117.396C117.533 9.70695 117.765 9.44389 118.06 9.26824C118.354 9.0926 118.696 9.01311 119.037 9.04088C119.305 9.02076 119.573 9.06111 119.823 9.15894C120.073 9.25677 120.298 9.40957 120.481 9.60603C120.663 9.80248 120.8 10.0376 120.879 10.2938C120.959 10.5501 120.98 10.821 120.941 11.0865V14.6469H119.855V11.3591C119.855 10.4752 119.471 10.0357 118.668 10.0357C118.487 10.0272 118.305 10.0581 118.137 10.1263C117.968 10.1945 117.816 10.2984 117.692 10.4307C117.567 10.5631 117.472 10.7209 117.414 10.8933C117.356 11.0657 117.336 11.2486 117.355 11.4294V14.647H116.27V9.14704Z"
              fill="white"
            />
            <path
              id="Vector_13"
              d="M130.254 13.162C130.106 13.6647 129.787 14.0995 129.351 14.3903C128.916 14.6812 128.392 14.8096 127.871 14.7532C127.508 14.7627 127.148 14.6933 126.815 14.5498C126.483 14.4062 126.185 14.192 125.943 13.9219C125.702 13.6518 125.521 13.3323 125.416 12.9856C125.31 12.639 125.281 12.2734 125.33 11.9143C125.282 11.5542 125.312 11.1878 125.417 10.8401C125.523 10.4924 125.702 10.1715 125.942 9.89901C126.183 9.62655 126.479 9.40893 126.811 9.26088C127.143 9.11284 127.503 9.03783 127.866 9.04092C129.396 9.04092 130.32 10.0865 130.32 11.8135V12.1923H126.436V12.2531C126.419 12.4549 126.444 12.6581 126.51 12.8496C126.576 13.041 126.682 13.2165 126.82 13.3649C126.958 13.5132 127.125 13.6311 127.311 13.711C127.497 13.7909 127.698 13.831 127.901 13.8287C128.16 13.8599 128.423 13.8131 128.656 13.6944C128.889 13.5757 129.082 13.3903 129.209 13.162L130.254 13.162ZM126.436 11.3895H129.214C129.227 11.2049 129.202 11.0195 129.14 10.8452C129.078 10.6709 128.98 10.5115 128.852 10.3773C128.725 10.2432 128.571 10.1371 128.4 10.0661C128.229 9.99502 128.045 9.96051 127.86 9.96475C127.672 9.96239 127.486 9.99764 127.312 10.0684C127.138 10.1392 126.98 10.2441 126.847 10.3768C126.715 10.5096 126.61 10.6676 126.539 10.8416C126.469 11.0155 126.433 11.2018 126.436 11.3895H126.436Z"
              fill="white"
            />
            <path
              id="Vector_14"
              d="M131.827 7H132.913V14.6469H131.827V7Z"
              fill="white"
            />
          </g>
          <path
            id="Border"
            d="M146.82 0.881378C149.432 0.881378 151.558 2.9535 151.558 5.5V38.5C151.558 41.0465 149.432 43.1186 146.82 43.1186H5.79483C3.18304 43.1186 1.05778 41.0465 1.05778 38.5V5.5C1.05778 2.9535 3.18304 0.881378 5.79483 0.881378H146.82ZM146.82 3.8147e-06H5.79483C2.69368 3.8147e-06 0.153809 2.47638 0.153809 5.5V38.5C0.153809 41.5236 2.69368 44 5.79483 44H146.82C149.922 44 152.462 41.5236 152.462 38.5V5.5C152.462 2.47638 149.922 3.8147e-06 146.82 3.8147e-06Z"
            fill="#A6A6A6"
          />
          <g id="Icon">
            <g id="Vector_15">
              <path
                d="M34.1444 21.7624C34.1116 18.2171 37.1217 16.4923 37.2593 16.412C35.5546 13.9887 32.9124 13.6576 31.9839 13.6312C29.7647 13.4035 27.6121 14.9259 26.4816 14.9259C25.3286 14.9259 23.5878 13.6532 21.7115 13.6906C19.2972 13.7269 17.0385 15.0898 15.7997 17.2062C13.2432 21.5215 15.1499 27.863 17.5992 31.3511C18.8245 33.0594 20.2562 34.9668 22.1301 34.8997C23.9634 34.826 24.6483 33.7601 26.8607 33.7601C29.0528 33.7601 29.6959 34.8997 31.607 34.8568C33.5746 34.826 34.8134 33.1408 35.9958 31.4171C37.4116 29.4591 37.9803 27.5308 38.0028 27.4318C37.9566 27.4164 34.1816 26.0117 34.1444 21.7624Z"
                fill="white"
              />
              <path
                d="M30.5341 11.3366C31.5202 10.1343 32.1948 8.49864 32.0075 6.83875C30.5804 6.90035 28.7955 7.80125 27.7678 8.97715C26.8584 10.0133 26.0461 11.7117 26.256 13.3089C27.8591 13.4255 29.5052 12.5202 30.5341 11.3366Z"
                fill="white"
              />
            </g>
          </g>
          <g id="App Store_2">
            <path
              id="A"
              d="M60.6764 34.6544H58.1142L56.7107 30.3545H51.8324L50.4954 34.6544H48.001L52.8342 20.0156H55.8194L60.6764 34.6544ZM56.2876 28.5505L55.0184 24.728C54.8842 24.3375 54.6326 23.4179 54.2614 21.9703H54.2163C54.0685 22.5929 53.8304 23.5125 53.5032 24.728L52.2566 28.5505H56.2876Z"
              fill="white"
            />
            <path
              id="p"
              d="M73.1058 29.2468C73.1058 31.042 72.6083 32.461 71.6132 33.5027C70.7219 34.43 69.6151 34.8931 68.294 34.8931C66.8679 34.8931 65.8435 34.3937 65.2196 33.3949H65.1745V38.9554H62.7692V27.5737C62.7692 26.4451 62.7387 25.2868 62.6801 24.0988H64.7954L64.9297 25.7719H64.9748C65.777 24.5113 66.9943 23.8821 68.6279 23.8821C69.9051 23.8821 70.9712 24.3738 71.8242 25.3583C72.6793 26.3439 73.1058 27.6397 73.1058 29.2468ZM70.6553 29.3326C70.6553 28.3052 70.4184 27.4582 69.9423 26.7916C69.4222 26.0964 68.7238 25.7488 67.8484 25.7488C67.2549 25.7488 66.7156 25.9424 66.2339 26.3241C65.751 26.7091 65.4351 27.2118 65.2873 27.8344C65.2129 28.1248 65.1756 28.3624 65.1756 28.5494V30.3094C65.1756 31.0772 65.4171 31.7251 65.9 32.2542C66.3828 32.7833 67.0101 33.0473 67.7818 33.0473C68.6877 33.0473 69.3929 32.7063 69.8972 32.0265C70.4026 31.3456 70.6553 30.448 70.6553 29.3326Z"
              fill="white"
            />
            <path
              id="p_2"
              d="M85.5578 29.2468C85.5578 31.042 85.0603 32.461 84.0641 33.5027C83.1739 34.43 82.0671 34.8931 80.746 34.8931C79.3199 34.8931 78.2955 34.3937 77.6728 33.3949H77.6276V38.9554H75.2223V27.5737C75.2223 26.4451 75.1918 25.2868 75.1332 24.0988H77.2486L77.3828 25.7719H77.4279C78.229 24.5113 79.4463 23.8821 81.0811 23.8821C82.3571 23.8821 83.4232 24.3738 84.2784 25.3583C85.1302 26.3439 85.5578 27.6397 85.5578 29.2468ZM83.1073 29.3326C83.1073 28.3052 82.8693 27.4582 82.3932 26.7916C81.8731 26.0964 81.177 25.7488 80.3004 25.7488C79.7058 25.7488 79.1676 25.9424 78.6848 26.3241C78.2019 26.7091 77.8871 27.2118 77.7393 27.8344C77.666 28.1248 77.6276 28.3624 77.6276 28.5494V30.3094C77.6276 31.0772 77.8691 31.7251 78.3497 32.2542C78.8326 32.7822 79.4598 33.0473 80.2338 33.0473C81.1397 33.0473 81.8449 32.7063 82.3492 32.0265C82.8546 31.3456 83.1073 30.448 83.1073 29.3326Z"
              fill="white"
            />
            <path
              id="S"
              d="M99.4799 30.5492C99.4799 31.7944 99.0365 32.8075 98.1463 33.5896C97.1682 34.4443 95.8064 34.8711 94.0566 34.8711C92.441 34.8711 91.1458 34.5675 90.1654 33.9592L90.7227 32.0045C91.7787 32.6271 92.9374 32.9395 94.1999 32.9395C95.1058 32.9395 95.8109 32.7393 96.3175 32.3411C96.8218 31.9429 97.0734 31.4083 97.0734 30.7417C97.0734 30.1477 96.8658 29.6472 96.4495 29.2413C96.0355 28.8354 95.3439 28.4581 94.3781 28.1094C91.7494 27.1535 90.4362 25.7532 90.4362 23.9118C90.4362 22.7084 90.8965 21.7217 91.8182 20.9539C92.7366 20.185 93.9618 19.8011 95.4939 19.8011C96.8602 19.8011 97.9952 20.0332 98.9011 20.4963L98.2998 22.4081C97.4536 21.9593 96.4969 21.7349 95.4262 21.7349C94.5801 21.7349 93.9189 21.9384 93.4451 22.3432C93.0446 22.7051 92.8438 23.1462 92.8438 23.6687C92.8438 24.2473 93.0728 24.7258 93.5331 25.102C93.9336 25.4496 94.6613 25.8258 95.7173 26.2317C97.0091 26.7388 97.9579 27.3317 98.5683 28.0115C99.1764 28.6891 99.4799 29.5372 99.4799 30.5492Z"
              fill="white"
            />
            <path
              id="t"
              d="M107.433 25.8588H104.781V30.9837C104.781 32.2872 105.248 32.9384 106.185 32.9384C106.615 32.9384 106.971 32.9021 107.253 32.8295L107.32 34.6104C106.846 34.7831 106.222 34.87 105.449 34.87C104.499 34.87 103.757 34.5873 103.221 34.023C102.687 33.4576 102.419 32.5094 102.419 31.1773V25.8566H100.839V24.0966H102.419V22.1639L104.781 21.4687V24.0966H107.433V25.8588Z"
              fill="white"
            />
            <path
              id="o"
              d="M119.395 29.2897C119.395 30.9122 118.919 32.2443 117.969 33.286C116.973 34.3585 115.65 34.8931 114.002 34.8931C112.414 34.8931 111.149 34.3794 110.206 33.352C109.262 32.3246 108.791 31.0277 108.791 29.4646C108.791 27.8289 109.276 26.4891 110.25 25.4474C111.221 24.4046 112.532 23.8832 114.18 23.8832C115.769 23.8832 117.047 24.3969 118.012 25.4254C118.935 26.4231 119.395 27.7112 119.395 29.2897ZM116.899 29.3656C116.899 28.3921 116.686 27.5572 116.254 26.8609C115.75 26.0183 115.029 25.5981 114.095 25.5981C113.128 25.5981 112.393 26.0194 111.889 26.8609C111.457 27.5583 111.244 28.4064 111.244 29.4096C111.244 30.3831 111.457 31.218 111.889 31.9132C112.409 32.7558 113.136 33.176 114.073 33.176C114.992 33.176 115.712 32.747 116.233 31.8912C116.676 31.1817 116.899 30.338 116.899 29.3656Z"
              fill="white"
            />
            <path
              id="r"
              d="M127.213 26.1613C126.975 26.1184 126.721 26.0964 126.455 26.0964C125.609 26.0964 124.955 26.4077 124.494 27.0314C124.094 27.5814 123.893 28.2766 123.893 29.1159V34.6544H121.489L121.511 27.423C121.511 26.2064 121.481 25.0987 121.421 24.0999H123.516L123.604 26.1195H123.671C123.925 25.4254 124.325 24.8666 124.873 24.4475C125.409 24.0702 125.988 23.8821 126.612 23.8821C126.834 23.8821 127.035 23.8975 127.213 23.925V26.1613Z"
              fill="white"
            />
            <path
              id="e"
              d="M137.971 28.8772C137.971 29.2974 137.943 29.6516 137.883 29.9409H130.667C130.695 30.9837 131.044 31.7812 131.714 32.3312C132.322 32.8229 133.108 33.0693 134.074 33.0693C135.142 33.0693 136.117 32.9032 136.994 32.5699L137.371 34.1979C136.346 34.6335 135.137 34.8502 133.741 34.8502C132.062 34.8502 130.745 34.3684 129.786 33.4059C128.829 32.4434 128.349 31.1509 128.349 29.5295C128.349 27.9378 128.795 26.6123 129.688 25.5552C130.622 24.4266 131.884 23.8623 133.473 23.8623C135.033 23.8623 136.214 24.4266 137.016 25.5552C137.652 26.4517 137.971 27.5605 137.971 28.8772ZM135.677 28.2689C135.693 27.5737 135.536 26.9731 135.21 26.466C134.794 25.8137 134.154 25.4881 133.293 25.4881C132.507 25.4881 131.867 25.806 131.379 26.444C130.978 26.9511 130.74 27.5594 130.667 28.2678H135.677V28.2689Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AppStore;
