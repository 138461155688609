import { useState } from "react";

import { MdPlaylistAdd } from "react-icons/md";
import { createList } from "features/markets/marketsActions";
import { RootState } from "store/store";
import AlertModal from "components/common/alert-modal";
import { InputSizes, UIInput } from "components/common/input";
import { trackAction } from "utils/amplitude";
import { screenNames } from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  open: boolean;
  setOpenModal: (open: boolean) => void;
  long_ticker?: string;
}

export const NewListModal = (props: PropTypes) => {
  const { long_ticker, open, setOpenModal } = props;
  const createListError = useAppSelector(
    (state: RootState) => state.markets.createListError
  );
  const [listName, setListName] = useState<string | undefined>();
  const dispatch = useAppDispatch();

  const closeModal = () => {
    setOpenModal(false);
  };

  const handleChangeListName = (value?: string) => {
    setListName(value);
  };

  const handleClickCreateList = () => {
    if (listName) {
      dispatch(createList({ name: listName, long_ticker }));
      trackAction(`${screenNames.market} - Create New List`, {
        name: listName,
      });
    }
    if (!createListError.message) {
      closeModal();
    }
  };

  return (
    <AlertModal
      alertIcon={<MdPlaylistAdd size={56} />}
      bodyContainer={
        <>
          <UIInput
            newClass={"modal-input-intro"}
            placeholder="Ingresá el nombre de tu nueva lista"
            size={InputSizes.Full}
            value={listName}
            onChange={handleChangeListName}
          />
        </>
      }
      classes="new-list-modal"
      headingText="Nueva lista"
      isFirstButtonDisabled={!listName}
      open={open}
      primaryButtonText={"Continuar"}
      secondaryButtonText={"Cancelar"}
      onClickMainButton={handleClickCreateList}
      onClickSecondButton={closeModal}
    />
  );
};
