import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import type { PeriodTime, BalanceResponse } from "interfaces/portfolio";
import type { BalanceCurrencies } from "interfaces/wallet";

interface useBalanceQueryProps {
  currency: BalanceCurrencies;
  period: PeriodTime;
}

export const fetchBalance = async ({
  currency,
  period,
}: useBalanceQueryProps) => {
  const { data } = await API.get(portfolioService.balance, {
    params: {
      currency,
      period,
    },
  });

  return data;
};

export const useBalanceQuery = ({ currency, period }: useBalanceQueryProps) => {
  return useAppQuery<BalanceResponse, ErrorMessage>({
    queryKey: () => portfolioKeys.balance(currency, period),
    queryFn: async () => fetchBalance({ currency, period }),
    options: {
      onError: () => {
        return {
          message: "Hubo un error para cargar los balances.",
          status: 500,
        };
      },
      staleTime: 5 * 60 * 10,
    },
  });
};
