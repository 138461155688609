interface PropTypes {
  size: number;
  color?: string;
  thickness?: number;
}

export const CircleCheckIcon = ({ color, size, thickness = 3 }: PropTypes) => {
  return (
    <svg
      className="circle-check-icon"
      fill="none"
      height={size || "56"}
      viewBox="0 0 56 56"
      width={size || "56"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3333 30.3333L23.3333 37.3333L39.6666 21"
        stroke={color || "#00C28F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        clipRule="evenodd"
        d="M28.0001 51.3334C40.8867 51.3334 51.3334 40.8867 51.3334 28C51.3334 15.1134 40.8867 4.66669 28.0001 4.66669C15.1134 4.66669 4.66675 15.1134 4.66675 28C4.66675 40.8867 15.1134 51.3334 28.0001 51.3334Z"
        fillRule="evenodd"
        stroke={color || "#00C28F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={thickness}
      />
    </svg>
  );
};
