import ff from "constants/featureFlags";

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import API from "apis";
import { cardService } from "apis/services";
import {
  CardSections,
  CardStatus,
  CardTypes,
  CardTypesLabel,
  UserStatus,
} from "interfaces/card/enums";
import { Navigate, useLocation } from "react-router-dom";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppSelector } from "hooks/redux";
import useFeatureFlag from "hooks/useFeatureFlag";
import CardErrorScreen from "components/page-cocos-card/Error";
import {
  Card,
  PomeloData,
  CardTransaction,
  CardTransactionsGroupResponse,
  ShippingInfoValues,
} from "interfaces/card/interface";
import { LimitPeriod } from "interfaces/api-responses";

interface Context {
  selectedSection: CardSections;
  setSelectedSection: (v: CardSections) => void;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  isError: boolean;
  setIsError: (v: boolean) => void;
  cardEnabled: boolean;
  selectedCard: CardTypesLabel;
  setSelectedCard: (v: CardTypesLabel) => void;
  pomeloData: PomeloData | null;
  setPomeloData: (v: PomeloData) => void;
  virtualCard: Card | undefined;
  physicalCard: Card | undefined;
  getPomeloData: () => Promise<void>;
  updateCardStatus: (status: CardStatus, idCard?: string) => void;
  cardStatus?: CardStatus;
  cardTransactions?: CardTransactionsGroupResponse;
  transactionSelected: CardTransaction;
  setTransactionSelected: (v: CardTransaction) => void;
  cardPaymentMethods: any;
  userStatus?: UserStatus;
  getCardTransactions: (v?: number) => Promise<void>;
  displayToast: ToastState;
  setDisplayToast: (v: ToastState) => void;
  setShippingInfo: (v: any) => void;
  shippingInfo: ShippingInfoValues;
}

interface ToastState {
  visible: boolean;
  message: string;
}

const CocosCardContext = createContext<Context | null>(null);

export const cardOptions = [
  { name: CardTypesLabel.PHYSICAL },
  { name: CardTypesLabel.VIRTUAL },
];

export const CocosCardProvider = ({ children }: { children: ReactNode }) => {
  const user = useAppSelector(getLoggedUser);
  const location = useLocation();

  const defaultCardSelected = location.search.includes(CardTypes.VIRTUAL)
    ? CardTypesLabel.VIRTUAL
    : CardTypesLabel.PHYSICAL;

  const [selectedSection, setSelectedSection] = useState<CardSections>(
    CardSections.ONBOARDING
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shippingInfo, setShippingInfo] = useState<any>();
  const {
    isFeatureEnabled: cardEnabled,
    isLoading: isFlagLoading,
  } = useFeatureFlag(ff.ENABLE_CARD);
  const [selectedCard, setSelectedCard] = useState<CardTypesLabel>(
    defaultCardSelected
  );
  const [pomeloData, setPomeloData] = useState<PomeloData | null>(null);
  const [cardTransactions, setCardTransactions] = useState();
  const [
    transactionSelected,
    setTransactionSelected,
  ] = useState<CardTransaction>({} as CardTransaction);
  const [displayToast, setDisplayToast] = useState<ToastState>({
    visible: false,
    message: "",
  });

  const getPomeloData = async () => {
    try {
      const { data, status } = await API.get<PomeloData>(cardService.user);
      status === 204 ? setPomeloData(null) : setPomeloData(data);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        setPomeloData(null);
        return;
      }
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getCardData = (cardType: CardTypes) =>
    pomeloData?.cards.find((card: Card) => card.cardType === cardType);

  const userStatus = pomeloData?.status;
  const virtualCard = getCardData(CardTypes.VIRTUAL);
  const physicalCard = getCardData(CardTypes.PHYSICAL);
  const isPhysicalCard = selectedCard === CardTypesLabel.PHYSICAL;

  const cardStatus = isPhysicalCard
    ? physicalCard?.status
    : virtualCard?.status;

  const cardPaymentMethods = isPhysicalCard
    ? physicalCard?.paymentMethodProfile
    : virtualCard?.paymentMethodProfile;

  const updateCardStatus = (status: CardStatus, idCard?: string) => {
    if (!pomeloData || !pomeloData.cards) return;

    const cardToUpdate = pomeloData.cards.find(
      (card) => card.idCard === idCard
    );
    if (cardToUpdate) {
      cardToUpdate.status = status;
      setPomeloData({
        ...pomeloData,
        cards: pomeloData.cards.map((card) =>
          card.idCard === idCard ? cardToUpdate : card
        ),
      });
    }
  };

  const getCardTransactions = async (limit?: number) => {
    setIsLoading(true);
    const idCard = isPhysicalCard ? physicalCard?.idCard : virtualCard?.idCard;
    const by = LimitPeriod.MONTH;

    if (
      !idCard ||
      (selectedCard === CardTypesLabel.PHYSICAL && physicalCard?.isShipping)
    ) {
      setCardTransactions(undefined);
      setIsLoading(false);
      return;
    }

    const params = { params: { by, ...(limit && { limit }) } };
    try {
      const { data } = await API.get(
        `v1/cards/${idCard}/transactions/grouped`,
        params
      );

      setCardTransactions(data);
    } catch (error: any) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getPomeloData();
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  const memoizedValues = useMemo(() => {
    return {
      selectedSection,
      setSelectedSection,
      isLoading,
      setIsLoading,
      isError,
      setIsError,
      cardEnabled,
      selectedCard,
      setSelectedCard,
      pomeloData,
      setPomeloData,
      virtualCard,
      physicalCard,
      getPomeloData,
      cardStatus,
      updateCardStatus,
      cardTransactions,
      transactionSelected,
      setTransactionSelected,
      cardPaymentMethods,
      userStatus,
      getCardTransactions,
      displayToast,
      setDisplayToast,
      setShippingInfo,
      shippingInfo,
    };
  }, [
    selectedSection,
    setSelectedSection,
    isLoading,
    setIsLoading,
    isError,
    setIsError,
    cardEnabled,
    selectedCard,
    setSelectedCard,
    pomeloData,
    setPomeloData,
    virtualCard,
    physicalCard,
    getPomeloData,
    cardStatus,
    updateCardStatus,
    cardTransactions,
    transactionSelected,
    setTransactionSelected,
    cardPaymentMethods,
    userStatus,
    getCardTransactions,
    displayToast,
    setDisplayToast,
    setShippingInfo,
    shippingInfo,
  ]);

  if (isFlagLoading) return null;

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  if (!cardEnabled) return <Navigate to={mainRoutes.home} replace />;

  if (isError) return <CardErrorScreen isConfigError={false} />;

  return (
    <CocosCardContext.Provider value={memoizedValues}>
      {children}
    </CocosCardContext.Provider>
  );
};

export default CocosCardContext;

export const useCocosCard = () => {
  const context = useContext(CocosCardContext);
  if (!context) {
    throw new Error("[CocosCardContext] Missing context");
  }
  return context;
};
