import { Typography } from "@mui/material";

const FCIHeader: React.FC = () => {
  return (
    <div className="markets-table-header-fci">
      <div className="grid-col especie">
        <Typography variant="textXS">Especie</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Último Precio</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Rend. Anual %</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Rend. Men. %</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Rend. Diario %</Typography>
      </div>
      <div className="grid-col ytd">
        <Typography variant="textXS">YTD</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Patrimonio</Typography>&nbsp;
        <Typography variant="textXS">AR$</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Riesgo</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Plazo de rescate</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">Reglamento</Typography>
      </div>
    </div>
  );
};

export default FCIHeader;
