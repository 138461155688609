import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { ThemeVariants } from "interfaces/theme";
import { CardTypesLabel } from "interfaces/card/enums";
import NavigationBar from "components/common/NavigationBar";
import LoadingSpinner from "components/common/LoadingSpinner";
import { cardOptions, useCocosCard } from "context/CocosCardProvider";
import { MessageCircleQuestion, ArrowLeft } from "lucide-react";
import mainRoutes from "router/routes";

import CardTransactionsList from "./TransactionsGroup";

import styles from "./styles.module.scss";

const CardTransactions: React.FC = () => {
  const navigate = useNavigate();
  const {
    selectedCard,
    setSelectedCard,
    getCardTransactions,
    isLoading,
  } = useCocosCard();

  const handleTabClick = (option: CardTypesLabel) => {
    setSelectedCard(option);
  };

  useEffect(() => {
    getCardTransactions();
  }, [selectedCard]);

  return (
    <>
      <NavigationBar
        title="Movimientos"
        leftIcon={ArrowLeft}
        onClickLeft={() => navigate(-1)}
        rightIcon={MessageCircleQuestion}
        onClickRight={() => navigate(mainRoutes.cardHelp)}
        segmentedControlProps={{
          options: cardOptions,
          selected: selectedCard,
          onClick: handleTabClick,
        }}
      />
      <div className={styles.cardTransactionsContainer}>
        {isLoading ? (
          <LoadingSpinner size={120} variant={ThemeVariants.Pay} />
        ) : (
          <CardTransactionsList />
        )}
      </div>
    </>
  );
};

export default CardTransactions;
