import { useEffect } from "react";

import { Typography } from "@mui/material";
import { usePrevious } from "hooks/usePrevious";
import { Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { formatPercentage, getCurrencyLabel, getFormattedNumber } from "utils";

import styles from "./styles.module.scss";

interface InstrumentLastPriceProps {
  instrumentType?: Instruments;
  currency: Currencies;
  returnsPercLastMonth?: number | null;
  hasCurrency?: boolean;
  last: number | null;
  close?: number | null;
  idTickSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentLastPrice: React.FC<InstrumentLastPriceProps> = ({
  last,
  close,
  currency,
  tickSize,
  hasCurrency,
  instrumentType,
  idTickSizeRule,
  returnsPercLastMonth,
}) => {
  const previousLast = usePrevious(last);

  const priceColorStyles =
    returnsPercLastMonth && returnsPercLastMonth > 0
      ? "price-highlight up"
      : "price-highlight down";

  let priceClass;

  useEffect(() => {
    if (!last) return;
    if (previousLast && previousLast < last) {
      priceClass = [styles.up];
    }
    if (previousLast && previousLast > last) {
      priceClass = [styles.down];
    }

    setTimeout(() => {
      priceClass = [""];
    }, 400);

    return () => {
      priceClass = [""];
    };
  }, [last]);

  if (instrumentType === Instruments.FCI)
    return (
      <div className="grid-col last-price">
        <div className={returnsPercLastMonth ? priceColorStyles : ""}>
          <div className="price">
            <Typography variant="textS_bold">
              {returnsPercLastMonth
                ? formatPercentage(returnsPercLastMonth || 0, true)
                : "-"}
            </Typography>
          </div>
        </div>
      </div>
    );

  return (
    <div className={styles.lastPrice}>
      <div className={styles.priceHighlight}>
        <div className={priceClass}>
          {hasCurrency && (
            <Typography variant="textXXS">{`${getCurrencyLabel(
              currency
            )} `}</Typography>
          )}
          <Typography variant="textS">
            {getFormattedNumber(last || close, idTickSizeRule, tickSize)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default InstrumentLastPrice;
