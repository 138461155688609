import { Currencies } from "./wallet";
import { Operations } from "./crypto/enums";

export enum FundsIntervals {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export type FundsTotalHolding = {
  currency: Currencies;
  value: number;
  lastProfit: {
    amount: number;
    percentage: number;
  };
};

export type FundsPeriodProfit = {
  currency: Currencies;
  value: number;
  from: string;
  to: string;
};

export type HistoricalProfitsData = {
  date?: string;
  from: string;
  to: string;
  value: number;
};

export type FundsHistoricalProfits = {
  interval: FundsIntervals;
  data: HistoricalProfitsData[];
};

export type FundsDailyMovements = {
  date: string;
  amount: {
    // total invested
    value: number;
    currency: Currencies;
  };
  movements: Array<{
    operation: Operations;
    amount: {
      value: number;
      currency: Currencies;
    };
    quantity: number;
    ticker: string; // instrument code
    name: string;
    shortName: string;
    type: string;
    subtype: string;
    logo?: string;
  }>;
};

export type FundsHoldingResponse = {
  hasMovements: boolean;
  holding: FundsTotalHolding;
  maxOffset: number;
  periodProfit: FundsPeriodProfit;
  historicalProfits: FundsHistoricalProfits;
  dailyMovements: FundsDailyMovements[];
};
