import { Typography } from "@mui/material";
import { getFormattedNumber } from "utils";

interface InstrumentBidProps {
  bid?: number | null;
  idTickerSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentBid: React.FC<InstrumentBidProps> = ({
  bid,
  idTickerSizeRule,
  tickSize,
}) => (
  <div className="grid-col size">
    <Typography variant="textXS_bold">
      {getFormattedNumber(bid ?? undefined, idTickerSizeRule, tickSize)}
    </Typography>
  </div>
);

export default InstrumentBid;
