import { useEffect, useState } from "react";

import { useTrading } from "context/TradingProvider";
import { InstrumentData } from "interfaces/api-responses";
import { BookSocketResponse } from "interfaces/api-responses/socket/book";
import { Schedules } from "interfaces/calendar";
import { Method, OrderType, SettlementTerms } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { isEmpty } from "lodash";
import { screenNames } from "router/routes";
import { socket } from "socket";
import { setCurrentScreen } from "features/global/globalSlice";
import {
  getMarketDetailByShortTicker,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets";
import { addTickerToSubscribeList } from "features/prices/capitalPricesSlice";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { Spinner } from "components/common/spinner";
import { calculateNetAmount, getSettlementDays, getTerm } from "utils";
import { trackScreen } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import TradingBody from "./components/trading-body";
import TradingHeader from "./components/trading-header";

import styles from "./styles.module.scss";

interface TradingContainerProps {
  instrumentProp: InstrumentData;
}

const TradingContainer: React.FC<TradingContainerProps> = ({
  instrumentProp,
}) => {
  const dispatch = useAppDispatch();
  const { setCurrency, setTerm, currency, term } = useTrading();

  const [currentInstrument, setCurrentInstrument] = useState<InstrumentData>(
    instrumentProp
  );
  const [pricesData, setPricesData] = useState<InstrumentData>(instrumentProp);

  const selectedSchedule =
    Schedules[getSettlementDays(currentInstrument.term) ?? SettlementTerms._24];
  const isMarketOpen = checkMarketOpen(selectedSchedule);
  const [socketData, setSocketData] = useState<BookSocketResponse>();

  const currentScreen = useAppSelector(getCurrentScreen);
  const { shortTickerData } = useAppSelector(
    (state: RootState) => state.markets
  );
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const anyDiffWithOrder =
    currency !== order.currency ||
    term !== order.term ||
    currentInstrument.long_ticker !== order.long_ticker;

  useEffect(() => {
    trackScreen(screenNames.trading, currentScreen);
    dispatch(setCurrentScreen(screenNames.trading));
    dispatch(getWallet());
  }, []);

  useEffect(() => {
    const messageHandler = (messageFromBackEnd: any) => {
      const message: BookSocketResponse = messageFromBackEnd;
      setSocketData(message);
    };
    socket.on("message", messageHandler);
    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (
      socketData &&
      socketData.long_ticker === currentInstrument.long_ticker
    ) {
      setPricesData({ ...currentInstrument, ...socketData });
    }
  }, [socketData]);

  useEffect(() => {
    if (
      instrumentProp &&
      instrumentProp.long_ticker &&
      instrumentProp.instrument_code
    ) {
      if (
        isEmpty(shortTickerData) ||
        (!isEmpty(shortTickerData) &&
          shortTickerData[0].instrument_code !== instrumentProp.instrument_code)
      )
        dispatch(
          getMarketDetailByShortTicker({
            short_ticker: instrumentProp.instrument_code,
          })
        );
      if (currentInstrument !== instrumentProp) {
        setCurrentInstrument(instrumentProp);
        setPricesData(instrumentProp);
      }
      const newTerm = getSettlementDays(instrumentProp.settlement_days);
      if (term !== newTerm) setTerm(newTerm);
      if (currency !== instrumentProp.currency)
        setCurrency(instrumentProp.currency ?? Currencies.ARS);
      dispatch(getSellingPower(instrumentProp.long_ticker));
    }
  }, [instrumentProp]);

  useEffect(() => {
    if (currentInstrument?.long_ticker) {
      dispatch(addTickerToSubscribeList(currentInstrument.long_ticker));
    }
  }, [currentInstrument?.long_ticker]);

  useEffect(() => {
    if (currentInstrument) {
      if (currentInstrument.instrument_code !== order.instrument_code) {
        dispatch(
          resetOrder({
            long_ticker: currentInstrument.long_ticker,
            instrument_code: currentInstrument.instrument_code,
            price: currentInstrument.last,
            currency: currentInstrument.currency,
            term: getSettlementDays(currentInstrument.settlement_days),
            method: Method.MARKET,
          })
        );
        return;
      }
      if (order.method === Method.MARKET) {
        dispatch(
          updateOrderOptions({
            ...order,
            price:
              order.type === OrderType.Buy ? pricesData.ask : pricesData.bid,
          })
        );
      }
    }
  }, [currentInstrument, order.type, order.method]);

  useEffect(() => {
    if (order.method === Method.MARKET && order.instrument_code) {
      dispatch(
        updateOrderOptions({
          ...order,
          price: order.type === OrderType.Buy ? pricesData.ask : pricesData.bid,
        })
      );
    }
  }, [pricesData]);

  useEffect(() => {
    if (order.quantity && order.quantity > 0 && order.price) {
      const newAmount = calculateNetAmount(
        order.price,
        order.quantity,
        currentInstrument.contract_size,
        currentInstrument.price_factor
      );
      dispatch(
        updateOrderOptions({
          ...order,
          amount: newAmount,
        })
      );
    }
  }, [order.price]);

  useEffect(() => {
    if (
      (anyDiffWithOrder || !instrumentProp.bid) &&
      !isEmpty(shortTickerData) &&
      shortTickerData[0].instrument_code === currentInstrument.instrument_code
    ) {
      const ticker = shortTickerData.find(
        (s: InstrumentData) =>
          s.term === getTerm(term) && s.currency === currency
      );
      if (ticker) {
        dispatch(
          updateOrderOptions({
            ...order,
            instrument_code: ticker.instrument_code,
            long_ticker: ticker.long_ticker,
            currency,
            term,
            amount: 0,
            quantity: 0,
          })
        );
        dispatch(setMarketDataDetail(ticker));
        setCurrentInstrument({ ...currentInstrument, ...ticker });
        setPricesData({ ...currentInstrument, ...ticker });
      }
    }
  }, [currency, term, shortTickerData]);

  return (
    <div className={styles.tradingContainer}>
      {!currentInstrument && <Spinner />}
      {currentInstrument && (
        <>
          <TradingHeader instrumentProp={pricesData} />
          <TradingBody
            instrumentProp={currentInstrument}
            isMarketOpen={isMarketOpen}
          />
        </>
      )}
    </div>
  );
};

export default TradingContainer;
