import type { Operations } from "./crypto/enums";
import type { Instruments } from "./markets";
import { BalanceCurrencies, Currencies } from "./wallet";

export enum PeriodTime {
  DAY = "1D",
  WEEK = "1W",
  MONTH = "1M",
  YEAR = "1Y",
  MAX = "MAX",
}

export enum PortfolioHistoryMetric {
  BALANCE = "balance",
  PERFORMANCE = "performance",
}

export enum SettlementPeriod {
  CI = "CI",
  ONE_DAY = "24hs",
  INF = "INF",
}

export type PortfolioSettlements = {
  amount: number;
  quantity: number;
  period: SettlementPeriod.CI | SettlementPeriod.ONE_DAY | SettlementPeriod.INF;
};

export type PortfolioHoldings = {
  allocation: number;
  code: string;
  isCrypto: boolean;
  logo: string;
  name: string;
  longTicker: string;
  securityId: string;
  settlements: PortfolioSettlements[];
  shortName: string;
  subtype: string;
  ticker: string;
  price: number;
  toBeSettled: number;
  type: Instruments;
  variation?: number;
};

export type SettlementMovement = {
  operation: Operations;
  baseTicker: string;
  quoteTicker?: string;
  baseQuantity: number;
  quoteQuantity: number | string;
};

export type CashHoldings = {
  ticker: Currencies;
  logo: string;
  settlements: PortfolioSettlements[];
  toBeSettled?: number;
};

export interface PortfolioQueryResponse {
  displayCurrency: BalanceCurrencies;
  cash: CashHoldings[];
  holdings: PortfolioHoldings[];
}

export interface PerformanceHolding {
  instrument_code: string;
  instrument_short_name: string;
  instrument_type: Instruments;
  short_ticker: string;
  quantity: number;
  last: number;
  result: number;
  average_price: number;
  result_percentage: number;
  logo_file_name: string;
}
export interface PerformanceQueryResponse {
  tickers: PerformanceHolding[];
}

export type BalanceResponse = {
  displayCurrency: BalanceCurrencies;
  totalBalance: number;
  variation: {
    amount: number;
    percentage: number;
  };
  period: PeriodTime;
};

export type PortfolioHistoryData = {
  time: string;
  balance: number;
  variation: {
    amount: number;
    percentage: number;
  };
};

export type PortfolioHistoryResponse = {
  displayCurrency: BalanceCurrencies;
  period: PeriodTime;
  metric: PortfolioHistoryMetric;
  data: PortfolioHistoryData[];
};
