import { useEffect } from "react";

import Text, { TextVariants } from "components/common/Text";
import BasicWrapper from "components/common/BasicWrapper";
import { useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import Error from "assets/illustrations/Error";
import { ampli } from "ampli";
import type { OptionalOrder } from "interfaces/markets";
import { useAppDispatch } from "hooks/redux";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import type { BalanceCurrencies } from "interfaces/wallet";

import styles from "./styles.module.scss";

interface FundErrorProps {
  currency: BalanceCurrencies;
  order: OptionalOrder;
  onClose: () => void;
}

const FundError = ({ currency, order, onClose }: FundErrorProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isDesktop } = useDeviceCheck();
  const { amount, type, instrument_code } = order;

  const onGoBackHome = () => {
    if (isDesktop) dispatch(closeRightModal());

    navigate(mainRoutes.home);
  };

  useEffect(() => {
    ampli.fciOrderError({
      amount,
      side: type,
      currency,
      ticker: instrument_code,
    });
  }, []);

  return (
    <BasicWrapper
      navigationHeaderProps={{
        title: "Algo salió mal",
        onClick: onClose,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: onGoBackHome,
      }}
    >
      <div className={styles.imageWrapper}>
        <Error />
      </div>
      <Text
        variant={TextVariants.RegularText}
        color="var(--slate800)"
        className={styles.errorText}
      >
        No pudimos realizar la operación. Por favor, volvé a intentarlo más
        tarde.
      </Text>
    </BasicWrapper>
  );
};

export default FundError;
