import { Spinner } from "components/common/spinner";

import styles from "./styles.module.scss";

const Loader: React.FC = () => {
  return (
    <div className={styles.loaderWrapper}>
      <Spinner />
    </div>
  );
};

export default Loader;
