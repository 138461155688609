import { Typography } from "@mui/material";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import UIPriceLabel from "components/common/price-label";
import UIVariationLabel from "components/common/variation-label";
import { getCurrencySymbol } from "utils";

import styles from "./styles.module.scss";

interface TradingCardVisibleProps {
  instrumentProp: any;
  numOfDecimals: number;
}

const TradingCardVisible: React.FC<TradingCardVisibleProps> = ({
  instrumentProp,
  numOfDecimals,
}) => {
  const variationValue =
    instrumentProp.last -
    (instrumentProp.previousClose ||
      instrumentProp.prev_close ||
      instrumentProp.open);

  const priceValue =
    instrumentProp.last ||
    instrumentProp.close ||
    instrumentProp.previousClose ||
    1;

  return (
    <div className={styles.selectedInstrument}>
      <div className={styles.instrumentData}>
        <InstrumentLogoUI
          logoFileName={instrumentProp.logo_file_name}
          placeholderStyle={"positive"}
          type={instrumentProp.instrument_type}
          variant={InstrumentLogoVariants.Secondary}
        />
        <Typography className={styles.instrumentTitle}>
          {instrumentProp.instrument_short_name
            ? instrumentProp.instrument_short_name
            : instrumentProp.short_ticker}
        </Typography>
      </div>
      <div className={styles.rightDiv}>
        <UIPriceLabel
          price={priceValue}
          currency={getCurrencySymbol(instrumentProp.currency)}
          numOfDecimals={numOfDecimals}
        />
        <div className={styles.instrumentVariation}>
          <UIVariationLabel
            value={instrumentProp.variation}
            withBackground
            isPercentage
          />
          <UIVariationLabel
            value={variationValue}
            currency={instrumentProp.currency}
            numOfDecimals={numOfDecimals}
          />
        </div>
      </div>
    </div>
  );
};

export default TradingCardVisible;
