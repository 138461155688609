import { Currencies } from "interfaces/wallet";

export const ACTIVE_BID = {
  long_ticker: "IRSA23-0002-C-CT-USD",
  price: 1,
  tir: 7.25,
  monto_minimo: 100,
  nombre: "ON IRSA Clase 23",
  lamina_minima: 0,
  lamina_incremental: 1,
  logo_file_name: "",
  instrument_code: "IRSA23",
  expiration: "25-10-2029",
  settlement_date: "23-10-2024",
  settlement_days: "48hs",
  tasa: 7.25,
  amort_title: "Amortización",
  currency: Currencies.USD,
};
