import React, { useEffect, useRef } from "react";

import * as d3 from "d3";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Currencies } from "interfaces/wallet";
import { useAppState } from "context/AppStateProvider";
import classNames from "classnames";

import TimeFrameTabs from "../LineChart/TimeFrameTabs";

import styles from "./styles.module.scss";

type MonthlyBarChart = d3.Selection<
  SVGSVGElement | null,
  unknown,
  null,
  undefined
>;

export interface MonthlyBarChartDataPoint {
  date: string;
  value: number;
  from: string;
  to: string;
  variation?: {
    amount: number;
    percentage: number;
  };
}

export interface MonthlyBar {
  date: string;
  value: number;
}

interface MonthlyBarChartProps {
  chartData: MonthlyBar[];
  color: string;
  currency?: Currencies;
  onChangeTime: (v: number) => void;
  selectedTime: number;
  timeOptions: { label: string; value: number }[];
}

const MonthlyBarChart: React.FC<MonthlyBarChartProps> = ({
  chartData,
  color,
  currency,
  onChangeTime,
  selectedTime,
  timeOptions,
}) => {
  const { isMobile } = useDeviceCheck();
  const { isBalanceHidden } = useAppState();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const isArs = currency === Currencies.ARS;
  const mobileRightMargin = isArs ? 50 : 70;
  const CHART_WIDTH = window.screen.width;
  const CHART_HEIGHT = isMobile ? 180 : 500;
  const CHART_RIGHT_MARGIN = isMobile ? mobileRightMargin : 250;
  const BAR_WIDTH = isMobile ? 12 : 30;

  const selectedCurrency = isArs ? "$" : "US$";

  const maxY = d3.max(chartData, (d: any) => Math.max(d.value, 0)) || 1;

  useEffect(() => {
    const svg = d3.select(svgRef.current);

    svg.selectAll("*").remove();

    const yAxis = d3
      .scaleLinear()
      .domain([0, maxY])
      .range([CHART_HEIGHT - 5, 5]);

    const formatCurrency = (value: number): string => {
      if (value >= 1e6) {
        return `${selectedCurrency} ${Math.floor(value / 1e5) / 10} M`;
      } else if (value >= 10e3) {
        return `${selectedCurrency} ${Math.floor(value / 1e3)} mil`;
      } else {
        const formattedArsValue =
          value >= 1000 ? (value / 1000).toFixed(3) : Math.floor(value);

        const formatValue = isArs ? formattedArsValue : value.toFixed(2);

        return `${selectedCurrency} ${formatValue}`;
      }
    };

    const yTicks = [0, maxY * 0.25, maxY * 0.5, maxY * 0.75, maxY];

    const setYAxis = () => {
      barChart
        .append("g")
        .attr("transform", `translate(${CHART_WIDTH - CHART_RIGHT_MARGIN}, 5)`)
        .call(
          d3
            .axisRight(yAxis)
            .tickValues(yTicks)
            .tickSizeInner(0)
            .tickFormat((d) => formatCurrency(d as number))
        )
        .call((g) => g.select(".domain").remove())
        .call((g) =>
          g
            .selectAll(".tick line")
            .clone()
            .attr("x2", -CHART_WIDTH)
            .attr("stroke-width", 1)
            .attr("stroke", "var(--borderColor)")
        )
        .call((g) => {
          g.selectAll(".tick text").each(function (d) {
            const textElement = d3.select(this);
            const price = formatCurrency(d as number);

            textElement.text(null);

            textElement
              .append("tspan")
              .attr("class", styles.yAxisTextSpanPrice)
              .text(price);

            textElement
              .append("tspan")
              .attr("class", styles.yAxisTextSpan)
              .text("● ● ● ●");
          });
        });
    };

    const xAxis = d3
      .scaleBand()
      .domain(chartData.map((d) => d.date))
      .range([0, CHART_WIDTH - CHART_RIGHT_MARGIN])
      .padding(0.1);

    const setXAxis = () => {
      barChart
        .append("g")
        .attr("transform", `translate(0, ${CHART_HEIGHT})`)
        .call(d3.axisBottom(xAxis))
        .call((g) => g.select(".domain").remove())
        .call((g) => g.selectAll(".tick line").remove())
        .selectAll("text")
        .style("text-anchor", "middle")
        .attr("dy", "1em");
    };

    const getBarChart = () => {
      return d3
        .select(svgRef.current)
        .attr("width", "100%")
        .attr("height", CHART_HEIGHT)
        .attr("viewBox", `0 0 ${CHART_WIDTH} ${CHART_HEIGHT}`)
        .attr("style", "max-width: 100%; height: auto;")
        .attr("class", styles.svg);
    };

    const drawBars = () => {
      barChart
        .selectAll(".bar")
        .data(chartData)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr(
          "x",
          (d: MonthlyBar) =>
            (xAxis(d.date) ?? 0) + xAxis.bandwidth() / 2 - BAR_WIDTH / 2
        )
        .attr("y", (d: MonthlyBar) =>
          d.value <= 0 ? yAxis(0) + 4 : yAxis(d.value)
        )
        .attr("width", BAR_WIDTH)
        .attr("height", (d: MonthlyBar) =>
          d.value <= 0 ? 1 : CHART_HEIGHT - yAxis(d.value)
        )
        .attr("fill", (d: MonthlyBar) =>
          d.value <= 0 ? "var(--slate600)" : color
        )
        .on("mouseover", function (event, d) {
          const tooltip = tooltipRef.current;

          if (tooltip && d.value > 0) {
            tooltip.style.display = "block";

            const formattedValue = new Intl.NumberFormat("es-ES", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(d.value);
            const amount = `${selectedCurrency}${formattedValue}`;
            tooltip.innerHTML = isBalanceHidden
              ? `${selectedCurrency}●●●●`
              : amount;
          }

          d3.select(this).attr("fill", "var(--blue900)");
        })
        .on("mousemove", function (event) {
          const tooltip = tooltipRef.current;

          if (tooltip) {
            const svgRect = svgRef.current?.getBoundingClientRect();
            const { clientX, clientY } = event;
            if (svgRect) {
              const tooltipX = clientX - svgRect.left;
              const tooltipY = clientY - 20;

              tooltip.style.left = `${tooltipX}px`;
              tooltip.style.top = `${tooltipY}px`;
            }
          }
        })

        .on("mouseout", function () {
          d3.select(this).attr("fill", color);
          const tooltip = tooltipRef.current;
          if (tooltip) {
            tooltip.style.display = "none";
          }
        })
        .on("click", function (event, d) {
          const tooltip = tooltipRef.current;

          if (tooltip && d.value > 0) {
            const { clientX, clientY } = event;
            const topTooltip = clientY * 0.9 - 20;

            const formattedValue = new Intl.NumberFormat("es-ES", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(d.value);
            tooltip.style.left = `${clientX - 30}px`;
            tooltip.style.top = `${topTooltip}px`;
            tooltip.style.display = "block";

            tooltip.innerHTML = isBalanceHidden
              ? `${selectedCurrency}●●●●`
              : `${selectedCurrency}${formattedValue}`;
          }
        });
    };

    const barChart = getBarChart();
    setYAxis();
    setXAxis();
    drawBars();
  }, [selectedTime, chartData, isBalanceHidden]);

  return (
    <div className={classNames({ [styles.hideBalances]: isBalanceHidden })}>
      <svg ref={svgRef} />
      <div ref={tooltipRef} className={styles.tooltip} />
      <TimeFrameTabs
        timeOptions={timeOptions}
        selectedTime={selectedTime}
        onClickOption={(v: number) => onChangeTime(v)}
        classname={styles.timeFramesTabs}
      />
    </div>
  );
};

export default MonthlyBarChart;
