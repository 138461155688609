import API from "apis";
import { fundsKeys } from "apis/queryKeyFactories";
import { fciService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import { useAppQuery } from "apis/queryClient";
import { PeriodTime } from "interfaces/portfolio";

import { FundsHoldingResponse } from "../../../interfaces/fci";

const FIVE_MINUTES = 60 * 1000 * 5;
const TEN_MINUTES = 60 * 1000 * 10;

interface useHoldingsQueryProps {
  securityId: number;
  period?: PeriodTime;
  offset?: number;
}

export const fetchHoldings = async ({
  securityId,
  period = PeriodTime.YEAR,
  offset = 0,
}: useHoldingsQueryProps) => {
  const url = fciService.holdings(securityId);
  const { data } = await API.get<FundsHoldingResponse>(url, {
    params: {
      period,
      offset,
    },
  });

  return data;
};

export const useFundsHoldings = ({
  securityId,
  period,
  offset,
}: useHoldingsQueryProps) => {
  return useAppQuery<FundsHoldingResponse, ErrorMessage>({
    queryKey: () => fundsKeys.holdings(securityId, period, offset),
    queryFn: async () => fetchHoldings({ securityId, period, offset }),
    options: {
      onError: () => {
        return {
          message: "Hubo un error para cargar las tenencias.",
          status: 500,
        };
      },
      staleTime: FIVE_MINUTES,
      cacheTime: TEN_MINUTES,
    },
  });
};
