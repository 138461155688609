import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { useTrading } from "context/TradingProvider";
import { Bid } from "interfaces/api-responses/socket/book";
import { Method, OrderType } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { screenNames } from "router/routes";
import { updateOrderOptions } from "features/markets/marketsSlice";
import { RootState } from "store/store";
import { UIPriceInputV2 } from "components/common/price-input";
import { UIToggle } from "components/common/toggle";
import { capitalizeEachWord } from "utils";
import { trackAction } from "utils/amplitude";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import styles from "./styles.module.scss";

interface TradingMethodProps {
  instrumentProp: any;
  numOfDecimals: number;
}

const TradingMethod: React.FC<TradingMethodProps> = ({
  instrumentProp,
  numOfDecimals,
}) => {
  const { type, method, setMethod } = useTrading();
  const dispatch = useAppDispatch();
  const { newOrder } = useAppSelector((state: RootState) => state.markets);
  const isBuy = type === OrderType.Buy;

  const messageError = "El precio ingresado no es válido para este activo.";

  const methodOptions = [
    {
      label: capitalizeEachWord(Method.MARKET),
      value: Method.MARKET,
    },
    {
      label: capitalizeEachWord(Method.LIMIT),
      value: Method.LIMIT,
    },
  ];

  const getInitialPrice = () => {
    if (instrumentProp.asks) {
      let values = instrumentProp.bids.map((bid: Bid) => {
        return bid.price;
      });
      if (isBuy) {
        values = instrumentProp.asks.map((ask: Bid) => {
          return ask.price;
        });
        return Math.min(...values);
      }
      return Math.max(...values);
    }
    return instrumentProp.last;
  };

  const [price, setPrice] = useState<number>(getInitialPrice());

  const onChangeMethod = (value: string) => {
    trackAction(`${screenNames.trading} - Change method ${value}`);
    if (value === Method.MARKET)
      setPrice(isBuy ? instrumentProp.ask : instrumentProp.bid);
    setMethod(value as Method);
  };

  const onChangePrice = (value: number) => {
    setPrice(value);
    dispatch(
      updateOrderOptions({
        ...newOrder,
        price: value,
      })
    );
  };

  const checkRightPrice = () => {
    const newPrice = price * 1000;
    const newTick = instrumentProp.tick_size * 1000;
    const rest = Math.round(newPrice) % Math.round(newTick) === 0;
    return rest;
  };

  useEffect(() => {
    setPrice(getInitialPrice());
  }, [instrumentProp]);

  return (
    <div className={styles.divContainer}>
      <div className={styles.label}>
        <Typography className={styles.title}>Precio</Typography>
        <UIToggle
          options={methodOptions}
          selectedValue={method}
          onChangeValue={onChangeMethod}
        />
      </div>

      {method === Method.LIMIT && (
        <UIPriceInputV2
          key="format-amount"
          value={price}
          currency={newOrder.currency ?? Currencies.ARS}
          handleChangeValue={onChangePrice}
          decimals={numOfDecimals}
          error={!checkRightPrice()}
          errorMessage={messageError}
          id="limit-input"
        />
      )}
    </div>
  );
};

export default TradingMethod;
