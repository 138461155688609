export const DotsIcon = ({ color }: any) => {
  return (
    <svg
      className="icons-dots"
      fill="none"
      height="20"
      viewBox="0 0 21 20"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4998 10.8333C10.9601 10.8333 11.3332 10.4602 11.3332 10C11.3332 9.53976 10.9601 9.16666 10.4998 9.16666C10.0396 9.16666 9.6665 9.53976 9.6665 10C9.6665 10.4602 10.0396 10.8333 10.4998 10.8333Z"
        stroke={color || "#002C65"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M10.4998 16.6667C10.9601 16.6667 11.3332 16.2936 11.3332 15.8333C11.3332 15.3731 10.9601 15 10.4998 15C10.0396 15 9.6665 15.3731 9.6665 15.8333C9.6665 16.2936 10.0396 16.6667 10.4998 16.6667Z"
        stroke={color || "#002C65"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
      <path
        d="M10.4998 5C10.9601 5 11.3332 4.6269 11.3332 4.16667C11.3332 3.70643 10.9601 3.33333 10.4998 3.33333C10.0396 3.33333 9.6665 3.70643 9.6665 4.16667C9.6665 4.6269 10.0396 5 10.4998 5Z"
        stroke={color || "#002C65"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </svg>
  );
};
