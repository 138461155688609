import { Instruments, SettlementTerms } from "./markets";
import { Payment, PaymentCurrencies } from "./pay/enums";
import { Currencies } from "./wallet";

export enum Source {
  CAPITAL = "CAPITAL",
  CARD = "CARD",
  COINAG = "COINAG",
  CRYPTO = "CRYPTO",
  DEPOSIT = "DEPOSIT",
  PAY = "PAY",
}

export enum OperationType {
  CHARGEBACK = "CHARGEBACK",
  PAY = "PAY",
}

export interface PayMovement {
  createdAt: string;
  idAccount: number;
  idPayment: string;
  idCashOut: string;
  cvuDestination: string;
  cuitDestination: string;
  nameDestination: string;
  quantity: number;
  idCurrency: Currencies;
  extCode: string;
  qrData: string;
  status: string;
  paymentType: Payment;
  cuitAdministrator: string;
  paymentMethod: PaymentCurrencies;
}
export interface CashMovementResponse {
  idAccount: number;
  idCashMovement: number;
  executionDate: string;
  settlementDate: string;
  shortName: string | null;
  instrumentType: Instruments | null;
  instrumentCode: string;
  quantity: number;
  idCurrency: Currencies;
  detail: PayMovement;
  idCashOut: string;
  operationType: string;
}

export interface CashMovement {
  id_account: number;
  execution_date: string;
  short_name?: string;
  price?: number;
  instrument_type?: Instruments;
  instrument_code?: string;
  label?: string;
  quantity: number;
  balance: number;
  description: string;
  detail: string;
  logo_file_name: string;
  id_currency: Currencies;
  settlement_date: string;
  operation_type: string;
  source: Source;
  created_at: string;
  id_cash_movement: number;
}

export interface CashMovements {
  executionDate: string;
  balance: number;
  cashMovements: CashMovement[];
}

export interface TickerMovement {
  id_account: number;
  execution_date: string;
  short_name: string;
  instrument_code: string;
  instrument_type: string;
  quantity: number;
  price: number;
  label: string;
  description: string;
  logo_file_name?: string;
  id_currency: Currencies;
  balance?: number;
  settlement?: SettlementTerms;
  amount?: number;
  settlement_date: string;
}

export interface TickersMovements {
  executionDate: string;
  tickerMovements: TickerMovement[];
}

export interface MovementsFilters {
  date_to: string;
  date_from: string;
  currency: string;
  type: string[];
}

export interface TickerMovements {
  executionDate: string;
  balance: number;
  tickerMovements: TickerMovement[];
}

export interface TickerDetails {
  id_account: number;
  short_name: string;
  instrument_code: string;
  instrument_type: string;
  buy_quantity: number;
  sell_quantity: number;
  total_quantity: number;
  total_holding: number;
  avg_buy_exec_price: number;
  avg_buy_avail_price: number;
  avg_sell_price: number;
  last_price: number;
  close_price: number;
  prev_close_price: number;
  var_daily_price: number;
  var_historic_price: number;
  var_daily_price_percentage: number;
  var_historic_price_percentage: number;
  daily_revenue: number;
  historic_exec_revenue: number;
  historic_avail_revenue: number;
  historic_revenue: number;
  logo_file_name: string;
}

export interface Pagination {
  total_items: number;
  total_pages: number;
  per_page: number;
  curr_page: number;
}

export enum MovementsTypes {
  MONETARY = "monetary",
  INSTRUMENT = "instrument",
}

export enum FilterSections {
  FILTERS = "filters",
  CALENDAR = "calendar",
}

export interface TickerMovementsResponse {
  data: TickerMovements[];
  pagination: Pagination;
}

export interface CashMovementsResponse {
  data: CashMovements[];
  pagination: Pagination;
}

export enum CurrencyCodes {
  PESOS = "PESOS",
  DOLARES = "USD",
}

export enum CurrencyValues {
  ARS = "AR$",
  USD = "US$",
}
