import { Chip } from "@mui/material";
import { CheckIcon } from "assets/icons/check";

import "./index.scss";

interface PropTypes {
  isSelected?: boolean;
  title: string;
  isActive?: any;
  onClick?: () => void;
  id?: string;
}

export const UIChipSelector = ({
  isSelected = false,
  title,
  onClick,
  id,
}: PropTypes) => {
  const handleClick = () => {
    onClick && onClick();
  };
  if (isSelected) {
    return (
      <Chip
        icon={<CheckIcon size={12} />}
        label={title}
        onClick={handleClick}
        className={`ui-chip-selector ${isSelected ? "selected" : "unselected"}`}
        id={id}
        style={{
          padding: "6px 16px",
          cursor: "pointer",
          height: "32px",
          borderRadius: "8px",
          width: "auto",
        }}
      />
    );
  } else {
    return (
      <Chip
        label={title}
        id={id}
        onClick={handleClick}
        className={`ui-chip-selector ${isSelected ? "selected" : "unselected"}`}
        style={{
          padding: "6px 16px",
          cursor: "pointer",
          height: "32px",
          borderRadius: "8px",
          width: "auto",
        }}
      />
    );
  }
};
