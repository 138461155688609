import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import type { PortfolioQueryResponse } from "interfaces/portfolio";
import type { BalanceCurrencies, PortfolioFromType } from "interfaces/wallet";

export const fetchPortfolioData = async (
  currency: BalanceCurrencies,
  from: PortfolioFromType
) => {
  const { data } = await API.get(portfolioService.portfolio, {
    params: { currency, from },
  });

  return data;
};

export const usePortfolioQuery = (
  currency: BalanceCurrencies,
  from: PortfolioFromType
) => {
  return useAppQuery<PortfolioQueryResponse, ErrorMessage>({
    queryKey: () => portfolioKeys.portfolio(currency, from),
    queryFn: () => fetchPortfolioData(currency, from),
    options: {
      onError: () => {
        return {
          message: "Hubo un error para cargar el portfolio",
          status: 500,
        };
      },
    },
  });
};
