interface PropTypes {
  size?: number;
  color?: string;
}

export const RefreshIcon = (props: PropTypes) => {
  const { color = "#0062E1", size = 26 } = props;

  return (
    <svg
      fill="none"
      height={size}
      viewBox={`0 0 26 29`}
      width={size + 3}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33333 2.33335V10.3333M1.33333 10.3333H9.33333M1.33333 10.3333L7.52 4.52001C9.36122 2.68191 11.7499 1.49197 14.3262 1.1295C16.9025 0.76703 19.5269 1.25166 21.8038 2.51037C24.0807 3.76907 25.8869 5.73367 26.9502 8.10815C28.0135 10.4826 28.2763 13.1383 27.699 15.6752C27.1217 18.212 25.7356 20.4925 23.7495 22.173C21.7634 23.8536 19.285 24.8431 16.6876 24.9926C14.0902 25.1421 11.5146 24.4434 9.34888 23.0018C7.18312 21.5603 5.54453 19.4539 4.68 17"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
