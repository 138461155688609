import { Typography } from "@mui/material";
import { Sections, Instruments, MarketSection } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { getCurrencyLabel } from "utils";

interface HeaderProps {
  selectedInstrumentType: MarketSection;
  currency: Currencies;
}

const Header: React.FC<HeaderProps> = ({
  selectedInstrumentType,
  currency,
}) => {
  const isFCI = selectedInstrumentType === Instruments.FCI;

  const getVariantHeader = (type: MarketSection): string => {
    if (type === Sections.HOME) {
      return "Tasa anual";
    }
    if (isFCI) {
      return "Rend. diario";
    }

    return "Var. diaria";
  };

  return (
    <div className="markets-table-header">
      <div className="grid-col">
        <Typography variant="textXS">Nombre</Typography>
      </div>
      {selectedInstrumentType === Sections.FAVORITOS ||
      selectedInstrumentType === Sections.HOME ? (
        <div className="grid-col">
          <Typography variant="textXS">Últ. Op.</Typography>
        </div>
      ) : (
        <div className="grid-col last-currency">
          <Typography variant="textXS">
            {isFCI ? "Rend. Men." : "Últ. Op."}
          </Typography>
          <Typography variant="textXS_bold">
            {isFCI ? "%" : getCurrencyLabel(currency)}
          </Typography>
        </div>
      )}
      <div className="grid-col">
        <Typography variant="textXS">
          {getVariantHeader(selectedInstrumentType)}
        </Typography>
      </div>
    </div>
  );
};

export default Header;
