export const CheckedCheckboxIcon = () => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect fill="#0062E1" height="16" rx="4" width="16" x="4" y="4" />
      <path
        d="M8 13L10.5 15.5L16.5 9.5"
        stroke="white"
        strokeLinecap="square"
        strokeWidth="2"
      />
    </svg>
  );
};
