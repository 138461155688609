interface PropTypes {
  size?: number;
  color?: string;
}

export const ArrowLeftIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      fill="none"
      height={size || "16"}
      viewBox="0 0 17 16"
      width={size ? size + 1 : "17"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2777 12.4446L5.83325 8.00011L10.2777 3.55566"
        stroke={color || "#0062E1"}
        strokeWidth="2"
      />
    </svg>
  );
};
