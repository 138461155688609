import { useEffect, useState } from "react";

import { Grid, Typography } from "@mui/material";
import ErrorContainer from "components/common/error-modal";
import Success from "components/common/success";
import dayjs from "dayjs";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { BondFlow } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useLocation } from "react-router-dom";
import {
  confirmOrder,
  getOrderById,
  setCreateOrderError,
  resetOrder,
} from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { formatToCurrency, getMessageError } from "utils";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  isInversion?: boolean;
}

export const AnalisisResult = (props: PropTypes) => {
  const { isInversion = false } = props;
  const resultado = useAppSelector(
    (state: RootState) => state.calculator.analisis
  );
  const { isDesktopOrTablet } = useDeviceCheck();
  const orderError = useAppSelector(
    (state: RootState) => state.markets.createOrderError
  );
  const getOrderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const location = useLocation();
  const state = location.state as { isInvestment?: boolean };
  const inversion = state?.isInvestment ? state?.isInvestment : isInversion;
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const [step, setStep] = useState(1);
  const [orderId, setOrderId] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (inversion) {
      if (order.id || orderError.message) {
        setStep(step + 1);
      }
    }
  }, [order.id, orderError]);

  useEffect(() => {
    if (inversion) {
      if (order.id) {
        setOrderId(order.id);
      }

      return () => {
        if (order.id && order.long_ticker) {
          dispatch(resetOrder({ long_ticker: order.long_ticker }));
        }
        dispatch(setCreateOrderError({ message: "", status: null }));
      };
    }
  }, [order.id]);

  const handleClickOrderDetail = () => {
    if (orderId) {
      dispatch(getOrderById(orderId));
    }
  };

  const getTotalRecibir = () => {
    let total = 0;

    resultado.flow.map((row: BondFlow, index: number) => {
      if (index > 0) {
        total += row.cashFlow;
      }
    });
    const receive = `${
      resultado.flow[1].id_currency === Currencies.ARS
        ? Currencies.ARS
        : Currencies.USD
    } ${formatToCurrency(total)}`;

    return receive;
  };

  const sendOrder = async () => {
    setIsLoading(true);
    await dispatch(
      confirmOrder({ order: order, instrument_type: "BONOS_CORP" })
    );
  };

  return (
    <div className="main-resultado-calculadora">
      {step === 1 && (
        <div
          className={
            isDesktopOrTablet
              ? "container-resultado-desktop"
              : "container-resultado"
          }
        >
          <div className="container-bond-data">
            <Grid container className="tabla-bono" spacing={0}>
              <Grid
                alignItems="center"
                className="section top-left"
                display="flex"
                justifyContent="center"
                xs={3}
              >
                <InstrumentLogoUI
                  logoFileName={resultado.logo_file_name}
                  type={"BONO"}
                  variant={InstrumentLogoVariants.Primary}
                />
              </Grid>
              <Grid className="section top-right" xs={9}>
                <Typography className="font-title font-blue">
                  ESPECIE
                </Typography>
                <Typography className="font-big font-blue bono">
                  {resultado.bond}{" "}
                  <p className="font-blue font-comment">
                    {resultado.flow[0].name}
                  </p>
                </Typography>
              </Grid>
              {inversion === true ? (
                <>
                  <Grid className="section" xs={4}>
                    <Typography className="font-title font-blue">
                      TIR
                    </Typography>
                    <Typography className="font-medium font-blue">
                      {formatToCurrency(resultado.tir)}%
                    </Typography>
                  </Grid>
                  <Grid className="section" xs={3}>
                    <Typography className="font-title font-blue">
                      DURATION
                    </Typography>
                    <Typography className="font-big font-blue">
                      {formatToCurrency(resultado.duration)}
                    </Typography>
                  </Grid>
                  <Grid className="section" xs={5}>
                    <Typography className="font-title font-gray">
                      FECHA DE LIQUIDACIÓN
                    </Typography>
                    <Typography className="font-medium font-gray">
                      {dayjs(resultado.expiration_date).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid className="section-result bottom-left" xs={6}>
                    <Typography className="font-title font-blue">
                      INVERSIÓN
                    </Typography>
                    <Typography className="font-medium font-blue">
                      {resultado.flow[1].id_currency === Currencies.ARS
                        ? Currencies.ARS
                        : Currencies.USD}
                      &nbsp;
                      {formatToCurrency(resultado.price)}
                    </Typography>
                  </Grid>
                  <Grid className="section-result bottom-right" xs={6}>
                    <Typography className="font-title font-blue">
                      TOTAL A RECIBIR AL VTO.
                    </Typography>
                    <Typography className="font-medium font-blue">
                      {getTotalRecibir()}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid className="section" xs={4}>
                    <Typography className="font-title font-blue">
                      PRECIO S/100 VN
                    </Typography>
                    <Typography className="font-medium font-blue">
                      {resultado.flow[1].id_currency === Currencies.ARS
                        ? "ARS "
                        : "USD "}
                      {formatToCurrency(
                        (resultado.price * 100) / resultado.quantity
                      )}
                    </Typography>
                  </Grid>
                  <Grid className="section" xs={4}>
                    <Typography className="font-title font-gray">
                      CANTIDAD
                    </Typography>
                    <Typography className="font-medium font-gray">
                      {formatToCurrency(resultado.quantity, true)}
                    </Typography>
                  </Grid>
                  <Grid className="section" xs={4}>
                    <Typography className="font-title font-gray">
                      F. LIQUIDACION
                    </Typography>
                    <Typography className="font-medium font-gray">
                      {dayjs(resultado.expiration_date).format("DD-MM-YYYY")}
                    </Typography>
                  </Grid>
                  <Grid className="section-result bottom-left" xs={4}>
                    <Typography className="font-title font-blue">
                      TIR
                    </Typography>
                    <Typography className="font-medium font-blue">
                      {formatToCurrency(resultado.tir)}%
                    </Typography>
                  </Grid>
                  <Grid className="section-result" xs={3}>
                    <Typography className="font-title font-blue">
                      DURATION
                    </Typography>
                    <Typography className="font-big font-blue">
                      {formatToCurrency(resultado.duration)}
                    </Typography>
                  </Grid>
                  <Grid className="section-result bottom-right" xs={5}>
                    <Typography className="font-title font-blue">
                      TOTAL A RECIBIR
                    </Typography>
                    <Typography className="font-big font-blue">
                      {getTotalRecibir()}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
          <div className="container-data-grid">
            <Typography className="font-blue font-big">
              Flujo de fondos{" "}
              {inversion === true
                ? "esperado"
                : `para ${formatToCurrency(resultado.quantity, true)} VN`}
              :
            </Typography>
            <Grid container columns={12} rowSpacing={1}>
              <Grid className="result-title" xs={3}>
                <Typography className="font-blue font-comment">
                  Fecha
                </Typography>
              </Grid>
              <Grid className="result-title" xs={3}>
                <Typography align="right" className="font-blue font-comment">
                  Capital
                </Typography>
              </Grid>
              <Grid className="result-title" xs={2}>
                <Typography align="right" className="font-blue font-comment">
                  Intereses
                </Typography>
              </Grid>
              <Grid className="result-title" xs={4}>
                <Typography align="right" className="font-blue font-comment">
                  Flujo
                </Typography>
              </Grid>
              {resultado.flow.map((row: any, index: number) => (
                <>
                  <Grid key={index + "01"} className="content-data" xs={3}>
                    <Typography className="font-blue font-comment">
                      {row.date}
                    </Typography>
                  </Grid>
                  <Grid key={index + "02"} className="content-data" xs={3}>
                    <Typography
                      align="right"
                      className="font-blue font-comment"
                    >
                      {index === 0 ? "-" : formatToCurrency(row.capital)}
                    </Typography>
                  </Grid>
                  <Grid key={index + "03"} className="content-data" xs={2}>
                    <Typography
                      align="right"
                      className="font-blue font-comment"
                    >
                      {index === 0 ? "-" : formatToCurrency(row.interes)}
                    </Typography>
                  </Grid>
                  <Grid key={index + "04"} className="content-data" xs={4}>
                    <Typography
                      align="right"
                      className="font-blue font-comment"
                    >
                      {formatToCurrency(row.cashFlow)}
                    </Typography>
                  </Grid>
                  <br />
                </>
              ))}
            </Grid>
            {inversion && (
              <div className="rev-compra-button">
                <ButtonUI
                  disabled={isLoading}
                  size={ButtonSizes?.Full}
                  variant={ButtonVariants.Primary}
                  onClick={sendOrder}
                >
                  Confirmar Compra
                </ButtonUI>
              </div>
            )}
          </div>
        </div>
      )}
      {step === 2 && order.id && (
        <div className="step-2">
          <Success
            loadingSecondaryButton={getOrderLoading}
            subtitle="Para ver el estado de la orden podes ingresar a movimientos o desde el activo que operaste."
            textPrimaryButton="Ir a Mercado"
            textSecondaryButton="Ver estado de orden"
            title="¡Tu orden fue enviada con éxito!"
            urlPrimaryButton={
              window.location.pathname.includes("market")
                ? undefined
                : mainRoutes.market
            }
            urlSecondaryButton={
              isMobile ? `${mainRoutes.orderDetail}/${order.id}` : undefined
            }
            onClickPrimaryButton={() => dispatch(closeRightModal())}
            onClickSecondaryButton={handleClickOrderDetail}
          />
        </div>
      )}
      {step === 2 && orderError.message && !order.id && (
        <ErrorContainer
          subtitle={getMessageError(orderError.message)}
          textPrimaryButton={"Volver al activo"}
          title="Ups... Hubo un error"
          onClickPrimaryButton={() => dispatch(closeRightModal())}
        />
      )}
    </div>
  );
};
