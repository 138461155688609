import Divider from "../Divider";
import Text, { TextVariants } from "../Text";

import styles from "./styles.module.scss";

export type StatData = {
  name: string;
  value: string | number;
};

interface StatBlockProps {
  statBlockData: StatData[];
  valueColor?: string;
}

const StatBlock = ({
  statBlockData,
  valueColor = "var(--slate900)",
}: StatBlockProps) => {
  const lastIndex = statBlockData.length - 1;

  return (
    <div className={styles.dataContainer}>
      {statBlockData.map((item, index) => (
        <div key={item.name} className={styles.dataContainer}>
          <div className={styles.dataItem}>
            <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
              {item.name}
            </Text>
            <Text variant={TextVariants.SemiboldTextS} color={valueColor}>
              {item.value}
            </Text>
          </div>
          {index !== lastIndex && (
            <Divider className={styles.divider} orientation="vertical" />
          )}
        </div>
      ))}
    </div>
  );
};

export default StatBlock;
