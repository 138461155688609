import { ReactNode, createContext, useContext, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";
import { Screens } from "interfaces/phoneValidation";
import { ValidationChallengeResponse } from "interfaces/api-responses";
import mainRoutes from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";

interface Context {
  screen: Screens;
  setScreen: (v: Screens) => void;
  challengeData: ValidationChallengeResponse;
  setChallengeData: (v: ValidationChallengeResponse) => void;
  remainingTime: number;
  setRemainingTime: (value: React.SetStateAction<number>) => void;
  goToHome: () => void;
  phone?: string;
  isLoading: boolean;
  setIsLoading: (v: boolean) => void;
  error: boolean;
  setError: (v: boolean) => void;
}

const validationChallengeInitialState = {
  id: "",
  expires_at: new Date(),
};

const PhoneValidationContext = createContext<Context | null>(null);

export const PhoneValidationProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const user = useAppSelector(getLoggedUser);

  const [screen, setScreen] = useState<Screens>(Screens.VERIFICATION_REQUIRED);
  const [
    challengeData,
    setChallengeData,
  ] = useState<ValidationChallengeResponse>(validationChallengeInitialState);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const goToHome = () => navigate(mainRoutes.home);

  const memoizedValues = useMemo(() => {
    return {
      screen,
      setScreen,
      challengeData,
      setChallengeData,
      goToHome,
      phone: user?.phone,
      remainingTime,
      setRemainingTime,
      isLoading,
      setIsLoading,
      error,
      setError,
    };
  }, [
    screen,
    setScreen,
    challengeData,
    setChallengeData,
    remainingTime,
    setRemainingTime,
    goToHome,
    isLoading,
    setIsLoading,
    error,
    setError,
  ]);

  return (
    <PhoneValidationContext.Provider value={memoizedValues}>
      {children}
    </PhoneValidationContext.Provider>
  );
};

export const usePhoneValidation = () => {
  const context = useContext(PhoneValidationContext);
  if (!context) {
    throw new Error("[PhoneValidationContext] Missing context");
  }

  return context;
};
