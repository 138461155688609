import FundsProvider from "context/FundsProvider";
import { useParams } from "react-router-dom";

import FundsWrapper from "./components/common/FundsWrapper";
import { CocosFundsList, CocosFund, AvailableFunds } from "./helpers";

const findCocosFund = (shortName: string | undefined): CocosFund | null => {
  return CocosFundsList.find((fund) => fund.ticker === shortName) ?? null;
};

const CapitalFunds = ({ shortName }: { shortName?: string }) => {
  const { fundId } = useParams<{
    fundId: AvailableFunds;
  }>();
  const selectedFund = findCocosFund(shortName ?? fundId);

  if (!selectedFund) {
    return null;
  }

  return (
    <FundsProvider>
      <FundsWrapper selectedFund={selectedFund} />
    </FundsProvider>
  );
};

export default CapitalFunds;
