import classNames from "classnames";
import BottomNavigation from "components/common/BottomNavigation";
import ErrorPage from "components/common/ErrorPage";
import LoadingSpinner from "components/common/LoadingSpinner";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface CryptoPageProps {
  children:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode[]
    | React.ReactNode;
  loading?: boolean;
  error?: boolean;
  className?: string;
}

const CryptoPage: React.FC<CryptoPageProps> = ({
  children,
  loading,
  error,
  className,
}) => {
  return (
    <div className={classNames(styles.cryptoPageContainer, className)}>
      {loading && (
        <div className={styles.loading}>
          <LoadingSpinner variant={ThemeVariants.Crypto} />
        </div>
      )}
      {error && !loading && (
        <ErrorPage errorMessage="En este momento no es posible cargar la página." />
      )}
      {!loading && !error && <div className={styles.children}>{children}</div>}
      <BottomNavigation variant={ThemeVariants.Crypto} />
    </div>
  );
};

export default CryptoPage;
