import { SettlementIndex } from "constants/portfolio";

import { ampli } from "ampli";
import Heading, { HeadingVariants } from "components/common/Heading";
import CurrencyBalanceDetail from "components/page-cocos-portfolio/CurrencyBalanceDetail";
import BalanceCard from "components/page-portfolio/Value/BalanceCard";
import { useAppState } from "context/AppStateProvider";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import type { CashHoldings } from "interfaces/portfolio";
import { Currencies } from "interfaces/wallet";

import ToBeSettledText from "../ToBeSettledText";

import styles from "./styles.module.scss";

interface MoneyBalanceCardsProps {
  cashHoldings: CashHoldings[];
}

const CashBalanceCards = ({ cashHoldings }: MoneyBalanceCardsProps) => {
  const {
    selectedCurrency,
    setPortfolioScreen,
    setSelectedCurrencyDetail,
  } = usePortfolio();
  const { openRightModalContent } = useAppState();
  const { isMobile } = useDeviceCheck();

  const settlements = cashHoldings.filter((ticker) => ticker.toBeSettled);
  const hasSettlements = settlements.length > 0;

  const sortOrder: Record<string, number> = {
    [Currencies.ARS]: 1,
    [Currencies.USD]: 2,
    [Currencies.EXT]: 3,
  };
  const sortedCashHoldings = cashHoldings.sort(
    (a, b) => sortOrder[a.ticker] - sortOrder[b.ticker]
  );

  const onBalanceCardClick = (currency: Currencies) => {
    ampli.portfolioExited({ referral: "currency_balance" });
    setSelectedCurrencyDetail(currency);

    if (isMobile) {
      setPortfolioScreen(PortfolioScreen.PORTFOLIO_CASH_DETAIL);
    } else {
      openRightModalContent(
        <CurrencyBalanceDetail selectedCurrencyDetail={currency} />
      );
    }
  };

  return (
    <div className={styles.moneyContainer}>
      <Heading
        className={styles.valueHeading}
        variant={HeadingVariants.RegularTitle}
        component="h2"
        color="var(--slate800)"
      >
        Dinero
      </Heading>
      <div className={styles.balanceCards}>
        {sortedCashHoldings.map(({ ticker, settlements, toBeSettled }) => (
          <BalanceCard
            key={ticker}
            withTimer={!!toBeSettled}
            amount={settlements[SettlementIndex.INF].amount}
            currency={ticker}
            selectedCurrency={selectedCurrency}
            onClick={() => onBalanceCardClick(ticker)}
          />
        ))}
      </div>
      {hasSettlements && <ToBeSettledText settlements={settlements} />}
    </div>
  );
};

export default CashBalanceCards;
