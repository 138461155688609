import Heading, { HeadingVariants } from "components/common/Heading";
import { Info } from "lucide-react";
import Text, { TextVariants } from "components/common/Text";
import Amount from "components/common/Amount";
import type { CashHoldings } from "interfaces/portfolio";
import { useAppState } from "context/AppStateProvider";
import px2rem from "utils/px2rem";
import { formatRoundAmount } from "utils";

import styles from "./styles.module.scss";

const DetailBox = ({
  detailHolding,
  handleCompositionClick,
}: {
  detailHolding: CashHoldings;
  handleCompositionClick: () => void;
}) => {
  const { isBalanceHidden } = useAppState();
  const detailBox = [
    {
      title: "Hoy (C. I.)",
      value: formatRoundAmount(
        detailHolding.ticker,
        detailHolding.settlements[0].amount
      ),
    },
    {
      title: "En 24 hs",
      value: formatRoundAmount(
        detailHolding.ticker,
        detailHolding.settlements[1].amount
      ),
    },
    {
      title: "Más de 24 hs",
      value: formatRoundAmount(
        detailHolding.ticker,
        detailHolding.settlements[2].amount
      ),
    },
  ];

  return (
    <div className={styles.detailBoxWrapper}>
      <div className={styles.detailBoxHeadingWrapper}>
        <Heading
          variant={HeadingVariants.RegularSubtitle}
          component="h3"
          color="var(--slate800)"
          className={styles.detailBoxHeading}
        >
          Composición por plazo
        </Heading>
        <Info
          color="var(--slate800)"
          size={px2rem(16)}
          onClick={handleCompositionClick}
          className={styles.infoIcon}
        />
      </div>
      <div className={styles.detailBox}>
        {detailBox.map((item) => (
          <div key={item.title} className={styles.detailBoxItem}>
            <Text color="var(--slate800)" variant={TextVariants.RegularTextL}>
              {item.title}
            </Text>
            <Amount
              isBalanceHidden={isBalanceHidden}
              amount={item.value}
              currency={detailHolding.ticker}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailBox;
