import { ChangeEvent } from "react";

import { Typography, TextField } from "@mui/material";

import styles from "./styles.module.scss";

interface AmountInputProps {
  amount: number;
  handleChangeQuantity: (event: ChangeEvent<HTMLInputElement>) => void;
}

const AmountInput: React.FC<AmountInputProps> = ({
  amount,
  handleChangeQuantity,
}) => (
  <div className={styles.amountWrapper}>
    <Typography className={styles.label}>Cantidad</Typography>
    <TextField
      className={styles.amountInput}
      inputProps={{ min: 1 }}
      placeholder="(Opcional)"
      type="number"
      value={amount}
      variant="outlined"
      onChange={handleChangeQuantity}
    />
  </div>
);

export default AmountInput;
