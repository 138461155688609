import { FundsIntervals, HistoricalProfitsData } from "interfaces/fci";

export const monthMap = new Map<string, string>([
  ["01", "Enero"],
  ["02", "Febrero"],
  ["03", "Marzo"],
  ["04", "Abril"],
  ["05", "Mayo"],
  ["06", "Junio"],
  ["07", "Julio"],
  ["08", "Agosto"],
  ["09", "Septiembre"],
  ["10", "Octubre"],
  ["11", "Noviembre"],
  ["12", "Diciembre"],
]);

const monthToLabel = (month: string): string => {
  const monthNumber = month.split("-")[1];
  return monthMap.get(monthNumber)?.substring(0, 3) as string;
};

const weekToLabel = (from: string, to: string): string => {
  const fromDate = from.split("-")[2];
  const toDate = to.split("-")[2];

  return `${fromDate} al ${toDate}`;
};

const dayToLabel = (from: string, to: string): string => {
  const fromDate = from.split("-")[2];
  const toDate = to.split("-")[1];

  return `${fromDate}/${toDate}`;
};

export const mapToDataLabels = (
  interval: FundsIntervals,
  data: HistoricalProfitsData[]
): any[] => {
  if (interval === FundsIntervals.DAY) {
    return data.map((d) => ({
      date: dayToLabel(d.from, d.to),
      value: d.value,
    }));
  }

  if (interval === FundsIntervals.WEEK) {
    return data.map((d) => ({
      date: weekToLabel(d.from, d.to),
      value: d.value,
    }));
  }

  return interval === FundsIntervals.MONTH
    ? data.map((d) => ({
        date: monthToLabel(d.from),
        value: d.value,
      }))
    : [];
};
