import { useState, useEffect } from "react";

import { usePrevious } from "hooks/usePrevious";
import { Typography } from "@mui/material";
import { getFormattedNumber } from "utils";

interface InstrumentLowProps {
  low?: number | null;
  idTickSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentLow: React.FC<InstrumentLowProps> = ({
  low,
  idTickSizeRule,
  tickSize,
}) => {
  const [lowClass, setLowClass] = useState<string>("grid-col");
  const previousLow = usePrevious(low);

  useEffect(() => {
    if (previousLow !== low) {
      setLowClass("grid-col change");
      setTimeout(() => {
        setLowClass("grid-col");
      }, 400);
    }
  }, [low]);

  return (
    <div className={lowClass}>
      <Typography variant="textXS">
        {getFormattedNumber(low, idTickSizeRule, tickSize)}
      </Typography>
    </div>
  );
};

export default InstrumentLow;
