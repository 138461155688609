import { CocosSmallLogo } from "assets/logos/cocos-logo";
import classNames from "classnames";
import Avatar from "components/common/avatar";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ThemeVariants } from "interfaces/theme";
import { Home, LogOut, PieChart, SquareEqual, TrendingUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import getPlatform from "utils/getPlatform";
import px2rem from "utils/px2rem";

import NavigationTab from "./NavigationTab";

import styles from "./styles.module.scss";

interface BottomNavigationProps {
  variant: ThemeVariants;
}

const BottomNavigation: React.FC<BottomNavigationProps> = ({ variant }) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();

  const { isIOS } = getPlatform();
  const isCapital = variant === ThemeVariants.Capital;

  const NavigationOptions = [
    {
      title: "Inicio",
      icon: Home,
      route: isCapital ? mainRoutes.home : CryptoRoutes.HOME,
      referral: isCapital && "home",
    },
    {
      title: "Mercado",
      icon: TrendingUp,
      route: isCapital ? mainRoutes.market : CryptoRoutes.MARKET,
      referral: isCapital && "market",
    },
    {
      title: "Actividad",
      icon: SquareEqual,
      route: isCapital ? mainRoutes.movements : CryptoRoutes.ACTIVITY,
      referral: isCapital && "activity",
    },
    {
      title: "Portfolio",
      icon: PieChart,
      route: isCapital ? mainRoutes.portfolio : CryptoRoutes.PORTFOLIO,
    },
  ];

  if (isMobile)
    return (
      <div
        className={classNames(styles.bottomNavigationContainer, {
          [styles.ios]: isIOS,
        })}
      >
        {NavigationOptions.map(({ title, icon, route, referral }) => (
          <NavigationTab
            key={title}
            icon={icon}
            title={title}
            route={route}
            variant={variant}
            referral={referral || undefined}
          />
        ))}
      </div>
    );

  return (
    <div className={styles.bottomNavigationContainer}>
      <div className={styles.topWrapper}>
        <CocosSmallLogo />

        <div className={styles.tabs}>
          {NavigationOptions.map(({ title, icon, route }) => (
            <NavigationTab
              key={title}
              icon={icon}
              title={title}
              route={route}
              variant={variant}
            />
          ))}
        </div>
      </div>
      <div className={styles.bottomWrapper}>
        <Avatar />
        <LogOut
          size={px2rem(24)}
          color="var(--slate800)"
          onClick={() => navigate(mainRoutes.logout)}
        />
      </div>
    </div>
  );
};

export default BottomNavigation;
