import { UIChipSelector } from "components/common/controls/chip-selector";

import styles from "./styles.module.scss";

interface ChipsContainerProps {
  chipsOptions: {
    value: number;
    label: string;
  }[];
  selectedChip?: number;
  onClickChip: (value: number) => void;
}

const ChipsContainer: React.FC<ChipsContainerProps> = ({
  chipsOptions,
  selectedChip,
  onClickChip,
}) => {
  return (
    <div className={styles.priceChips}>
      {chipsOptions.map((option) => (
        <UIChipSelector
          isSelected={option.value === selectedChip}
          key={option.value}
          id={option.label}
          title={option.label}
          onClick={() => onClickChip(option.value)}
        />
      ))}
    </div>
  );
};

export default ChipsContainer;
