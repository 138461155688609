import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import TradingContainer from "components/common/trading-container";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import {
  InstrumentData,
  InstrumentSubtype,
  SearchListResponse,
} from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import LayoutV2 from "layouts/v2";
import { useNavigate } from "react-router-dom";
import { openRightModal } from "features/rightModal/rightModalSlice";
import mainRoutes from "router/routes";
import { useAppState } from "context/AppStateProvider";

import styles from "./styles.module.scss";

interface SearchResultListProps {
  searchTicker: string;
  list: SearchListResponse[];
  loading: boolean;
  quickOperation: boolean;
  onClickRow: (ticker: InstrumentData) => void;
}

const SearchResultList: React.FC<SearchResultListProps> = ({
  searchTicker,
  list,
  loading,
  quickOperation,
  onClickRow,
}) => {
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const { openRightModalContent } = useAppState();

  const redirectToTrading = (
    code: string,
    ticker: InstrumentData,
    type?: Instruments
  ) => {
    navigate(`${mainRoutes.market}/${type}/${code}`, { state: ticker });
  };

  const handleOnClickRow = (ticker: InstrumentData) => (ev: any) => {
    ev.stopPropagation();
    onClickRow(ticker);
    if (quickOperation) {
      if (isMobile) {
        navigate(
          `/market/${ticker.instrument_type}/${ticker.instrument_code}`,
          { state: ticker }
        );
      } else {
        openRightModalContent(
          <LayoutV2
            title={ticker.instrument_code ?? ""}
            withSearch
            quickOperation
            onClickExtended={() =>
              redirectToTrading(
                ticker.instrument_code ?? "",
                ticker,
                ticker.instrument_type
              )
            }
            instrument={ticker}
          >
            <TradingContainer instrumentProp={ticker} />
          </LayoutV2>
        );
      }
    } else {
      if (isMobile) {
        navigate(
          `/market/${ticker.instrument_type}/${ticker.instrument_code}`,
          { state: ticker }
        );
      } else {
        openRightModalContent(
          <LayoutV2
            title={ticker.instrument_code ?? ""}
            withSearch
            quickOperation
            instrument={ticker}
          >
            <TradingContainer instrumentProp={ticker} />
          </LayoutV2>
        );
        openRightModal();
      }
    }
  };
  return (
    <List id={styles.searchList}>
      {list.length === 0 && searchTicker.length > 1 && !loading && (
        <Typography className={styles.noResult} component={"p"}>
          No hay resultados similares.
        </Typography>
      )}
      {list.map((instrument_type: SearchListResponse) => {
        return instrument_type.instrument_subtypes.map(
          (li: InstrumentSubtype, index: number) => (
            <div key={instrument_type.instrument_type}>
              <ListSubheader key={index} className={styles.typeHeader}>
                <Typography>
                  {instrument_type.type_name !== null
                    ? instrument_type.type_name
                    : "Otros"}
                </Typography>
              </ListSubheader>
              {li.market_data.map((t: InstrumentData, index: number) => (
                <ListItem key={index} onClick={handleOnClickRow(t)}>
                  <ListItemText>
                    <Typography>{t.short_ticker}</Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </div>
          )
        );
      })}
    </List>
  );
};

export default SearchResultList;
