import "./index.scss";

interface IconTextProps {
  icon: JSX.Element;
  label: JSX.Element;
  newClass?: string;
}

const IconText: React.FC<IconTextProps> = ({ label, icon, newClass }) => {
  return (
    <div className={`ui-icon-text ${newClass && newClass}`}>
      {icon}
      {label}
    </div>
  );
};

export default IconText;
