import FilledButton from "components/common/FilledButton";
import { ThemeVariants } from "interfaces/theme";
import getPlatform from "utils/getPlatform";
import classNames from "classnames";
import { FundScreen } from "components/page-capital-funds/helpers";

import styles from "./styles.module.scss";

interface FundsButtonsProps {
  handleOnClick: (screen: FundScreen) => void;
}

const FundsButtons = ({ handleOnClick }: FundsButtonsProps) => {
  const { isIOS } = getPlatform();

  return (
    <div
      className={classNames(styles.buttonsWrapper, { [styles.isIos]: isIOS })}
    >
      <FilledButton
        variant={ThemeVariants.Capital}
        onClick={() => handleOnClick(FundScreen.ADD_FUNDS)}
      >
        Agregar
      </FilledButton>
      <FilledButton
        className={styles.rescueButton}
        variant={ThemeVariants.Capital}
        onClick={() => handleOnClick(FundScreen.WITHDRAW_FUNDS)}
      >
        Retirar
      </FilledButton>
    </div>
  );
};

export default FundsButtons;
