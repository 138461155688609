import { useAppSelector } from "hooks/redux";
import classNames from "classnames";
import { Typography, IconButton } from "@mui/material";
import { DotsIcon } from "assets/icons/dots";
import { formatPercentage } from "utils";
import { trackAction } from "utils/amplitude";
import { getCurrentScreen } from "features/global/globalSlice";

import styles from "./styles.module.scss";

interface InstrumentVariationProps {
  variation: number;
  onClickMarketOptions: () => void;
}

const InstrumentVariation: React.FC<InstrumentVariationProps> = ({
  variation,
  onClickMarketOptions,
}) => {
  const currentScreen = useAppSelector(getCurrentScreen);

  const handleClickMarketMenu = (event: React.MouseEvent) => {
    event.stopPropagation();
    trackAction(`${currentScreen} - Open Instrument Options`);
    onClickMarketOptions();
  };

  const variationColorStyles = classNames({
    [styles.positive]: variation > 0,
    [styles.negative]: variation < 0,
  });

  return (
    <div className={styles.variation}>
      <div className={variationColorStyles}>
        <Typography variant="textS_bold">
          {formatPercentage(variation, true)}
        </Typography>
      </div>

      <IconButton
        aria-label="menu"
        className={styles.dotsIcon}
        color="inherit"
        edge="start"
        onClick={handleClickMarketMenu}
      >
        <DotsIcon />
      </IconButton>
    </div>
  );
};

export default InstrumentVariation;
