import IconButton, { IconButtonSizes } from "components/common/IconButton";
import { ArrowLeft, ArrowRight } from "lucide-react";
import Amount, { AmountSizes } from "components/common/Amount";
import { useAppState } from "context/AppStateProvider";
import Text, { TextVariants } from "components/common/Text";
import type { FundsPeriodProfit } from "interfaces/fci";
import { useFunds } from "context/FundsProvider";

import styles from "./styles.module.scss";

interface ArrowButtonsReturnsProps {
  periodText: string;
  periodProfit: FundsPeriodProfit;
  maxOffset: number;
}

const ArrowButtonsReturns = ({
  periodText,
  periodProfit,
  maxOffset,
}: ArrowButtonsReturnsProps) => {
  const { isBalanceHidden } = useAppState();
  const { offset, setOffset } = useFunds();

  const { currency, value } = periodProfit;

  const returnsValue = value < 0 ? 0 : value;
  const isNextArrowDisabled = offset === 0;
  const isPrevArrowDisabled = offset === maxOffset;

  return (
    <div className={styles.arrowRateContainer}>
      <IconButton
        className={styles.arrowButton}
        icon={ArrowLeft}
        size={IconButtonSizes.Large}
        onClick={() => setOffset(offset + 1)}
        disabled={isPrevArrowDisabled}
      />
      <div className={styles.returnsContainer}>
        <Amount
          isSmallCurrency
          amount={returnsValue}
          currency={currency}
          secondaryColor="var(--green800)"
          isBalanceHidden={isBalanceHidden}
          mainColor="var(--green800)"
          size={AmountSizes.Large}
        />
        <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
          {periodText}
        </Text>
      </div>
      <IconButton
        className={styles.arrowButton}
        icon={ArrowRight}
        size={IconButtonSizes.Large}
        onClick={() => setOffset(offset - 1)}
        disabled={isNextArrowDisabled}
      />
    </div>
  );
};

export default ArrowButtonsReturns;
