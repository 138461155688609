import API from "apis";

import { marketService } from "./services";

export const searchTicker = (ticker: string, signal: AbortSignal) => {
  const response = API.get(marketService.searchTicker, {
    params: { q: ticker },
    signal,
  });

  return response;
};
