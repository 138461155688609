import { CDN_URL } from "constants/index";

import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { motion } from "framer-motion";
import { usePrevious } from "hooks/usePrevious";
import { InstrumentData } from "interfaces/api-responses";
import { Currencies } from "interfaces/wallet";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  resetMarketDataDetail,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets/marketsSlice";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { getFormattedNumber, getSettlementDays } from "utils";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface MarketRowFCIDesktopProps {
  market: InstrumentData;
  onClickRow?: (market: InstrumentData) => void;
}

const MarketRowFCIDesktop: React.FC<MarketRowFCIDesktopProps> = ({
  market,
  onClickRow,
}) => {
  const dispatch = useAppDispatch();
  const prevPrice = usePrevious(market.last);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  let intervalId: NodeJS.Timeout;
  const [highlightClass, setHighlightClass] = useState<string>();

  useEffect(() => {
    if (market.last) {
      clearTimeout(intervalId);
      if (prevPrice && prevPrice < market.last) {
        setHighlightClass("price-highlight up");
      }
      if (prevPrice && prevPrice > market.last) {
        setHighlightClass("price-highlight down");
      }
    }
    intervalId = setInterval(() => {
      setHighlightClass("price-highlight");
    }, 3000);

    return () => {
      setHighlightClass(undefined);
    };
  }, [market.last]);

  const getVariationClass = (value: number | null | undefined) => {
    if (!value) {
      return "grid-col variation";
    }

    return `grid-col variation ${value > 0 ? "positive" : "negative"}`;
  };

  const formatPercentage = (percentage: number) => {
    const formattedVariation = (percentage * 100).toFixed(2);

    return `${percentage > 0 ? "+" : ""}${formattedVariation}%`;
  };

  const handleClickRow = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (
      market.short_ticker &&
      !order.long_ticker?.includes(market.short_ticker)
    ) {
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
      dispatch(clearSellingPower());
      dispatch(cleanHistoricalData());
      dispatch(
        resetOrder({
          price: market.last,
          long_ticker: market.long_ticker,
          instrument_short_name: market.instrument_short_name,
          term: getSettlementDays(market.settlement_days), // ci, 24 y 48
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
          returns_perc_last_year: market.returns_perc_last_year || 0,
          instrument_code: market.instrument_code,
        })
      );
    } else {
      dispatch(
        updateOrderOptions({
          ...order,
          price: market.last,
          long_ticker: market.long_ticker,
          instrument_short_name: market.instrument_short_name,
          term: getSettlementDays(market.settlement_days), // ci, 24 y 48
          currency:
            market.currency === Currencies.ARS ||
            market.currency === Currencies.USD
              ? market.currency
              : Currencies.ARS,
          returns_perc_last_year: market.returns_perc_last_year || 0,
          instrument_code: market.instrument_code,
        })
      );
    }
    dispatch(setMarketDataDetail(market));
    onClickRow && onClickRow(market);
  };

  return (
    <motion.div
      className="comp-desktop-market-row wide-fci"
      whileHover={{ scale: 1.01 }}
      whileTap={{ scale: 0.99 }}
      onClick={handleClickRow}
    >
      <div className="grid-col img">
        <div className="market-logo">
          <InstrumentLogoUI
            logoFileName={market.logo_file_name}
            type={market.instrument_type}
            variant={InstrumentLogoVariants.Primary}
          />
        </div>
      </div>

      <div className="grid-col short_ticker">
        <div>
          <Typography variant="textS_bold">
            {market.long_ticker?.split("-")[0]}
          </Typography>
        </div>
      </div>
      <div className="grid-col last-price">
        <div className={highlightClass || "price-highlight"}>
          <div className="price">
            <Typography variant="textXS">
              {getFormattedNumber(
                market.last || market.close,
                market.id_tick_size_rule,
                market.tick_size
              )}
            </Typography>
          </div>
        </div>
      </div>
      <div className={getVariationClass(market.returns_perc_last_year)}>
        <div>
          <Typography variant="textXS_bold">
            {market.returns_perc_last_year !== undefined
              ? formatPercentage(market.returns_perc_last_year || 0)
              : "-"}
          </Typography>
        </div>
      </div>
      <div className={getVariationClass(market.returns_perc_last_month)}>
        <div>
          <Typography variant="textXS_bold">
            {market.returns_perc_last_month !== undefined
              ? formatPercentage(market.returns_perc_last_month || 0)
              : "-"}
          </Typography>
        </div>
      </div>
      <div className={getVariationClass(market.variation)}>
        <div>
          <Typography variant="textXS_bold">
            {market.variation !== undefined
              ? formatPercentage(market.variation || 0)
              : "-"}
          </Typography>
        </div>
      </div>
      <div className={getVariationClass(market.returns_perc_begin_year)}>
        <div>
          <Typography variant="textXS_bold">
            {market.returns_perc_begin_year !== undefined
              ? formatPercentage(market.returns_perc_begin_year || 0)
              : "-"}
          </Typography>
        </div>
      </div>
      <div className="grid-col">
        <div className="price">
          <Typography variant="textXS">
            <Currency withoutDecimals>{market.nav || "-"}</Currency>
          </Typography>
        </div>
      </div>
      <div className="grid-col">
        <Typography variant="textXS_bold">{market.risk || "-"}</Typography>
      </div>
      <div className="grid-col">
        <Typography variant="textXS">
          {getSettlementDays(
            typeof market.redemption_settlement_days === "number"
              ? market.redemption_settlement_days
              : 1
          )}
        </Typography>
      </div>
      <div className="grid-col">
        <Typography className="link" variant="textXS_bold">
          <a
            href={`${CDN_URL}/reglamentos/${market.rules_file_name}`}
            rel="noreferrer"
            target="_blank"
          >
            Reglamento
          </a>
        </Typography>
      </div>
    </motion.div>
  );
};

export default MarketRowFCIDesktop;
