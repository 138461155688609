import { Skeleton } from "@mui/material";

interface LoaderRowProps {
  rowsLength: number;
}

const LoaderRows: React.FC<LoaderRowProps> = ({ rowsLength }) => {
  return (
    <>
      {Array.from(Array(rowsLength).keys()).map((i) => (
        <Skeleton animation="wave" height={72} key={i} />
      ))}
    </>
  );
};

export default LoaderRows;
