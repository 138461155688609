import React, { useEffect, useState } from "react";

import { IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons/arrow-left";
import { CandleChart } from "assets/icons/candle-chart";
import { FillStar } from "assets/icons/favorite/fill-star";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import BondCalculator from "components/common/BondCalculator";
import MarketClosedHeader from "components/common/market-closed-header";
import InputSearch from "components/common/searchV2";
import { TradingProvider } from "context/TradingProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { InstrumentData } from "interfaces/api-responses";
import { Schedules } from "interfaces/calendar";
import { Instruments, SettlementTerms } from "interfaces/markets";
import DrawerLayout from "layouts/drawer";
import { BsCalculator } from "react-icons/bs";
import { IoArrowBack, IoClose } from "react-icons/io5";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { checkFlow } from "features/calculator/calculatorSlice";
import { getMarketsTypesLists, toggleInList } from "features/markets";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { RootState } from "store/store";
import { checkMarketOpen } from "store/selectors/markets.selector";
import { getLoggedUser } from "store/selectors/user.selector";
import { getSettlementDays } from "utils";
import { trackAction } from "utils/amplitude";
import { getColor } from "utils/getColor";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import styles from "./styles.module.scss";

const DEFAULT_TERM = 2;

interface PropTypes {
  children?:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode[]
    | React.ReactNode;
  title: string;
  goBack?: () => void;
  withSearch?: boolean;
  quickOperation?: boolean;
  isInvestment?: boolean;
  withFavorite?: boolean;
  newClass?: string;
  withGoBack?: boolean;
  onClickExtended?: () => void;
  instrument?: InstrumentData;
}

const LayoutV2 = ({
  children,
  title,
  goBack,
  withSearch = false,
  quickOperation = false,
  withFavorite = true,
  newClass,
  withGoBack = false,
  isInvestment = false,
  onClickExtended,
  instrument,
}: PropTypes) => {
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();

  const { marketDetail, shortTickerData } = useAppSelector(
    (state: RootState) => state.markets
  );

  const [selectedSchedule, setSelectedSchedule] = useState<SettlementTerms>(
    getSettlementDays(instrument?.term || DEFAULT_TERM)
  );
  const isMarketOpen = checkMarketOpen(Schedules[selectedSchedule]);

  const [isFavorite, setIsFavorite] = useState(
    shortTickerData.some((ticker: InstrumentData) => ticker.isFavorite)
  );
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);

  const isBond =
    instrument &&
    (instrument.instrument_type === Instruments.BONOS_PUBLICOS ||
      instrument.instrument_type === Instruments.BONOS_CORP);

  useEffect(() => {
    if (!shortTickerData) return;
    setIsFavorite(
      shortTickerData.some((ticker: InstrumentData) => ticker.isFavorite)
    );
  }, [shortTickerData]);

  useEffect(() => {
    if (isBond && instrument.instrument_code) {
      dispatch(
        checkFlow({
          instrument_code: instrument.instrument_code,
          invest: false,
        })
      );
    }
  }, [instrument]);

  useEffect(() => {
    if (
      marketDetail &&
      marketDetail.instrument_code === instrument?.instrument_code
    ) {
      setSelectedSchedule(getSettlementDays(marketDetail.term));
    }
  }, [marketDetail]);

  const iconColor = getColor("--darkblue-to-light");

  const user = useAppSelector(getLoggedUser);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const { favoritesLists } = user;

  const handleCalcu = () => {
    const trackProps = {
      ticker: instrument?.long_ticker,
    };
    trackAction(`${screenNames.newOrder} - Open Bond Calculator`, trackProps);
    const typeOrPublicBond =
      (instrument && instrument.instrument_type) ?? Instruments.BONOS_PUBLICOS;
    dispatch(
      getMarketsTypesLists({
        type: typeOrPublicBond,
        currency: undefined,
        settlement_days: SettlementTerms._24,
        subtype:
          typeOrPublicBond === Instruments.BONOS_PUBLICOS ? "BONOSP" : "BONOSC",
      })
    );

    if (isMobile) {
      navigate(
        `${mainRoutes.calculator}?bondType=${typeOrPublicBond}&bond=${instrument?.instrument_code}`
      );
      return;
    }

    searchParams.set("bondType", instrument?.instrument_type as string);
    searchParams.set("bond", instrument?.short_ticker as string);
    setSearchParams(searchParams);

    openRightModalContent(
      <DrawerLayout>
        <BondCalculator />
      </DrawerLayout>
    );
  };

  const handleGoBack = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (goBack) {
      goBack();
    } else navigate(-1);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(closeRightModal());
  };

  const handleToggleFavorite = async (ev: React.MouseEvent) => {
    ev.stopPropagation();
    const trackProps = {
      type: isFavorite ? "remove" : "add",
      ticker: instrument?.long_ticker,
    };
    trackAction(
      `${
        screenNames[
          instrument?.instrument_type === Instruments.FCI ? "fci" : "newOrder"
        ]
      } - Add Favorites`,
      trackProps
    );
    setIsFavorite(!isFavorite);
    if (instrument) {
      await toggleInList(
        !!isFavorite,
        favoritesLists[0].id_list,
        instrument.long_ticker
      );
    }
  };

  return (
    <div className={`${styles.layoutV2} ${newClass && newClass}`}>
      {(quickOperation || isInvestment) && (
        <MarketClosedHeader
          open={!isMarketOpen}
          type={Schedules[selectedSchedule]}
        />
      )}
      {!isMobile && onClickExtended && (
        <div className={styles.expand} onClick={onClickExtended}>
          <ArrowLeftIcon />
          <Typography variant="buttonS" id="expand-layout">
            Expandir
          </Typography>
          <CandleChart size={20} />
        </div>
      )}
      <div className={styles.layoutV2Header}>
        {!withGoBack && !isMobile && (
          <IconButton color="inherit" onClick={handleClose} id="close-layout">
            <IoClose />
          </IconButton>
        )}
        {(withGoBack || isMobile) && (
          <IconButton
            aria-label="menu"
            className="icon-button-header"
            color="inherit"
            edge="start"
            onClick={handleGoBack}
          >
            <IoArrowBack />
          </IconButton>
        )}
        {withSearch ? (
          <InputSearch value={title} quickOperation={quickOperation} />
        ) : (
          <Typography className={styles.titleHeader} variant="heading4">
            {title}
          </Typography>
        )}
        {gotFlow && isBond && (
          <IconButton onClick={handleCalcu}>
            <BsCalculator
              className="icon-calculadora"
              color={getColor("--text-color-primary")}
              size={26}
            />
          </IconButton>
        )}
        {withFavorite &&
          (!isFavorite ? (
            <IconButton
              className="no-favorite"
              id="favorite-star"
              onClick={handleToggleFavorite}
            >
              <TransparentStar size={20} color={iconColor} />
            </IconButton>
          ) : (
            <IconButton
              className="favorite"
              onClick={handleToggleFavorite}
              id="favorite-start-fill"
            >
              <FillStar size={20} color={iconColor} />
            </IconButton>
          ))}
      </div>
      {quickOperation && (
        <TradingProvider>
          <div className={styles.layoutChildren}>{children}</div>
        </TradingProvider>
      )}
      {!quickOperation && (
        <div className={styles.layoutChildren}>{children}</div>
      )}
    </div>
  );
};

export default LayoutV2;
