interface PropTypes {
  size?: number;
  color?: string;
}

export const SearchIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      fill="none"
      height={`${size ? size : 24}`}
      viewBox="0 0 24 24"
      width={`${size ? size : 24}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M17.2 15.8L21.7 20.3C22.1 20.7 22.1 21.3 21.7 21.7C21.5 21.9 21.2 22 21 22C20.8 22 20.5 21.9 20.3 21.7L15.8 17.2C14.3 18.3 12.5 19 10.5 19C5.8 19 2 15.2 2 10.5C2 5.8 5.8 2 10.5 2C15.2 2 19 5.8 19 10.5C19 12.5 18.3 14.4 17.2 15.8ZM10.5 4C6.9 4 4 6.9 4 10.5C4 14.1 6.9 17 10.5 17C12.3 17 13.9 16.3 15.1 15.1C16.3 13.9 17 12.3 17 10.5C17 6.9 14.1 4 10.5 4Z"
        fill={color || "#002C65"}
        fillRule="evenodd"
      />
      <mask
        height="20"
        id="mask0_739_24476"
        maskUnits="userSpaceOnUse"
        width="20"
        x="2"
        y="2"
      >
        <path
          clipRule="evenodd"
          d="M17.2 15.8L21.7 20.3C22.1 20.7 22.1 21.3 21.7 21.7C21.5 21.9 21.2 22 21 22C20.8 22 20.5 21.9 20.3 21.7L15.8 17.2C14.3 18.3 12.5 19 10.5 19C5.8 19 2 15.2 2 10.5C2 5.8 5.8 2 10.5 2C15.2 2 19 5.8 19 10.5C19 12.5 18.3 14.4 17.2 15.8ZM10.5 4C6.9 4 4 6.9 4 10.5C4 14.1 6.9 17 10.5 17C12.3 17 13.9 16.3 15.1 15.1C16.3 13.9 17 12.3 17 10.5C17 6.9 14.1 4 10.5 4Z"
          fill={color || "white"}
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_739_24476)">
        <rect fill={color || "#002C65"} height="24" width="24" />
      </g>
    </svg>
  );
};
