import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { portfolioService } from "apis/services";
import type { ErrorMessage } from "interfaces/errors";
import {
  PortfolioHistoryMetric,
  PeriodTime,
  PortfolioHistoryResponse,
} from "interfaces/portfolio";
import type { BalanceCurrencies } from "interfaces/wallet";

export const fetchPortfolioHistoryData = async (
  currency: BalanceCurrencies,
  period: PeriodTime
) => {
  const { data } = await API.get(portfolioService.history, {
    params: { metric: PortfolioHistoryMetric.BALANCE, period, currency },
  });

  if (!data || !data.data || data.data.length === 0) {
    throw new Error("No historic data available");
  }

  return data;
};

export const usePortfolioHistoryQuery = (
  currency: BalanceCurrencies,
  period: PeriodTime
) => {
  return useAppQuery<PortfolioHistoryResponse, ErrorMessage>({
    queryKey: () => portfolioKeys.history(currency, period),
    queryFn: () => fetchPortfolioHistoryData(currency, period),
    options: {
      onError: (error: any) => {
        return {
          message: error,
          status: 500,
        };
      },
    },
  });
};
