import Error from "assets/illustrations/Error";
import BasicWrapper from "components/common/BasicWrapper";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";

import styles from "./styles.module.scss";

interface PortfolioErrorProps {
  onCloseError: () => void;
  variant: ThemeVariants;
}

const PortfolioError: React.FC<PortfolioErrorProps> = ({
  onCloseError,
  variant,
}) => {
  return (
    <BasicWrapper
      themeVariant={variant}
      navigationHeaderProps={{
        title: "Algo salió mal",
        onClick: onCloseError,
        withCloseIcon: true,
      }}
      primaryButtonProps={{
        children: "Volver al inicio",
        onClick: onCloseError,
      }}
    >
      <div className={styles.errorWrapper}>
        <Error />
        <Text
          color="var(--slate800)"
          className={styles.text}
          variant={TextVariants.RegularText}
        >
          Hubo un error al momento de procesar los datos de tu portfolio. Por
          favor, volvé a intentar más tarde.
        </Text>
      </div>
    </BasicWrapper>
  );
};

export default PortfolioError;
