import { useState } from "react";

import {
  Card,
  CardActions,
  CardContent,
  Collapse,
  IconButton,
  IconButtonProps,
  Typography,
  styled,
} from "@mui/material";
import { MdExpandMore } from "react-icons/md";

import styles from "./styles.module.scss";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface UIDropdownCardProps {
  children: React.ReactNode[];
  expandLabel?: boolean;
  trackFunction?: (v: boolean) => void;
}

const UIDropdownCard: React.FC<UIDropdownCardProps> = ({
  children,
  expandLabel = false,
  trackFunction,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    trackFunction && trackFunction(expanded);
    setExpanded(!expanded);
  };

  return (
    <Card id={styles.card}>
      <CardContent className={styles.cardContent}>{children[0]}</CardContent>
      <Collapse
        className={styles.extendedContent}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        {children[1]}
      </Collapse>
      <CardActions className={styles.cardActions}>
        <div
          className={styles.viewMoreButton}
          id={`show-${expanded ? "less" : "more"}`}
          onClick={handleExpandClick}
        >
          {expandLabel && (
            <Typography>Ver {expanded ? "menos" : "más"}</Typography>
          )}
          <ExpandMore
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
            className="expand"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardActions>
    </Card>
  );
};

export default UIDropdownCard;
