import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";

import CardCarrousel from "./CardCarrousel";

import styles from "./styles.module.scss";

const HowToInvest: React.FC = () => {
  return (
    <div className={styles.howToInvestContainer}>
      <div className={styles.greenOverlay} />
      <div className={styles.purpleOverlay} />
      <div className={styles.wrapper}>
        <Heading
          variant={HeadingVariants.RegularTitle}
          color="var(--slate900)"
          component="h3"
        >
          Te enseñamos a invertir
        </Heading>
        <Text
          className={styles.detail}
          variant={TextVariants.RegularTextS}
          color="var(--slate800)"
        >
          Porque creemos que invertir es para todos y que la educación es el
          medio para alcanzarlo.
        </Text>
      </div>
      <div className={styles.gradientRectangle}>
        <CardCarrousel />
      </div>
    </div>
  );
};

export default HowToInvest;
