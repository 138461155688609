import { useState, useEffect } from "react";

import { Typography } from "@mui/material";
import { usePrevious } from "hooks/usePrevious";
import { getFormattedNumber } from "utils";

interface InstrumentLastPriceProps {
  last: number | null;
  close?: number | null;
  idTickSizeRule?: string | null;
  tickSize?: number | null;
}

const InstrumentLastPrice: React.FC<InstrumentLastPriceProps> = ({
  last,
  close,
  idTickSizeRule,
  tickSize,
}) => {
  const [highlightClass, setHighlightClass] = useState<string>();
  const prevPrice = usePrevious(last);

  useEffect(() => {
    if (!last) return;
    if (prevPrice && prevPrice < last) {
      setHighlightClass("price-highlight up");
    }
    if (prevPrice && prevPrice > last) {
      setHighlightClass("price-highlight down");
    }

    setTimeout(() => {
      setHighlightClass("price-highlight");
    }, 400);

    return () => {
      setHighlightClass(undefined);
    };
  }, [last]);

  return (
    <div className="grid-col last-price">
      <div className={highlightClass || "price-highlight"}>
        <div className="price">
          <Typography variant="textXS">
            {getFormattedNumber(last || close, idTickSizeRule, tickSize)}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default InstrumentLastPrice;
