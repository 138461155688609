import React, { useEffect, useRef, useState } from "react";

import { IconButton, TextField } from "@mui/material";
import { searchTicker } from "apis/api-calls";
import { SearchIcon } from "assets/icons/search-icon";
import { useOutsideClick } from "hooks/useOutsideClick";
import { InstrumentData, SearchListResponse } from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import { getColor } from "utils/getColor";

import SearchResultList from "./components/result-list";

import styles from "./styles.module.scss";

interface PropTypes {
  value: string;
  quickOperation?: boolean;
}

let timerId: NodeJS.Timeout;

const InputSearch = (props: PropTypes) => {
  const { value, quickOperation = false } = props;
  const initialValue = value;

  const [newValue, setNewValue] = useState<string>(value);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<SearchListResponse[]>([]);
  const abortControllerRef = useRef<AbortController>();

  const getOptions = (signal: AbortSignal) => {
    const searchResponse = searchTicker(newValue, signal);
    searchResponse
      .then((response: any) => {
        setOpen(true);
        if (response) {
          if (quickOperation) {
            const instrument_types = [
              Instruments.ACCIONES,
              Instruments.BONOS_CORP,
              Instruments.BONOS_PUBLICOS,
              Instruments.CEDEARS,
              Instruments.LETRAS,
            ];
            const filters = response.data.filter((l: any) =>
              instrument_types.includes(l.instrument_type)
            );
            setList([...filters]);
          } else {
            setList([...response.data]);
          }
        } else {
          setList([]);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== "AbortError") {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (newValue !== initialValue) {
      clearTimeout(timerId);

      // Abort any pending requests
      if (abortControllerRef.current) {
        setLoading(false);
        abortControllerRef.current.abort();
      }

      // Create new abort controller
      const newAbortController = new AbortController();
      abortControllerRef.current = newAbortController;

      if (searchTicker.length > 1) {
        setLoading(true);
        timerId = setTimeout(function () {
          getOptions(newAbortController.signal);
          clearTimeout(timerId);
        }, 500);
      }
    }

    // Clean up
    return () => {
      // Abort any pending requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [newValue]);

  const ref: React.RefObject<HTMLDivElement> = useOutsideClick(
    () => setOpen(false),
    open
  );

  const textFieldRef: React.RefObject<HTMLDivElement> = React.createRef();

  const onChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewValue(event.target.value.toUpperCase());
    if (event.target.value === "") setOpen(false);
  };

  const handleOnClickRow = (ticker: InstrumentData) => {
    setNewValue(ticker.short_ticker ?? "");
    setOpen(false);
  };

  return (
    <div ref={ref} className={styles.searchV2}>
      {open && (
        <div className={styles.listContainer}>
          <SearchResultList
            searchTicker={newValue}
            list={list}
            onClickRow={handleOnClickRow}
            loading={loading}
            quickOperation={quickOperation}
          />
        </div>
      )}
      <TextField
        value={newValue}
        variant="standard"
        InputLabelProps={{
          shrink: false,
        }}
        InputProps={{
          disableUnderline: true,
        }}
        inputRef={textFieldRef}
        onChange={onChangeSearchValue}
        id="search-input"
      />
      <IconButton
        className="search"
        onClick={() => textFieldRef.current?.focus()}
      >
        <SearchIcon size={20} color={getColor("--darkblue-to-light")} />
      </IconButton>
    </div>
  );
};

export default InputSearch;
