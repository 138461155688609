import { SVGProps } from "react";

const NothingHere: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="204"
      height="174"
      viewBox="0 0 204 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_218_24)">
        <path
          opacity="0.1"
          d="M203.065 119.968C197.493 91.858 164.85 69.3915 158.483 40.0988C146.911 2.83053 108.771 -3.06165 83.9858 13.1239C44.7145 38.7702 86.6088 45.1345 9.61049 105.812C-9.40214 120.794 -3.34386 186.931 56.4688 171.753C88.6573 163.584 98.4425 162.438 112.921 161.093C149.415 157.704 212.417 167.154 203.065 119.968Z"
          fill="#002C65"
        />
        <path
          d="M159.468 127.013L103.064 156.818L46.7439 127.013V82.0063H159.468V127.013Z"
          fill="white"
        />
        <path
          d="M103.064 157.275C102.989 157.275 102.915 157.256 102.85 157.221L46.5319 127.417C46.4586 127.378 46.3973 127.32 46.3544 127.25C46.3115 127.179 46.2887 127.098 46.2884 127.015V82.0064C46.2884 81.8851 46.3366 81.7688 46.4222 81.6831C46.5079 81.5974 46.6242 81.5492 46.7453 81.5492H159.468C159.59 81.5492 159.706 81.5974 159.791 81.6831C159.877 81.7688 159.925 81.8851 159.925 82.0064V127.013C159.925 127.096 159.902 127.177 159.859 127.248C159.816 127.319 159.755 127.377 159.682 127.415L103.277 157.22C103.211 157.256 103.138 157.274 103.064 157.275ZM47.2008 126.739L103.064 156.301L159.011 126.738V82.4635H47.2008V126.739Z"
          fill="#002C65"
        />
        <path
          d="M159.468 72.3485L103.064 42.5442L128.41 30.3214L184.814 60.1261L159.468 72.3485Z"
          fill="white"
        />
        <path
          d="M159.468 72.8057C159.394 72.8057 159.321 72.7876 159.255 72.753L102.85 42.9483C102.775 42.9088 102.713 42.8492 102.67 42.7764C102.627 42.7035 102.605 42.6202 102.607 42.5356C102.608 42.451 102.633 42.3686 102.679 42.2973C102.724 42.2261 102.789 42.1689 102.865 42.1321L128.211 29.9093C128.276 29.878 128.346 29.8624 128.418 29.8637C128.49 29.8651 128.56 29.8833 128.623 29.9169L185.028 59.7216C185.103 59.7611 185.165 59.8206 185.208 59.8934C185.251 59.9663 185.273 60.0496 185.271 60.1342C185.27 60.2187 185.245 60.3012 185.199 60.3724C185.154 60.4436 185.089 60.5007 185.013 60.5375L159.667 72.7603C159.605 72.7902 159.537 72.8057 159.468 72.8057ZM104.077 42.5622L159.478 71.8358L183.801 60.1067L128.4 30.8338L104.077 42.5622Z"
          fill="#002C65"
        />
        <path
          d="M46.7439 72.3485L103.149 42.5442L77.8025 30.3214L21.3977 60.1261L46.7439 72.3485Z"
          fill="white"
        />
        <path
          d="M46.7439 72.8057C46.6751 72.8057 46.6073 72.7902 46.5454 72.7603L21.2007 60.5375C21.1246 60.5007 21.0601 60.4436 21.0145 60.3724C20.9689 60.3012 20.9439 60.2187 20.9424 60.1342C20.9408 60.0496 20.9627 59.9663 21.0057 59.8934C21.0487 59.8206 21.111 59.7611 21.1857 59.7216L77.5905 29.9169C77.6537 29.8833 77.724 29.8651 77.7956 29.8637C77.8672 29.8624 77.938 29.878 78.0025 29.9093L103.349 42.1321C103.425 42.1689 103.489 42.2261 103.535 42.2973C103.58 42.3686 103.605 42.451 103.607 42.5356C103.608 42.6202 103.587 42.7035 103.544 42.7764C103.501 42.8492 103.438 42.9088 103.364 42.9483L46.9588 72.753C46.8925 72.7879 46.8187 72.806 46.7439 72.8057ZM22.4113 60.1074L46.7344 71.8366L102.135 42.5629L77.812 30.8338L22.4113 60.1074Z"
          fill="#002C65"
        />
        <path
          d="M46.7439 72.3485L103.149 102.153L74.1472 118.033L17.7424 88.2284L46.7439 72.3485Z"
          fill="white"
        />
        <path
          d="M74.1472 118.49C74.0728 118.49 73.9995 118.472 73.9338 118.437L17.529 88.6321C17.4561 88.5935 17.3951 88.5359 17.3524 88.4653C17.3096 88.3948 17.2867 88.3141 17.2861 88.2316C17.2855 88.1491 17.3072 88.068 17.349 87.9969C17.3907 87.9258 17.4508 87.8673 17.5231 87.8276L46.5246 71.9477C46.5908 71.9114 46.665 71.8921 46.7406 71.8916C46.8161 71.891 46.8906 71.9093 46.9573 71.9448L103.362 101.749C103.435 101.788 103.496 101.846 103.539 101.916C103.581 101.987 103.604 102.068 103.605 102.15C103.606 102.232 103.584 102.314 103.542 102.385C103.5 102.456 103.44 102.514 103.368 102.554L74.3665 118.434C74.2993 118.471 74.2239 118.49 74.1472 118.49ZM18.7074 88.2211L74.1432 117.514L102.184 102.16L46.7479 72.8675L18.7074 88.2211Z"
          fill="#002C65"
        />
        <path
          d="M159.468 72.3485L103.064 102.153L132.065 118.033L188.47 88.2284L159.468 72.3485Z"
          fill="white"
        />
        <path
          d="M132.066 118.49C131.989 118.49 131.914 118.471 131.846 118.434L102.845 102.554C102.773 102.514 102.712 102.456 102.671 102.385C102.629 102.314 102.607 102.232 102.608 102.15C102.609 102.068 102.631 101.987 102.674 101.916C102.717 101.846 102.778 101.788 102.851 101.749L159.256 71.9448C159.322 71.9093 159.397 71.891 159.472 71.8916C159.548 71.8921 159.622 71.9114 159.688 71.9477L188.69 87.8276C188.762 87.8673 188.822 87.9258 188.864 87.9969C188.906 88.068 188.927 88.1491 188.927 88.2316C188.926 88.3141 188.903 88.3948 188.861 88.4653C188.818 88.5359 188.757 88.5935 188.684 88.6321L132.279 118.437C132.213 118.472 132.14 118.49 132.066 118.49ZM104.03 102.16L132.07 117.514L187.506 88.2211L159.465 72.8675L104.03 102.16Z"
          fill="#002C65"
        />
        <path
          d="M103.149 42.5443L46.7439 72.3486L103.064 102.153L159.468 72.3486L103.149 42.5443Z"
          fill="#002C65"
        />
        <path
          d="M103.52 102.153H102.607V156.818H103.52V102.153Z"
          fill="#002C65"
        />
        <path
          d="M71.461 69.6427C71.3853 69.6428 71.3116 69.6191 71.25 69.5751C71.1709 69.519 71.1173 69.4338 71.101 69.3382C71.0847 69.2426 71.107 69.1444 71.163 69.0653C71.2307 68.9698 77.8369 59.4148 70.462 50.7038C69.1567 49.154 67.5284 47.9085 65.6913 47.0547C63.8541 46.2008 61.8526 45.7593 59.8269 45.761H59.7275C60.4278 48.1951 60.3028 50.3593 59.3111 52.0196C58.8886 52.7698 58.265 53.3871 57.5107 53.8019C56.7564 54.2167 55.9013 54.4126 55.0417 54.3674C54.3302 54.3091 53.6511 54.0444 53.0877 53.6059C52.5242 53.1673 52.1008 52.5738 51.8693 51.8982C51.0582 49.7815 51.9059 47.7292 54.14 46.4064C55.5553 45.6256 57.1279 45.173 58.7416 45.0822C58.2167 43.6897 57.534 42.362 56.7071 41.125C54.0442 37.0562 49.1911 34.2395 43.3916 33.3973C38.4767 32.6838 33.7665 33.5242 31.3902 35.5378C31.3162 35.6004 31.2203 35.6311 31.1237 35.623C31.0271 35.615 30.9376 35.5689 30.875 35.4948C30.8123 35.4207 30.7817 35.3248 30.7897 35.2282C30.7978 35.1315 30.8439 35.042 30.9179 34.9793C33.4467 32.8367 38.3839 31.9309 43.4965 32.6754C49.5033 33.548 54.541 36.482 57.319 40.726C58.2196 42.0719 58.9522 43.5231 59.5005 45.0471C61.6857 44.9946 63.8548 45.4332 65.8481 46.3307C67.8414 47.2281 69.6081 48.5616 71.0179 50.2327C78.7646 59.3822 71.8283 69.39 71.7574 69.4902C71.7236 69.5373 71.6791 69.5757 71.6276 69.6022C71.576 69.6288 71.5189 69.6426 71.461 69.6427ZM58.9789 45.7876C57.4143 45.8544 55.8863 46.2817 54.5139 47.0362C52.5854 48.1779 51.8711 49.855 52.5536 51.6367C52.7385 52.1826 53.0784 52.6628 53.5318 53.0184C53.9852 53.3741 54.5324 53.5898 55.1064 53.6393C55.8292 53.6716 56.5467 53.5023 57.1788 53.15C57.8108 52.7978 58.3324 52.2766 58.6853 51.6447C59.5955 50.1197 59.6789 48.0927 58.9778 45.7876H58.9789Z"
          fill="#292A2E"
        />
        <path
          d="M25.0815 41.434C24.4603 41.6207 23.7909 41.5574 23.2156 41.2576C22.6404 40.9579 22.2049 40.4453 22.0017 39.8291C21.7986 39.2128 21.844 38.5416 22.1281 37.9583C22.4123 37.375 22.9128 36.9257 23.5231 36.7061C24.1334 36.4865 24.8053 36.5139 25.3957 36.7825C25.9861 37.0511 26.4484 37.5397 26.6841 38.1442C26.9198 38.7488 26.9104 39.4215 26.6577 40.0191C26.4051 40.6168 25.9293 41.0921 25.3315 41.344C25.2499 41.3785 25.1664 41.4086 25.0815 41.434ZM23.8661 37.3703C23.8069 37.3881 23.7487 37.4091 23.6918 37.4332C23.2712 37.6106 22.9365 37.9453 22.7589 38.366C22.5813 38.7866 22.5749 39.26 22.741 39.6854C22.9072 40.1107 23.2327 40.4543 23.6483 40.6431C24.064 40.8318 24.5368 40.8507 24.9662 40.6958C25.3956 40.5409 25.7475 40.2244 25.9471 39.8138C26.1467 39.4031 26.1781 38.9307 26.0347 38.4972C25.8913 38.0636 25.5844 37.7032 25.1793 37.4928C24.7743 37.2823 24.3031 37.2384 23.8661 37.3703Z"
          fill="#292A2E"
        />
        <path
          d="M25.0771 35.7034C24.4895 35.879 23.8578 35.831 23.3035 35.5687C22.7491 35.3065 22.3112 34.8484 22.0739 34.2827C21.8837 33.8291 21.8322 33.3293 21.9259 32.8465C22.0197 32.3636 22.2544 31.9195 22.6004 31.5701C22.9465 31.2207 23.3883 30.9819 23.8701 30.8837C24.3518 30.7856 24.8518 30.8326 25.3069 31.0188C25.7619 31.205 26.1516 31.522 26.4265 31.9298C26.7014 32.3375 26.8494 32.8177 26.8515 33.3096C26.8537 33.8014 26.71 34.2829 26.4387 34.693C26.1674 35.1032 25.7805 35.4236 25.3272 35.6138C25.2454 35.648 25.162 35.6779 25.0771 35.7034ZM23.8632 31.6394C23.8033 31.6574 23.7444 31.6785 23.6867 31.7027C23.2578 31.8829 22.9181 32.226 22.7421 32.6567C22.5661 33.0875 22.5682 33.5705 22.748 33.9996C22.8791 34.3125 23.098 34.5806 23.3783 34.7715C23.6585 34.9625 23.988 35.0682 24.327 35.0757C24.666 35.0832 24.9999 34.9924 25.2883 34.814C25.5768 34.6357 25.8074 34.3776 25.9523 34.0709C26.0972 33.7642 26.1501 33.4221 26.1048 33.0859C26.0594 32.7497 25.9176 32.4339 25.6966 32.1766C25.4756 31.9194 25.1849 31.7317 24.8595 31.6363C24.5341 31.5409 24.1881 31.5422 23.8632 31.6394Z"
          fill="#292A2E"
        />
        <path
          d="M24.3676 38.2897C26.1627 38.2897 27.6179 37.3173 27.6179 36.1178C27.6179 34.9183 26.1627 33.9459 24.3676 33.9459C22.5726 33.9459 21.1173 34.9183 21.1173 36.1178C21.1173 37.3173 22.5726 38.2897 24.3676 38.2897Z"
          fill="white"
        />
        <path
          d="M25.8064 38.4495C25.3404 38.5873 24.8568 38.6568 24.3709 38.6557C22.343 38.6557 20.7522 37.544 20.7522 36.1211C20.7522 34.6981 22.3382 33.5824 24.3654 33.5802C25.2493 33.5632 26.1188 33.8047 26.8675 34.275C27.2037 34.4502 27.4855 34.7143 27.6823 35.0385C27.8791 35.3628 27.9833 35.7347 27.9836 36.114C27.984 36.4933 27.8804 36.8655 27.6842 37.1901C27.488 37.5146 27.2067 37.7792 26.8708 37.9551C26.5424 38.1717 26.1837 38.3383 25.8064 38.4495ZM23.1519 34.4856C22.1752 34.7782 21.4829 35.4098 21.4832 36.1192C21.4832 37.0982 22.8054 37.9243 24.3709 37.9229C25.1105 37.9384 25.8385 37.7382 26.4661 37.3465C26.7015 37.2381 26.9009 37.0643 27.0405 36.8459C27.1802 36.6275 27.2543 36.3736 27.2541 36.1143C27.2538 35.855 27.1793 35.6013 27.0392 35.3831C26.8992 35.165 26.6995 34.9916 26.4639 34.8835C25.8359 34.4927 25.1075 34.2937 24.368 34.3108C23.9563 34.3098 23.5466 34.3687 23.1519 34.4856Z"
          fill="#292A2E"
        />
        <path
          d="M25.1649 38.2235L25.0881 33.9996L24.0383 33.9572L23.9648 38.2729L25.1649 38.2235Z"
          fill="#292A2E"
        />
        <path
          d="M20.0555 37.0368C20.2408 37.0368 20.391 36.8865 20.391 36.7011C20.391 36.5157 20.2408 36.3654 20.0555 36.3654C19.8702 36.3654 19.7199 36.5157 19.7199 36.7011C19.7199 36.8865 19.8702 37.0368 20.0555 37.0368Z"
          fill="#292A2E"
        />
        <path
          d="M20.3176 35.2405C20.5029 35.2405 20.6531 35.0902 20.6531 34.9047C20.6531 34.7193 20.5029 34.569 20.3176 34.569C20.1323 34.569 19.982 34.7193 19.982 34.9047C19.982 35.0902 20.1323 35.2405 20.3176 35.2405Z"
          fill="#292A2E"
        />
        <path
          d="M128.895 48.5527C128.816 48.5527 128.74 48.5276 128.677 48.4811C128.615 48.4345 128.568 48.369 128.545 48.2942C127.333 43.7645 127.009 39.0427 127.593 34.3898C128.538 27.5878 132.287 18.6607 144.784 14.7517C146.542 14.1861 148.345 13.7724 150.174 13.5152C150.763 9.83591 153.003 5.84465 158.013 3.09092C169.915 -3.45221 177.955 2.41839 178.035 2.47837C178.112 2.53656 178.163 2.62319 178.177 2.71921C178.191 2.81522 178.166 2.91276 178.108 2.99035C178.05 3.06794 177.963 3.11924 177.867 3.13295C177.771 3.14667 177.674 3.12168 177.596 3.06349C177.52 3.00644 169.837 -2.57453 158.365 3.73236C153.684 6.3058 151.541 9.99938 150.932 13.4282C156.444 12.8851 159.083 14.7246 160.268 16.1139C161.001 16.9494 161.472 17.9821 161.622 19.0835C161.773 20.1849 161.596 21.3063 161.114 22.3078C160.733 23.1173 160.116 23.7922 159.343 24.2433C158.571 24.6944 157.68 24.9005 156.788 24.8344C153.71 24.6538 151.162 22.0631 150.299 18.2342C150.014 16.9381 149.938 15.6047 150.075 14.2847C148.354 14.5319 146.658 14.9223 145.002 15.4516C135.263 18.4983 129.65 24.905 128.316 34.4926C127.745 39.0398 128.06 43.6548 129.244 48.0821C129.261 48.1367 129.265 48.1946 129.255 48.251C129.245 48.3073 129.223 48.3606 129.189 48.4066C129.155 48.4526 129.11 48.49 129.059 48.5157C129.008 48.5415 128.952 48.5549 128.895 48.5549V48.5527ZM150.82 14.1932C150.668 15.4882 150.733 16.7995 151.012 18.073C151.802 21.5757 154.086 23.9428 156.831 24.1041C157.579 24.1577 158.326 23.9839 158.973 23.6054C159.621 23.2269 160.139 22.6614 160.46 21.9831C160.878 21.1097 161.029 20.1326 160.896 19.1735C160.763 18.2144 160.351 17.3157 159.711 16.5893C158.002 14.5827 154.883 13.7723 150.82 14.1932Z"
          fill="#292A2E"
        />
        <path
          d="M186.549 6.17451C186.057 6.17348 185.575 6.03318 185.159 5.76982C184.743 5.50646 184.41 5.13077 184.198 4.68611C183.986 4.24144 183.904 3.74593 183.962 3.25676C184.02 2.76759 184.215 2.30471 184.524 1.92153C184.833 1.53836 185.245 1.25051 185.71 1.0912C186.176 0.931904 186.678 0.907645 187.157 1.02123C187.636 1.13481 188.073 1.38161 188.418 1.73313C188.763 2.08466 189.001 2.52658 189.106 3.0079C189.188 3.38945 189.183 3.78449 189.093 4.16411C189.002 4.54374 188.828 4.89832 188.583 5.20191C188.338 5.5055 188.028 5.7504 187.676 5.91869C187.324 6.08697 186.939 6.17438 186.549 6.17451ZM186.555 1.68223C186.166 1.68222 185.787 1.80286 185.469 2.02751C185.152 2.25216 184.912 2.56977 184.783 2.93656C184.653 3.30334 184.641 3.70126 184.747 4.07546C184.853 4.44965 185.073 4.78171 185.375 5.02586C185.678 5.27002 186.049 5.41424 186.437 5.43867C186.825 5.46309 187.211 5.36651 187.542 5.16223C187.872 4.95795 188.132 4.65604 188.284 4.2981C188.436 3.94016 188.474 3.54381 188.391 3.16369C188.302 2.74312 188.071 2.36615 187.736 2.09629C187.402 1.82644 186.984 1.6802 186.555 1.68223Z"
          fill="#292A2E"
        />
        <path
          d="M185.449 12.1431C184.779 12.1431 184.134 11.8861 183.648 11.425C183.162 10.9639 182.871 10.3339 182.835 9.66457C182.799 8.99527 183.021 8.33765 183.455 7.82707C183.889 7.3165 184.502 6.99184 185.168 6.91993C185.834 6.84801 186.502 7.03432 187.035 7.44049C187.568 7.84667 187.925 8.4418 188.033 9.10336C188.14 9.76493 187.99 10.4426 187.614 10.9968C187.237 11.5511 186.662 11.9397 186.008 12.0828C185.824 12.1229 185.637 12.1431 185.449 12.1431ZM185.458 7.65047C185.323 7.65053 185.188 7.66512 185.056 7.69399C184.589 7.79659 184.179 8.07432 183.91 8.47006C183.642 8.86579 183.535 9.34941 183.612 9.82143C183.69 10.2934 183.945 10.7179 184.325 11.0076C184.705 11.2972 185.182 11.43 185.658 11.3786C186.133 11.3271 186.57 11.0954 186.88 10.731C187.19 10.3667 187.348 9.89747 187.322 9.41988C187.297 8.94229 187.089 8.49267 186.742 8.16354C186.396 7.83441 185.936 7.65079 185.458 7.65047Z"
          fill="#292A2E"
        />
        <path
          d="M189.374 7.24289C189.605 5.99378 188.276 4.7014 186.407 4.3563C184.537 4.0112 182.835 4.74406 182.605 5.99317C182.375 7.24228 183.703 8.53464 185.572 8.87974C187.442 9.22484 189.144 8.492 189.374 7.24289Z"
          fill="white"
        />
        <path
          d="M186.493 9.33124C186.162 9.3307 185.832 9.3001 185.506 9.23981C184.587 9.08722 183.732 8.66959 183.046 8.03812C182.746 7.78199 182.516 7.4543 182.376 7.08536C182.236 6.71642 182.191 6.31812 182.246 5.92729C182.334 5.54272 182.518 5.18667 182.78 4.89201C183.043 4.59736 183.375 4.3736 183.747 4.24141C184.616 3.91285 185.559 3.82838 186.473 3.99713C187.387 4.16587 188.238 4.58142 188.933 5.19881C189.233 5.45494 189.463 5.78263 189.603 6.15157C189.743 6.52051 189.788 6.91881 189.733 7.30964C189.645 7.69421 189.461 8.05026 189.199 8.34492C188.936 8.63957 188.604 8.86333 188.232 8.99552C187.681 9.22373 187.089 9.33793 186.493 9.33124ZM185.483 4.63636C184.987 4.63005 184.495 4.72421 184.036 4.9132C183.776 5.00018 183.542 5.15061 183.355 5.35086C183.168 5.55111 183.034 5.79485 182.965 6.06004C182.935 6.33243 182.973 6.60802 183.076 6.86184C183.179 7.11566 183.344 7.33971 183.556 7.5137C184.147 8.03 184.866 8.37763 185.638 8.52015C186.41 8.66267 187.206 8.59482 187.942 8.32373C188.202 8.23675 188.436 8.08632 188.623 7.88607C188.81 7.68582 188.944 7.44207 189.013 7.17689C189.044 6.90448 189.005 6.62883 188.902 6.37498C188.799 6.12112 188.634 5.89709 188.422 5.72323C187.84 5.19214 187.116 4.84228 186.338 4.71645C186.057 4.664 185.771 4.63719 185.485 4.63636H185.483Z"
          fill="#292A2E"
        />
        <path
          d="M185.567 4.27213L184.832 8.68468L185.917 8.93189L186.826 4.45242L185.567 4.27213Z"
          fill="#292A2E"
        />
        <path
          d="M189.974 9.0193C190.17 9.0193 190.329 8.86016 190.329 8.66384C190.329 8.46753 190.17 8.30838 189.974 8.30838C189.778 8.30838 189.619 8.46753 189.619 8.66384C189.619 8.86016 189.778 9.0193 189.974 9.0193Z"
          fill="#292A2E"
        />
        <path
          d="M190.593 7.19957C190.789 7.19957 190.948 7.04043 190.948 6.84411C190.948 6.64779 190.789 6.48865 190.593 6.48865C190.397 6.48865 190.238 6.64779 190.238 6.84411C190.238 7.04043 190.397 7.19957 190.593 7.19957Z"
          fill="#292A2E"
        />
      </g>
      <defs>
        <clipPath id="clip0_218_24">
          <rect width="204" height="174" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NothingHere;
