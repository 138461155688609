export enum Steps {
  INTRO,
  DNI_FRONT,
  DNI_BACK,
  MANUAL_INPUT,
  SELFIE,
  PERSONAL_INFO,
  FINAL,
}

export enum StatusOnboarding {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  FINISHED = "FINISHED",
}
