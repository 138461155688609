import { ReactNode } from "react";

import classNames from "classnames";

import styles from "./styles.module.scss";

interface FixedHeaderProps {
  children: ReactNode;
  className?: string;
}

const FixedHeader = ({ children, className }: FixedHeaderProps) => {
  return (
    <div className={classNames(styles.fixedHeader, className)}>{children}</div>
  );
};

export default FixedHeader;
