interface PropTypes {
  size?: number;
  color?: string;
}

export const CandleChart = ({ color, size }: PropTypes) => {
  return (
    <svg
      fill="none"
      className="candle-chart"
      height={`${size ? size : 24}`}
      viewBox="0 0 24 24"
      width={`${size ? size : 24}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.27273 4H3V15.4545H6.27273V4Z"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M13.6363 10.5454H10.3635V17.0909H13.6363V10.5454Z"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M21 8.90918H17.7273V20.3637H21V8.90918Z"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M4.63647 15.4546V20.3637"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 17.0908V20.3635"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M12 10.5455V6.45459"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
      <path
        d="M19.3635 8.90909V4"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
