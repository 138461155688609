import { Instruments, InstrumentTypeAndSubtypes } from "interfaces/markets";

export const INSTRUMENTS: InstrumentTypeAndSubtypes[] = [
  {
    instrument_type: Instruments.CEDEARS,
    type_code: "CE",
    name: "Cedears",
    subtypes: [
      { name: "Top Cedears", code: "TOP" },
      { name: "Cedears Crypto", code: "CRYPTO" },
      { name: "Otros Cedears", code: "OTROS" },
    ],
  },
  {
    instrument_type: Instruments.ACCIONES,
    type_code: "AC",
    name: "Acciones",
    subtypes: [
      { name: "Panel líder", code: "LIDERES" },
      { name: "Panel General", code: "GENERAL" },
    ],
  },
  {
    instrument_type: Instruments.BONOS_PUBLICOS,
    type_code: "BO",
    name: "Bonos públicos",
    subtypes: [
      { name: "Bonos soberanos US$", code: "NACIONALES_USD" },
      { name: "Bonos soberanos AR$", code: "NACIONALES_ARS" },
      { name: "Bonos provinciales", code: "PROVINCIALES" },
    ],
  },
  {
    instrument_type: Instruments.BONOS_CORP,
    type_code: "ON",
    name: "Obligaciones negociables",
    subtypes: [{ name: "Obligaciones Negociables", code: "TOP" }],
  },
  {
    instrument_type: Instruments.LETRAS,
    type_code: "LT",
    name: "Letras",
    subtypes: [
      { name: "Tasa fija", code: "TASA_FIJA" },
      { name: "Ajuste por CER", code: "CER" },
    ],
  },
  {
    instrument_type: Instruments.CAUCION,
    type_code: "CA",
    name: "Caución",
    subtypes: null,
  },
  {
    instrument_type: Instruments.FCI,
    type_code: "FC",
    name: "Fondos de Inversión",
    subtypes: [{ name: "FCI Personas Fisicas", code: "PF" }],
  },
];

export const getFirstSubtypeCode = (instrument?: Instruments) => {
  const foundInstrument = INSTRUMENTS.find(
    ({ instrument_type }) => instrument_type === instrument
  );

  if (!foundInstrument || !foundInstrument.subtypes) return null;

  return foundInstrument.subtypes[0].code;
};

export const getSubtypes = (instrument: Instruments) => {
  const foundInstrument = INSTRUMENTS.find(
    ({ instrument_type }) => instrument_type === instrument
  );

  if (!foundInstrument || !foundInstrument.subtypes) return null;

  return foundInstrument.subtypes;
};

export const getInstrumentName = (instrument: Instruments) => {
  const foundInstrument = INSTRUMENTS.find(
    ({ instrument_type }) => instrument_type === instrument
  );

  return foundInstrument?.name;
};

export enum CocosFunds {
  USD = "COCOAUSD",
  RMA = "COCORMA",
  AHORRO = "COCOA",
}
