import React, { useEffect, useState } from "react";

import { Device } from "@capacitor/device";
import AppStore from "assets/icons/AppStore";
import GooglePlay from "assets/icons/GooglePlay";
import DownloadQR from "assets/icons/DownloadQR";
import Text, { TextVariants } from "components/common/Text";

import styles from "./styles.module.scss";

interface DownloadButtonProps {
  text?: string;
  textColor?: string;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  text = "Descargá la app",
  textColor = "var(--blue900)",
}) => {
  const [
    buttonComponent,
    setButtonComponent,
  ] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    fetchDownloadButton();
  }, []);

  const fetchDownloadButton = async () => {
    const { operatingSystem } = await Device.getInfo();

    if (operatingSystem === "android") {
      return setButtonComponent(
        <a href="https://play.google.com/store/apps/details?id=capital.cocos.app.twa">
          <GooglePlay />
        </a>
      );
    }

    if (operatingSystem === "ios") {
      return setButtonComponent(
        <a href="https://apps.apple.com/us/app/cocos-capital/id1634675415">
          <AppStore />
        </a>
      );
    }

    return setButtonComponent(<DownloadQR />);
  };

  return (
    <div className={styles.badgeContainer}>
      <Text variant={TextVariants.SemiboldText} color={textColor}>
        {text}
      </Text>
      {buttonComponent}
    </div>
  );
};

export default DownloadButton;
