import React from "react";

import Switch from "@mui/material/Switch";

import "./index.scss";

export enum ToggleSizes {
  Small = "small",
  Medium = "medium",
  Large = "large",
}
interface PropTypes {
  size: ToggleSizes;
  onClick?: (ev: any) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  value?: any;
  onChange?: () => void;
  checked?: boolean;
}

export const ToggleUI = ({
  defaultChecked = false,
  disabled = false,
  size,
  value,
  onChange,
  onClick,
  checked = false,
}: PropTypes) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.stopPropagation;
    onClick && onClick(event);
  };

  const handleOnChange = (event: React.ChangeEvent) => {
    event.stopPropagation;
    onChange && onChange();
  };
  return (
    <div>
      <Switch
        className={`ui-toggle ${size}`}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        value={value || null}
        onChange={handleOnChange}
        onClick={handleOnClick}
      />
    </div>
  );
};
