interface PropTypes {
  size?: number;
  color?: string;
}

export const ArrowTopIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      className="arrow-top-icon"
      fill="none"
      height={`${size ? size : 16}`}
      viewBox={`0 0 ${size ? size + 1 : 17} ${size || 16}`}
      width={`${size ? size + 1 : 17}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.05566 10.6666L8.50011 6.22217L12.9446 10.6666"
        stroke={color || "#0062E1"}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
