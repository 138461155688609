import { Typography } from "@mui/material";
import { Currency } from "components/common/currency";
import { getCurrencyLabel } from "utils";

import "./index.scss";

interface PropTypes {
  label: string;
  last: number;
  variation: number;
  currency: string;
}
export const FCIHoldingRow = (props: PropTypes) => {
  const { label, last, variation, currency } = props;

  const formatPercentage = (percentage: number) => {
    const formattedVariation = (percentage * 100).toFixed(2);

    return `${percentage > 0 ? "+" : ""}${formattedVariation}%`;
  };

  const getVariationClass = () => {
    if (variation === undefined) {
      return "";
    }

    return variation > 0 ? " positive" : " negative";
  };

  return (
    <div className="fci-holding-row">
      <span className="icon-holding">FCI</span>
      <div className="ticker-holding">
        <Typography variant="textS">{label}</Typography>
      </div>
      <div className="amount-holding">
        <Typography variant="textXS_bold">
          {`${getCurrencyLabel(currency)} `}
          <Currency>{last}</Currency>
        </Typography>
        <Typography
          className={`percent variation${getVariationClass()}`}
          variant="textXS"
        >
          {formatPercentage(variation)}
        </Typography>
      </div>
      {/* <DotsIcon /> */}
    </div>
  );
};
