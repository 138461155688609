import { CurrencyValues } from "interfaces/movements";
import { Currencies } from "interfaces/wallet";

export const getOrderName = (name?: string) => {
  if (!name) return null;

  if (name === Currencies.ARS) return CurrencyValues.ARS;

  if (name === Currencies.USD) return CurrencyValues.USD;

  return name;
};
