import { Dialog } from "@mui/material";
import { InstrumentData } from "interfaces/api-responses";
import { UIModalSlider } from "components/common/modal-slider";
import { DesktopOrTablet, Mobile } from "utils/responsive";

import ContentAddListCommon from "./action-content";

import "./index.scss";

interface PropTypes {
  selectedMarket?: InstrumentData;
  open: boolean;
  onCloseModal: () => void;
  onToggleDisplayModal: () => void;
  onClickCreateList: () => void;
}

const MarketRowActions: React.FC<PropTypes> = (props) => {
  const {
    open,
    selectedMarket,
    onCloseModal,
    onToggleDisplayModal,
    onClickCreateList,
  } = props;

  return (
    <div className="actions-row-market">
      <Mobile>
        <UIModalSlider
          classes="market-row-actions-modal"
          open={open}
          onClose={onCloseModal}
          onToggleDisplay={onToggleDisplayModal}
        >
          <ContentAddListCommon
            selectedMarket={selectedMarket}
            onClickCreateList={onClickCreateList}
          />
        </UIModalSlider>
      </Mobile>
      <DesktopOrTablet>
        <div>
          <Dialog className="add-list-modal" open={open} onClose={onCloseModal}>
            <ContentAddListCommon
              selectedMarket={selectedMarket}
              onClickCreateList={onClickCreateList}
            />
          </Dialog>
        </div>
      </DesktopOrTablet>
    </div>
  );
};

export default MarketRowActions;
