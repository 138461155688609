import MarketDetailSidebar from "components/common/market-detail-sidebar";
import { InstrumentData } from "interfaces/api-responses";
import DrawerLayout from "layouts/drawer";

const MarketDetail = (selectedId: string, ticker: InstrumentData) => (
  <DrawerLayout>
    <MarketDetailSidebar id={selectedId} ticker={ticker} />
  </DrawerLayout>
);

export default MarketDetail;
