import { ampli } from "ampli";
import Text, { TextVariants } from "components/common/Text";
import { ThemeVariants } from "interfaces/theme";
import { LucideIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import mainRoutes from "router/routes";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

interface NavigationTabProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title: string;
  route: string;
  icon: LucideIcon;
  referral?: string;
  variant: ThemeVariants;
}

const NavigationTab: React.FC<NavigationTabProps> = ({
  icon: Icon,
  title,
  route,
  variant,
  referral,
  ...props
}) => {
  const isCapital = variant === ThemeVariants.Capital;
  const navigate = useNavigate();
  const location = useLocation();

  const selected =
    title === "Inicio"
      ? location.pathname === route
      : location.pathname.includes(route);

  const selectedColor = isCapital ? "var(--blue900)" : "var(--purple900)";
  const color = selected ? selectedColor : "var(--slate600)";

  const onClickTab = () => {
    if (location.pathname === mainRoutes.home && isCapital) {
      ampli.homeExited({ referral: referral || "" });
    }
    navigate(route);
  };

  return (
    <button
      className={styles.navigationTabContainer}
      {...props}
      onClick={onClickTab}
    >
      <div className={styles.icon}>
        <Icon size={px2rem(24)} color={color} />
      </div>
      <Text variant={TextVariants.SemiboldTextXS} color={color}>
        {title}
      </Text>
    </button>
  );
};

export default NavigationTab;
