interface PropTypes {
  size: number;
  color?: string;
}

export const CheckIcon = ({ color, size }: PropTypes) => {
  return (
    <svg
      className="check-icon"
      fill="none"
      height={`${size}`}
      viewBox="0 0 16 16"
      width={`${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5252 13.314L0.575195 8.364L1.9892 6.95L5.5267 10.4835L5.5252 10.485L14.0102 2L15.4242 3.414L6.9392 11.9L5.5262 13.313L5.5252 13.314Z"
        fill={color || "#060D12"}
      />
    </svg>
  );
};
