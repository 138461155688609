import { useEffect } from "react";

import { Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { UIPriceInputV2 } from "components/common/price-input";

import {
  getIsCableEnabled,
  getMinToOperateMessage,
  getNominalValue,
} from "../../utils";
import TradingCurrencySettlement from "../advance-operation/currency-settlement";
import TradingMethod from "../advance-operation/method";

import styles from "./styles.module.scss";

interface BodySellProps {
  instrumentProp: any;
  ableOperation: (value: boolean) => void;
  advancePurchase: boolean;
  handleChangePrice: (value: number) => void;
  numOfDecimals: number;
}

const BodySell: React.FC<BodySellProps> = ({
  instrumentProp,
  ableOperation,
  handleChangePrice,
  advancePurchase,
  numOfDecimals,
}) => {
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const sellWallet = useAppSelector(
    (state: RootState) => state.wallet.sellingPower
  );

  const sellingPower = +(sellWallet?.available?.["24hs"] ?? 0);

  const minToOperate = instrumentProp.min_lot_size ?? 1;

  const minAmount =
    ((order.price || instrumentProp.bid || instrumentProp.last) *
      instrumentProp.contract_size *
      instrumentProp.min_lot_size) /
    instrumentProp.price_factor;

  const isCableEnabled = getIsCableEnabled(instrumentProp.instrument_type);

  const maxToOperate = sellingPower;

  const getInputLabel = () => {
    const nominalValue = getNominalValue(instrumentProp.instrument_type);
    const isMale = [
      Instruments.CEDEARS,
      Instruments.BONOS_CORP,
      Instruments.BONOS_PUBLICOS,
    ].includes(instrumentProp.instrument_type);

    return `¿${isMale ? "Cuántos" : "Cuántas"} ${nominalValue} querés vender?`;
  };

  const errorMessage = getMinToOperateMessage(
    minToOperate,
    minAmount,
    instrumentProp.currency,
    instrumentProp.instrument_type
  );

  useEffect(() => {
    if (
      order.quantity &&
      order.quantity >= minToOperate &&
      order.quantity <= maxToOperate
    ) {
      ableOperation(true);
      return;
    }
    ableOperation(false);
  }, [order.quantity]);

  return (
    <div>
      {advancePurchase && (
        <TradingCurrencySettlement isCableEnabled={isCableEnabled} />
      )}
      <UIPriceInputV2
        label={getInputLabel()}
        newClass={styles.investmentAmount}
        key="quantity"
        value={order.quantity}
        currency={instrumentProp.instrument_type}
        handleChangeValue={handleChangePrice}
        logoFileName={instrumentProp.logo_file_name}
        errorMessage={errorMessage}
        id="investment-amount-sell"
      />
      <UIPriceInputV2
        label="Monto aproximado a recibir"
        newClass={styles.amountToReceive}
        key="format-amount"
        value={order.amount}
        currency={order.currency ?? Currencies.ARS}
        decimals={numOfDecimals}
        disabled
        id="amount-to-receive-sell"
      />
      {advancePurchase && (
        <TradingMethod
          instrumentProp={instrumentProp}
          numOfDecimals={numOfDecimals}
        />
      )}
    </div>
  );
};

export default BodySell;
