import { getCrypto } from "constants/cryptos";

import DailyReturnCard from "components/common/DailyReturnCard";
import Text, { TextVariants } from "components/common/Text";
import { CryptoPrice } from "interfaces/api-responses";
import { isEmpty } from "lodash";

import styles from "./styles.module.scss";

interface AssetsListProps {
  assetsList: CryptoPrice[];
}

const AssetsList: React.FC<AssetsListProps> = ({ assetsList }) => {
  if (isEmpty(assetsList) || !assetsList)
    return (
      <Text variant={TextVariants.RegularText} color="var(--slate900)">
        No hay activos para mostrar
      </Text>
    );

  return (
    <div className={styles.assetsListContainer}>
      {assetsList.map(({ baseTicker, varDailyPricePercentage, ask }) => {
        const { icon, ticker } = getCrypto(baseTicker);
        return (
          <DailyReturnCard
            key={ticker}
            isRow
            short_name={ticker}
            icon={icon}
            variation={varDailyPricePercentage}
            amount={ask}
          />
        );
      })}
    </div>
  );
};

export default AssetsList;
