import React from "react";

import { InstrumentData } from "interfaces/api-responses";
import { Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { useNavigate } from "react-router-dom";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  resetMarketDataDetail,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
} from "features/markets/marketsSlice";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { getSettlementDays } from "utils";
import {
  InstrumentLogoUI,
  InstrumentLogoVariants,
} from "components/common/instument-logo";
import { trackAction } from "utils/amplitude";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { isCocosFundRedesign } from "components/page-capital-funds/helpers";

import InstrumentTicker from "../common/InstrumentTicker";
import InstrumentLastPrice from "./InstrumentLastPrice";
import InstrumentVariation from "./InstrumentVariation";

import "./index.scss";

interface MarketRowProps {
  market: InstrumentData;
  onClickRow: () => void;
  onClickMarketOptions: (market: InstrumentData) => void;
  hasCurrency?: boolean;
}

const MarketRow: React.FC<MarketRowProps> = ({
  market,
  onClickRow,
  hasCurrency,
  onClickMarketOptions,
}) => {
  const {
    last,
    close,
    currency,
    variation,
    tick_size,
    long_ticker,
    short_ticker,
    logo_file_name,
    settlement_days,
    instrument_code,
    instrument_type,
    id_tick_size_rule,
    instrument_short_name,
    returns_perc_last_month,
  } = market;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const currentScreen = useAppSelector(getCurrentScreen);

  const handleClickRow = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(setMarketDataDetail(market));

    if (order && !order.instrument_code?.includes(instrument_code ?? "")) {
      dispatch(
        resetOrder({
          long_ticker,
          price: last,
          instrument_code,
          instrument_short_name,
          term: getSettlementDays(settlement_days),
          currency: currency === Currencies.USD ? currency : Currencies.ARS,
        })
      );
      dispatch(clearSellingPower());
      dispatch(clearShortTickerMarketData());
      dispatch(resetMarketDataDetail());
      dispatch(cleanHistoricalData());
    } else {
      dispatch(
        updateOrderOptions({
          ...order,
          long_ticker,
          price: last,
          instrument_code,
          instrument_short_name,
          term: getSettlementDays(settlement_days),
          currency: currency === Currencies.USD ? currency : Currencies.ARS,
        })
      );
    }
    dispatch(setMarketDataDetail(market));
    trackAction(`${currentScreen} - Select Instrument`, {
      type: instrument_type,
      ticker: short_ticker,
    });

    if (instrument_type !== Instruments.FCI) {
      sessionStorage.setItem("lastPage", location.pathname);
      navigate(
        `${mainRoutes.market}/${market.instrument_type}/${market.instrument_code}`,
        {
          state: market,
        }
      );
    } else {
      navigate(
        instrument_code && isCocosFundRedesign(instrument_code)
          ? `${mainRoutes.funds}/${instrument_code}`
          : `${mainRoutes.market}/${instrument_type}/${instrument_code}`
      );
    }
    onClickRow();
  };

  return (
    <div
      className="comp-market-row"
      onClick={handleClickRow}
      id={`${market.instrument_short_name}-market-row`}
    >
      <InstrumentLogoUI
        logoFileName={logo_file_name}
        type={instrument_type}
        variant={InstrumentLogoVariants.Primary}
      />
      <InstrumentTicker
        instrumentShortName={instrument_short_name ?? ""}
        longTicker={long_ticker}
      />
      <InstrumentLastPrice
        last={last ?? null}
        close={close}
        currency={currency ?? Currencies.ARS}
        tickSize={tick_size}
        hasCurrency={hasCurrency}
        instrumentType={instrument_type}
        idTickSizeRule={id_tick_size_rule}
        returnsPercLastMonth={returns_perc_last_month}
      />
      <InstrumentVariation
        variation={variation ?? 0}
        onClickMarketOptions={() => onClickMarketOptions(market)}
      />
    </div>
  );
};

export default MarketRow;
