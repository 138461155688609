import ff from "constants/featureFlags";

import { useEffect, useState } from "react";

import ErrorContainer from "components/common/error-modal";
import {
  FundScreen,
  getCuotapartes,
  CocosFund,
} from "components/page-capital-funds/helpers";
import { getPortfolio } from "features/wallet/portfolioSlice";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { getSellingPower, getWallet } from "features/wallet/walletSlice";
import {
  getMarketDetailByShortTicker,
  updateOrderOptions,
} from "features/markets";
import { ampli } from "ampli";
import mainRoutes from "router/routes";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import { useFundOrderMutation } from "apis/market/mutations/useFundOrderMutation";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { useFundsHoldings } from "apis/fci/queries/useFundsHoldings";
import type { RootState } from "store/store";
import { Instruments } from "interfaces/markets";
import useFeatureFlag from "hooks/useFeatureFlag";

import AddWithdrawFunds from "../../AddWithdrawFunds";
import FCIHoldingsSkeletonLoader from "../FCIHoldingsSkeletonLoader";
import FundError from "../../AddWithdrawFunds/components/FundError";
import FundSuccess from "../../AddWithdrawFunds/components/FundSuccess";
import FundsHoldings from "../../FundsHoldings";

import styles from "./styles.module.scss";

const FundsWrapper = ({ selectedFund }: { selectedFund: CocosFund }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isMobile } = useDeviceCheck();
  const fundOrderMutation = useFundOrderMutation();
  const { isFeatureEnabled: isEarningsEnabled } = useFeatureFlag(
    ff.ENABLE_FCI_EARNINGS
  );

  const {
    data: holdingResponse,
    isLoading: isHoldingsLoading,
    isError,
  } = useFundsHoldings({
    securityId: selectedFund.securityId,
  });

  const [screen, setScreen] = useState<FundScreen>(FundScreen.FUND_RETURNS);

  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const { holdings, loading: isPortfolioLoading } = useAppSelector(
    (state: RootState) => state.portfolio
  );
  const { sellingPower, loading: isSellingPowerLoading } = useAppSelector(
    (state: RootState) => state.wallet
  );
  const shortTickerData = useAppSelector(
    (state: RootState) => state.markets.shortTickerData
  );

  const currentHolding =
    holdings &&
    holdings[Instruments.FCI]?.find((t) => t.ticker === selectedFund.ticker);

  const getAvailableSell = () => {
    let currentTicker;
    if (holdings) {
      currentTicker = currentHolding;
    }
    if (currentTicker && currentTicker.last && sellingPower?.available) {
      return (
        (currentTicker.last / 1000) * getCuotapartes(sellingPower.available)
      );
    }

    return 0;
  };

  const availableToSell = getAvailableSell();
  const hasSellingPower = availableToSell > 0;

  const onActionsClose = () => {
    if (!isMobile && !hasSellingPower) dispatch(closeRightModal());
    if (hasSellingPower) return setScreen(FundScreen.FUND_RETURNS);

    return navigate(mainRoutes.home);
  };

  const onFundOrderConfirm = async () => {
    try {
      ampli.fciOrderConfirmed({
        ticker: order.instrument_code,
        side: order.type,
        amount: order.amount,
        currency: order.currency,
      });

      const orderId = await fundOrderMutation.mutateAsync(order);

      dispatch(updateOrderOptions({ ...order, id: orderId }));
      dispatch(getWallet());

      dispatch(getPortfolio());

      if (order.long_ticker) {
        dispatch(getSellingPower(order.long_ticker));
      }
    } catch (error) {
      console.error("Mutation failed", error);
    }
  };

  const handleGoBack = () => {
    if (isMobile) return navigate(-1);

    return dispatch(closeRightModal());
  };

  const onCloseSuccessOrError = () => {
    if (!isMobile) return dispatch(closeRightModal());

    navigate(-1);
  };

  useEffect(() => {
    dispatch(getPortfolio());

    if (selectedFund.ticker) {
      dispatch(getSellingPower(selectedFund.ticker));
      dispatch(
        getMarketDetailByShortTicker({
          short_ticker: selectedFund.ticker,
          instrument_type: Instruments.FCI,
        })
      );
    }
  }, []);

  if (fundOrderMutation.isError)
    return (
      <FundError
        currency={selectedFund.currency}
        order={order}
        onClose={onCloseSuccessOrError}
      />
    );

  if (fundOrderMutation.isSuccess)
    return (
      <FundSuccess
        currency={selectedFund.currency}
        order={order}
        onClose={onCloseSuccessOrError}
      />
    );

  if (
    isPortfolioLoading ||
    isSellingPowerLoading ||
    isHoldingsLoading ||
    fundOrderMutation.isLoading
  )
    return <FCIHoldingsSkeletonLoader onClick={handleGoBack} />;

  if (isError)
    return (
      <div className={styles.errorContainer}>
        <ErrorContainer
          subtitle="En este momento no podemos mostrar tus tenencias."
          textPrimaryButton="Volver a la home"
          onClickPrimaryButton={() => navigate(mainRoutes.home)}
        />
      </div>
    );

  if (
    screen === FundScreen.FUND_RETURNS &&
    holdingResponse &&
    holdingResponse.hasMovements
  )
    return (
      <FundsHoldings
        holdings={holdingResponse}
        selectedFund={selectedFund}
        onClick={handleGoBack}
        setScreen={setScreen}
      />
    );

  return (
    <AddWithdrawFunds
      isEarningsEnabled={isEarningsEnabled}
      availableToSell={availableToSell}
      holdings={currentHolding}
      marketData={shortTickerData[0]}
      onClose={onActionsClose}
      onFundOrderConfirm={onFundOrderConfirm}
      selectedFund={selectedFund}
      tab={screen}
    />
  );
};

export default FundsWrapper;
