import { Currencies } from "interfaces/wallet";
import { UIPriceInput } from "components/common/input/price";
import { NumberFormatValues } from "react-number-format";
import { BondData } from "interfaces/markets";

import styles from "./styles.module.scss";

interface BondExchangeRateInputProps {
  selectedBond?: BondData;
  currency: Currencies;
  exchangeRate: number;
  handleChangeExchangeRate: ({ floatValue }: NumberFormatValues) => void;
}

const BondExchangeRateInput: React.FC<BondExchangeRateInputProps> = ({
  currency,
  exchangeRate,
  selectedBond,
  handleChangeExchangeRate,
}) => (
  <div className={styles.exchangeRate}>
    <UIPriceInput
      disabled={selectedBond ? currency === selectedBond?.id_currency : true}
      label={"Tipo de cambio"}
      placeholder={"-"}
      value={exchangeRate?.toString()}
      onChangeValue={handleChangeExchangeRate}
    />
  </div>
);

export default BondExchangeRateInput;
