import classNames from "classnames";
import LoadingSpinner from "components/common/LoadingSpinner";
import PageHeader from "components/common/PageHeader";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ThemeVariants } from "interfaces/theme";
import { ArrowLeft } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import OptionsList from "./SectionList";
import { SectionRowProps } from "./SectionList/SectionRow";

import styles from "./styles.module.scss";

interface ProfileSectionProps {
  title: string;
  subtitle?: string;
  options: SectionRowProps[];
  loading?: boolean;
  withHeader?: boolean;
  secondarySubtitle?: string;
  secondaryOptions?: SectionRowProps[];
}

const ProfileSection: React.FC<ProfileSectionProps> = ({
  title,
  subtitle,
  options,
  loading = false,
  withHeader = false,
  secondarySubtitle,
  secondaryOptions,
}) => {
  const { isMobile } = useDeviceCheck();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goBack = () => {
    if (isMobile) {
      navigate(-1);
      return;
    }
    dispatch(closeRightModal());
  };

  if (loading)
    return (
      <div
        className={classNames(styles.loaderWrapper, {
          [styles.desktop]: !isMobile,
        })}
      >
        <LoadingSpinner variant={ThemeVariants.Capital} />
      </div>
    );

  return (
    <div
      className={classNames(styles.profileSectionContainer, {
        [styles.desktop]: !isMobile,
      })}
    >
      {withHeader && (
        <PageHeader
          className={styles.header}
          icon={ArrowLeft}
          iconColor="var(--blue900)"
          title={title}
          onClick={goBack}
        />
      )}
      <OptionsList title={subtitle} options={options} />
      {secondarySubtitle && secondaryOptions && (
        <div className={styles.secontadaryOptions}>
          <OptionsList title={secondarySubtitle} options={secondaryOptions} />
        </div>
      )}
    </div>
  );
};

export default ProfileSection;
