import { Typography } from "@mui/material";

import MobileHeader from "../../components/common/header";

import "./index.scss";

interface DrawerLayoutProps {
  children?:
    | React.ReactChild
    | React.ReactChild[]
    | React.ReactNode[]
    | React.ReactNode;
  headerTitle?: string;
  subtitle?: string | React.ReactChild;
  search?: (v: string) => void;
  withHeader?: boolean;
  hideBottomNavigation?: boolean;
  hideToolbar?: boolean;
  hideSidebar?: boolean;
  goBack?: () => void;
  assetToolbar?: boolean;
  classes?: string;
}

const DrawerLayout: React.FC<DrawerLayoutProps> = ({
  children,
  headerTitle,
  subtitle,
  search,
  goBack,
  withHeader = true,
  assetToolbar = false,
  classes,
}) => {
  return (
    <div className={`layouts-drawer ${classes || ""}`}>
      {withHeader && (
        <MobileHeader
          assetToolbar={assetToolbar}
          goBack={goBack}
          search={search}
          showGoBack={false}
          title={headerTitle}
        />
      )}
      {subtitle && (
        <Typography
          component={"p"}
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "18px",
            marginLeft: "24px",
            marginBottom: "24px",
            width: "70%",
          }}
          className="drawer-subtitle"
        >
          {subtitle}
        </Typography>
      )}
      <div className="content-wrapper">{children}</div>
    </div>
  );
};

export default DrawerLayout;
