interface PropTypes {
  size?: number;
  color?: string;
}

export const DownArrow = ({ color = "#002C65", size = 16 }: PropTypes) => {
  return (
    <svg
      fill="none"
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4446 6.22217L8.00011 10.6666L3.55566 6.22217"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
      />
    </svg>
  );
};
