import { IconProps } from "assets/icons/BTC";
import { LucideIcon } from "lucide-react";
import px2rem from "utils/px2rem";

import styles from "./styles.module.scss";

const OverlappedIcons: React.FC<{
  icons: (React.FC<IconProps> | LucideIcon)[];
}> = ({ icons }) => {
  return (
    <div className={styles.overlappedIconsContainer}>
      {icons.map((Icon, index) => (
        <Icon key={index} size={px2rem(20)} />
      ))}
    </div>
  );
};

export default OverlappedIcons;
