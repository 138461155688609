interface PropTypes {
  size?: number;
  color?: string;
}

export const FillStar = (props: PropTypes) => {
  const { size = 16, color = "#002C65" } = props;

  return (
    <svg
      fill={color}
      height={size}
      viewBox={`0 0 22 21`}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0001 1L14.0901 7.26L21.0001 8.27L16.0001 13.14L17.1801 20.02L11.0001 16.77L4.82006 20.02L6.00006 13.14L1.00006 8.27L7.91006 7.26L11.0001 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
