import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import dayjs from "dayjs";
import {
  BusinessDay,
  CrosshairMode,
  IChartApi,
  ISeriesApi,
  LineStyle,
  MouseEventHandler,
  SeriesMarker,
  Time,
  createChart,
} from "lightweight-charts";
import _ from "lodash";
import { Currency } from "components/common/currency";
import { DecimalRound } from "components/common/decimal-round-rules";
import { Price } from "interfaces/api-responses";
import { ChartSerie } from "interfaces/markets";

import "./index.scss";

interface PropTypes {
  data?: ChartSerie[];
  prices: Price[];
  name?: string;
  id_tick_size_rule?: string | null;
  tick_size?: number | null;
  loading?: boolean;
}

interface SerieDataType {
  time: Time;
  value: number;
}

let chart: IChartApi;

const getLocaleNumber = (num: number | string | null) => {
  if (num === null) return "-";

  return Number(num)
    .toFixed(2)
    .toString()
    .replace(".", ",")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const UIChartLine = (props: PropTypes) => {
  const { loading, prices, id_tick_size_rule, tick_size } = props;
  const chartRef = React.useRef<any>(null);
  const chartContainerRef = React.useRef<any>(null);
  const chartSeriesDataRef = React.useRef<ISeriesApi<"Area"> | null>(null);
  const last = _.maxBy(props.prices, "date");
  const [priceLabel, setPriceLabel] = useState(last?.close);

  const [, setDateLabel] = useState(last?.date || "");
  const [tooltipDisplay, setTooltipDisplay] = useState("none");
  const [tooltipLeft, setTooltipLeft] = useState("");
  const [tooltipTop, setTooltipTop] = useState("");

  const serieData = (data: Price[]): SerieDataType[] =>
    data.map((p: Price) => ({
      time: {
        year: dayjs(p.date, "YYYY-MM-DD").year(),
        month: dayjs(p.date, "YYYY-MM-DD").month() + 1,
        day: dayjs(p.date, "YYYY-MM-DD").date(),
      },
      value: p.close,
    }));

  useEffect(() => {
    chart = createChart(chartRef.current, {
      height: 164,
      width: chartContainerRef.current.parentNode.clientWidth,
      leftPriceScale: {
        visible: false,
      },
      rightPriceScale: {
        visible: false,
      },
      timeScale: {
        borderVisible: false,
        visible: true,
      },
      localization: {
        locale: "es",
      },
      grid: {
        horzLines: {
          color: "#eee",
          visible: false,
        },
        vertLines: {
          color: "#ffffff",
        },
      },
      crosshair: {
        mode: CrosshairMode.Magnet,
        horzLine: {
          visible: false,
          labelVisible: false,
        },
        vertLine: {
          visible: true,
          width: 1,
          color: "#B5C1D2",
          labelVisible: true,
          style: LineStyle.Solid,
        },
      },
      handleScroll: {
        horzTouchDrag: false,
        vertTouchDrag: false,
        mouseWheel: false,
        pressedMouseMove: false,
      },
      kineticScroll: {
        mouse: false,
        touch: false,
      },
      handleScale: {
        mouseWheel: false,
      },
    });

    prepareChart(chart);
    chart.timeScale().fitContent();
    chart.timeScale().scrollToPosition(10, false);

    new ResizeObserver((entries) => {
      if (entries.length === 0 || entries[0].target !== chartRef.current) {
        return;
      }
      const newRect = entries[0].contentRect;

      chart.applyOptions({ height: newRect.height, width: newRect.width });
    }).observe(chartRef.current);
  }, []);

  const prepareChart = (chart: IChartApi) => {
    chartSeriesDataRef.current = chart.addAreaSeries({
      topColor: "transparent",
      bottomColor: "transparent",
      lineColor: "#0062E1",
      priceLineVisible: true,
      lineWidth: 3,
      crosshairMarkerBackgroundColor: "#0062E1",
      crosshairMarkerRadius: 6,
    });

    const toolTipWidth = 50;

    const businessDayToString = (businessDay?: BusinessDay) => {
      if (!businessDay) return "";

      return businessDay.year + "-" + businessDay.month + "-" + businessDay.day;
    };

    const mouseMoveHandler: MouseEventHandler<Time> = (param) => {
      if (
        param.point === undefined ||
        param.point.x < 0 ||
        param.point.x > chartRef.current.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartRef.current.clientHeight
      ) {
        setTooltipDisplay("none");
      } else {
        const dateStr = businessDayToString(param.time as BusinessDay);

        setTooltipDisplay("block");
        const paramValue:
          | {
              time: BusinessDay;
              value: number;
            }
          | undefined = param.seriesData.values().next().value;

        setPriceLabel(
          Number((Math.round(100 * (paramValue?.value || 0)) / 100).toFixed(2))
        );
        setDateLabel(dateStr);

        const coordinate = param.point;
        let shiftedCoordinate = param.point.x - toolTipWidth;

        if (coordinate === null) {
          return;
        }
        shiftedCoordinate = Math.max(
          0,
          Math.min(
            chartContainerRef.current.parentNode.clientWidth - toolTipWidth * 2,
            shiftedCoordinate
          )
        );
        const coordinateY = coordinate.y;

        setTooltipLeft(shiftedCoordinate + "px");
        setTooltipTop(coordinateY + "px");
      }
    };

    chart.subscribeCrosshairMove(mouseMoveHandler);
  };

  const addMarkers = (seriesData: SerieDataType[]) => {
    // add max and min
    const max = _.maxBy(seriesData, "value");
    const min = _.minBy(seriesData, "value");

    if (seriesData && max && min) {
      const markers: SeriesMarker<Time>[] = [];

      if (max.time < min.time) {
        markers.push({
          time: max.time,
          position: "aboveBar",
          color: "#76767e",
          shape: "arrowDown",
          text: "Máx: " + getLocaleNumber(max.value),
        });
        markers.push({
          time: min.time,
          position: "belowBar",
          color: "#76767e",
          shape: "arrowUp",
          text: "Min: " + getLocaleNumber(min.value),
        });
      } else {
        markers.push({
          time: min.time,
          position: "belowBar",
          color: "#76767e",
          shape: "arrowUp",
          text: "Min: " + getLocaleNumber(min.value),
        });
        markers.push({
          time: max.time,
          position: "aboveBar",
          color: "#76767e",
          shape: "arrowDown",
          text: "Máx: " + getLocaleNumber(max.value),
        });
      }

      chartSeriesDataRef.current?.setMarkers(markers);
    }
  };

  useEffect(() => {
    if (prices) {
      try {
        const last = _.maxBy(prices, "date");

        setTooltipDisplay("block");
        setPriceLabel(last?.close);
        setDateLabel(last?.date || "");
        chartSeriesDataRef.current?.setData(serieData(props.prices));
        addMarkers(serieData(props.prices));
        setTooltipLeft(chartRef?.current?.clientWidth - 100 + "px");
        chart?.timeScale().fitContent();
        chart?.timeScale().scrollToPosition(10, false);
      } catch (e) {
        console.log(e);
      }
      //setPriceLabel();
    }
  }, [prices]);

  return (
    <div ref={chartContainerRef} className="ui-chart-line">
      <div ref={chartRef} className="chart-container">
        <div
          className={`floating-tooltip-2 ${
            prices.length === 0 ? "no-prices" : ""
          }`}
          style={{
            left: prices.length !== 0 ? tooltipLeft : undefined,
            display: "block",
          }}
        >
          {prices.length === 0 && !loading && (
            <Typography variant="textM_bold">
              No hay datos históricos
            </Typography>
          )}
          <div
            style={{
              position: "absolute",
              top: tooltipTop,
              display: tooltipDisplay,
            }}
          >
            <Typography variant="textL_bold">
              {id_tick_size_rule || tick_size ? (
                <DecimalRound ruleId={id_tick_size_rule} tickSize={tick_size}>
                  {priceLabel}
                </DecimalRound>
              ) : (
                <Currency>{priceLabel}</Currency>
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
