import { Input, InputAdornment, Typography } from "@mui/material";
import NumberFormat, { NumberFormatValues } from "react-number-format";
import { getCurrencyLabel } from "utils";

import "./index.scss";

export enum InputSizes {
  Large = "large",
  Medium = "medium",
  Small = "small",
  Full = "full",
}

interface PropTypes {
  value?: string;
  placeholder?: string;
  currencyValue?: string;
  onChangeValue?: (values: NumberFormatValues) => void;
  size?: InputSizes;
  disabled?: boolean;
  customSuffix?: string;
  customPrefix?: string;
  endAdorment?: string;
  disableDecimals?: boolean;
  label?: string;
  decimalScale?: number | null;
  isCrypto?: boolean;
  prefix?: boolean;
}

export const UIPriceInput = (props: PropTypes) => {
  const {
    value,
    currencyValue,
    placeholder,
    size = InputSizes.Medium,
    onChangeValue,
    disabled,
    disableDecimals = false,
    endAdorment,
    label,
    decimalScale,
    isCrypto = false,
    prefix = true,
  } = props;

  const handleChangeValue = (values: NumberFormatValues) => {
    if (onChangeValue) {
      onChangeValue(values);
    }
  };

  return (
    <div className={`ui-price-input ${isCrypto && "crypto"}`}>
      {label && <Typography variant="textS">{label}</Typography>}
      <NumberFormat
        fixedDecimalScale
        className={
          "price-input " +
          `${size} ` +
          (value === "0" || value === "" ? "empty" : "completed")
        }
        customInput={Input}
        decimalScale={disableDecimals ? 0 : decimalScale || 2}
        decimalSeparator=","
        disabled={disabled}
        displayType="input"
        endAdornment={
          endAdorment && (
            <InputAdornment position="start">{endAdorment}</InputAdornment>
          )
        }
        inputProps={{
          inputMode: "decimal",
        }}
        pattern="[0-9]+([\.,][0-9]+)?"
        placeholder={disabled ? "0" : placeholder || "Ingresa precio"}
        prefix={prefix ? `${getCurrencyLabel(currencyValue)} ` : ""}
        thousandSeparator="."
        value={value ? value.replace(".", ",") : null}
        onValueChange={handleChangeValue}
      />
    </div>
  );
};
