import React from "react";

import { InstrumentSubtype as Subtype } from "interfaces/markets";
import {
  BottomNavigation,
  BottomNavigationAction,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MarketSection } from "interfaces/markets";
import { FavoriteList } from "interfaces/user";
import mainRoutes from "router/routes";

import "./index.scss";

export interface SectionsButtonsOption {
  name: string;
  value: string;
}
interface SectionsButtonsProps {
  onClickHeaderOption: (option: MarketSection) => void;
  selected: string;
  subTypesOptions?: Subtype[];
  listsOptions?: FavoriteList[];
  customOptions?: SectionsButtonsOption[];
  newClass?: string;
}

const SectionsButtons: React.FC<SectionsButtonsProps> = ({
  onClickHeaderOption,
  customOptions,
  selected,
  subTypesOptions,
  listsOptions,
  newClass,
}) => {
  const navigate = useNavigate();
  const handleChange = (event: any, newValue: MarketSection) => {
    if (selected !== newValue) {
      onClickHeaderOption(newValue);
      if (!subTypesOptions && !listsOptions) {
        navigate(`${mainRoutes.market}/${newValue}`);
      }
    }
  };

  return (
    <div className={`ui-type-subtype-header-container ${newClass && newClass}`}>
      <BottomNavigation
        showLabels
        className="ui-type-subtype-header"
        style={{ justifyContent: "normal" }}
        value={selected}
        onChange={handleChange}
      >
        {customOptions &&
          customOptions.map((option) => (
            <BottomNavigationAction
              key={option.value}
              id={option.value}
              label={
                <Typography variant="textS_bold">{option.name}</Typography>
              }
              value={option.value}
            />
          ))}
        {subTypesOptions &&
          subTypesOptions.map((option) => (
            <BottomNavigationAction
              key={option.code}
              id={option.code}
              label={
                <Typography variant="textS_bold">{option.name}</Typography>
              }
              value={option.code}
            />
          ))}
        {listsOptions &&
          listsOptions.map((option) => (
            <BottomNavigationAction
              key={option.id_list}
              label={
                <Typography variant="textS_bold">{option.name}</Typography>
              }
              value={option.id_list}
            />
          ))}
      </BottomNavigation>
    </div>
  );
};

export default SectionsButtons;
