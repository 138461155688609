import { INSTRUMENTS } from "constants/instruments";

import { MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useAppSelector } from "hooks/redux";
import { RootState } from "store/store";
import { MarketSection } from "interfaces/markets";
import { FavoriteList } from "interfaces/user";

import "./index.scss";

interface HeaderListsProps {
  options?: FavoriteList[];
  placeholder?: string;
  selected?: string;
  isActive: boolean;
  onClickSelectOptions: (value: MarketSection) => void;
  customOptions?: { name: string; value: string }[];
  onClick?: () => void;
}

export const HeaderLists: React.FC<HeaderListsProps> = ({
  onClickSelectOptions,
  selected,
  options,
  isActive,
  placeholder,
  customOptions,
  onClick,
}) => {
  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );
  const color = darkMode ? "#464646" : "#ffffff";
  const fontColor = darkMode ? "#accefa" : "#002c65";
  const handleSelect = (event: SelectChangeEvent<string>) => {
    onClickSelectOptions(event.target.value as MarketSection);
  };

  const handleRenderValue = () => {
    if (!selected) {
      return (
        <Typography className="placeholder" variant="textS">
          {placeholder}
        </Typography>
      );
    }

    return <Typography variant="textS_bold">{selected}</Typography>;
  };

  return (
    <div
      className="select-home-container select-market-introjs"
      onClick={onClick}
      id="list-header"
    >
      <Select
        displayEmpty
        className={`select-home ${isActive ? "active" : ""}`}
        inputProps={{
          MenuProps: {
            MenuListProps: {
              sx: {
                backgroundColor: color,
              },
            },
          },
        }}
        renderValue={placeholder ? handleRenderValue : undefined}
        value={selected}
        onChange={handleSelect}
      >
        {options?.map((option) => (
          <MenuItem key={option.id_list} value={option.id_list}>
            <Typography color={fontColor} variant="textS_bold">
              {option.name}
            </Typography>
          </MenuItem>
        ))}
        {!options &&
          INSTRUMENTS.map((instrument) => (
            <MenuItem
              key={instrument.type_code}
              value={instrument.instrument_type}
            >
              <Typography color={fontColor} variant="textS_bold">
                {instrument.name}
              </Typography>
            </MenuItem>
          ))}
        {customOptions?.map((customOption) => (
          <MenuItem key={customOption.value} value={customOption.value}>
            <Typography color={fontColor} variant="textS_bold">
              {customOption.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
