import { DETAIL_BALANCE } from "constants/portfolio";

import { usePortfolioQuery } from "apis/portfolio/queries/usePortfolioQuery";
import { ThemeVariants } from "interfaces/theme";
import { Currencies, PortfolioFromType } from "interfaces/wallet";
import Skeleton from "react-loading-skeleton";
import BasicWrapper from "components/common/BasicWrapper";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { useAppDispatch } from "hooks/redux";
import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import { useDeviceCheck } from "hooks/useDeviceCheck";

import BalanceDetails from "./components/BalanceDetails";

import styles from "./styles.module.scss";

interface CurrencyBalanceDetailProps {
  selectedCurrencyDetail: Currencies;
  variant?: ThemeVariants;
}

const CurrencyBalanceDetail: React.FC<CurrencyBalanceDetailProps> = ({
  selectedCurrencyDetail,
  variant = ThemeVariants.Capital,
}) => {
  const portfolioFrom =
    variant === ThemeVariants.Capital
      ? PortfolioFromType.BROKER
      : PortfolioFromType.CRYPTO;

  const currency =
    selectedCurrencyDetail === Currencies.ARS ? Currencies.ARS : Currencies.USD;

  const { data: portfolioResponse, isLoading } = usePortfolioQuery(
    currency,
    portfolioFrom
  );
  const dispatch = useAppDispatch();
  const { isMobile } = useDeviceCheck();
  const { setPortfolioScreen } = usePortfolio();

  const currencyDetail = DETAIL_BALANCE[selectedCurrencyDetail];

  const handleOnClickExit = () => {
    if (isMobile) {
      setPortfolioScreen(PortfolioScreen.PORTFOLIO);
      return;
    }
    dispatch(closeRightModal());
  };

  return (
    <BasicWrapper
      navigationHeaderProps={{
        variant,
        title: "Moneda",
        onClick: handleOnClickExit,
        withCloseIcon: false,
        titleColor: "var(--slate800)",
        iconColor:
          variant === ThemeVariants.Crypto
            ? "var(--purple900)"
            : "var(--blue900)",
      }}
    >
      <div className={styles.container}>
        <div className={styles.detailBalanceContainer}>
          {portfolioResponse && !isLoading ? (
            <BalanceDetails
              currencyDetail={currencyDetail}
              holdings={portfolioResponse}
              variant={variant}
            />
          ) : (
            <>
              <Skeleton className={styles.skeletonLoading} />
              <Skeleton className={styles.skeletonLoadingBalance} />
              <Skeleton height={200} />
            </>
          )}
        </div>
      </div>
    </BasicWrapper>
  );
};

export default CurrencyBalanceDetail;
