import { useDeviceCheck } from "hooks/useDeviceCheck";
import { Currencies } from "interfaces/wallet";
import { MarketSection } from "interfaces/markets";

import DesktopHeader from "./desktop";
import MobileHeader from "./mobile";

interface TableHeaderProps {
  currency: Currencies;
  selectedInstrumentType: MarketSection;
}

const TableHeader: React.FC<TableHeaderProps> = ({
  currency,
  selectedInstrumentType,
}) => {
  const { isMobile } = useDeviceCheck();

  return (
    <>
      {isMobile && (
        <MobileHeader
          currency={currency}
          selectedInstrumentType={selectedInstrumentType}
        />
      )}
      {!isMobile && (
        <DesktopHeader selectedInstrumentType={selectedInstrumentType} />
      )}
    </>
  );
};

export default TableHeader;
