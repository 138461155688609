import { IconProps } from "assets/icons/BTC";

const Swap: React.FC<IconProps> = ({ width = 199, height = 80, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 199 80"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_14549_16104)">
      <path
        d="M158.675 0H40.0952V80H158.675V0Z"
        fill="url(#paint0_linear_14549_16104)"
      />
      <path
        d="M158.675 80C180.766 80 198.675 62.0914 198.675 40C198.675 17.9086 180.766 0 158.675 0C136.584 0 118.675 17.9086 118.675 40C118.675 62.0914 136.584 80 158.675 80Z"
        fill="#70B8FF"
      />
      <path
        d="M158.675 70.0907C175.294 70.0907 188.766 56.6186 188.766 40C188.766 23.3813 175.294 9.90918 158.675 9.90918C142.056 9.90918 128.584 23.3813 128.584 40C128.584 56.6186 142.056 70.0907 158.675 70.0907Z"
        fill="url(#paint1_linear_14549_16104)"
      />
      <path
        d="M158.661 70.0907C166.642 70.0907 174.295 66.9205 179.939 61.2773C185.582 55.6342 188.752 47.9805 188.752 40C188.752 32.0194 185.582 24.3657 179.939 18.7226C174.295 13.0794 166.642 9.90918 158.661 9.90918"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M138.461 17.7046C134.747 21.0801 131.934 25.3306 130.28 30.0695C128.626 34.8085 128.182 39.8858 128.99 44.8398C129.797 49.7938 131.83 54.4674 134.903 58.4359C137.977 62.4044 141.993 65.5419 146.587 67.5633"
        stroke="white"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M149.896 48.2044C149.896 52.75 153.826 56.4315 158.675 56.4315C163.524 56.4315 167.453 52.7546 167.453 48.2044C167.453 38.8697 151.441 39.2834 151.441 31.0517C151.441 27.3059 154.658 24.2725 158.675 24.2725C162.692 24.2725 165.909 27.3059 165.909 31.0517"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M158.675 61.156V19.5474"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M40.0952 80C62.1866 80 80.0952 62.0914 80.0952 40C80.0952 17.9086 62.1866 0 40.0952 0C18.0038 0 0.0952148 17.9086 0.0952148 40C0.0952148 62.0914 18.0038 80 40.0952 80Z"
        fill="#D19DFF"
      />
      <path
        d="M40.0952 70.0907C56.7138 70.0907 70.1859 56.6186 70.1859 40C70.1859 23.3813 56.7138 9.90918 40.0952 9.90918C23.4765 9.90918 10.0044 23.3813 10.0044 40C10.0044 56.6186 23.4765 70.0907 40.0952 70.0907Z"
        fill="url(#paint2_linear_14549_16104)"
      />
      <path
        d="M40.0815 70.0907C48.0621 70.0907 55.7158 66.9205 61.3589 61.2773C67.002 55.6342 70.1723 47.9805 70.1723 40C70.1723 32.0194 67.002 24.3657 61.3589 18.7226C55.7158 13.0794 48.0621 9.90918 40.0815 9.90918"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M19.8815 17.7046C16.1667 21.0801 13.3544 25.3306 11.7003 30.0695C10.0462 34.8085 9.60264 39.8858 10.41 44.8398C11.2174 49.7938 13.2502 54.4674 16.3234 58.4359C19.3967 62.4044 23.413 65.5419 28.0074 67.5633"
        stroke="white"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M53.4836 29.5852C51.3094 26.7607 48.3055 24.6877 44.8934 23.657C41.4812 22.6263 37.8321 22.6895 34.4577 23.8379C31.0834 24.9862 28.1531 27.1621 26.0781 30.0602C24.003 32.9583 22.8872 36.4333 22.8872 39.9977C22.8872 43.5621 24.003 47.0371 26.0781 49.9352C28.1531 52.8333 31.0834 55.0092 34.4577 56.1575C37.8321 57.3059 41.4812 57.3691 44.8934 56.3384C48.3055 55.3077 51.3094 53.2347 53.4836 50.4102"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M36.3401 23.3762V17.7046"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M43.0505 23.0039V17.7046"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M36.3401 62.3647V56.5093"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
      <path
        d="M43.0505 62.3648V57.0654"
        stroke="#1C2024"
        strokeWidth="1.46616"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_14549_16104"
        x1="38.675"
        y1="1.55808"
        x2="159.953"
        y2="78.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.29" stopColor="#D19DFF" stopOpacity="0.4" />
        <stop offset="0.72" stopColor="#70C6FF" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_14549_16104"
        x1="187.667"
        y1="40"
        x2="127.486"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#70B8FF" />
        <stop offset="0.22" stopColor="#77BBFF" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#8AC5FF" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#A9D4FF" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#D3E9FF" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_14549_16104"
        x1="69.0875"
        y1="40"
        x2="8.90592"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#D19DFF" />
        <stop offset="0.22" stopColor="#D3A2FF" stopOpacity="0.97" />
        <stop offset="0.38" stopColor="#D9AFFF" stopOpacity="0.89" />
        <stop offset="0.58" stopColor="#E3C4FF" stopOpacity="0.76" />
        <stop offset="0.8" stopColor="#F1E1FF" stopOpacity="0.58" />
        <stop offset="1" stopColor="white" stopOpacity="0.4" />
      </linearGradient>
      <clipPath id="clip0_14549_16104">
        <rect
          width="198.58"
          height="80"
          fill="white"
          transform="translate(0.0952148)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Swap;
