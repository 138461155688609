import classNames from "classnames";
import Text, { TextVariants } from "components/common/Text";
import { PeriodTime } from "interfaces/portfolio";

import styles from "./styles.module.scss";

interface TimeFrameTabsProps {
  selectedTime: number;
  onClickOption: (v: number) => void;
  timeOptions: { label: string; value: number }[];
  classname?: string;
}

const TimeFrameTabs: React.FC<TimeFrameTabsProps> = ({
  timeOptions,
  selectedTime,
  onClickOption,
  classname,
}) => {
  const getLabel = (label: string) => {
    switch (label) {
      case PeriodTime.DAY:
        return "1D";

      case PeriodTime.WEEK:
        return "1S";

      case PeriodTime.MONTH:
        return "1M";

      case PeriodTime.YEAR:
        return "1A";

      case PeriodTime.MAX:
        return "MÁX";

      default:
        return label;
    }
  };

  return (
    <div className={classNames(styles.timeFrameWrapper, classname)}>
      {timeOptions.map(({ label, value }) => (
        <button
          className={classNames(styles.button, [
            { [styles.selectedButton]: selectedTime === value },
          ])}
          key={value}
          onClick={() => onClickOption(value)}
        >
          <Text
            color="var(--slate900)"
            variant={TextVariants.SemiboldUpperValueS}
          >
            {getLabel(label)}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default TimeFrameTabs;
