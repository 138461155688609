import {
  BuyingPowerCurrencies,
  PortfolioTicker,
  PortfolioTotalApi,
  SellingPowerResponse,
} from "../api-responses";

export interface Wallet {
  "24hs": BuyingPowerCurrencies;
  CI: BuyingPowerCurrencies;
}

export interface SellingPower {
  long_ticker: string;
  available: SellingPowerResponse;
}

export enum Currencies {
  ARS = "ARS",
  USD = "USD",
  EXT = "EXT",
  EXT_ESP = "EXT_ESP",
}

export type BalanceCurrencies = Currencies.ARS | Currencies.USD;

export enum PortfolioFromType {
  BROKER = "BROKER",
  CRYPTO = "CRYPTO",
}

export interface MappedHoldingsType {
  [key: string]: PortfolioTicker[];
}

export interface PortfolioTotal {
  total?: PortfolioTotalApi;
  available?: {
    ars: number;
    usd: number;
  };
}
