import React, { useEffect, useState } from "react";

import API from "apis";
import dayjs from "dayjs";
import { TickersResponse } from "interfaces/api-responses";
import { BondData, Instruments } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { NumberFormatValues } from "react-number-format";
import { useAppSelector } from "hooks/redux";
import { useSearchParams } from "react-router-dom";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { Spinner } from "components/common/spinner";
import { trackAction } from "utils/amplitude";
import { calculatorService, marketService, publicService } from "apis/services";

import AmountInput from "./AmountInput";
import BondAlertModal from "./BondAlertModal";
import BondExchangeRateInput from "./BondExchangeRateInput";
import BondPrice from "./BondPrice";
import BondSelector from "./BondSelector";
import CalculateButton from "./CalculateButton";
import DateSelector from "./DateSelector";
import Error from "./Error";
import Header from "./Header";
import SettlementButtons from "./SettlementButtons";

import styles from "./styles.module.scss";

export enum SettlementCodes {
  _1 = "1",
  _2 = "2",
  _3 = "3",
  OTHER = "otro",
}

const bondListCache = {
  ttl: 60 * 60 * 1000,
};

const bondCache = {
  ttl: 90 * 1000,
};

const BondCalculator: React.FC = () => {
  const { dolarMep } = useAppSelector((state: RootState) => state.markets);
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedBond = searchParams.get("bond");
  const bondTypeParam = searchParams.get("bondType");

  const currentScreen = useAppSelector(getCurrentScreen);

  const bondType =
    bondTypeParam === Instruments.BONOS_CORP ||
    bondTypeParam === Instruments.BONOS_PUBLICOS
      ? bondTypeParam
      : Instruments.BONOS_PUBLICOS;

  const [amount, setAmount] = useState<number>(100);
  const [exchangeRate, setExchangeRate] = useState<number>(
    dolarMep.open.ask || 0
  );
  const [price, setPrice] = useState<number>(0.0);
  const [open, setOpen] = useState<boolean>(false);
  const [settlementCode, setSettlementCode] = useState<SettlementCodes>(
    SettlementCodes._3
  );
  const [settlementDate, setSettlementDate] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [bondList, setBondList] = useState<BondData[]>();
  const [currency, setCurrency] = useState<Currencies>(Currencies.ARS);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedBondData, setSelectedBondData] = useState<TickersResponse[]>();
  const [bondCurrentData, setBondCurrentData] = useState<TickersResponse>();

  const mainBond = bondList?.find((bond) =>
    selectedBondData?.some(
      (selectedBond) => selectedBond.short_ticker === bond.short_ticker
    )
  );

  const getBondsFlowList = async (
    type: Instruments.BONOS_CORP | Instruments.BONOS_PUBLICOS
  ) => {
    try {
      setIsLoading(true);
      setError(false);

      const { data } = await API.get<BondData[]>(
        calculatorService.bondFlowList,
        {
          params: {
            instrument_type: type,
          },
          cache: bondListCache,
        }
      );

      if (!selectedBond) {
        setSearchParams({ bondType: type, bond: data[0].short_ticker });
      }

      if (data) {
        setBondList(data);
      }
    } catch (error: any) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getBondData = async () => {
    try {
      setIsLoading(true);
      setError(false);

      const bond =
        selectedBond || (bondList && bondList[0].short_ticker) || "AL30";

      const { data } = await API.get<TickersResponse[]>(
        `${marketService.tickers}/${bond}?segment=C`,
        {
          cache: bondCache,
        }
      );

      setSelectedBondData(data);
    } catch (error: any) {
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const getInitialData = async () => {
      await getBondsFlowList(bondType);
      await getBondData();
    };
    getInitialData();
  }, []);

  useEffect(() => {
    if (!bondList) return;
    getBondsFlowList(bondType);
  }, [bondType]);

  useEffect(() => {
    if (!selectedBond) return;

    getBondData();
  }, [selectedBond]);

  useEffect(() => {
    if (!selectedBondData) return;

    const bondsWithCurrency = selectedBondData.filter((bond) =>
      bond.long_ticker.includes(currency)
    );

    if (!bondsWithCurrency) {
      setBondCurrentData(selectedBondData[0]);
      return;
    }
    const actualBond = bondsWithCurrency.find((bond) =>
      bond.long_ticker.includes(
        `000${
          settlementCode === SettlementCodes.OTHER
            ? SettlementCodes._3
            : settlementCode
        }`
      )
    );

    if (!actualBond) {
      setBondCurrentData(bondsWithCurrency[0]);
      return;
    }

    setBondCurrentData(actualBond);
  }, [currency, settlementCode, selectedBondData]);

  useEffect(() => {
    if (!bondCurrentData) return;

    setPrice(
      bondCurrentData.asks[0].price ||
        bondCurrentData.last ||
        bondCurrentData.close
    );
  }, [bondCurrentData]);

  useEffect(() => {
    if (dayjs().day() === 3 || dayjs().day() === 4) {
      setSettlementDate(dayjs().add(4, "days").format("YYYY-MM-DD"));
    } else {
      setSettlementDate(dayjs().add(2, "days").format("YYYY-MM-DD"));
    }

    if (exchangeRate) return;

    const getExchangeRate = async () => {
      try {
        const { data } = await API.get(publicService.mepPrices);
        setExchangeRate(data.open.ask);
      } catch (error) {
        setExchangeRate(0);
      }
    };

    getExchangeRate();
  }, []);

  const handleChangeBondType = (
    event: React.MouseEvent<HTMLElement>,
    value: Instruments.BONOS_CORP | Instruments.BONOS_PUBLICOS
  ) => {
    event.stopPropagation();

    trackAction(`${currentScreen} - Change bond type`, { bondType: value });
    setSearchParams({ bondType: value });
  };

  const handleChangeBond = (value?: string) => {
    if (!value) return;

    trackAction(`${currentScreen} - Select bond`, { bond: value });
    setSearchParams({ bondType, bond: value });
  };

  const handleChangeCurrency = (newValue: Currencies) => {
    trackAction(`${currentScreen} - Change currency`, { currency: newValue });
    setCurrency(newValue);
  };

  const handleChangeExchangeRate = ({ floatValue }: NumberFormatValues) => {
    if (!floatValue) return;

    setExchangeRate(floatValue);
  };

  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nonNumbersRegex = /[^0-9]/g;
    setAmount(Number(event.target.value.replace(nonNumbersRegex, "")));
  };

  const handleChangePrice = ({ floatValue }: NumberFormatValues) => {
    if (!floatValue) return;

    setPrice(floatValue);
  };

  const handleChangeDate = (newDate?: string) => {
    if (!newDate) return;

    trackAction(`${currentScreen} - Change settlement date`);

    setSettlementDate(newDate);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const retryOnError = () => {
    if (!bondList) {
      getBondsFlowList(bondType);
      return;
    }

    getBondData();
  };

  if (isLoading)
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner />
      </div>
    );

  if (error || !bondList || !selectedBondData || !bondCurrentData)
    return <Error onRetry={retryOnError} />;

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Header bondType={bondType} handleChangeType={handleChangeBondType} />
        <BondSelector
          isLoading={isLoading}
          selectedBond={selectedBond || bondList[0].short_ticker}
          defaultBondOpt={bondList[0].short_ticker}
          handleChangeBond={handleChangeBond}
          bondList={bondList}
        />
        <BondPrice
          price={price}
          currency={currency}
          bondCurrentData={bondCurrentData}
          handleChangePrice={handleChangePrice}
          handleChangeCurrency={handleChangeCurrency}
        />
        <BondExchangeRateInput
          currency={currency}
          exchangeRate={exchangeRate}
          selectedBond={mainBond}
          handleChangeExchangeRate={handleChangeExchangeRate}
        />
        <AmountInput
          amount={amount}
          handleChangeQuantity={handleChangeQuantity}
        />
        <SettlementButtons
          settlementCode={settlementCode}
          setSettlementDate={setSettlementDate}
          setSettlementCode={setSettlementCode}
        />
        <DateSelector
          settlementDate={settlementDate}
          settlementCode={settlementCode}
          handleChangeDate={handleChangeDate}
        />
      </div>
      <CalculateButton
        price={price}
        amount={amount}
        setOpen={setOpen}
        currency={currency}
        mainBond={mainBond}
        isLoading={isLoading}
        exchangeRate={exchangeRate}
        setIsLoading={setIsLoading}
        settlementDate={settlementDate}
      />
      <BondAlertModal isOpen={open} onClickMainButton={closeModal} />
    </div>
  );
};

export default BondCalculator;
