import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { CashbackIcon } from "assets/icons/cashback";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";

import "./index.scss";

interface SuccessProps {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  textPrimaryButton: string;
  textSecondaryButton?: string;
  urlPrimaryButton?: string;
  urlSecondaryButton?: string;
  onClickSecondaryButton?: () => void;
  onClickPrimaryButton?: () => void;
  loadingSecondaryButton?: boolean;
  icon?: JSX.Element;
  displayMepCloseDisclaimer?: boolean;
  trackingEvent?: () => void;
}

const Success: React.FC<SuccessProps> = ({
  title,
  subtitle,
  textPrimaryButton,
  textSecondaryButton,
  urlPrimaryButton,
  onClickPrimaryButton,
  onClickSecondaryButton,
  urlSecondaryButton,
  loadingSecondaryButton = false,
  icon,
  displayMepCloseDisclaimer,
  trackingEvent,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClickSecondaryButton = () => {
    if (onClickSecondaryButton) {
      onClickSecondaryButton();
    }
    if (urlSecondaryButton) {
      navigate(urlSecondaryButton);
    }
  };

  const handleClickPrimaryButton = () => {
    if (onClickPrimaryButton) {
      onClickPrimaryButton();
    }
    if (urlPrimaryButton) {
      navigate(urlPrimaryButton, {
        state: { from: location.pathname },
      });
    }
  };

  useEffect(() => {
    if (trackingEvent) {
      trackingEvent();
    }
  }, []);

  return (
    <div className="component-success">
      <div className="success">
        {icon ? icon : <CashbackIcon />}
        <Typography component="p" variant="heading4">
          {title}
        </Typography>
        <Typography component="p" variant="textL">
          {subtitle}
        </Typography>
        {displayMepCloseDisclaimer && (
          <Typography
            component="span"
            variant="textL_bold"
            className="mepDisclaimer"
          >
            Tus pesos ya están disponibles.
          </Typography>
        )}
      </div>
      <div className="buttons-wrapper">
        <ButtonUI
          size={ButtonSizes.Full}
          variant={ButtonVariants.Primary}
          onClick={handleClickPrimaryButton}
          id={textPrimaryButton.replace(" ", "-").toLowerCase()}
        >
          <Typography variant="buttonL">{textPrimaryButton}</Typography>
        </ButtonUI>
        {textSecondaryButton && (
          <ButtonUI
            disabled={loadingSecondaryButton}
            size={ButtonSizes.Full}
            variant={ButtonVariants.Secondary}
            onClick={handleClickSecondaryButton}
            id={textSecondaryButton.split(" ").join("-").toLowerCase()}
          >
            <Typography variant="buttonL">
              {loadingSecondaryButton ? (
                <CircularProgress />
              ) : (
                textSecondaryButton
              )}
            </Typography>
          </ButtonUI>
        )}
      </div>
    </div>
  );
};

export default Success;
