interface PropTypes {
  size?: number;
  color?: string;
}

export const ArrowRigth = ({ color, size }: PropTypes) => {
  return (
    <svg
      className="arrow-rigth-icon"
      fill="none"
      height={`${size ? size : 24}`}
      viewBox={`0 0 24 24`}
      width={`${size ? size : 24}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 18L20 12L14 6"
        stroke={color || "#002C65"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};
