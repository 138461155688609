import { useEffect, useState } from "react";

import Heading, { HeadingVariants } from "components/common/Heading";
import { CryptoPrice } from "interfaces/api-responses";

import { DailyTrendOptions } from "../utils";
import AssetsList from "./AssetsList";
import ChipList from "./ChipsList";

import styles from "./styles.module.scss";

interface DailyTrendsProps {
  pricesData?: CryptoPrice[];
}

const DailyTrends: React.FC<DailyTrendsProps> = ({ pricesData }) => {
  const [dailyTrendFilter, setDailyTrendFilter] = useState<DailyTrendOptions>(
    DailyTrendOptions.GAIN
  );
  const [dailyTrendAssets, setDailyTrendAssets] = useState<CryptoPrice[]>([]);

  useEffect(() => {
    if (pricesData) {
      const isGain = dailyTrendFilter === DailyTrendOptions.GAIN;

      const filteredData = pricesData.filter((crypto) =>
        isGain
          ? crypto.varDailyPricePercentage >= 0
          : crypto.varDailyPricePercentage < 0
      );

      const sortedData = filteredData
        .sort((a, b) =>
          isGain
            ? b.varDailyPricePercentage - a.varDailyPricePercentage
            : a.varDailyPricePercentage - b.varDailyPricePercentage
        )
        .slice(0, 3);

      setDailyTrendAssets(sortedData);
    }
  }, [pricesData, dailyTrendFilter]);

  return (
    <div className={styles.dailyTrendsContainer}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h3"
        color="var(--slate800)"
      >
        Tendencias diarias
      </Heading>
      <ChipList
        selectedChip={dailyTrendFilter}
        onClickChip={setDailyTrendFilter}
      />
      <AssetsList assetsList={dailyTrendAssets} />
    </div>
  );
};

export default DailyTrends;
