import { Typography } from "@mui/material";
import AlertModal from "components/common/alert-modal";
import { VscWarning } from "react-icons/vsc";

interface BondAlertModalProps {
  isOpen: boolean;
  onClickMainButton: () => void;
}

const BondAlertModal: React.FC<BondAlertModalProps> = ({
  isOpen,
  onClickMainButton,
}) => (
  <AlertModal
    alertIcon={<VscWarning color="red" size={30} />}
    bodyText={
      <>
        <Typography component="p" variant="textL_bold">
          Por favor asegurese, de cargar todos los datos antes de continuar...
        </Typography>
      </>
    }
    headingText={
      <Typography component="p" variant="heading4">
        Datos incompletos!
      </Typography>
    }
    open={isOpen}
    primaryButtonText="OK"
    onClickMainButton={onClickMainButton}
  />
);

export default BondAlertModal;
