import { useEffect } from "react";

import { PortfolioScreen, usePortfolio } from "context/PortfolioProvider";
import { ThemeVariants } from "interfaces/theme";
import CryptoPage from "layouts/CryptoPageLayout";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import mainRoutes, { CryptoRoutes } from "router/routes";
import { getLoggedUser } from "store/selectors/user.selector";

import CurrencyBalanceDetail from "../CurrencyBalanceDetail";
import PortfolioContent from "../PortfolioContent";
import PortfolioError from "../PortfolioError";

import styles from "./styles.module.scss";

const CryptoPortfolioPage = () => {
  const {
    selectedCurrencyDetail,
    portfolioScreen,
    selectedCurrency,
  } = usePortfolio();
  const user = useSelector(getLoggedUser);
  const navigate = useNavigate();

  const cryptoVariant = ThemeVariants.Crypto;

  const isPortfolioScreen = portfolioScreen === PortfolioScreen.PORTFOLIO;
  const isPortfolioError = portfolioScreen === PortfolioScreen.PORTFOLIO_ERROR;

  useEffect(() => {
    document.documentElement.classList.add("newStyles");

    return () => {
      document.documentElement.classList.remove("newStyles");
    };
  }, []);

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  if (isPortfolioError)
    return (
      <PortfolioError
        variant={ThemeVariants.Crypto}
        onCloseError={() => navigate(CryptoRoutes.HOME)}
      />
    );

  return (
    <CryptoPage className={styles.pagesPortfolio}>
      {isPortfolioScreen ? (
        <PortfolioContent
          selectedCurrency={selectedCurrency}
          user={user}
          variant={cryptoVariant}
        />
      ) : (
        <CurrencyBalanceDetail
          selectedCurrencyDetail={selectedCurrencyDetail}
          variant={cryptoVariant}
        />
      )}
    </CryptoPage>
  );
};

export default CryptoPortfolioPage;
