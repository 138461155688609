import { ReactNode } from "react";

import BottomNavigation from "components/common/BottomNavigation";
import HomeHeader from "components/common/HomeHeader";
import { ThemeVariants } from "interfaces/theme";

interface HomeLayoutProps {
  className?: string;
  children: ReactNode;
  setIsNoAccountSheetOpen: (isOpen: boolean) => void;
  variant: ThemeVariants.Capital | ThemeVariants.Crypto;
}

const HomeLayout: React.FC<HomeLayoutProps> = ({
  variant,
  children,
  className,
  setIsNoAccountSheetOpen,
}) => {
  return (
    <div className={className}>
      <HomeHeader
        variant={variant}
        setIsNoAccountSheetOpen={setIsNoAccountSheetOpen}
      />
      {children}
      <BottomNavigation variant={variant} />
    </div>
  );
};

export default HomeLayout;
