import Text, { TextVariants } from "components/common/Text";
import { formatToCurrency } from "utils";
import { Operations } from "interfaces/crypto/enums";
import HiddenValue from "components/common/HiddenValue";

interface SecondaryInstrumentProps {
  operationType:
    | Operations.BUY
    | Operations.SELL
    | Operations.SWAP
    | Operations.REDEMPTION
    | Operations.SUBSCRIPTION;
  baseAmount: number;
  baseInstrument: string;
  quoteAmount: number;
  isCapital: boolean;
  isBalanceHidden?: boolean;
}

const SecondaryInstrument: React.FC<SecondaryInstrumentProps> = ({
  operationType,
  isCapital,
  isBalanceHidden = false,
  ...props
}) => {
  const { quoteAmount, baseInstrument, baseAmount } = props;
  const formatSign = (type: Operations) => {
    if (type === Operations.BUY) return "+";
    if (type === Operations.SELL || type === Operations.REDEMPTION) return "-";

    return "";
  };

  const mainColor = "var(--slate800)";
  const capitalLabel = `${formatSign(operationType)} ${Number(
    quoteAmount.toFixed(2)
  )} ${baseInstrument}`;

  if (isBalanceHidden) {
    return <HiddenValue size={7} color="var(--slate800)" count={4} />;
  }

  if (isCapital) {
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        {capitalLabel}
      </Text>
    );
  }

  if (operationType === Operations.SWAP)
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        {formatToCurrency(baseAmount)} {baseInstrument}
      </Text>
    );

  if (operationType === Operations.SELL)
    return (
      <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
        $ {formatToCurrency(quoteAmount)}
      </Text>
    );

  return (
    <Text variant={TextVariants.RegularUpperValueS} color={mainColor}>
      $ {formatToCurrency(quoteAmount)}
    </Text>
  );
};

export default SecondaryInstrument;
