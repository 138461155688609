import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import { cryptoService, walletService } from "apis/services";
import { PerformancePeriods } from "components/page-cocos-portfolio/utils";
import { CryptoPerformance } from "interfaces/api-responses";
import type { ErrorMessage } from "interfaces/errors";
import { Instruments } from "interfaces/markets";
import type { PerformanceQueryResponse } from "interfaces/portfolio";
import { PeriodTime } from "interfaces/portfolio";

export const fetchPerformanceData = async (
  period: PeriodTime,
  isCrypto: boolean
) => {
  const isDaily = period === PeriodTime.DAY;

  if (isCrypto) {
    const period = isDaily
      ? PerformancePeriods.DAILY
      : PerformancePeriods.HISTORIC;

    const { data } = await API.get<CryptoPerformance[]>(
      `${cryptoService.performance}/${period}`
    );

    const transformedData = data.map((item) => ({
      instrument_code: item.ticker,
      instrument_short_name: item.ticker,
      instrument_type: Instruments.ACCIONES,
      short_ticker: item.ticker,
      quantity: item.quantity,
      last: 1,
      result: item.revenue,
      average_price: 1,
      result_percentage: item.revenuePercentage,
      logo_file_name: "",
    }));
    return { tickers: transformedData };
  }
  const endpoint = isDaily
    ? walletService.dailyPerformance
    : walletService.historicPerformance;

  const { data } = await API.get(endpoint);

  if (!isDaily) {
    return { tickers: data };
  }

  return data;
};

export const usePerformanceQuery = (period: PeriodTime, isCrypto: boolean) => {
  return useAppQuery<PerformanceQueryResponse, ErrorMessage>({
    queryKey: () => portfolioKeys.performance(period, isCrypto),
    queryFn: () => fetchPerformanceData(period, isCrypto),
    options: {
      onError: () => {
        return {
          message: "Hubo un error al cargar los rendimiento",
          status: 500,
        };
      },
    },
  });
};
