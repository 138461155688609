import { Typography } from "@mui/material";
import { Currencies } from "interfaces/wallet";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";
import { compactNumber, formatPercentage, getCurrencyLabel } from "utils";

import styles from "./styles.module.scss";

interface PropTypes {
  value: number;
  withBackground?: boolean;
  isPercentage?: boolean;
  currency?: Currencies;
  newClass?: string;
  numOfDecimals?: number;
}

const UIVariationLabel = (props: PropTypes) => {
  const {
    value,
    withBackground = false,
    isPercentage = false,
    currency = Currencies.ARS,
    newClass,
    numOfDecimals,
  } = props;
  const isPositive = value > 0;
  const isNegative = value < 0;

  const getValueString = () => {
    let newValue;
    let valueToFormat: string | number = value;
    if (isNaN(value)) {
      valueToFormat = 0;
    }
    if (isPercentage) {
      newValue = formatPercentage(valueToFormat, false);
      return newValue;
    }
    valueToFormat = compactNumber(valueToFormat, numOfDecimals);
    newValue = `${getCurrencyLabel(currency)} ${valueToFormat}`;
    return newValue;
  };

  const getClass = () => {
    if (isPositive) {
      if (withBackground) return styles.positiveBackground;
      return styles.positive;
    }
    if (isNegative) {
      if (withBackground) return styles.negativeBackground;
      return styles.negative;
    }
    if (withBackground) return styles.grayBackground;
    return styles.gray;
  };

  return (
    <div className={styles.variationLabelContainer}>
      <Typography
        className={`${styles.variationLabel} ${getClass()} ${newClass}`}
      >
        {isPercentage && (
          <Typography component={"span"}>
            {isPositive ? <MdArrowUpward /> : <MdArrowDownward />}
          </Typography>
        )}
        &nbsp;
        {getValueString().replace(".", ",")}
      </Typography>
    </div>
  );
};

export default UIVariationLabel;
