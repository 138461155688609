import Heading, { HeadingVariants } from "components/common/Heading";
import CocosBrand from "assets/icons/CocosBrand";
import { getFCIIconColor } from "components/page-cocos-pay/utils";
import px2rem from "utils/px2rem";
import Variation from "components/common/CurrentVariation";
import { useAppState } from "context/AppStateProvider";
import { ThemeVariants } from "interfaces/theme";
import AvailableLabel from "components/common/AvailableLabel";
import type { CocosFund } from "components/page-capital-funds/helpers";
import { FundsTotalHolding } from "interfaces/fci";

import styles from "./styles.module.scss";

interface FundsHeaderProps {
  fund: CocosFund;
  holding: FundsTotalHolding;
}

const FundsHeader = ({ fund, holding }: FundsHeaderProps) => {
  const { isBalanceHidden } = useAppState();
  const { name, ticker, currency } = fund;

  return (
    <div className={styles.fundsHeaderWrapper}>
      <div className={styles.fundsHeading}>
        <CocosBrand color={getFCIIconColor(ticker)} size={px2rem(32)} />
        <Heading
          component="h2"
          variant={HeadingVariants.RegularTitle}
          color="var(--slate800)"
        >
          {name}
        </Heading>
      </div>
      <AvailableLabel
        currency={currency}
        available={holding.value}
        variant={ThemeVariants.Capital}
      />
      <Variation
        currency={currency}
        value={holding.lastProfit.percentage}
        amount={holding.lastProfit.amount}
        hideBalances={isBalanceHidden}
        text="último rendimiento"
      />
    </div>
  );
};

export default FundsHeader;
