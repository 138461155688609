import API from "apis";
import { useAppQuery } from "apis/queryClient";
import { cryptoKeys } from "apis/queryKeyFactories";
import { cryptoService } from "apis/services";
import { AxiosError } from "axios";
import { CryptoPrice } from "interfaces/api-responses";

const ONE_MINUTE = 60000;

const fetchPrices = async () => {
  const { data } = await API.get<CryptoPrice[]>(cryptoService.prices);

  return data;
};

export const usePricesQuery = () => {
  return useAppQuery<CryptoPrice[], AxiosError>({
    queryKey: () => cryptoKeys.prices,
    queryFn: fetchPrices,
    options: {
      onError: (e: AxiosError) => {
        return e;
      },
      retry: false,
      refetchInterval: ONE_MINUTE,
      staleTime: ONE_MINUTE,
    },
  });
};
