import { ReactNode } from "react";

import { useNavigate } from "react-router-dom";
import GoogleTagManager from "react-gtm-module";
import { trackAnalyticsEvent, AnalyticsEvents } from "utils/firebaseAnalytics";
import { closeRightModal } from "features/rightModal/rightModalSlice";
import { trackFbEvent, FbEvents } from "utils/fbTracking";
import ButtonUI, {
  ButtonSizes,
  ButtonVariants,
} from "components/common/button";
import mainRoutes from "router/routes";
import { useAppDispatch } from "hooks/redux";

import styles from "./styles.module.scss";

interface OpenAccountButtonProps {
  buttonSize: ButtonSizes;
  children: ReactNode;
}

const OpenAccountButton: React.FC<OpenAccountButtonProps> = ({
  buttonSize,
  children,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleRedirect = () => {
    GoogleTagManager.dataLayer({
      dataLayer: { event: "wapp_home_clic_abrir_comitente" },
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.fbq("track", "wapp_home_clic_abrir_comitente");

    trackAnalyticsEvent(AnalyticsEvents.HOME_CLIC_ABRIR_COMITENTE);
    trackFbEvent(FbEvents.HOME_CLIC_ABRIR_COMITENTE);

    navigate(mainRoutes.createAccount);
    dispatch(closeRightModal());
  };

  return (
    <div className={styles.button_container}>
      <ButtonUI
        size={buttonSize}
        variant={ButtonVariants.Primary}
        onClick={handleRedirect}
      >
        {children}
      </ButtonUI>
    </div>
  );
};

export default OpenAccountButton;
