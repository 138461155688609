import { TickersRulesResponse } from "interfaces/api-responses";
import { Instruments, MarketSection } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import { formatToCurrency, getCurrencyFormatted } from "utils";

interface Formas {
  singular: string;
  plural: string;
}

const instrumentMapping: Record<Instruments, Formas> = {
  CEDEARS: { singular: "Cedear", plural: "Cedears" },
  BONOS_PUBLICOS: { singular: "Bono", plural: "Bonos" },
  BONOS_CORP: { singular: "Bono", plural: "Bonos" },
  ACCIONES: { singular: "Accion", plural: "Acciones" },
  LETRAS: { singular: "Letra", plural: "Letras" },
  FCI: { singular: "Fondo", plural: "Fondos" },
  CAUCION: { singular: "Caucion", plural: "Cauciones" },
};

export const getNominalValue = (
  type?: Instruments,
  isSingular: boolean = false
): string => {
  if (!type) {
    return isSingular ? "Nominal" : "Nominales";
  }
  const formas = instrumentMapping[type];
  return isSingular ? formas.singular : formas.plural;
};

export const getMinToOperateMessage = (
  minQuantity: number,
  minAmount: number,
  currency: Currencies,
  type: Instruments
) => {
  const isSingular = minQuantity === 1;
  const errorMessage = `La operación mínima permitida es de ${formatToCurrency(
    minQuantity,
    true
  )} ${getNominalValue(
    type,
    isSingular
  ).toLowerCase()} (Monto estimado ${getCurrencyFormatted(
    currency,
    minAmount
  )})`;

  return errorMessage;
};

export const getNumOfDecimals = (
  tickerRules?: TickersRulesResponse,
  id_tick_size_rule?: string,
  tick_size?: number
) => {
  let numOfDecimals = 2;

  if (tickerRules && id_tick_size_rule && tick_size) {
    const rule = tickerRules[id_tick_size_rule].find(
      (r) => tick_size === r.tick_size
    );
    if (rule) numOfDecimals = rule?.num_decimals;
  }
  return numOfDecimals;
};

export const getIsCableEnabled = (type: Instruments | MarketSection) => {
  return type === Instruments.BONOS_PUBLICOS || type === Instruments.BONOS_CORP;
};
