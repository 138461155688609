import { Currencies } from "interfaces/wallet";
import classNames from "classnames";
import dayjs from "dayjs";

import Heading, { HeadingVariants } from "../Heading";
import Text, { TextVariants } from "../Text";
import Amount from "../Amount";

import styles from "./styles.module.scss";

interface MovementHeaderProps {
  date: string;
  text?: string;
  currency: Currencies;
  amount: number;
  className?: string;
  isBalanceHidden?: boolean;
}

const MovementHeader = ({
  date,
  text,
  currency,
  amount,
  className,
  isBalanceHidden,
}: MovementHeaderProps) => {
  const today = dayjs().format("YYYY-MM-DD");
  const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");

  const formatDate = (date: string): string => {
    if (date === today) return "Hoy";
    if (date === yesterday) return "Ayer";

    return dayjs(date).locale("es").format("D [de] MMM");
  };

  return (
    <div className={classNames(styles.movementHeadingContainer, className)}>
      <Heading
        variant={HeadingVariants.RegularSubtitle}
        component="h3"
        color="var(--slate800)"
      >
        {formatDate(date)}
      </Heading>
      {text && (
        <div className={styles.movementHeadingBalance}>
          <Text variant={TextVariants.RegularTextS} color="var(--slate800)">
            {text}
          </Text>
          <Amount
            amount={amount}
            currency={currency}
            isSmallCurrency
            isBalanceHidden={isBalanceHidden}
          />
        </div>
      )}
    </div>
  );
};

export default MovementHeader;
