import { useEffect, useState } from "react";

import { CircularProgress, IconButton, Typography } from "@mui/material";
import { ArrowLeftIcon } from "assets/icons/arrow-left";
import { CandleChart } from "assets/icons/candle-chart";
import { FillStar } from "assets/icons/favorite/fill-star";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import BondCalculator from "components/common/BondCalculator";
import { ConfirmOrder } from "components/common/confirm-order";
import FCIMarketDetailTrade from "components/common/fci-detail-trade";
import MarketDetailTradeCommon from "components/common/market-detail-trade";
import SearchBarComp from "components/common/search-bar";
import { Orders } from "components/page-market/page-market-detail/mobile/orders";
import { InstrumentData } from "interfaces/api-responses";
import { BookSocketResponse } from "interfaces/api-responses/socket/book";
import { Instruments, OrderType } from "interfaces/markets";
import { Currencies } from "interfaces/wallet";
import DrawerLayout from "layouts/drawer";
import { isEmpty } from "lodash";
import { BsCalculator } from "react-icons/bs";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import mainRoutes, { screenNames } from "router/routes";
import { socket } from "socket";
import { checkFlow } from "features/calculator/calculatorSlice";
import { setCurrentScreen } from "features/global/globalSlice";
import {
  cleanHistoricalData,
  clearShortTickerMarketData,
  resetOrder,
  setMarketDataDetail,
  updateOrderOptions,
  getHistoricalDataByLongTicker,
  getMarketDetailByShortTicker,
  getOrderById,
  toggleInList,
} from "features/markets";
import { addTickerToSubscribeList } from "features/prices/capitalPricesSlice";
import {
  closeRightModal,
  openRightModal,
} from "features/rightModal/rightModalSlice";
import { getPortfolio } from "features/wallet/portfolioSlice";
import { clearSellingPower } from "features/wallet/walletSlice";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { getLoggedUser } from "store/selectors/user.selector";
import { useAppState } from "context/AppStateProvider";
import { UIChartLine } from "components/common/charts/line";
import { SearchTypes } from "components/common/search-bar-ui";
import {
  calculateNetAmount,
  getComisiones,
  getCurrencyLabel,
  getFormattedNumber,
} from "utils";
import { trackScreen } from "utils/amplitude";
import { intervalsOptions } from "utils/constants";
import { getColor } from "utils/getColor";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import { OrderDetailSidebarDesktop } from "../order-detail-sidebar";

import "./index.scss";

interface MarketDetailSidebarDesktopProps {
  id: string;
  ticker: InstrumentData;
}

const MarketDetailSidebarDesktop: React.FC<MarketDetailSidebarDesktopProps> = ({
  id,
  ticker,
}) => {
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { openRightModalContent } = useAppState();

  const { marketDetailLoading } = useAppSelector(
    (state: RootState) => state.markets
  );

  const { shortTickerData } = useAppSelector(
    (state: RootState) => state.markets
  );

  const [market, setMarket] = useState(ticker);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [fciRescueAlmostTotal, setFciRescueAlmostTotal] = useState(false);
  const order = useAppSelector((state: RootState) => state.markets.newOrder);

  const [selectedInterval, setselectedInterval] = useState("1A");

  const user = useAppSelector(getLoggedUser);

  const marketHistoricData = useAppSelector(
    (state: RootState) => state.markets.marketHistoricData
  );
  const orderLoading = useAppSelector(
    (state: RootState) => state.markets.getOrderLoading
  );
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);
  const portfolio = useAppSelector(
    (state: RootState) => state.portfolio.holdings
  );

  const currentScreen = useAppSelector(getCurrentScreen);

  const [isFavorite, setIsFavorite] = useState(
    shortTickerData.some((tickerData) => tickerData.isFavorite)
  );

  useEffect(() => {
    setIsFavorite(shortTickerData.some((tickerData) => tickerData.isFavorite));
  }, [shortTickerData]);

  const typeOrAction = market.instrument_type ?? Instruments.ACCIONES;
  const isBond =
    market.instrument_type === Instruments.BONOS_PUBLICOS ||
    market.instrument_type === Instruments.BONOS_CORP;
  const isFCI = market.instrument_type === Instruments.FCI;

  const [orderDetail, setOrderDetail] = useState<string>();
  const [socketData, setSocketData] = useState<BookSocketResponse>();
  const comisiones = getComisiones(
    market.instrument_type,
    market?.instrument_subtype,
    order.type,
    order.price && order.quantity
      ? calculateNetAmount(
          order.price,
          order.quantity,
          market?.contract_size,
          market?.price_factor
        )
      : calculateNetAmount(
          ((order.amount || 0) / (order.quantity || 1)) *
            (market?.price_factor || 1),
          order.quantity || 1,
          market?.contract_size,
          market?.price_factor
        )
  );

  useEffect(() => {
    const actualScreen = isFCI ? screenNames.fci : screenNames.newOrder;
    const trackProps = {
      ticker: market.long_ticker,
      instrumentType: market.instrument_type,
    };
    trackScreen(actualScreen, currentScreen, trackProps);
    dispatch(setCurrentScreen(actualScreen));
    dispatch(getPortfolio());

    return () => {
      trackScreen(currentScreen, actualScreen);
      dispatch(setCurrentScreen(currentScreen));
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(
        getMarketDetailByShortTicker({
          short_ticker: id,
          instrument_type: market.instrument_type,
        })
      );
    }
    if (
      shortTickerData &&
      shortTickerData.length > 0 &&
      shortTickerData[0]?.instrument_code !== id
    ) {
      dispatch(clearShortTickerMarketData());
    }

    if (order && !order.instrument_code?.includes(id)) {
      dispatch(cleanHistoricalData());
      dispatch(clearSellingPower());

      if (market) {
        dispatch(
          resetOrder({
            long_ticker: market.long_ticker,
            instrument_code: market.instrument_code,
          })
        );
      } else {
        dispatch(resetOrder());
      }
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (
      shortTickerData.length > 0 &&
      shortTickerData.some((s) => s.instrument_code === id)
    ) {
      const selectedMarket =
        shortTickerData[0].instrument_type === Instruments.FCI
          ? shortTickerData[0]
          : shortTickerData.filter(
              (m) => m.term === order.term && m.currency === order.currency
            )[0];

      if (selectedMarket) {
        dispatch(
          getHistoricalDataByLongTicker({
            long_ticker: selectedMarket.long_ticker,
          })
        );
        dispatch(setMarketDataDetail(selectedMarket));
      }
    }
  }, [shortTickerData, id, order.term, order.currency]);

  useEffect(() => {
    if (market.long_ticker && market.instrument_code) {
      if (isBond) {
        dispatch(
          checkFlow({ instrument_code: market.instrument_code, invest: false })
        );
      }
      dispatch(addTickerToSubscribeList(market.long_ticker));
    }
  }, [id, market.long_ticker]);

  useEffect(() => {
    if (ticker.long_ticker !== market.long_ticker) {
      setMarket(ticker);
    }
  }, [ticker]);

  useEffect(() => {
    const messageHandler = (messageFromBackEnd: any) => {
      const message: BookSocketResponse = messageFromBackEnd;
      setSocketData(message);
    };
    socket.on("message", messageHandler);
    return () => {
      socket.off("message", messageHandler);
    };
  }, []);

  useEffect(() => {
    if (
      socketData &&
      socketData !== market &&
      (!market || socketData.long_ticker === market.long_ticker)
    ) {
      setMarket({ ...market, ...socketData });
      dispatch(setMarketDataDetail(socketData));
    }
  }, [socketData]);

  useEffect(() => {
    if (!openConfirmModal && market && market.instrument_code === id) {
      dispatch(setMarketDataDetail(market));
    }
  }, [market]);

  useEffect(() => {
    const instrument_short_name = market.instrument_short_name ?? undefined;

    const long_ticker =
      market.instrument_type === Instruments.FCI
        ? market.instrument_code
        : market.long_ticker;

    if (order && !order.instrument_code?.includes(id)) {
      dispatch(clearSellingPower());
      dispatch(cleanHistoricalData());

      if (
        market &&
        id === market?.instrument_code &&
        !isEmpty(shortTickerData)
      ) {
        dispatch(
          updateOrderOptions({
            ...order,
            long_ticker,
            instrument_code: market.instrument_code,
            instrument_short_name,
            currency: shortTickerData[0].currency,
          })
        );
      } else {
        dispatch(resetOrder());
      }
    }
    if (market && id === market?.instrument_code && !isEmpty(shortTickerData)) {
      dispatch(
        updateOrderOptions({
          ...order,
          long_ticker,
          instrument_code: market.instrument_code,
          instrument_short_name,
          currency: shortTickerData[0].currency,
        })
      );
    }
  }, [market?.instrument_code, market.long_ticker, shortTickerData]);

  const handleUpdateTicker = (ticker: any) => {
    setMarket(ticker);
  };

  const handleToggleOpenConfirmModal = (fciAlmostTotal?: boolean) => {
    setFciRescueAlmostTotal(fciAlmostTotal ?? false);
    setOpenConfirmModal(!openConfirmModal);
  };

  if (!user) return <Navigate to={mainRoutes.logout} replace />;

  const handleToogleAssetInList = async (ev: any) => {
    ev.stopPropagation();
    setIsFavorite(!isFavorite);
    if (market.long_ticker) {
      await toggleInList(
        isFavorite,
        user.favoritesLists[0].id_list,
        market.long_ticker
      );
    }
  };

  const handleChangeInterval = (interval: string) => () => {
    if (market.long_ticker) {
      setselectedInterval(interval);
      dispatch(
        getHistoricalDataByLongTicker({
          long_ticker: market.long_ticker,
          interval,
        })
      );
    }
  };

  const redirectMarketDetail = () => {
    navigate(`${mainRoutes.market}/${market.instrument_type}/${id}`, {
      state: market,
    });
    dispatch(closeRightModal());
  };

  const handleCloseSidebar = () => {
    dispatch(closeRightModal());
  };

  const handleClickOrderDetail = () => {
    if (order.id) {
      dispatch(getOrderById(order.id.toString()));
      setOrderDetail(order.id.toString());
    }
  };

  const handleClickOrderRow = (orderId: string) => {
    dispatch(getOrderById(orderId));
  };

  const openCalcu = () => (
    <DrawerLayout goBack={() => navigate(mainRoutes.market)}>
      <BondCalculator />
    </DrawerLayout>
  );

  const handleCalcu = () => {
    openRightModalContent(openCalcu());
    dispatch(
      openRightModal({
        onClose: () => setSearchParams({}),
      })
    );
    if (!market.instrument_type) return;

    if (market.instrument_code)
      setSearchParams({
        bondType: String(market.instrument_type),
        bond: market.instrument_code,
      });
  };

  if (openConfirmModal) {
    if (orderDetail && !orderLoading) {
      return (
        <OrderDetailSidebarDesktop
          id={orderDetail}
          onCloseSidebar={handleCloseSidebar}
        />
      );
    } else {
      let data;
      const isHardcodedMessage =
        market.currency === Currencies.USD &&
        market.instrument_type === "BONOS_PUBLICOS" &&
        market.short_ticker &&
        (market.short_ticker.startsWith("AL") ||
          market.short_ticker.startsWith("GD"));
      if (!isFCI) {
        if (isHardcodedMessage) {
          data = [
            {
              label: "Especie",
              value: `${market.long_ticker?.split("-")[0]}${
                market?.instrument_short_name
                  ? ` | ${market.instrument_short_name}`
                  : ""
              }`,
            },
            {
              label: "Plazo de liquidación",
              value: order.term || "24hs",
            },
            {
              label: order.method === "limite" ? "Precio límite" : "Precio",
              value:
                order.method === "limite"
                  ? getFormattedNumber(
                      order.price,
                      market?.id_tick_size_rule,
                      market?.tick_size
                    )
                  : "Mercado",
            },
            {
              label: "Cantidad de Nominales",
              value: order.quantity ? Math.floor(order.quantity) : "",
            },
            {
              label:
                order.type === OrderType.Buy
                  ? "Monto a Invertir"
                  : "Monto a recibir",
              value: `${getCurrencyLabel(
                order.currency
              )} ${comisiones[0].toFixed(2)}`,
            },
            {
              label:
                order.type === OrderType.Buy
                  ? "Estimado a debitar"
                  : "Estimado a acreditar",
              value: `${getCurrencyLabel(order.currency)} ${(
                comisiones[0] + comisiones[1]
              ).toFixed(2)}`,
            },
          ];
        } else {
          data = [
            {
              label: "Especie",
              value: `${market.long_ticker?.split("-")[0]}${
                market?.instrument_short_name
                  ? ` | ${market.instrument_short_name}`
                  : ""
              }`,
            },
            {
              label: "Plazo de liquidación",
              value: order.term || "24hs",
            },
            {
              label: order.method === "limite" ? "Precio límite" : "Precio",
              value:
                order.method === "limite"
                  ? getFormattedNumber(
                      order.price,
                      market?.id_tick_size_rule,
                      market?.tick_size
                    )
                  : "Mercado",
            },
            {
              label: "Cantidad de Nominales",
              value: order.quantity ? Math.floor(order.quantity) : "",
            },
            {
              label:
                order.type === OrderType.Buy
                  ? "Monto a Invertir"
                  : "Monto a recibir",
              value: `${getCurrencyLabel(
                order.currency
              )} ${comisiones[0].toFixed(2)}`,
            },
            {
              label: "Comisiones Estimadas",
              value: `${getCurrencyLabel(
                order.currency
              )} ${comisiones[1].toFixed(2)}`,
            },
            {
              label:
                order.type === OrderType.Buy
                  ? "Estimado a debitar"
                  : "Estimado a acreditar",
              value: `${getCurrencyLabel(order.currency)} ${(
                comisiones[0] + comisiones[1]
              ).toFixed(2)}`,
            },
          ];
        }
      } else {
        data = [
          {
            label: "Fondo",
            value: `${market.long_ticker?.split("-")[0]} | ${
              market?.instrument_short_name
            }`,
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Monto a Invertir"
                : "Monto a recibir",
            value: `${getCurrencyLabel(order.currency)} ${order.amount?.toFixed(
              2
            )}`,
          },
          {
            label: "Comisiones estimadas",
            value: `${getCurrencyLabel(order.currency)} ${comisiones[1].toFixed(
              2
            )}`,
          },
          {
            label:
              order.type === OrderType.Buy
                ? "Estimado a debitar"
                : "Estimado a acreditar",
            value: `${getCurrencyLabel(order.currency)} ${(
              order.amount + comisiones[1]
            ).toFixed(2)} `,
          },
        ];
      }
      return (
        <ConfirmOrder
          data={data}
          onClickOrderDetail={handleClickOrderDetail}
          onClose={handleCloseSidebar}
          instrument_type={typeOrAction}
          infoLabel={
            fciRescueAlmostTotal
              ? "Dado el monto ingresado, se realizará un rescate por el total de cuotapartes."
              : undefined
          }
        />
      );
    }
  } else
    return (
      <div className="market-detail-sidebar">
        <div className="wrapper">
          {!market ? (
            <div className="loading">
              <CircularProgress size={40} />
            </div>
          ) : (
            <div>
              <div
                className={`comp-desktop-market-detail ${isFCI ? "fci" : ""}`}
              >
                <div className="go-to-detail">
                  <div
                    className="expandir"
                    id="expandir"
                    onClick={redirectMarketDetail}
                  >
                    <ArrowLeftIcon />
                    <Typography variant="buttonS">Expandir</Typography>
                    <CandleChart size={20} />
                  </div>
                </div>
                <div className="header">
                  <SearchBarComp
                    classes="search-quick-purchase"
                    isHeaderSearchBar
                    marketDetail
                    placeholder={""}
                    type={SearchTypes.Primary}
                    value={market.short_ticker}
                  />
                  <div className="header-actions-buttons">
                    {gotFlow && isBond && (
                      <IconButton onClick={handleCalcu}>
                        <BsCalculator
                          className="icon-calculadora"
                          color={getColor("--text-color-primary")}
                          size={26}
                        />
                      </IconButton>
                    )}
                    {!isFavorite && (
                      <IconButton onClick={handleToogleAssetInList}>
                        <TransparentStar size={26} />
                      </IconButton>
                    )}
                    {isFavorite && (
                      <IconButton onClick={handleToogleAssetInList}>
                        <FillStar color="#0062E1" size={26} />
                      </IconButton>
                    )}
                  </div>
                </div>

                {isFCI && (
                  <FCIMarketDetailTrade
                    onClickContinueTrade={handleToggleOpenConfirmModal}
                  />
                )}
                {!isFCI && (
                  <MarketDetailTradeCommon
                    market={market}
                    portfolio={portfolio}
                    onClickContinueTrade={handleToggleOpenConfirmModal}
                    onChangeCurrency={handleUpdateTicker}
                    onChangeSettlement={handleUpdateTicker}
                  />
                )}
                <Orders onClickOrderRow={handleClickOrderRow} />
                <div className="chart-wrapper">
                  <UIChartLine
                    id_tick_size_rule={market.id_tick_size_rule}
                    loading={marketDetailLoading}
                    name={market.short_ticker}
                    prices={marketHistoricData?.prices || []}
                    tick_size={market.tick_size}
                  />
                  <div className="intervals-wrapper">
                    {intervalsOptions.map((i) => (
                      <div
                        key={i}
                        className={`interval ${
                          selectedInterval === i ? "selected" : ""
                        }`}
                        onClick={handleChangeInterval(i)}
                      >
                        <Typography variant="textS">{i}</Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
};

export default MarketDetailSidebarDesktop;
