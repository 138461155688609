interface PropTypes {
  size?: number;
  color?: string;
}
export const ErrorIcon = (props: PropTypes) => {
  const { color, size } = props;

  return (
    <svg
      fill="none"
      height={`${size ? size : 48}`}
      viewBox="0 0 48 48"
      width={`${size ? size : 48}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M24 2C11.8 2 2 11.8 2 24C2 36.2 11.8 46 24 46C36.2 46 46 36.2 46 24C46 11.8 36.2 2 24 2ZM24 42C14 42 6 34 6 24C6 14 14 6 24 6C34 6 42 14 42 24C42 34 34 42 24 42ZM26.8 24L31.4 19.4C32.2 18.6 32.2 17.4 31.4 16.6C30.6 15.8 29.4 15.8 28.6 16.6L24 21.2L19.4 16.6C18.6 15.8 17.4 15.8 16.6 16.6C15.8 17.4 15.8 18.6 16.6 19.4L21.2 24L16.6 28.6C15.8 29.4 15.8 30.6 16.6 31.4C17 31.8 17.4 32 18 32C18.6 32 19 31.8 19.4 31.4L24 26.8L28.6 31.4C29 31.8 29.4 32 30 32C30.6 32 31 31.8 31.4 31.4C32.2 30.6 32.2 29.4 31.4 28.6L26.8 24Z"
        fill="#EB0A3A"
        fillRule="evenodd"
      />
      <mask
        height={`${size ? size - 4 : 44}`}
        id="mask0_1438_25166"
        maskUnits="userSpaceOnUse"
        width={`${size ? size - 4 : 44}`}
        x="2"
        y="2"
      >
        <path
          clipRule="evenodd"
          d="M24 2C11.8 2 2 11.8 2 24C2 36.2 11.8 46 24 46C36.2 46 46 36.2 46 24C46 11.8 36.2 2 24 2ZM24 42C14 42 6 34 6 24C6 14 14 6 24 6C34 6 42 14 42 24C42 34 34 42 24 42ZM26.8 24L31.4 19.4C32.2 18.6 32.2 17.4 31.4 16.6C30.6 15.8 29.4 15.8 28.6 16.6L24 21.2L19.4 16.6C18.6 15.8 17.4 15.8 16.6 16.6C15.8 17.4 15.8 18.6 16.6 19.4L21.2 24L16.6 28.6C15.8 29.4 15.8 30.6 16.6 31.4C17 31.8 17.4 32 18 32C18.6 32 19 31.8 19.4 31.4L24 26.8L28.6 31.4C29 31.8 29.4 32 30 32C30.6 32 31 31.8 31.4 31.4C32.2 30.6 32.2 29.4 31.4 28.6L26.8 24Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_1438_25166)">
        <rect
          fill={color || "#EB0A3A"}
          height={size || 48}
          width={size || 48}
        />
      </g>
    </svg>
  );
};
