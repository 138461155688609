import { OrderBookSide } from "components/common/order-book-side";
import { Bid } from "interfaces/api-responses/socket/book";
import _ from "lodash";

import styles from "./styles.module.scss";
interface PropTypes {
  bids: Bid[];
  asks: Bid[];
  numOfDecimals: number;
}

export const OrderBook = (props: PropTypes) => {
  const { bids, asks, numOfDecimals } = props;

  const totalBids = bids.reduce((total, obj) => total + obj.size, 0);
  const totalAsks = asks.reduce((total, obj) => total + obj.size, 0);

  const maxAcumulate = _.max([totalBids, totalAsks]) ?? totalBids;

  return (
    <div className={styles.orderBook}>
      <div className={styles.orderBookData}>
        <OrderBookSide
          data={bids}
          total={maxAcumulate}
          numOfDecimals={numOfDecimals}
        />
        <OrderBookSide
          data={asks}
          isBid={false}
          total={maxAcumulate}
          numOfDecimals={numOfDecimals}
        />
      </div>
    </div>
  );
};
