import { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import CapitalReceivePage from "components/page-capital-receive";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { OrderType } from "interfaces/markets";
import Drawer from "layouts/drawer";
import { useNavigate } from "react-router";
import { RootState } from "store/store";
import mainRoutes from "router/routes";
import { useAppSelector } from "hooks/redux";
import { useAppState } from "context/AppStateProvider";

import styles from "./styles.module.scss";

interface TradingErrorProps {
  instrumentProp: any;
}

const TradingError: React.FC<TradingErrorProps> = ({ instrumentProp }) => {
  const navigate = useNavigate();
  const { openRightModalContent } = useAppState();
  const { isMobile } = useDeviceCheck();
  const order = useAppSelector((state: RootState) => state.markets.newOrder);
  const isBuy = order.type === OrderType.Buy;

  const buyWallet = useAppSelector((state: RootState) => state.wallet.wallet);
  const sellWallet = useAppSelector(
    (state: RootState) => state.wallet.sellingPower
  );

  const currency: "ars" | "usd" | "ext" =
    (instrumentProp.currency && instrumentProp.currency.toLowerCase()) ?? "ars";

  const buyingPower = +(+(buyWallet?.["24hs"]?.[currency] ?? 0));
  const sellingPower = +(sellWallet?.available?.["24hs"] ?? 0);

  const maxToOperate = isBuy ? buyingPower : sellingPower;

  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>("");

  const noBuyingPowerError = () => {
    return (
      <Typography id={styles.errorMessage} onClick={depositFunds}>
        No tenés suficiente saldo para hacer esta operación.
        <br />
        <b>Ingresá dinero acá</b>
      </Typography>
    );
  };

  const insufficientAssets = () => {
    return (
      <Typography id={styles.errorMessage}>
        No tenés activos suficientes para realizar la operación.
      </Typography>
    );
  };

  useEffect(() => {
    handleCheckError();
  }, [order.type, order.amount, order.quantity, sellWallet, buyWallet]);

  const handleCheckError = () => {
    if (isBuy) {
      if (buyingPower <= 0) {
        setErrorMessage(noBuyingPowerError());
        return;
      }
      if (order.amount === 0 || order.amount === undefined) {
        setErrorMessage("");
        return;
      }
      if (order.amount > maxToOperate) {
        setErrorMessage(noBuyingPowerError());
        return;
      }
      setErrorMessage("");
    } else {
      if (order.quantity === 0 || order.quantity === undefined) {
        setErrorMessage("");
        return;
      }
      if (order.quantity > maxToOperate) {
        setErrorMessage(insufficientAssets());
        return;
      }
      setErrorMessage("");
    }
  };

  const depositFunds = () => {
    if (isMobile) {
      navigate(mainRoutes.receive);
    } else {
      openRightModalContent(
        <Drawer>
          <CapitalReceivePage isDesktop />
        </Drawer>
      );
    }
  };

  return <div>{errorMessage}</div>;
};

export default TradingError;
