import { ampli } from "ampli";
import { fetchBalance } from "apis/portfolio/queries/useBalance";
import { fetchPortfolioData } from "apis/portfolio/queries/usePortfolioQuery";
import { queryClient } from "apis/queryClient";
import { portfolioKeys } from "apis/queryKeyFactories";
import CurrencySwitch from "components/common/CurrencySwtich";
import Text, { TextVariants } from "components/common/Text";
import { usePortfolio } from "context/PortfolioProvider";
import { ThemeVariants } from "interfaces/theme";
import {
  BalanceCurrencies,
  Currencies,
  PortfolioFromType,
} from "interfaces/wallet";

import styles from "./styles.module.scss";

export enum SelectedTab {
  RETURNS = "Returns",
  VALUE = "Value",
}

interface PortfolioBalanceProps {
  selectedTab: SelectedTab;
  selectedCurrency: BalanceCurrencies;
  setSelectedCurrency: (currency: BalanceCurrencies) => void;
  variant?: ThemeVariants;
}

const PortfolioBalance = ({
  selectedTab,
  selectedCurrency,
  setSelectedCurrency,
  variant = ThemeVariants.Capital,
}: PortfolioBalanceProps) => {
  if (variant === ThemeVariants.Crypto) return null;

  const portfolioFrom =
    variant === ThemeVariants.Capital
      ? PortfolioFromType.BROKER
      : PortfolioFromType.CRYPTO;

  const { selectedPeriod } = usePortfolio();

  const handleClickSwitch = async () => {
    const switchOption =
      selectedCurrency === Currencies.ARS ? Currencies.USD : Currencies.ARS;

    ampli.portfolioCurrencySwitched({
      tab: selectedTab,
      currency: switchOption,
    });
    setSelectedCurrency(switchOption);

    await queryClient.fetchQuery(
      portfolioKeys.portfolio(switchOption, portfolioFrom),
      () => fetchPortfolioData(switchOption, portfolioFrom)
    );
    await queryClient.fetchQuery(
      portfolioKeys.balance(switchOption, selectedPeriod),
      () =>
        fetchBalance({
          currency: switchOption,
          period: selectedPeriod,
        })
    );
  };

  return (
    <div className={styles.switchAmount}>
      <Text
        variant={TextVariants.RegularTextL}
        color="var(--slate800)"
        className={styles.switchAmountText}
      >
        Expresado en
      </Text>
      <CurrencySwitch
        selectedCurrency={selectedCurrency}
        onClickSwitch={handleClickSwitch}
      />
    </div>
  );
};

export default PortfolioBalance;
