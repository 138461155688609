import FilledButton from "components/common/FilledButton";
import Heading, { HeadingVariants } from "components/common/Heading";
import Text, { TextVariants } from "components/common/Text";
import { useDeviceCheck } from "hooks/useDeviceCheck";
import { ThemeVariants } from "interfaces/theme";
import { ArrowLeft } from "lucide-react";

import styles from "./styles.module.scss";

interface CompositionInfoProps {
  onButtonClick: () => void;
  variant?: ThemeVariants;
}

const CompositionInfo = ({
  onButtonClick,
  variant = ThemeVariants.Capital,
}: CompositionInfoProps) => {
  const { isMobile } = useDeviceCheck();

  const COMPOSITION_INFO = [
    {
      title: "Hoy (Contado Inmediato)",
      description:
        "Es el dinero que tenés disponible para extraer de Cocos u operar ahora mismo.",
    },
    {
      title: "En 24hs",
      description:
        "Es el dinero que tendrás disponible el próximo día hábil cuando se liquiden tus órdenes operadas a 24hs.",
    },
    {
      title: "Más de 24hs",
      description:
        "Es el dinero que tendrás disponible cuando se liquiden tus órdenes operadas a más de 24hs.",
    },
  ];

  return (
    <div className={styles.compositionInfoContainer}>
      <div>
        {!isMobile && (
          <div>
            <ArrowLeft
              color="var(--slate900)"
              onClick={onButtonClick}
              className={styles.compositionArrowBack}
            />
          </div>
        )}
        <Heading
          component="h2"
          variant={HeadingVariants.RegularSubtitle}
          color="var(--slate900)"
          className={styles.compositionTitle}
        >
          Composición por plazo
        </Heading>
        <div className={styles.compositionDetailBoxWrapper}>
          {COMPOSITION_INFO.map(({ title, description }) => (
            <div key={title} className={styles.compositionDetailBox}>
              <Heading
                component="h2"
                variant={HeadingVariants.RegularSubtitle}
                color="var(--slate900)"
              >
                {title}
              </Heading>
              <Text
                variant={TextVariants.RegularValueS}
                color="var(--slate800)"
              >
                {description}
              </Text>
            </div>
          ))}
        </div>
      </div>
      <FilledButton variant={variant} onClick={onButtonClick}>
        Entendido
      </FilledButton>
    </div>
  );
};

export default CompositionInfo;
