import React, { useEffect, useState } from "react";

import { IconButton, Toolbar, Typography } from "@mui/material";
import { DownArrow } from "assets/icons/down-icon";
import { FillStar } from "assets/icons/favorite/fill-star";
import { TransparentStar } from "assets/icons/favorite/transparent-star";
import SearchModal from "components/common/search-modal";
import { Instruments } from "interfaces/markets";
import { BsCalculator } from "react-icons/bs";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { screenNames } from "router/routes";
import { resetOrder } from "features/markets/marketsSlice";
import { toggleInList } from "features/markets/marketsActions";
import { RootState } from "store/store";
import { getCurrentScreen } from "features/global/globalSlice";
import { trackAction } from "utils/amplitude";
import { capitalizeEachWord } from "utils/index";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import "./index.scss";

interface PropTypes {
  title?: string;
  subtitle?: string | null;
  assetToolbar: boolean;
  search?: (v: string) => void;
  goBack?: () => void;
  showGoBack?: boolean;
  isBond?: boolean;
  quickPurchase?: boolean;
}

const MobileHeader = ({
  title,
  subtitle,
  assetToolbar = false,
  goBack,
  showGoBack = true,
  isBond = false,
  quickPurchase,
}: PropTypes) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const favoritesLists = useAppSelector(
    (state: RootState) => state.user.user?.favoritesLists
  );
  const { marketDetail } = useAppSelector((state: RootState) => state.markets);

  const [openSearchModal, setOpenSearchModal] = useState(false);
  const gotFlow = useAppSelector((state: RootState) => state.calculator.check);
  const currentScreen = useAppSelector(getCurrentScreen);
  const darkMode = useAppSelector(
    (state: RootState) => state.user.user?.dark_theme
  );

  const [isFavorite, setIsFavorite] = useState(marketDetail?.isFavorite);

  useEffect(() => {
    if (!marketDetail) return;
    setIsFavorite(marketDetail.isFavorite);
  }, [marketDetail]);

  const handleGoBack = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (goBack) {
      goBack();
    } else {
      navigate(-1);
    }
  };
  const handleToogleSearchModal = (market_data?: any) => {
    if (!openSearchModal) {
      trackAction(`${currentScreen} - Open search`);
    }
    setOpenSearchModal(!openSearchModal);
    if (market_data && quickPurchase) {
      dispatch(resetOrder());
      trackAction(`${screenNames.newOrder} - Open search`);
      navigate(
        `/market/${market_data.instrument_type}/${market_data.instrument_code}`,
        market_data
      );
    }
  };

  const handleToogleAssetInList = async (ev: any) => {
    if (!favoritesLists) return;
    ev.stopPropagation();
    const trackProps = {
      type: isFavorite ? "remove" : "add",
      ticker: marketDetail?.long_ticker,
    };
    trackAction(
      `${
        screenNames[
          marketDetail?.instrument_type === Instruments.FCI ? "fci" : "newOrder"
        ]
      } - Add Favorites`,
      trackProps
    );
    setIsFavorite(!isFavorite);
    if (marketDetail) {
      await toggleInList(
        !!isFavorite,
        favoritesLists[0].id_list,
        marketDetail.long_ticker
      );
    }
  };

  const handleCalcu = () => {
    const trackProps = {
      ticker: marketDetail?.long_ticker,
    };
    trackAction(`${screenNames.newOrder} - Open Bond Calculator`, trackProps);

    navigate(
      `/calculadora?bondType=${marketDetail?.instrument_type}&bond=${marketDetail?.instrument_code}`
    );
  };

  return (
    <Toolbar className="header-mobile-content" variant="dense">
      {showGoBack && (
        <IconButton
          aria-label="menu"
          className="icon-button-header"
          color="inherit"
          edge="start"
          onClick={handleGoBack}
        >
          <IoArrowBack />
        </IconButton>
      )}
      {assetToolbar && (
        <>
          <div
            className={`title-sidebar ${
              showGoBack ? "with-goback" : "without-goback"
            }`}
          >
            <div className="titles">
              <Typography className="title-header" variant="heading4">
                {title}
              </Typography>
              <Typography className="subtitle-header" variant="textXS">
                {subtitle && capitalizeEachWord(subtitle)}
              </Typography>
            </div>
            <IconButton onClick={handleToogleSearchModal}>
              <DownArrow color="#0062E1" />
            </IconButton>
            <SearchModal
              handleToggleDisplayModal={handleToogleSearchModal}
              open={openSearchModal}
              quickPurchase={quickPurchase}
            />
          </div>
          <div>
            {isBond && gotFlow && (
              <IconButton onClick={handleCalcu}>
                <BsCalculator
                  className="icon-calculadora"
                  color={darkMode ? "#ffffff" : "#002C65"}
                  size={20}
                />
              </IconButton>
            )}
            {!isFavorite && (
              <IconButton
                className="no-favorite"
                id="favorite-star"
                onClick={handleToogleAssetInList}
              >
                <TransparentStar color="#0062E1" size={20} />
              </IconButton>
            )}
            {isFavorite && (
              <IconButton
                className="favorite"
                onClick={handleToogleAssetInList}
                id="favorite-start-fill"
              >
                <FillStar color="#0062E1" size={20} />
              </IconButton>
            )}
          </div>
        </>
      )}
      {!assetToolbar && (
        <Typography className="title-header" variant="heading4">
          {title}
        </Typography>
      )}
    </Toolbar>
  );
};

export default MobileHeader;
